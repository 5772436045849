import React from 'react';
import clsx from 'clsx';
import { Tooltip, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'relative',

        '&.size-xs': {
          width: 34,
          height: 12,
          '& svg': {
            [theme.breakpoints.down('lg')]: {
              width: 10,
            },
          },
        },
      },
      statItem: {
        minWidth: 96,
        height: 40,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 16,
        padding: 8,
        cursor: ({ selectable }) => (selectable ? 'pointer' : 'default'),
        [theme.breakpoints.down('lg')]: {
          minWidth: 64,
          height: 30,
          marginLeft: 8,
          padding: 4,
        },
        '&:first-child': {
          marginLeft: 0,
        },
        '& svg': {
          width: 24,
          [theme.breakpoints.down('lg')]: {
            width: 14,
          },
        },
        '&.error': {
          background: 'rgba(236, 84, 74, 0.07)',
          '& .stroke path': {
            stroke: '#EC544A',
          },
          '& .fill path': {
            fill: '#EC544A',
          },
          '& p': {
            color: '#EC544A',
          },
        },
        '&.success': {
          background: 'rgba(66, 189, 93, 0.07)',
          '& .stroke path': {
            stroke: '#42BD5D',
          },
          '& .fill path': {
            fill: '#42BD5D',
          },
          '& p': {
            color: '#42BD5D',
          },
        },
        '&.primary': {
          background: 'rgba(2, 44, 230, 0.05)',
          '& .stroke path': {
            stroke: '#022CE6',
          },
          '& .fill path': {
            fill: '#022CE6',
          },
          '& p': {
            color: '#022CE6',
          },
        },
        '&.neutral': {
          background: 'rgba(138, 138, 138, 0.07)',
          '& .stroke path': {
            stroke: '#8A8A8A',
          },
          '& .fill path': {
            fill: '#8A8A8A',
          },
          '& p': {
            color: '#8A8A8A',
          },
        },
        '&.orange': {
          background: 'rgba(255, 165, 0, 0.07)',
          '& .stroke path': {
            stroke: theme.palette.secondary.dark,
          },
          '& .fill path': {
            fill: theme.palette.secondary.dark,
          },
          '& p': {
            color: theme.palette.secondary.dark,
          },
        },
      },
      selected: {
        borderWidth: 2,
        borderStyle: 'solid',
        '&.primary': {
          borderColor: '#022CE6',
        },
        '&.error': {
          borderColor: '#EC544A',
        },
        '&.success': {
          borderColor: '#42BD5D',
        },
        '&.neutral': {
          borderColor: '#8A8A8A',
        },
        '&.orange': {
          borderColor: theme.palette.secondary.dark,
        },
      },
      number: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '24px',
        marginLeft: 8,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    }),
  {
    name: 'StatisticItem',
  }
);

const shouldBeDisplayed = (item) => !Number.isNaN(item) && typeof item !== 'undefined';

// color - primary | success | error | neutral
// for using colors in SVG add className in SVG component - fill | stroke
const StatisticItem = ({ icon, number, color, className, tooltip, selected, onSelect }) => {
  const classes = useStyles({ selectable: !!onSelect });

  if (!shouldBeDisplayed(number)) return null;

  return (
    <Tooltip title={tooltip} disableHoverListener={!tooltip}>
      <div
        onClick={() => {
          if (onSelect) {
            onSelect(!selected);
          }
        }}
        className={clsx(classes.statItem, color, className, {
          [classes.selected]: selected,
        })}
      >
        {icon}
        <Typography className={classes.number}>{number}</Typography>
      </div>
    </Tooltip>
  );
};

export { StatisticItem };
