import preparedReducer from './reducer';
import {
  requestMobilePatrolEmailSettings,
  enableMobilePatrolEmailSetting,
  disableMobilePatrolEmailSetting,
  requestMobilePatrolEmailRecipients,
  updateMobilePatrolEmailRecipients,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'mobilePatrolEmailSettings';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestMobilePatrolEmailSettings,
  enableMobilePatrolEmailSetting,
  disableMobilePatrolEmailSetting,
  requestMobilePatrolEmailRecipients,
  updateMobilePatrolEmailRecipients,
};
