import {
  TEXT_LINE_INPUT,
  TEXT_BOX_INPUT,
  CATEGORY_INPUT,
  LABEL_INPUT,
  SPACE_LAYOUT,
  DIVIDER_LAYOUT,
  COMMENT_LAYOUT,
  DATE_INPUT,
  DATE_TIME_INPUT,
  CLIENT_NAME_INPUT,
  CLIENT_ADDRESS_INPUT,
  SITE_NAME_INPUT,
  SITE_ADDRESS_INPUT,
  CREATED_BY_INPUT,
  APPROVED_BY_INPUT,
  CHECKBOXES_INPUT,
  RADIO_BUTTONS_INPUT,
  DROPDOWN_INPUT,
  PHOTOS_INPUT,
  SIGNATURE_INPUT,
  TEXT_GROUP_NAME,
  ORGANAZING_GROUP_NAME,
  LAYOUT_GROUP_NAME,
  DATE_TIME_GROUP,
  DATA_GROUP,
  AUTOFILL_GROUP,
  SELECTORS_GROUP,
  ATTACHMENTS_GROUP,
  OTHER_GROUP,
} from 'app/types/form-builder';

export const FORM_TYPE = {
  ONE_LEVEL: 'ONE_LEVEL',
  TWO_LEVEL: 'TWO_LEVEL',
};

export const FORM_TABS = {
  REVIEWER: 'REVIEWER_TAB',
  CREATOR: 'CREATOR_TAB',
};

export const FORM_USER_TYPE = {
  REVIEWER: 'REVIEWER',
  CREATOR: 'CREATOR',
};

export const FORM_USER_ACCESS = {
  FIRST_USER: 'FIRST_USER',
  ANYONE: 'ANYONE',
};

// Group list description
export const FORM_INPUT_GROUPS = [
  {
    name: TEXT_GROUP_NAME,
    items: [TEXT_LINE_INPUT, TEXT_BOX_INPUT],
  },
  {
    name: LAYOUT_GROUP_NAME,
    items: [COMMENT_LAYOUT, SPACE_LAYOUT, DIVIDER_LAYOUT],
  },
  {
    name: DATA_GROUP,
    items: [CLIENT_NAME_INPUT, CLIENT_ADDRESS_INPUT, SITE_NAME_INPUT, SITE_ADDRESS_INPUT],
  },
  {
    name: ORGANAZING_GROUP_NAME,
    items: [CATEGORY_INPUT, LABEL_INPUT],
  },
  {
    name: DATE_TIME_GROUP,
    items: [DATE_INPUT, DATE_TIME_INPUT],
  },
  {
    name: SELECTORS_GROUP,
    items: [CHECKBOXES_INPUT, RADIO_BUTTONS_INPUT, DROPDOWN_INPUT],
  },
  {
    name: AUTOFILL_GROUP,
    items: [CREATED_BY_INPUT, APPROVED_BY_INPUT],
  },
  {
    name: ATTACHMENTS_GROUP,
    items: [PHOTOS_INPUT],
  },
  {
    name: OTHER_GROUP,
    items: [SIGNATURE_INPUT],
  },
];
