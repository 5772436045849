import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import {
  taskSuccess,
  getTaskLogsSuccess,
  tasksSuccess,
  enableTaskSuccess,
  disableTaskSuccess,
} from './actions';

import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return initialState;
    },
    [getTaskLogsSuccess](state, { payload: { data, taskID } }) {
      return {
        ...state,
        logs: {
          [taskID]: data,
        },
      };
    },
    [taskSuccess](state, { payload: item }) {
      return {
        ...state,
        info: {
          ...state.info,
          [item.taskID]: item,
        },
      };
    },
    [enableTaskSuccess](state, { payload: taskID }) {
      return {
        ...state,
        info: {
          ...state.info,
          [taskID]: {
            ...state.info[taskID],
            active: true,
          },
        },
      };
    },
    [disableTaskSuccess](state, { payload: taskID }) {
      return {
        ...state,
        info: {
          ...state.info,
          [taskID]: {
            ...state.info[taskID],
            active: false,
          },
        },
      };
    },
    [tasksSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
  },
  initialState
);
