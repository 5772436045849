import { call } from 'redux-saga/effects';
import {
  PHOTOS_INPUT,
  SIGNATURE_INPUT,
  LABEL_INPUT,
  CHECKBOXES_INPUT,
  DATE_TIME_INPUT,
  DATE_INPUT,
} from 'app/types/form-builder';
import { MediaAssetTypes } from 'ducks/mediaAssets/types';
import { bulkUploadMediaAssetsSaga, uploadMediaAssetSaga } from 'ducks/mediaAssets/sagas';
import { formatDateTimeUTC, formatLocalDateTime } from 'util/formatting';
import { SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from 'app/types/constants';

const serializeValueByType = (type, value) => {
  switch (type) {
    case LABEL_INPUT:
    case CHECKBOXES_INPUT:
      return (value || []).join(';');

    case DATE_TIME_INPUT:
      return value ? formatDateTimeUTC(value, SERVER_DATE_TIME_FORMAT) : undefined;

    case DATE_INPUT:
      return value ? formatLocalDateTime(value, SERVER_DATE_FORMAT) : undefined;

    default:
      return value;
  }
};

export function* prepareFieldValueByType({ type, value, tableName, entityID }) {
  switch (type) {
    case PHOTOS_INPUT: {
      const uploadedPhotos = [];
      const newPhotos = [];
      (value || []).forEach((item) => {
        if (item.mediaAssetID) {
          uploadedPhotos.push(item);
        } else {
          newPhotos.push(item);
        }
      });

      const files = newPhotos.map((item) => ({
        fileUploaded: item.photo,
        mediaAssetInfo: {
          entityID,
          description: item.description,
          tableName: tableName,
          type: MediaAssetTypes.PHOTO,
        },
      }));

      const newMediaAssetIDs = yield call(bulkUploadMediaAssetsSaga, { payload: { files } });
      const uploadedMediaAssetIDs = uploadedPhotos.map((item) => item.mediaAssetID);

      return [...uploadedMediaAssetIDs, ...newMediaAssetIDs].join(';');
    }

    case SIGNATURE_INPUT: {
      const mediaAssetID = yield call(uploadMediaAssetSaga, {
        payload: {
          fileUploaded: value,
          mediaAssetInfo: {
            entityID,
            description: 'signature',
            tableName: tableName,
            type: MediaAssetTypes.PHOTO,
          },
        },
      });

      return mediaAssetID;
    }

    default:
      return serializeValueByType(type, value);
  }
}
