import { createFailure, createSuccess } from 'ducks/helpers';
import { createAction } from 'redux-actions';
import constants from './constants';

export const loginRequest = createAction(constants.login);
export const loginSuccess = createSuccess(constants.login);
export const loginFailure = createFailure(constants.login);
export const login = loginRequest;

export const acceptAgreementRequest = createAction(constants.acceptAgreement);
export const acceptAgreementSuccess = createSuccess(constants.logacceptAgreementin);
export const acceptAgreementFailure = createFailure(constants.acceptAgreement);
export const acceptAgreement = acceptAgreementRequest;

export const logoutRequest = createAction(constants.logout);
export const logoutSuccess = createSuccess(constants.logout);
export const logoutFailure = createFailure(constants.logout);
export const logout = logoutRequest;

export const showExpiredModel = createAction(`${constants.expiredModal}_SHOW`);
export const hideExpiredModel = createAction(`${constants.expiredModal}_HIDE`);

export const resetLogin = createAction(constants.resetLogin);

