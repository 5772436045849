import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const changePasswordRequest = createRequest(constants.users);
export const changePasswordSuccess = createSuccess(constants.users);
export const changePasswordFailure = createFailure(constants.users);

export const loginDetailsRequest = createRequest(`${constants.users}_LOGIN_DETAILS`);
export const loginDetailsSuccess = createSuccess(`${constants.users}_LOGIN_DETAILS`);
export const loginDetailsFailure = createFailure(`${constants.users}_LOGIN_DETAILS`);

export const updateLoginDetailsRequest = createRequest(`${constants.users}_UPDATE_LOGIN_DETAILS`);
export const updateLoginDetailsSuccess = createSuccess(`${constants.users}_UPDATE_LOGIN_DETAILS`);
export const updateLoginDetailsFailure = createFailure(`${constants.users}_UPDATE_LOGIN_DETAILS`);

export const updateUserStatusRequest = createRequest(`${constants.users}_UPDATE_USER_STATUS`);
export const updateUserStatusSuccess = createSuccess(`${constants.users}_UPDATE_USER_STATUS`);
export const updateUserStatusFailure = createFailure(`${constants.users}_UPDATE_USER_STATUS`);

export const getExcludedEventTypesRequest = createRequest(`${constants.users}_GET_EXCLUDED_TYPES`);
export const getExcludedEventTypesSuccess = createSuccess(`${constants.users}_GET_EXCLUDED_TYPES`);
export const getExcludedEventTypesFailure = createFailure(`${constants.users}_GET_EXCLUDED_TYPES`);

export const updateExcludedEventTypesRequest = createRequest(`${constants.users}_UPDATE_EXCLUDED_TYPES`);
export const updateExcludedEventTypesSuccess = createSuccess(`${constants.users}_UPDATE_EXCLUDED_TYPES`);
export const updateExcludedEventTypesFailure = createFailure(`${constants.users}_UPDATE_EXCLUDED_TYPES`);

export const createLoginDetailsRequest = createRequest(`${constants.users}_CREATE_LOGIN_DETAILS`);
export const createLoginDetailsSuccess = createSuccess(`${constants.users}_CREATE_LOGIN_DETAILS`);
export const createLoginDetailsFailure = createFailure(`${constants.users}_CREATE_LOGIN_DETAILS`);

export const createLoginDetails = createLoginDetailsRequest;
export const requestChangePassword = changePasswordRequest;
export const getLoginDetails = loginDetailsRequest;
export const updateLoginDetails = updateLoginDetailsRequest;
export const updateUserStatus = updateUserStatusRequest;
export const getExcludedEventTypes = getExcludedEventTypesRequest;
export const updateExcludedEventTypes = updateExcludedEventTypesRequest;
