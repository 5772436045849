import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const taskRequest = createRequest(constants.task);
export const taskSuccess = createSuccess(constants.task);
export const taskFailure = createFailure(constants.task);

export const tasksRequest = createRequest(constants.tasks);
export const tasksSuccess = createSuccess(constants.tasks);
export const tasksFailure = createFailure(constants.tasks);

export const createTaskRequest = createRequest(`${constants.task}_CREATE`);
export const createTaskSuccess = createSuccess(`${constants.task}_CREATE`);
export const createTaskFailure = createFailure(`${constants.task}_CREATE`);

export const updateTaskRequest = createRequest(`${constants.task}_UPDATE`);
export const updateTaskSuccess = createSuccess(`${constants.task}_UPDATE`);
export const updateTaskFailure = createFailure(`${constants.task}_UPDATE`);

export const enableTaskRequest = createRequest(`${constants.task}_ENABLE`);
export const enableTaskSuccess = createSuccess(`${constants.task}_ENABLE`);
export const enableTaskFailure = createFailure(`${constants.task}_ENABLE`);

export const disableTaskRequest = createRequest(`${constants.task}_DISABLE`);
export const disableTaskSuccess = createSuccess(`${constants.task}_DISABLE`);
export const disableTaskFailure = createFailure(`${constants.task}_DISABLE`);

export const deleteTaskRequest = createRequest(`${constants.task}_DELETE`);
export const deleteTaskSuccess = createSuccess(`${constants.task}_DELETE`);
export const deleteTaskFailure = createFailure(`${constants.task}_DELETE`);

export const getTaskLogsRequest = createRequest(`${constants.task}_LOGS`);
export const getTaskLogsSuccess = createSuccess(`${constants.task}_LOGS`);
export const getTaskLogsFailure = createFailure(`${constants.task}_LOGS`);

export const requestTask = taskRequest;
export const requestTasks = tasksRequest;
export const createTask = createTaskRequest;
export const updateTask = updateTaskRequest;
export const enableTask = enableTaskRequest;
export const disableTask = disableTaskRequest;
export const deleteTask = deleteTaskRequest;
export const getTaskLogs = getTaskLogsRequest;
