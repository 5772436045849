import { createFailure, createRequest, createSuccess, createAction } from 'ducks/helpers';
import constants from './constants';

export const activityIRRequest = createRequest(constants.activityIR);

export const activityIRSuccess = createSuccess(constants.activityIR);

export const activityIRFailure = createFailure(constants.activityIR);

export const requestActivityIR = activityIRRequest;
export const setRequestState = createAction(`${constants.activityIR}_IS_LOADING`);
