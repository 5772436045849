import _ from 'lodash';

const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    isoCode: 'ENG',
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Spanish',
    isoCode: 'SPA',
  },
  {
    languageId: 'ro',
    locale: 'ro',
    name: 'Romanian',
    isoCode: 'RON',
  },
  {
    languageId: 'hr',
    locale: 'hr',
    name: 'Croatian',
    isoCode: 'HRV',
  },
  {
    languageId: 'th',
    locale: 'th',
    name: 'Thai',
    isoCode: 'THA',
  },
  {
    languageId: 'pt',
    locale: 'pt',
    name: 'Portuguese',
    isoCode: 'POR',
  },
];

export default _.sortBy(languageData, 'name');
