import { handleActions } from 'redux-actions';
import { accountInfoSuccess } from './actions';
import initialState from './states';
import _ from 'lodash';

export default handleActions(
  {
    [accountInfoSuccess](state, { payload }) {
      if (_.isEqual(state, payload)) {
        return state;
      }

      return Object.freeze(payload);
    },
  },
  initialState,
);
