export default Object.freeze({
  type: null,

  employees: [],
  activity: null,

  selectedSite: null,
  selectedShift: null,
  selectedMU: null,

  activeOnly: null,

  mapSites: [],
  mapPatrols: [],
});
