import { createAction } from 'ducks/helpers';
import constants from './constants';
import { parseAction } from './selectors';
import { initialState, STATES } from './states';

export default function actionStatusReducer(state = {}, { type, payload, meta = {} }) {
  if (type === `${createAction(constants.reset)}`) {
    const { actionID } = parseAction(`${payload}`) || {};

    state[actionID] = initialState;
    return {
      ...state,
    };
  }

  const { actionID, requestState } = parseAction(type) || {};

  if (!actionID) {
    return state;
  }

  if (STATES.enumValueOf(requestState) === STATES.REQUEST) {
    state[actionID] = {
      ...initialState,
      requestState,
      requestParams: payload,
    };
    return {
      ...state,
    };
  }

  if (STATES.enumValueOf(requestState) === STATES.FAILURE) {
    try {
      meta.errors = JSON.parse(payload.message);
    } catch (e) {
      meta.errors = [payload?.toString()];
    }
  }

  state[actionID] = {
    ...state[actionID],
    requestState,
    errors: meta.errors || [],
    warnings: meta.warnings || [],
    payload: payload,
  };

  return {
    ...state,
  };
}
