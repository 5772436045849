import { handleActions } from 'redux-actions';

import { mobilePatrolEmailRecipientsSuccess, mobilePatrolEmailSettingsSuccess } from './actions';

import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [mobilePatrolEmailSettingsSuccess](state, { payload: { mobilePatrolID, settings } }) {
      return {
        ...state,
        settings: {
          ...state.settings,
          [mobilePatrolID]: settings,
        },
      };
    },
    [mobilePatrolEmailRecipientsSuccess](state, { payload: { recipients } }) {
      return {
        ...state,
        recipients,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
