import isEqual from 'react-fast-compare';
import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { codesSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [codesSuccess](state, { payload: { type, codes } }) {
      if (isEqual(state[type], codes)) {
        return state;
      }

      return {
        ...state,
        [type]: Object.freeze(codes),
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  {
    initialState,
  }
);
