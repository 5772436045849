import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const scheduleRequest = createRequest(constants.schedule);
export const scheduleSuccess = createSuccess(constants.schedule);
export const scheduleFailure = createFailure(constants.schedule);

export const createScheduleRequest = createRequest(constants.createSchedule);
export const createScheduleSuccess = createSuccess(constants.createSchedule);
export const createScheduleFailure = createFailure(constants.createSchedule);

export const updateScheduleRequest = createRequest(constants.updateSchedule);
export const updateScheduleSuccess = createSuccess(constants.updateSchedule);
export const updateScheduleFailure = createFailure(constants.updateSchedule);

export const requestSchedule = scheduleRequest;
export const createSchedule = createScheduleRequest;
export const updateSchedule = updateScheduleRequest;
