const MediaAssetTypes = {
  PHOTO: 'photo',
  VOICE_MEMO: 'voice_m4a',
  PLAN: 'plan',
  PDF: 'pdf',
};

const MediaAssetTables = {
  NOTEBOOK_NOTES: 'notebook_notes',
  INCIDENT_REPORTS: 'incident_report',
  INFO_NOTES: 'info_notes',
  EMPLOYEES: 'employees',
  CHECKPOINT_PLAN: 'checkpoints',
  TASKS: 'tasks',
  SITES: 'sites',
  AREAS: 'areas',
  FORMS: 'forms',
  CLIENTS: 'clients',
  CONTACTS: 'contacts',
  MOBILE_PATROLS: 'mobile_patrols',
};

const MediaAssetCategories = {
  ATTACHMENT: 'attachment',
  AVATAR: 'avatar',
};

export {
  MediaAssetTables,
  MediaAssetTypes,
  MediaAssetCategories,
};
