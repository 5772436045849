import { createAction, createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const addField = createAction(constants.addProfileField);
export const updateBuilderFields = createAction(constants.updateProfileBuilderFields);

export const profileFieldsRequest = createRequest(constants.profileFields);
export const profileFieldsSuccess = createSuccess(constants.profileFields);
export const profileFieldsFailure = createFailure(constants.profileFields);

export const profileFieldResultsRequest = createRequest(constants.profileFieldResults);
export const profileFieldResultsSuccess = createSuccess(constants.profileFieldResults);
export const profileFieldResultsFailure = createFailure(constants.profileFieldResults);

export const updateProfileFieldsRequest = createRequest(constants.updateProfileFields);
export const updateProfileFieldsSuccess = createSuccess(constants.updateProfileFields);
export const updateProfileFieldsFailure = createFailure(constants.updateProfileFields);

export const updateProfileFieldResultsRequest = createRequest(constants.updateProfileFieldResults);
export const updateProfileFieldResultsSuccess = createSuccess(constants.updateProfileFieldResults);
export const updateProfileFieldResultsFailure = createFailure(constants.updateProfileFieldResults);

export const requestProfileFields = profileFieldsRequest;
export const requestProfileFieldResults = profileFieldResultsRequest;
export const updateProfileFields = updateProfileFieldsRequest;
export const updateProfileFieldResults = updateProfileFieldResultsRequest;
