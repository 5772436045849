import { all, put, takeLatest } from 'redux-saga/effects';
import { userManualRequest, userManualSuccess, userManualFailure, setIsLoading } from './actions';
import { HELP } from 'app/api-routes';
import axios from 'axios';

function* getUserManualSaga() {
  try {
    yield put(setIsLoading(true));
    const { payload } = yield axios.get(HELP);
    yield put(userManualSuccess(payload));
  } catch (err) {
    yield put(userManualFailure(err));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function* () {
  yield all([yield takeLatest(userManualRequest, getUserManualSaga)]);
}
