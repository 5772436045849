import {all, call, put, takeLatest} from 'redux-saga/effects';

import {
  mobilePatrolsFailure,
  mobilePatrolsRequest,
  mobilePatrolsSuccess,
  mobilePatrolInfoFailure,
  mobilePatrolInfoRequest,
  mobilePatrolInfoSuccess,
  updateMobilePatrolsStatusFailure,
  updateMobilePatrolsStatusRequest,
  updateMobilePatrolsStatusSuccess,
  createMobilePatrolsFailure,
  createMobilePatrolsRequest,
  updateMobilePatrolsFailure,
  updateMobilePatrolsRequest,
  deleteMobileUnitFailure,
  deleteMobileUnitRequest,
  updateMobilePatrolsSuccess,
  deleteMobileUnitSuccess,
} from './actions';
import axios from 'axios';
import { MOBILE_PATROL } from 'app/api-routes';

function* mobilePatrolsSaga() {
  try {
    const { payload, warnings } = yield axios.get(MOBILE_PATROL);

    yield put(mobilePatrolsSuccess(payload, { warnings }));
  } catch (err) {
    yield put(mobilePatrolsFailure(err));
  }
}

function* mobilePatrolInfoSaga({ payload: mobilePatrolId }) {
  try {
    const { payload } = yield axios.get(`${MOBILE_PATROL}/${mobilePatrolId}`);

    yield put(mobilePatrolInfoSuccess({ mobilePatrolId, data: payload }));
  } catch (err) {
    yield put(mobilePatrolInfoFailure(err));
  }
}

function* updateMobilePatrolsStatusSaga({ payload: { mobilePatrolId, status, onSuccess } }) {
  try {
    yield axios.put(`${MOBILE_PATROL}/${mobilePatrolId}/status/${status}`);
    yield put(updateMobilePatrolsStatusSuccess({ mobilePatrolId, status }));
    onSuccess?.();
  } catch (err) {
    yield put(updateMobilePatrolsStatusFailure(err));
  }
}

function* createMobilePatrolsSaga({ payload: { data, callback } }) {
  try {
    const { payload: id } = yield axios.post(`${MOBILE_PATROL}`, {
      payload: data
    });
    callback?.(id);
  } catch (err) {
    yield put(createMobilePatrolsFailure(err));
  }
}

function* updateMobilePatrolsSaga({ payload: { data, mobilePatrolID, callback} }) {
  try {
    yield axios.put(`${MOBILE_PATROL}/${mobilePatrolID}`, {
      payload: data
    });
    callback?.();
    yield put(updateMobilePatrolsSuccess(mobilePatrolID));
    yield call(mobilePatrolInfoSaga, { payload: mobilePatrolID });
  } catch (err) {
    yield put(updateMobilePatrolsFailure(err));
  }
}

function* deleteMobileUnitSaga({ payload: { mobilePatrolID, callback} }) {
  try {
    yield axios.delete(`${MOBILE_PATROL}/${mobilePatrolID}`);
    callback?.();
    yield put(deleteMobileUnitSuccess(mobilePatrolID));
  } catch (err) {
    yield put(deleteMobileUnitFailure(err));
  }
}

export default function*() {
  yield all([
    yield takeLatest(mobilePatrolsRequest, mobilePatrolsSaga),
    yield takeLatest(mobilePatrolInfoRequest, mobilePatrolInfoSaga),
    yield takeLatest(updateMobilePatrolsStatusRequest, updateMobilePatrolsStatusSaga),
    yield takeLatest(createMobilePatrolsRequest, createMobilePatrolsSaga),
    yield takeLatest(updateMobilePatrolsRequest, updateMobilePatrolsSaga),
    yield takeLatest(deleteMobileUnitRequest, deleteMobileUnitSaga),
  ]);
}
