import { createFailure, createRequest, createSuccess } from "ducks/helpers";
import constants from "./constants";

export const contactsRequest = createRequest(constants.contacts);
export const contactsSuccess = createSuccess(constants.contacts);
export const contactsFailure = createFailure(constants.contacts);

export const requestContacts = contactsRequest;

export const createContactRequest = createRequest(constants.createContact);
export const createContactSuccess = createSuccess(constants.createContact);
export const createContactFailure = createFailure(constants.createContact);

export const createContact = createContactRequest;

export const updateContactRequest = createRequest(constants.updateContact);
export const updateContactSuccess = createSuccess(constants.updateContact);
export const updateContactFailure = createFailure(constants.updateContact);

export const updateContact = updateContactRequest;

export const deleteContactRequest = createRequest(constants.deleteContact);
export const deleteContactSuccess = createSuccess(constants.deleteContact);
export const deleteContactFailure = createFailure(constants.deleteContact);

export const deleteContact = deleteContactRequest;

export const contactInfoRequest = createRequest(constants.contactInfo);
export const contactInfoSuccess = createSuccess(constants.contactInfo);
export const contactInfoFailure = createFailure(constants.contactInfo);

export const getContact = contactInfoRequest;

export const updateContactFormRequest = createRequest(constants.updateContactForm);
export const updateContactFormSuccess = createSuccess(constants.updateContactForm);
export const updateContactFormFailure = createFailure(constants.updateContactForm);

export const updateContactForm = updateContactFormRequest;
