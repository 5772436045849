import saMessages from '../locales/hr_HR.json';

const saLang = {
    messages: {
        ...saMessages
    },
    locale: 'hr',

};
export default saLang;
