import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import PublicHeader from './components/PublicHeader';
import { LOGIN_PAGE } from 'new-design/routes/common/constants';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as  auth from 'ducks/auth';

const PublicLayout = ({ children, disableRedirect, onlyLogo }) => {
  const classes = useStyles();
  const { sessionID } = useSelector(auth.selectors);
  
  if (sessionID && !disableRedirect) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PAGE,
        }}
      />
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.headerBlock}>
        <PublicHeader onlyLogo={onlyLogo} />
      </Box>
      <Box className={classes.contentBlock}>
        <Box className={classes.childrenContent}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export { PublicLayout };
