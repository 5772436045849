import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  siteEmailSettingsRequest,
  siteEmailSettingsSuccess,
  siteEmailSettingsFailure,
  enableSiteEmailSettingRequest,
  enableSiteEmailSettingFailure,
  disableSiteEmailSettingRequest,
  disableSiteEmailSettingFailure,
  siteEmailRecipientsSuccess,
  siteEmailRecipientsFailure,
  siteEmailRecipientsRequest,
  updateSiteEmailRecipientsRequest,
  updateSiteEmailRecipientsSuccess,
  updateSiteEmailRecipientsFailure,
} from './actions';
import axios from 'axios';
import { SITES_V2 } from 'app/api-routes';

function* siteEmailSettingsSaga({ payload: siteID }) {
  try {
    const { payload } = yield axios.get(`${SITES_V2}/${siteID}/email-settings`);
    yield put(siteEmailSettingsSuccess({ siteID, settings: payload }));
  } catch (err) {
    yield put(siteEmailSettingsFailure(err));
  }
}

function* enableSiteEmailSettingSaga({ payload: { siteID, type } }) {
  try {
    yield axios.put(`${SITES_V2}/${siteID}/email-settings/${type}/enable`);
    yield call(siteEmailSettingsSaga, { payload: siteID });
  } catch (err) {
    yield put(enableSiteEmailSettingFailure(err));
  }
}

function* disableSiteEmailSettingSaga({ payload: { siteID, type } }) {
  try {
    yield axios.put(`${SITES_V2}/${siteID}/email-settings/${type}/disable`);
    yield call(siteEmailSettingsSaga, { payload: siteID });
  } catch (err) {
    yield put(disableSiteEmailSettingFailure(err));
  }
}

function* siteEmailRecipientsSaga({ payload: { siteID, type } }) {
  try {
    const { payload } = yield axios.get(`${SITES_V2}/${siteID}/email-recipients`, { params: { type } });
    yield put(siteEmailRecipientsSuccess({ recipients: payload }));
  } catch (err) {
    yield put(siteEmailRecipientsFailure(err));
  }
}

function* updateSiteEmailRecipientsSaga({ payload: { siteID, type, values } }) {
  try {
    yield axios.post(
      `${SITES_V2}/${siteID}/email-recipients`,
      { payload: values },
      {
        params: { type },
      }
    );

    yield call(siteEmailSettingsSaga, { payload: siteID });
    yield put(updateSiteEmailRecipientsSuccess({ siteID, type, payload: values }));
  } catch (err) {
    yield put(updateSiteEmailRecipientsFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(siteEmailSettingsRequest, siteEmailSettingsSaga),
    yield takeLatest(enableSiteEmailSettingRequest, enableSiteEmailSettingSaga),
    yield takeLatest(disableSiteEmailSettingRequest, disableSiteEmailSettingSaga),
    yield takeLatest(siteEmailRecipientsRequest, siteEmailRecipientsSaga),
    yield takeLatest(updateSiteEmailRecipientsRequest, updateSiteEmailRecipientsSaga),
  ]);
}
