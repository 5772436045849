import preparedReducer from './reducer';
import {
  requestInfoNote,
  postMessage,
  getAllInfoNote,
  createInfoNote,
  updateInfoNote,
  ackInfoNote,
  toggleInfoNote,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'infoNote';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestInfoNote,
  postMessage,
  getAllInfoNote,
  createInfoNote,
  updateInfoNote,
  ackInfoNote,
  toggleInfoNote,
};
