import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { AlarmTypeIcon, GpsAlertIcon, NoActivityIcon, PersonIcon } from 'assets/icons';
import { useSelector } from 'react-redux';
import * as notifications from 'ducks/notifications';
import { formatUTCInLocal } from 'util/formatting';
import { ALARM, GPS_ALERT, INACTIVITY } from 'app/types/events';
import { getSiteWeight } from '../GoogleMap/helpers';
import { DATE_TIME_FORMAT } from 'app/types/constants';
import axios from 'axios';
import IntlMessages from 'util/IntlMessages';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      notificationsPopup: {
        cursor: 'pointer',

        '& .header': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: 15,
          alignItems: 'center',
          borderBottom: '2px solid #F7F7F7',

          '& .counter': {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '21px',
            textAlign: 'center',
            color: '#8A8A8A',
          },

          '& .title': {
            fontWeight: 500,
            fontSize: 18,
            lineHeight: '27px',
            color: '#222222',
          },
        },
      },

      notification: {
        maxHeight: 270,
        overflowY: 'auto',
        background: '#F7F8FB',

        '&::-webkit-scrollbar': {
          width: 2,
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.primary.main,
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.2)',
          borderRadius: 10,
        },
        '& .notificationBlock': {
          display: 'flex',
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 15,
          paddingRight: 15,
          boxShadow: '0px 1px 0px #E4E8EE',
          width: '100%',
          [theme.breakpoints.down('lg')]: {
            paddingTop: 10,
            paddingBottom: 10,
          },

          '&:last-child': {
            borderBottom: 'none',
          },

          '& .ledContainer': {
            marginRight: 16,

            '.icon-type': {},
          },
          '& .content': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',

            '& .messageHeader': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',

              '& .title': {
                fontWeight: 600,
                fontSize: 14,
                lineHeight: '20px',
                color: '#222222',
              },
              '& .date': {
                fontWeight: 'normal',
                fontSize: 12,
                lineHeight: '18px',
                color: '#BBBBBB',
              },
            },

            '& .message': {
              fontWeight: 'normal',
              fontSize: 16,
              lineHeight: '24px',
              color: '#8A8A8A',
              [theme.breakpoints.down('lg')]: {
                fontSize: 12,
                lineHeight: '18px',
              },
            },

            '& .descriptionBlock': {
              '& .title': {
                fontWeight: 600,
                fontSize: 14,
                lineHeight: '20px',
                color: '#222222',
              },

              '& .employeeBlock': {
                marginTop: 4,
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  width: 16,
                  marginRight: 8,
                },
                '& .employee': {
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '18px',
                  color: '#5B5B5B',
                },
              },
            },
          },
        },
      },
      alarmIcon: {
        '& path': {
          fill: theme.palette.alarm.color,
        },
      },
    }),
  {
    name: 'NotificationsList',
  }
);

const NotificationsList = ({ onClose }) => {
  const classes = useStyles();
  const notificationsItems = useSelector(notifications.selectors);

  const getIcon = (type) => {
    switch (type) {
      case ALARM:
        return <AlarmTypeIcon className={classes.alarmIcon} />;
      case GPS_ALERT:
        return <GpsAlertIcon className="icon-type" />;
      case INACTIVITY:
        return <NoActivityIcon className="icon-type" />;
      default:
        return null;
    }
  };

  const handleClick = (item) => {
    let link = '';
    try {
      axios.put(`/api/notifications/${item.notificationID}/check`);
      // eslint-disable-next-line no-empty
    } catch {}

    switch (item.type) {
      case ALARM:
        link = `/app/activity-log/alarm#shiftLogID=${item.entityID}`;
        break;
      case GPS_ALERT:
        link = `/app/activity-log/gps-alert#shiftLogID=${item.entityID}`;
        break;
      case INACTIVITY:
        link = `/app/activity-log/no-activity#shiftLogID=${item.entityID}`;
        break;
      default:
        return null;
    }

    window.open(link, '_blank');
    onClose();
  };

  const getTitle = (type) => {
    switch (type) {
      case ALARM:
        return <IntlMessages id="alarm" />;
      case GPS_ALERT:
        return <IntlMessages id="gps_alert" />;
      case INACTIVITY:
        return <IntlMessages id="inactivity" />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.notificationsPopup}>
      <div className="header">
        <Typography className="title">
          <IntlMessages id="notifications" />
        </Typography>
        <Typography className="counter">{notificationsItems.length}</Typography>
      </div>
      <div className={classes.notification}>
        {notificationsItems
          .sort((el1, el2) => getSiteWeight(el2.type) - getSiteWeight(el1.type))
          .map((el) => (
            <div className="notificationBlock" key={el.notificationId} onClick={() => handleClick(el)}>
              <div className="ledContainer">{getIcon(el.type)}</div>
              <div className="content">
                <div className="messageHeader">
                  <Typography className="title">{el.siteName}</Typography>
                  <Typography className="date">
                    {formatUTCInLocal(el.dateCreated, DATE_TIME_FORMAT)}
                  </Typography>
                </div>
                <div className="descriptionBlock">
                  {el.description && (
                    <Typography className="title">
                      {getTitle(el.type)}, {el.description}
                    </Typography>
                  )}
                  <div className="employeeBlock">
                    <PersonIcon />
                    <Typography className="employee">{`${el.fullName}, ${el.position}`}</Typography>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {!notificationsItems.length && (
          <Box marginTop={3} marginBottom={3} padding={2}>
            <Typography className="no_notifications">
              <IntlMessages id="no_notifications" />
            </Typography>
          </Box>
        )}
      </div>
    </div>
  );
};

export default NotificationsList;
