import preparedReducer from './reducer';
import { requestCodes, requestCodesUpdates } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'codes';

export const selectors = state => state[SLICE_NAME];

export const getCodes = (state, type) => state.codes[type];

export const getCodeTitle = (state, type, id) => {
  const code = (getCodes(state, type) || []).find(item => item.id === id);
  return (code || {}).description;
};

export const actions = {
  requestCodes,
  requestCodesUpdates,
};
