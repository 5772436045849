import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { notebookNoteSuccess, shiftNotesSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return initialState;
    },
    [notebookNoteSuccess](state, { payload: item }) {
      return {
        ...state,
        [item.notebookNoteID]: item,
      };
    },
    [shiftNotesSuccess](state, { payload: item }) {
      return {
        ...state,
        notes: {
          ...state,
          [item.shiftID]: item,
        },
      };
    },
  },
  initialState,
);
