import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import {
  notebookNoteTagsSuccess,
  updateNotebookNoteTagsSuccess,
} from './actions';

import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return initialState;
    },
    [notebookNoteTagsSuccess](
      state,
      { payload: { notebookNoteTags, siteID } }
    ) {
      return {
        ...state,
        [siteID]: notebookNoteTags,
      };
    },
    [updateNotebookNoteTagsSuccess](
      state,
      { payload: { siteID, notebookNoteTags } }
    ) {
      return {
        ...state,
        [siteID]: notebookNoteTags,
      };
    },
  },
  initialState
);
