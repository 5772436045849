import preparedReducer from './reducer';
import {
  requestVisitors,
  requestVisitor,
  createVisitor,
  updateVisitor,
  deleteVisitor,
  updateVisitorStatus,
  requestVisitorQRCodeData,
  updateVisitorQRTag,
  sendVisitorQRCode,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'visitors';

export const selectors = (state) => state[SLICE_NAME];

export const selectVisitorInfo = (state, visitorID) => state[SLICE_NAME].info[visitorID];

export const selectVisitorQRData = (state, visitorID) => state[SLICE_NAME].qrData[visitorID];

export const actions = {
  requestVisitors,
  requestVisitor,
  createVisitor,
  updateVisitor,
  deleteVisitor,
  updateVisitorStatus,
  requestVisitorQRCodeData,
  updateVisitorQRTag,
  sendVisitorQRCode,
};
