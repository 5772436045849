import {all, call, put, takeLatest} from 'redux-saga/effects';

import { employeeInfoFailure, employeeInfoRequest, employeeInfoSuccess } from './actions';
import axios from 'axios';
import { EMPLOYEES } from 'app/api-routes';
import { getLoginDetailsSaga } from 'ducks/users/sagas';

export function* employeeInfoSaga({payload: employeeID}) {
  try {
    const {payload, warnings} = yield axios.get(`${EMPLOYEES}/${employeeID}`);
    if (payload.userID) {
      yield call(getLoginDetailsSaga, { payload: payload.userID });
    }
    yield put(employeeInfoSuccess(payload, {warnings}));
  } catch (err) {
    yield put(employeeInfoFailure(err));
  }
}

export default function*() {
  yield all([
    yield takeLatest(employeeInfoRequest, employeeInfoSaga),
  ]);
}
