import React from 'react';
import clsx from 'clsx';
import { Modal, Box } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles(
  {
    body: {
      width: ({ width }) => width || 600,
      borderRadius: 24,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      [theme.breakpoints.down('lg')]: {
        width: ({ width }) => width ? width * 0.8 : 400,
      }
    },
  },
), {
  name: 'Modal'
});

const CustomModal = ({ open, handleClose, children, width, className, bodyClassName }) => {
  const classes = useStyles({ width });

  return (
    <Modal
      open={!!open}
      className={className}
      onClose={handleClose}
    >
      <Box className={clsx(classes.body, 'modal-body', bodyClassName)}>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
