import preparedReducer from './reducer';
import { requestStatsPerDate } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'statsPerDate';

export const selectors = state => state[SLICE_NAME];
export const getStatsPerDate = (state, siteID) => state.statsPerDate[siteID];

export const actions = {
  requestStatsPerDate,
};
