import { take } from 'redux-saga/effects';
import { isRequests, isFailure } from '../helpers';
import { NotificationManager } from 'react-notifications';

function* watchAndLog() {
  while (true) {
    const action = yield take('*');

    if (isFailure(action.type)) {
      const errors = action?.payload?.response?.data?.errors;
      if (errors?.length > 0) {
        errors.forEach((error) => {
          NotificationManager.error(error);
        });
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('%c ERROR >>> ', 'color: red', action);
          NotificationManager.error(action?.payload?.message);
        }
      }
    }
    if (isRequests(action.type)) {
      const warnings = action?.payload?.response?.data?.warnings;
      if (warnings?.length > 0) {
        warnings.forEach((warning) => {
          NotificationManager.warning(warning);
        });
      }
    }
  }
}

export default watchAndLog;
