import preparedReducer from './reducer';
import {
  setStatisticState,
  setAdminTabsState,
  setChipsStripName,
  toggleControls,
  setHiddenChips,
} from './actions';
import constants from './constants';

export const reducer = preparedReducer;

export const SLICE_NAME = constants.mainLayout;

export const selectors = (state) => state[SLICE_NAME];

export const actions = {
  setStatisticState,
  setAdminTabsState,
  setChipsStripName,
  toggleControls,
  setHiddenChips,
};
