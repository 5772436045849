import { useHistory } from 'react-router';

export const usePageId = () => {
  const history = useHistory();
  const parsedHash = new URLSearchParams(
    history.location.hash?.substr(1) // skip the first char (#)
  );

  return {
    id: history.location.pathname.split('/').pop(),
    hashId: parsedHash.get('id'),
  };
}
