import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const entitiesSummaryRequest = createRequest(constants.entitiesSummary);
export const entitiesSummarySuccess = createSuccess(constants.entitiesSummary);
export const entitiesSummaryFailure = createFailure(constants.entitiesSummary);

export const requestEntitiesSummary = entitiesSummaryRequest;

export const eventsSummaryRequest = createRequest(constants.eventsSummary);
export const eventsSummarySuccess = createSuccess(constants.eventsSummary);
export const eventsSummaryFailure = createFailure(constants.eventsSummary);

export const requestEventsSummary = eventsSummaryRequest;

export const entityOverviewRequest = createRequest(constants.entityOverview);
export const entityOverviewSuccess = createSuccess(constants.entityOverview);
export const entityOverviewFailure = createFailure(constants.entityOverview);

export const requestEntityOverview = entityOverviewRequest;

export const eventOverviewRequest = createRequest(constants.eventOverview);
export const eventOverviewSuccess = createSuccess(constants.eventOverview);
export const eventOverviewFailure = createFailure(constants.eventOverview);

export const requestEventOverview = eventOverviewRequest;
