import React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
// TODO: refactoring.
import asyncComponent from 'util/asyncComponent';

const Routes = () => {
  return (
    <Switch>
      <Route path={`/terms`} component={(asyncComponent(() => import('../../pages/public/Terms')))}/>
      <Route path={`/policy`} exact component={(asyncComponent(() => import('../../pages/public/Privacy')))}/>
    </Switch>
  );
}

export default Routes;

