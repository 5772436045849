import React, { useEffect } from 'react';
import { Box, InputLabel, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useField } from 'formik';

const useStyles = makeStyles((theme) => createStyles(
  {
    inputRoot: {
      marginTop: theme.spacing(1.25),
    },
    text: {
      marginLeft: 16,
      fontSize: 16,
      lineHeight: '24px',
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        lineHeight: '21px',
      }
    },
    input: {
      display: 'flex',
      padding: ({ min }) => min ? '0 14px' : '12px 14px',
      '& svg': {
        [theme.breakpoints.down('lg')]: {
          width: 20,
          height: 20,
        },
      },
      '& path': {
        fill: theme.palette.secondary.main,
      }
    },
    notActive: {
      color: '#BBBBBB'
    }
  }
), {
  name: 'DefinedInput'
});

export const FormDefinedInputField = ({
  label,
  name,
  className,
  dynamicClassName,
  icon,
  placeholder,
  valueToDisplay,
  defaultValue,
}) => {
  const classes = useStyles();
  const [{ value }, , helpers] = useField(name);

  useEffect(() => {
    if (defaultValue) {
      helpers.setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box className={className}>
      {!!label && (
        <InputLabel htmlFor={name}>{label}</InputLabel>
      )}
      <Box className={clsx(classes.input, !!dynamicClassName && dynamicClassName(value))}>
        {icon}
        <Typography className={clsx(classes.text, {
          [classes.notActive]: !(valueToDisplay || value),
        })}>
          {valueToDisplay || value || placeholder}
        </Typography>
      </Box>
    </Box>
  );
};
