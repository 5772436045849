import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';
import { logoutSuccess } from 'ducks/auth/actions';
import { addField, profileFieldsSuccess, profileFieldResultsSuccess, updateBuilderFields } from './actions';
import initialState from './states';
import { INPUTS_DESCRIPTION } from 'app/types/form-builder';
import _ from 'lodash';

export default handleActions(
  {
    [profileFieldsSuccess](state, { payload: { tableName, fields, setupBuilder } }) {
      return {
        ...state,
        fields: {
          ...state.fields,
          [tableName]: fields,
        },
        builderFields: setupBuilder ? fields : state.builderFields,
      };
    },
    [profileFieldResultsSuccess](state, { payload: { tableName, entityID, fieldResults } }) {
      return {
        ...state,
        fieldResults: {
          ...state.fieldResults,
          [tableName]: {
            ...(state.fieldResults[tableName] || {}),
            [entityID]: fieldResults,
          },
        },
      };
    },
    [addField](state, { payload: { type, index } }) {
      const { name, label } = INPUTS_DESCRIPTION[type];
      const newState = _.cloneDeep(state);
      const newField = {
        profileFieldID: uuidv4(),
        type,
        config: {
          name,
          required: false,
          label,
        },
      };

      const newFields = newState.builderFields;
      newFields.splice(_.isNumber(index) ? index : newFields.length, 0, newField);
      newState.builderFields = [...newFields];

      return newState;
    },
    [updateBuilderFields](state, { payload }) {
      return {
        ...state,
        builderFields: _.isFunction(payload) ? payload(state.builderFields) : state.builderFields,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
