import React, { useCallback } from 'react';
import { Box, darken, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from 'new-design/common/ui';
import { CloseIcon } from 'assets/icons';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '20px 30px',
    alignItems: 'center',
    minHeight: 10,
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    '& .Cmt-appIcon': {
      color: theme.palette.text.secondary,
      '&:hover, &:focus': {
        color: darken(theme.palette.text.secondary, 0.2),
      },
      [theme.breakpoints.down('xs')]: {
        padding: 7,
      },
    },
    '& .Cmt-horizontalNavMenu': {
      position: 'static',
    },
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: theme.palette.common.dark,
    },
  },
  logo: {
    marginTop: -2,
    marginRight: 15,
    height: 33,
  },
  closeIcon: {
    '& path': {
      stroke: theme.palette.common.black,
    }
  }
}));

const PublicHeader = ({ onlyLogo }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleCloseClick = useCallback(() => {
    history.goBack();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Toolbar className={classes.root}>
      <Box display="flex">
        <img className={classes.logo} src={`${process.env.PUBLIC_URL}/logos/default.png`} alt="logo" />
      </Box>
      {
        !onlyLogo && (
          <>
            <IconButton className={classes.closeIcon} onClick={handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </>
        )
      }
    </Toolbar>
  );
};

export default PublicHeader;
