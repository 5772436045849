import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

export const TextInput = ({ icon, iconContainerClassName, endIcon, readOnly, label, ...rest }) => (
  <TextField
    variant="outlined"
    label={label}
    {...rest}
    InputProps={{
      ...rest.InputProps,
      readOnly,
      startAdornment: !!icon && (
        <InputAdornment position="start" className={iconContainerClassName}>
          {icon}
        </InputAdornment>
      ),
      endAdornment: !!endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>,
    }}
  />
);
