import { SITE_ADDRESSES } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { siteInfoAddressFailure, siteInfoAddressRequest, siteInfoAddressSuccess } from './actions';

function* siteInfoAddressSaga({ payload: siteID }) {
  try {
    const { payload: siteInfoAddress } = yield axios.get(`${SITE_ADDRESSES}/${siteID}`);
    siteInfoAddress.siteID=siteID;
    yield put(siteInfoAddressSuccess(siteInfoAddress));
  } catch (err) {
    yield put(siteInfoAddressFailure(err));
  }
}

export default function*() {
  yield all([yield takeLatest(siteInfoAddressRequest, siteInfoAddressSaga)]);
}
