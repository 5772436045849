import preparedReducer from './reducer';
import {
  requestArea,
  requestCreateArea,
  requestMakeAllMasterCheckpoint,
  requestRemoveAllMasterCheckpoint,
  requestRenameArea,
  updateAreaStatus,
  deleteArea,
  deleteCheckpoints,
  updateNote,
  addMarker,
  deleteMarker,
  requestAreas,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'areas';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestArea,
  requestCreateArea,
  requestMakeAllMasterCheckpoint,
  requestRemoveAllMasterCheckpoint,
  requestRenameArea,
  updateAreaStatus,
  deleteArea,
  deleteCheckpoints,
  updateNote,
  addMarker,
  deleteMarker,
  requestAreas,
};
