import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const companyRequest = createRequest(constants.company);
export const companySuccess = createSuccess(constants.company);
export const companyFailure = createFailure(constants.company);

export const enableVisitorLogRequest = createRequest(constants.enableVisitorLog);
export const enableVisitorLogSuccess = createSuccess(constants.enableVisitorLog);
export const enableVisitorLogFailure = createFailure(constants.enableVisitorLog);

export const disableVisitorLogRequest = createRequest(constants.disableVisitorLog);
export const disableVisitorLogSuccess = createSuccess(constants.disableVisitorLog);
export const disableVisitorLogFailure = createFailure(constants.disableVisitorLog);

export const requestCompany = companyRequest;
export const enableVisitorLog = enableVisitorLogRequest;
export const disableVisitorLog = disableVisitorLogRequest;
