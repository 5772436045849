import preparedReducer from './reducer';
import { requestSiteInfoTours } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'siteInfoTours';

export const selectors = state => state[SLICE_NAME];
export const getSiteInfoTours = (state, siteID) => state.siteInfoTours[siteID];

export const actions = {
  requestSiteInfoTours,
};
