import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import { scheduleSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [scheduleSuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.scheduleID]: payload,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
