import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  taskFailure,
  taskRequest,
  taskSuccess,
  createTaskRequest,
  createTaskFailure,
  updateTaskRequest,
  updateTaskFailure,
  enableTaskRequest,
  enableTaskSuccess,
  enableTaskFailure,
  disableTaskRequest,
  disableTaskSuccess,
  disableTaskFailure,
  deleteTaskRequest,
  deleteTaskFailure,
  getTaskLogsRequest,
  getTaskLogsSuccess,
  getTaskLogsFailure,
  tasksRequest,
  tasksSuccess,
  tasksFailure,
  updateTaskSuccess,
  deleteTaskSuccess,
} from './actions';

import axios from 'axios';
import { TASKS, TASKS_V2 } from 'app/api-routes';

function* taskSaga({ payload: { taskID, shiftID } }) {
  try {
    const { payload } = yield axios.get(`${TASKS}/${taskID}`, {
      params: {
        shiftID,
      },
    });

    yield put(taskSuccess(payload));
  } catch (err) {
    yield put(taskFailure(err));
  }
}

function* tasksSaga({ payload: { siteID } }) {
  try {
    const params = {};
    if (siteID) {
      params.siteID = siteID;
    }

    const { payload } = yield axios.get(TASKS, { params });

    yield put(tasksSuccess(payload));
  } catch (err) {
    yield put(tasksFailure(err));
  }
}

function* createTaskSaga({ payload: { data, callback } }) {
  try {
    const { payload } = yield axios.post(`${TASKS}`, {
      payload: data,
    });

    if (callback) {
      callback(payload);
    }
  } catch (err) {
    yield put(createTaskFailure(err));
  }
}

function* updateTaskSaga({ payload: { taskID, data, callback } }) {
  try {
    const { payload } = yield axios.put(`${TASKS}/${taskID}`, {
      payload: data,
    });

    if (callback) {
      callback(payload);
    }
    yield call(taskSaga, { payload: { taskID } });
    yield put(updateTaskSuccess(taskID));
  } catch (err) {
    yield put(updateTaskFailure(err));
  }
}

function* enableTaskSaga({ payload: { taskID, callback } }) {
  try {
    const { payload } = yield axios.put(`${TASKS}/${taskID}/enable`);

    if (callback) {
      callback(payload);
    }
    yield put(enableTaskSuccess(taskID));
  } catch (err) {
    yield put(enableTaskFailure(err));
  }
}

function* disableTaskSaga({ payload: { taskID, callback } }) {
  try {
    const { payload } = yield axios.put(`${TASKS}/${taskID}/disable`);

    if (callback) {
      callback(payload);
    }
    yield put(disableTaskSuccess(taskID));
  } catch (err) {
    yield put(disableTaskFailure(err));
  }
}

function* deleteTaskSaga({ payload: { taskID, callback } }) {
  try {
    const { payload } = yield axios.delete(`${TASKS}/${taskID}`);

    if (callback) {
      callback(payload);
    }
    yield put(deleteTaskSuccess(taskID));
  } catch (err) {
    yield put(deleteTaskFailure(err));
  }
}

function* getTaskLogsSaga({ payload: { taskID } }) {
  try {
    const { payload: data } = yield axios.get(`${TASKS_V2}/${taskID}/log`);

    yield put(getTaskLogsSuccess({ data, taskID }));
  } catch (err) {
    yield put(getTaskLogsFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(taskRequest, taskSaga),
    yield takeLatest(tasksRequest, tasksSaga),
    yield takeLatest(createTaskRequest, createTaskSaga),
    yield takeLatest(updateTaskRequest, updateTaskSaga),
    yield takeLatest(enableTaskRequest, enableTaskSaga),
    yield takeLatest(disableTaskRequest, disableTaskSaga),
    yield takeLatest(deleteTaskRequest, deleteTaskSaga),
    yield takeLatest(getTaskLogsRequest, getTaskLogsSaga),
  ]);
}
