import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { OperationsRoutes } from './operations';
import { MonitorRoutes } from './monitor';
import { AnalyticsRoutes } from './analytics';
import { ManagementRoutes } from './management';
import { AdminRoutes } from './admin';
import { InformationRoutes } from './information';
import { MainLayout } from '../layouts';

// TODO: made routes secured using values from /sessions request.
const Routes = (props) => (
  <MainLayout>
    <OperationsRoutes {...props} />
    <MonitorRoutes {...props} />
    <AnalyticsRoutes {...props} />
    <AdminRoutes {...props} />
    <ManagementRoutes {...props} />
    <InformationRoutes {...props} />
    <Route exact path="/app">
      <Redirect to="/app/control-panel" />
    </Route>
    <Route exact path="/">
      <Redirect to="/app/control-panel" />
    </Route>
  </MainLayout>
);
  
export default Routes;

