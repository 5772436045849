export default {
  formResults: 'FORM_RESULTS',
  formResultInfo: 'FORM_RESULT_INFO',
  submitFormResult: 'SUBMIT_FORM_RESULT',
  approveFormResult: 'APPROVE_FORM_RESULT',
  updateFormResult: 'UPDATE_FORM_RESULT',
  deleteFormResult: 'DELETE_FORM_RESULT',
  formResultReport: 'FORM_RESULT_REPORT',
  excelFormResults: 'EXCEL_FORM_RESULTS',
};
