import { createFailure, createRequest, createSuccess } from "ducks/helpers";
import constants from "./constants";

export const employeesRequest = createRequest(constants.employees);
export const employeesSuccess = createSuccess(constants.employees);
export const employeesFailure = createFailure(constants.employees);

export const requestEmployees = employeesRequest;

export const createEmployeeRequest = createRequest(constants.createEmployee);
export const createEmployeeSuccess = createSuccess(constants.createEmployee);
export const createEmployeeFailure = createFailure(constants.createEmployee);

export const createEmployee = createEmployeeRequest;

export const updateEmployeeRequest = createRequest(constants.updateEmployee);
export const updateEmployeeSuccess = createSuccess(constants.updateEmployee);
export const updateEmployeeFailure = createFailure(constants.updateEmployee);

export const updateEmployee = updateEmployeeRequest;

export const deleteEmployeeRequest = createRequest(constants.deleteEmployee);
export const deleteEmployeeSuccess = createSuccess(constants.deleteEmployee);
export const deleteEmployeeFailure = createFailure(constants.deleteEmployee);

export const deleteEmployee = deleteEmployeeRequest;

export const updateEmployeeFormRequest = createRequest(constants.updateEmployeeFormConst);
export const updateEmployeeFormSuccess = createSuccess(constants.updateEmployeeFormConst);
export const updateEmployeeFormFailure = createFailure(constants.updateEmployeeFormConst);

export const updateEmployeeForm = updateEmployeeFormRequest;

export const getUserRequest = createRequest(`${constants.users}_GET_USER`);
export const getUserSuccess = createSuccess(`${constants.users}_GET_USER`);
export const getUserFailure = createFailure(`${constants.users}_GET_USER`);

export const getUser = getUserRequest;
