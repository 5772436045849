import isEqual from 'react-fast-compare';
import { combineActions, handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import {
  checkpointInfoSuccess,
  updateNoteSuccess,
  checkpointsSuccess,
  checkpointQRCodeDataSuccess,
  updateCheckpointQRTagSuccess,
} from './actions';

import initialState from './states';
import { updateMediaAssetSuccess } from 'ducks/mediaAssets/actions';
import { MediaAssetTables } from 'ducks/mediaAssets/types';

export default handleActions(
  {
    [checkpointsSuccess](state, { payload }) {
      if (isEqual(state.list, payload)) {
        return state;
      }

      return {
        ...state,
        list: payload,
      };
    },
    [checkpointInfoSuccess](state, { payload }) {
      if (isEqual(state.info[payload.checkpointID], payload)) {
        return state;
      }
      return {
        ...state,
        info: {
          ...state.info,
          [payload.checkpointID]: Object.freeze(payload),
        },
      };
    },
    [updateNoteSuccess](state, { payload: { id, item } }) {
      return {
        ...state,
        info: {
          ...state.info,
          [id]: {
            ...state?.info?.[id],
            notes: state?.info?.[id]?.notes.map((el) => {
              if (el.typeID === item.typeID) {
                return {
                  ...el,
                  ...item,
                };
              } else {
                return el;
              }
            }),
          },
        },
      };
    },
    [updateMediaAssetSuccess](state, { payload: { mediaAssetInfo } }) {
      const { entityID, tableName, mediaAssetID, description } = mediaAssetInfo;

      if (!(tableName === MediaAssetTables.CHECKPOINT_PLAN)) {
        return state;
      }

      return {
        ...state,
        info: {
          ...state.info,
          [entityID]: {
            ...state.info[entityID],
            photos: state.info[entityID].photos.map((item) =>
              item.mediaAssetID === mediaAssetID ? { ...item, description } : item
            ),
          },
        },
      };
    },
    [combineActions(checkpointQRCodeDataSuccess, updateCheckpointQRTagSuccess)](
      state,
      { payload: { checkpointID, data } }
    ) {
      return {
        ...state,
        qrData: {
          ...state.qrData,
          [checkpointID]: data,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
