import { all, put, takeLatest } from 'redux-saga/effects';
import { mapActivityRequest, mapActivitySitesSuccess, mapActivityPatrolsSuccess, mapActivityFailure, mapActivitySuccess } from './actions';
import { ACTIVITY_MAP } from 'app/api-routes';
import axios from 'axios';

function* getMapActivitySaga() {
  try {
    const { payload } = yield axios.get(ACTIVITY_MAP);
    yield put(mapActivitySitesSuccess(payload.sites));
    yield put(mapActivityPatrolsSuccess(payload.mobilePatrols));
    yield put(mapActivitySuccess());
  } catch (err) {
    yield put(mapActivityFailure(err));
  }
}

export default function* () {
  yield all([yield takeLatest(mapActivityRequest, getMapActivitySaga)]);
}
