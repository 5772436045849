import { handleActions } from 'redux-actions';
import { loginSuccess, logoutSuccess, resetLogin, showExpiredModel, hideExpiredModel, acceptAgreementSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [loginSuccess](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
    [resetLogin]() {
      return initialState;
    },
    [showExpiredModel](state) {
      return {
        ...state,
        showExpired: true,
      };
    },
    [hideExpiredModel](state) {
      return {
        ...state,
        showExpired: false,
      };
    },
    [acceptAgreementSuccess](state) {
      return {
        ...state,
        termsInfo: {
          acceptRequired: true,
          termsChanged: true,
        },
      };
    },
  },
  initialState,
);
