import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

const Card = ({ children, itemPosition, className, fullHeight, style }) => {
  const classes = useStyles({ itemPosition, fullHeight });

  return (
    <div className={clsx(classes.root, className)} style={style}>
      {children}
    </div>
  )
};

export default Card;
