import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { persistReducer } from 'redux-persist';
import { authPersistConfig } from "./persistConfig";

import * as settings from 'ducks/settings';
import * as auth from 'ducks/auth';
import * as requests from 'ducks/requests';
import * as credentials from 'ducks/credentials';
import * as codes from 'ducks/codes';
import * as dashboard from 'ducks/dashboard';
import * as sites from 'ducks/sites';
import * as employees from 'ducks/employees';
import * as contacts from 'ducks/contacts';
import * as employeeInfo from 'ducks/employeeInfo';
import * as mobilePatrols from 'ducks/mobile-patrols';
import * as checkpoints from 'ducks/checkpoints';
import * as notebookNotes from 'ducks/notebookNotes';
import * as notebookNoteTags from 'ducks/notebookNoteTags';
import * as tasks from 'ducks/tasks';
import * as taskRecords from 'ducks/taskRecords';
import * as tourLog from 'ducks/tourLog';
import * as visits from 'ducks/visits';
import * as incidentReports from 'ducks/incidentReports';
import * as clients from 'ducks/clients';
import mediaAssets from 'ducks/mediaAssets/reducer';
import selectedSite from 'ducks/selectedSite/reducer';
import * as siteInfo from 'ducks/siteInfo';
import * as siteInfoTasks from 'ducks/siteInfoTasks';
import * as siteInfoCP from 'ducks/siteInfoCP';
import * as siteInfoTours from 'ducks/siteInfoTours';
import * as siteInfoAddress from 'ducks/siteInfoAddress';
import * as irPerDate from 'ducks/incidentReportsMonth';
import * as statsPerDate from 'ducks/statsPerDate';
import * as hoursPerDate from 'ducks/hoursPerDate';
import * as activity from 'ducks/activity';
import * as activityIR from 'ducks/activityIR';
import help from 'ducks/help/help.reducer';
import * as pageStore from 'ducks/page_store';
import * as tsByClient from 'ducks/tsByClient';
import * as tsByEmployee from 'ducks/tsByEmployee';
import * as tsBySite from 'ducks/tsBySite';
import * as shifts from 'ducks/shifts';
import * as shiftInfo from 'ducks/shiftInfo';
import * as users from 'ducks/users';
import * as adminTab from 'ducks/adminTab';
import * as dev from 'ducks/devSettings';
import * as noteTypes from 'ducks/noteTypes';
import * as mainLayout from 'ducks/main-layout';
import * as accountInfo from 'ducks/account-info';
import * as planList from 'ducks/plan-list';
import * as areas from 'ducks/areas';
import * as roles from 'ducks/roles';
import * as activityMap from 'ducks/activity-map';
import * as infoNote from 'ducks/info-note';
import * as performance from 'ducks/performance';
import * as notifications from 'ducks/notifications';
import * as userManuals from 'ducks/user-manuals';
import * as forms from 'ducks/forms';
import * as formResults from 'ducks/formResults';
import * as siteEmailSettings from 'ducks/siteEmailSettings';
import * as mobilePatrolEmailSettings from 'ducks/mobilePatrolEmailSettings';
import * as clientEmailSettings from 'ducks/clientEmailSettings';
import * as attachments from 'ducks/attachments';
import * as profiles from 'ducks/profiles';
import * as tours from 'ducks/tours';
import * as schedules from 'ducks/schedules';
import * as statistics from 'ducks/statistics';
import * as auditLog from 'ducks/auditLog';
import * as companies from 'ducks/companies';
import * as visitors from 'ducks/visitors';
import * as visitorLog from 'ducks/visitorLog';
import * as taskLog from 'ducks/taskLog';
import * as tsByMobilePatrol from 'ducks/tsByMobilePatrol';
import * as analytics from 'ducks/analytics';
import * as visitLocations from 'ducks/visitLocations';
import * as biTours from 'ducks/biTours';

export default (history) => combineReducers({
  router: connectRouter(history),
  help,
  selectedSite,
  pendingMediaAssets: mediaAssets,
  [dev.SLICE_NAME]: dev.reducer,
  [areas.SLICE_NAME]: areas.reducer,
  [forms.SLICE_NAME]: forms.reducer,
  [formResults.SLICE_NAME]: formResults.reducer,
  [roles.SLICE_NAME]: roles.reducer,
  [sites.SLICE_NAME]: sites.reducer,
  [users.SLICE_NAME]: users.reducer,
  [codes.SLICE_NAME]: codes.reducer,
  [tasks.SLICE_NAME]: tasks.reducer,
  [taskRecords.SLICE_NAME]: taskRecords.reducer,
  [shifts.SLICE_NAME]: shifts.reducer,
  [tourLog.SLICE_NAME]: tourLog.reducer,
  [clients.SLICE_NAME]: clients.reducer,
  [contacts.SLICE_NAME]: contacts.reducer,
  [infoNote.SLICE_NAME]: infoNote.reducer,
  [adminTab.SLICE_NAME]: adminTab.reducer,
  [settings.SLICE_NAME]: settings.reducer,
  [requests.SLICE_NAME]: requests.reducer,
  [visits.SLICE_NAME]: visits.reducer,
  [siteInfo.SLICE_NAME]: siteInfo.reducer,
  [activity.SLICE_NAME]: activity.reducer,
  [tsBySite.SLICE_NAME]: tsBySite.reducer,
  [planList.SLICE_NAME]: planList.reducer,
  [shiftInfo.SLICE_NAME]: shiftInfo.reducer,
  [pageStore.SLICE_NAME]: pageStore.reducer,
  [dashboard.SLICE_NAME]: dashboard.reducer,
  [employees.SLICE_NAME]: employees.reducer,
  [irPerDate.SLICE_NAME]: irPerDate.reducer,
  [noteTypes.SLICE_NAME]: noteTypes.reducer,
  [mainLayout.SLICE_NAME]: mainLayout.reducer,
  [tsByClient.SLICE_NAME]: tsByClient.reducer,
  [siteInfoCP.SLICE_NAME]: siteInfoCP.reducer,
  [activityIR.SLICE_NAME]: activityIR.reducer,
  [userManuals.SLICE_NAME]: userManuals.reducer,
  [activityMap.SLICE_NAME]: activityMap.reducer,
  [credentials.SLICE_NAME]: credentials.reducer,
  [checkpoints.SLICE_NAME]: checkpoints.reducer,
  [accountInfo.SLICE_NAME]: accountInfo.reducer,
  [performance.SLICE_NAME]: performance.reducer,
  [tsByEmployee.SLICE_NAME]: tsByEmployee.reducer,
  [statsPerDate.SLICE_NAME]: statsPerDate.reducer,
  [hoursPerDate.SLICE_NAME]: hoursPerDate.reducer,
  [employeeInfo.SLICE_NAME]: employeeInfo.reducer,
  [notifications.SLICE_NAME]: notifications.reducer,
  [notebookNotes.SLICE_NAME]: notebookNotes.reducer,
  [mobilePatrols.SLICE_NAME]: mobilePatrols.reducer,
  [siteInfoTasks.SLICE_NAME]: siteInfoTasks.reducer,
  [siteInfoTours.SLICE_NAME]: siteInfoTours.reducer,
  [incidentReports.SLICE_NAME]: incidentReports.reducer,
  [siteInfoAddress.SLICE_NAME]: siteInfoAddress.reducer,
  [notebookNoteTags.SLICE_NAME]: notebookNoteTags.reducer,
  [siteEmailSettings.SLICE_NAME]: siteEmailSettings.reducer,
  [mobilePatrolEmailSettings.SLICE_NAME]: mobilePatrolEmailSettings.reducer,
  [clientEmailSettings.SLICE_NAME]: clientEmailSettings.reducer,
  [attachments.SLICE_NAME]: attachments.reducer,
  [profiles.SLICE_NAME]: profiles.reducer,
  [tours.SLICE_NAME]: tours.reducer,
  [schedules.SLICE_NAME]: schedules.reducer,
  [statistics.SLICE_NAME]: statistics.reducer,
  [auditLog.SLICE_NAME]: auditLog.reducer,
  [companies.SLICE_NAME]: companies.reducer,
  [visitors.SLICE_NAME]: visitors.reducer,
  [visitorLog.SLICE_NAME]: visitorLog.reducer,
  [taskLog.SLICE_NAME]: taskLog.reducer,
  [tsByMobilePatrol.SLICE_NAME]: tsByMobilePatrol.reducer,
  [analytics.SLICE_NAME]: analytics.reducer,
  [visitLocations.SLICE_NAME]: visitLocations.reducer,
  [biTours.SLICE_NAME]: biTours.reducer,
  [auth.SLICE_NAME]: persistReducer(authPersistConfig, auth.reducer),
  [roles.SLICE_NAME]: roles.reducer,
});
