import isEqual from 'react-fast-compare';
import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { rolesSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [rolesSuccess](state, { payload: { userType, roles } }) {
      if (isEqual(state[userType], roles)) {
        return state;
      }

      return {
        ...state,
        [userType]: roles,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState,
);
