import { all, put, call, takeLatest } from 'redux-saga/effects';

import {
  contactsRequest,
  contactsSuccess,
  contactsFailure,
  createContactFailure,
  createContactRequest,
  createContactSuccess,
  contactInfoSuccess,
  contactInfoFailure,
  contactInfoRequest,
  deleteContactSuccess,
  deleteContactFailure,
  deleteContactRequest,
  updateContactSuccess,
  updateContactFailure,
  updateContactRequest,
  updateContactFormRequest,
  updateContactFormSuccess,
  updateContactFormFailure,
} from './actions';
import axios from 'axios';
import { CONTACTS, USERS } from 'app/api-routes';
import {
  createLoginDetailsSaga,
  updateLoginDetailsSaga,
  updateExcludedEventTypesSaga,
} from 'ducks/users/sagas';
import _ from 'lodash';

function* contactsSaga({ payload: { siteID, clientID } = {} }) {
  try {
    const { payload, warnings } = yield axios.get(CONTACTS, {
      params: {
        siteID: siteID || null,
        clientID: clientID || null,
      },
    });

    yield put(contactsSuccess(payload, { warnings }));
  } catch (err) {
    yield put(contactsFailure(err));
  }
}

function* contactInfoSaga({ payload: contactID }) {
  try {
    const { payload, warnings } = yield axios.get(`${CONTACTS}/${contactID}`);

    yield put(contactInfoSuccess(payload, { warnings }));
  } catch (err) {
    yield put(contactInfoFailure(err));
  }
}

function* createContactSaga({ payload: body }) {
  try {
    const { payload, warnings } = yield axios.post(CONTACTS, { payload: body });

    yield put(createContactSuccess(payload, { warnings }));
  } catch (err) {
    yield put(createContactFailure(err));
  }
}

function* updateContactSaga({ payload: { contactID, values } }) {
  try {
    yield axios.put(`${CONTACTS}/${contactID}`, { payload: values });

    yield put(
      updateContactSuccess({
        contactID,
        payload: values,
      })
    );
  } catch (err) {
    yield put(updateContactFailure(err));
  }
}

function* deleteContactSaga({ payload: contactID }) {
  try {
    const { payload, warnings } = yield axios.delete(`${CONTACTS}/${contactID}`);

    yield put(deleteContactSuccess(payload, { warnings }));
  } catch (err) {
    yield put(deleteContactFailure(err));
  }
}

function* updateContactFormSaga({ payload: { contactID, values, callback } }) {
  try {
    const { info, loginDetails, eventTypes } = values;

    let userID = info?.userID;

    if (loginDetails.loginName && loginDetails.roleID) {
      if (userID) {
        yield call(updateLoginDetailsSaga, {
          payload: {
            userID: loginDetails.userID,
            payload: {
              ..._.omit(loginDetails, 'siteIDs'),
              userSites: {
                userSite: loginDetails?.siteIDs?.map((siteID) => ({ siteID: siteID })),
              },
            },
          },
        });
      } else {
        userID = yield call(createLoginDetailsSaga, {
          payload: {
            payload: {
              ..._.omit(loginDetails, 'siteIDs'),
              userSites: {
                userSite: loginDetails?.siteIDs?.map((siteID) => ({ siteID: siteID })),
              },
            },
          },
        });
      }
    }

    yield call(updateContactSaga, {
      payload: {
        contactID,
        values: {
          ...info,
          notes: Object.values(info.notes || {}),
          userID,
        },
      },
    });

    if (userID) {
      yield call(updateExcludedEventTypesSaga, {
        payload: {
          userID: userID,
          data: eventTypes || [],
        },
      });
    }

    let updatedLoginDetails = {};
    if (userID) {
      const { payload: responsePayload } = yield axios.get(`${USERS}/${userID}/login-details`);
      updatedLoginDetails = responsePayload;
    }

    if (callback) {
      callback({
        ...values,
        info: {
          ...values.info,
          userID,
        },
        loginDetails: {
          ...values.loginDetails,
          ...updatedLoginDetails,
          userID,
        },
        eventTypes: {
          [userID]: values.eventTypes[userID] || [],
        },
      });
    }

    yield put(updateContactFormSuccess(values));
  } catch (err) {
    yield put(updateContactFormFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(contactsRequest, contactsSaga),
    yield takeLatest(createContactRequest, createContactSaga),
    yield takeLatest(contactInfoRequest, contactInfoSaga),
    yield takeLatest(updateContactRequest, updateContactSaga),
    yield takeLatest(deleteContactRequest, deleteContactSaga),
    yield takeLatest(updateContactFormRequest, updateContactFormSaga),
  ]);
}
