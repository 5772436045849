import preparedReducer from './reducer';
import {
  requestChangePassword,
  getLoginDetails,
  updateLoginDetails,
  updateUserStatus,
  getExcludedEventTypes,
  updateExcludedEventTypes,
  createLoginDetails,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'users';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestChangePassword,
  getLoginDetails,
  updateLoginDetails,
  updateUserStatus,
  getExcludedEventTypes,
  updateExcludedEventTypes,
  createLoginDetails,
};
