import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  createVisitLocationFailure,
  createVisitLocationRequest,
  createVisitLocationSuccess,
  deleteVisitLocationFailure,
  deleteVisitLocationRequest,
  deleteVisitLocationSuccess,
  updateVisitLocationFailure,
  updateVisitLocationRequest,
  updateVisitLocationSuccess,
  updateVisitLocationStatusFailure,
  updateVisitLocationStatusRequest,
  updateVisitLocationStatusSuccess,
  visitLocationsFailure,
  visitLocationsRequest,
  visitLocationsSuccess,
} from './actions';
import axios from 'axios';
import { SITES } from 'app/api-routes';

function* visitLocationsSaga({ payload: siteID }) {
  try {
    const { payload } = yield axios.get(`${SITES}/${siteID}/visit-locations`);

    yield put(visitLocationsSuccess(payload));
  } catch (err) {
    yield put(visitLocationsFailure(err));
  }
}

function* createVisitLocationSaga({ payload: { siteID, payload } }) {
  try {
    const { payload: visitLocationID } = yield axios.post(`${SITES}/${siteID}/visit-locations`, {
      payload,
    });

    yield put(createVisitLocationSuccess(visitLocationID));
    yield call(visitLocationsSaga, { payload: siteID });
  } catch (err) {
    yield put(createVisitLocationFailure(err));
  }
}

function* updateVisitLocationSaga({ payload: { siteID, visitLocationID, payload } }) {
  try {
    yield axios.put(`${SITES}/${siteID}/visit-locations/${visitLocationID}`, {
      payload,
    });

    yield put(updateVisitLocationSuccess(visitLocationID));
    yield call(visitLocationsSaga, { payload: siteID });
  } catch (err) {
    yield put(updateVisitLocationFailure(err));
  }
}

function* updateVisitLocationStatusSaga({ payload: { siteID, visitLocationID, status } }) {
  try {
    yield axios.put(`${SITES}/${siteID}/visit-locations/${visitLocationID}/status/${status}`);

    yield put(updateVisitLocationStatusSuccess(visitLocationID));
    yield call(visitLocationsSaga, { payload: siteID });
  } catch (err) {
    yield put(updateVisitLocationStatusFailure(err));
  }
}

function* deleteVisitLocationSaga({ payload: { siteID, visitLocationID } }) {
  try {
    yield axios.delete(`${SITES}/${siteID}/visit-locations/${visitLocationID}`);

    yield put(deleteVisitLocationSuccess(visitLocationID));
    yield call(visitLocationsSaga, { payload: siteID });
  } catch (err) {
    yield put(deleteVisitLocationFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(visitLocationsRequest, visitLocationsSaga),
    yield takeLatest(createVisitLocationRequest, createVisitLocationSaga),
    yield takeLatest(updateVisitLocationRequest, updateVisitLocationSaga),
    yield takeLatest(updateVisitLocationStatusRequest, updateVisitLocationStatusSaga),
    yield takeLatest(deleteVisitLocationRequest, deleteVisitLocationSaga),
  ]);
}
