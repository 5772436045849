import preparedReducer from './reducer';
import { requestShifts, endShift } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'shifts';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestShifts,
  endShift,
};
