import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const auditLogRequest = createRequest(constants.auditLog);
export const auditLogSuccess = createSuccess(constants.auditLog);
export const auditLogFailure = createFailure(constants.auditLog);

export const auditEventRequest = createRequest(constants.auditEvent);
export const auditEventSuccess = createSuccess(constants.auditEvent);
export const auditEventFailure = createFailure(constants.auditEvent);

export const requestAuditLog = auditLogRequest;
export const requestAuditEvent = auditEventRequest;
