import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  mobilePatrolEmailSettingsRequest,
  mobilePatrolEmailSettingsSuccess,
  mobilePatrolEmailSettingsFailure,
  enableMobilePatrolEmailSettingRequest,
  enableMobilePatrolEmailSettingFailure,
  disableMobilePatrolEmailSettingRequest,
  disableMobilePatrolEmailSettingFailure,
  mobilePatrolEmailRecipientsSuccess,
  mobilePatrolEmailRecipientsFailure,
  mobilePatrolEmailRecipientsRequest,
  updateMobilePatrolEmailRecipientsRequest,
  updateMobilePatrolEmailRecipientsSuccess,
  updateMobilePatrolEmailRecipientsFailure,
} from './actions';
import axios from 'axios';
import { MOBILE_PATROLS_V2 } from 'app/api-routes';

function* mobilePatrolEmailSettingsSaga({ payload: mobilePatrolID }) {
  try {
    const { payload } = yield axios.get(`${MOBILE_PATROLS_V2}/${mobilePatrolID}/email-settings`);
    yield put(mobilePatrolEmailSettingsSuccess({ mobilePatrolID, settings: payload }));
  } catch (err) {
    yield put(mobilePatrolEmailSettingsFailure(err));
  }
}

function* enableMobilePatrolEmailSettingSaga({ payload: { mobilePatrolID, type } }) {
  try {
    yield axios.put(`${MOBILE_PATROLS_V2}/${mobilePatrolID}/email-settings/${type}/enable`);
    yield call(mobilePatrolEmailSettingsSaga, { payload: mobilePatrolID });
  } catch (err) {
    yield put(enableMobilePatrolEmailSettingFailure(err));
  }
}

function* disableMobilePatrolEmailSettingSaga({ payload: { mobilePatrolID, type } }) {
  try {
    yield axios.put(`${MOBILE_PATROLS_V2}/${mobilePatrolID}/email-settings/${type}/disable`);
    yield call(mobilePatrolEmailSettingsSaga, { payload: mobilePatrolID });
  } catch (err) {
    yield put(disableMobilePatrolEmailSettingFailure(err));
  }
}

function* mobilePatrolEmailRecipientsSaga({ payload: { mobilePatrolID, type } }) {
  try {
    const { payload } = yield axios.get(`${MOBILE_PATROLS_V2}/${mobilePatrolID}/email-recipients`, { params: { type } });
    yield put(mobilePatrolEmailRecipientsSuccess({ recipients: payload }));
  } catch (err) {
    yield put(mobilePatrolEmailRecipientsFailure(err));
  }
}

function* updateMobilePatrolEmailRecipientsSaga({ payload: { mobilePatrolID, type, values } }) {
  try {
    yield axios.post(
      `${MOBILE_PATROLS_V2}/${mobilePatrolID}/email-recipients`,
      { payload: values },
      {
        params: { type },
      }
    );

    yield call(mobilePatrolEmailSettingsSaga, { payload: mobilePatrolID });
    yield put(updateMobilePatrolEmailRecipientsSuccess({ mobilePatrolID, type, payload: values }));
  } catch (err) {
    yield put(updateMobilePatrolEmailRecipientsFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(mobilePatrolEmailSettingsRequest, mobilePatrolEmailSettingsSaga),
    yield takeLatest(enableMobilePatrolEmailSettingRequest, enableMobilePatrolEmailSettingSaga),
    yield takeLatest(disableMobilePatrolEmailSettingRequest, disableMobilePatrolEmailSettingSaga),
    yield takeLatest(mobilePatrolEmailRecipientsRequest, mobilePatrolEmailRecipientsSaga),
    yield takeLatest(updateMobilePatrolEmailRecipientsRequest, updateMobilePatrolEmailRecipientsSaga),
  ]);
}
