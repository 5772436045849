import React, { useCallback, useMemo, useState } from 'react';
import {
  Box, Typography
} from '@material-ui/core';
import {
  IconButton,
  Select,
  VDivider,
} from '.';
import {
  FirstPageIcon,
  LastPageIcon,
  ArrowLeftIcon,
  ArrowRightIcon
} from 'assets/icons';
import { useURLStore } from 'new-design/hooks/useURLStore';

import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles({
  pagination: {
    marginTop: 24,
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    [theme.breakpoints.down('lg')]: {
      marginTop: 12,
    },
  },
  pages: {
    position: 'absolute',
    height: 32,
  },
  paginationButton: {
    padding: 4,
    marginLeft: 2,
    marginRight: 2,
    minWidth: 32,
    borderRadius: '8px',
    '& .MuiButton-label': {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'center',
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
        lineHeight: '19px',
      }
    }
  },
  active: {
    padding: 3,
    border: `1px solid ${theme.palette.primary.main}`,
    '& .MuiButton-label': {
      color: theme.palette.common.black,
    }
  },
  perPage: {
    position: 'absolute',
    right: 0,
  },
  pageLabel: {
    height: 24,
    width: 125,
  },
  pageLabelItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '24px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      lineHeight: '19px',
    }
  },
  nextPage: {
  },
  previousPage: {
  },
  dots: {
    display: 'flex',
    color: theme.palette.secondary.main,
    alignItems: 'center',
    justifyContent: 'center',
    width: 22,
  }
}), {
  name: 'Pagination',
});

const perPageOptions = [
  {
    key: 10,
    label: 10,
  },
  {
    key: 20,
    label: 20,
  },
  {
    key: 30,
    label: 30,
  },
  {
    key: 40,
    label: 40,
  },
];

const DEFAULT_PAGE_SIZE = 10;

const Pagination = ({
  canPreviousPage,
  previousPage,
  gotoPage,
  pageIndex,
  nextPage,
  canNextPage,
  setPageSize,
  pageCount,
  totalRows,
  disabledPageSize,
  pageSize
}) => {
  const classes = useStyles();
  const { addURLParam, deleteURLParam } = useURLStore();
  const [localPageSize, setLocalPageSize] = useState(DEFAULT_PAGE_SIZE);

  const toPage = useCallback((numberOfPage) => {
    addURLParam('page', numberOfPage);
    gotoPage(numberOfPage);
  }, [gotoPage]);

  const countStartRange = useMemo(() => {
    return (pageIndex * localPageSize) + 1;
  }, [pageIndex, localPageSize]);

  const countEndRange = useMemo(() => {
    const countedNumber = (pageIndex + 1) * localPageSize;
    return countedNumber > totalRows ? totalRows : countedNumber;
  }, [pageIndex, localPageSize, totalRows]);

  const handleNextPage = useCallback(() => {
    addURLParam('page', pageIndex + 1);
    nextPage();
  }, [pageIndex]);

  const handlePreviousPagePage = useCallback(() => {
    if (pageIndex - 1 === 0) {
      deleteURLParam('page');
    } else {
      addURLParam('page', pageIndex - 1);
    }
    previousPage();
  }, [pageIndex]);

  return (
    <Box className={classes.pagination}>
      <Box className={classes.pages} display="flex">
        {/** Go to first page */}
        <IconButton className={classes.nextPage} onClick={() => toPage(0)} disabled={!canPreviousPage}>
          <FirstPageIcon />
        </IconButton>
        {/** Go to previous page */}
        <IconButton className={classes.nextPage} onClick={() => handlePreviousPagePage()} disabled={!canPreviousPage}>
          <ArrowLeftIcon />
        </IconButton>
        <Box display="flex" alignItems="center" justifyContent="center">
          {/** Current page view */}
          <Box display="flex" justifyContent="center" className={classes.pageLabel}>
            <Typography component="span" color="secondary" className={classes.pageLabelItem}>
              {countStartRange} - {countEndRange}
            </Typography>
            <VDivider />
            <Typography component="span" color="secondary" className={classes.pageLabelItem}>{totalRows}</Typography>
          </Box>
          {/** Go to next page */}
          <IconButton className={classes.previousPage} onClick={() => handleNextPage()} disabled={!canNextPage}>
            <ArrowRightIcon />
          </IconButton>
          {/** Go to last page */}
          <IconButton className={classes.previousPage} onClick={() => toPage(pageCount - 1)} disabled={!canNextPage}>
            <LastPageIcon />
          </IconButton>
        </Box>
      </Box>
      {
        !disabledPageSize && (
          <Box className={classes.perPage}>
            <Select variant="outlined" onChange={(value) => {
              setPageSize(value);
              setLocalPageSize(value);
              deleteURLParam('page');
              if (value === DEFAULT_PAGE_SIZE) {
                deleteURLParam('pageSize');
              } else {
                addURLParam('pageSize', value);
              }
            }} options={perPageOptions} defaultValue={pageSize || DEFAULT_PAGE_SIZE} />
          </Box>
        )
      }
    </Box>
  )
};

export { Pagination };
