import React from 'react';
import { Field } from 'formik';
import { Checkbox } from 'new-design/common/ui';

export const FormCheckboxField = ({
  label,
  name,
  className,
  labelPlacement,
  value,
  disabled,
  ...rest
}) => (
  <Field
    type="checkbox"
    name={name}
    value={value}
    render={
      ({ field }) => {
        return (
          <Checkbox
            {...field}
            {...rest}
            label={label}
            className={className}
            labelPlacement={labelPlacement}
            disabled={disabled}
          />
        )
      }
    }
  />
);
