import React, { useCallback, useRef, useState } from 'react';
import DateTimePickerNew from 'react-datepicker';
import { createStyles, ClickAwayListener, Popper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ArrowDownIcon, ArrowUpIcon, CalendarIcon, RefreshIcon } from 'assets/icons';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ru';
import 'moment/locale/ro';
import 'moment/locale/th';
import 'moment/locale/hr';
import './react-datepicker.css';
import * as settings from 'ducks/settings';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';

import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import ru from 'date-fns/locale/ru';
// import hr from 'date-fns/locale/hr';
import ro from 'date-fns/locale/ro';
import th from 'date-fns/locale/th';
registerLocale('es', es);
registerLocale('ru', ru);
// registerLocale('hr', hr);
registerLocale('th', th);
registerLocale('ro', ro);

import { CustomHeader } from './CustomHeader';
import { CustomTimeInput } from './CustomTimeInput';
import { CustomInput } from './CustomInput';
import { TextInput } from '../TextInput';
import { IconButton } from '../IconButton';
import { DATE_FORMAT, DATE_TIME_FORMAT_SHORT } from 'app/types/constants';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      pickerInput: {
        '& .MuiInputBase-input': {
          textAlign: 'start !important',
        },
      },
      icon: {
        '& path': {
          fill: theme.palette.secondary.main,
        },
      },
    }),
  {
    name: 'DateTimePicker',
  }
);

function DateTimePicker({
  onChange,
  label,
  date,
  infinityEndDate,
  dateOnly,
  readOnly,
  name,
  defaultValue,
  className,
  emptyInput,
  emptyPlaceholder,
  hideRefresh,
}) {
  const classes = useStyles();

  const { locale } = useSelector(settings.selectors);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  const tDate = !isNil(date) ? date : !isNil(defaultValue) ? moment(defaultValue).toDate() : null;

  const handleClick = useCallback((e) => {
    e.preventDefault();
    setIsOpen((idOpenState) => !idOpenState);
  }, []);

  const handleChange = useCallback(
    (selectedDate) => {
      if (!moment(selectedDate).isSame(moment(tDate))) {
        onChange(selectedDate);
      }
    },
    [date, defaultValue, tDate]
  );

  const handleAwayClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <ClickAwayListener onClickAway={handleAwayClick}>
        <Box className={className}>
          <TextInput
            fullWidth
            inputRef={inputRef}
            icon={<CalendarIcon className={classes.icon} />}
            endIcon={
              <Box display="flex" flexDirection="row" alignItems="center">
                {emptyInput && !hideRefresh && (
                  <Box mr={1}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(undefined);
                      }}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Box>
                )}
                <Box>{isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}</Box>
              </Box>
            }
            readOnly
            label={label}
            className={classes.pickerInput}
            type="button"
            disabled={readOnly}
            onClick={handleClick}
            value={
              tDate ? moment(tDate).format(dateOnly ? DATE_FORMAT : DATE_TIME_FORMAT_SHORT) : emptyPlaceholder
            }
          />
          <Popper
            open={isOpen}
            anchorEl={inputRef.current}
            placement="bottom"
            style={{
              zIndex: 2000,
            }}
          >
            <DateTimePickerNew
              inline
              locale={locale?.locale}
              selected={tDate}
              onChange={handleChange}
              minDate={null}
              maxDate={infinityEndDate ? null : new Date()}
              customInput={<CustomInput />}
              renderCustomHeader={(renderProps) => (
                <CustomHeader
                  infinityEndDate={infinityEndDate}
                  name={name}
                  {...renderProps}
                  dateChange={handleChange}
                />
              )}
              timeInputLabel=""
              showTimeInput={!dateOnly}
              customTimeInput={<CustomTimeInput />}
            />
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
}

export { DateTimePicker };
