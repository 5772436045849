import { handleActions } from 'redux-actions';

import {
  initPlanList,
  clearPlanList,
  addCheckpoint,
  addArea,
  setRefEl,
  renameArea,
  renameCheckpoint,
  setAreaActive,
  deleteArea,
  updateNode,
  setSelectedItem,
  setSelectedTab,
  setMarkerEnabled,
  setMarker,
  setDraggableMode,
} from './actions';
import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';
import constants from './constants';

function insertInTree(tree, { parentID, insertItem }) {
  tree = tree.map((el) => {
    if (el.id === parentID) {
      if (el.children) {
        el.children.push(insertItem);
      } else {
        el.children = [insertItem];
      }
      return el;
    }
    if (el.children) {
      el.children = insertInTree(el.children, { parentID, insertItem });
    }
    return el;
  });

  return tree;
}

function deleteItemInTree(tree, { id }) {
  tree = tree.filter((el) => {
    if (el.children) {
      el.children = deleteItemInTree(el.children, { id });
    }
    return el.id !== id;
  });

  return tree;
}

function updateItemInTree(tree, { id, name, settings }) {
  tree = tree.map((el) => {
    if (el.id === id) {
      if (name) {
        el.name = name;
      }
      if (settings) {
        el.settings = {
          ...el.settings,
          ...settings,
        };
      }
      return el;
    }
    if (el.children) {
      el.children = updateItemInTree(el.children, { id, name });
    }
    return el;
  });

  return tree;
}

export function filterTree(node, filter, checkSub, array) {
  if (!array) {
    array = [];
  }

  if (filter(node)) {
    array.push(node);
  } else if (!checkSub) {
    return array;
  }

  for (let i = 0; i < node?.children?.length; ++i) {
    filterTree(node.children[i], filter, checkSub, array);
  }

  return array;
}

export default handleActions(
  {
    [initPlanList](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [addCheckpoint](state, { payload: { parentID, name, id } }) {
      if (!state.list) {
        throw new Error('Please, initialize root list');
      }

      return {
        ...state,
        list: insertInTree(state.list, {
          parentID,
          insertItem: {
            type: constants.CHECKPOINT,
            name,
            id: `area-${id}`,
            settings: {
              id,
            },
          },
        }),
      };
    },
    [addArea](state, { payload: { parentID, name, id } }) {
      if (!state.list) {
        throw new Error('Please, initialize root list');
      }

      return {
        ...state,
        list: insertInTree(state.list, {
          parentID,
          insertItem: {
            type: constants.AREA,
            name,
            id: `area-${id}`,
            settings: {
              id,
            },
          },
        }),
      };
    },
    [renameArea](state, { payload: { name, id } }) {
      if (!state.list) {
        throw new Error('Please, initialize root list');
      }

      return {
        ...state,
        list: updateItemInTree(state.list, { id, name }),
      };
    },
    [renameCheckpoint](state, { payload: { name, id } }) {
      if (!state.list) {
        throw new Error('Please, initialize root list');
      }

      return {
        ...state,
        list: updateItemInTree(state.list, { id, name }),
      };
    },
    [deleteArea](state, { payload: { id } }) {
      if (!state.list) {
        throw new Error('Please, initialize root list');
      }

      return {
        ...state,
        list: deleteItemInTree(state.list, { id }),
      };
    },
    [setAreaActive](state, { payload: { id, active } }) {
      if (!state.list) {
        throw new Error('Please, initialize root list');
      }

      return {
        ...state,
        list: updateItemInTree(state.list, { id, settings: { active } }),
      };
    },
    [updateNode](state, { payload: { id, name, settings } }) {
      if (!state.list) {
        throw new Error('Please, initialize root list');
      }

      return {
        ...state,
        list: updateItemInTree(state.list, { id, name, settings }),
      };
    },
    [clearPlanList](state) {
      return {
        ...state,
        list: null,
      };
    },
    [setSelectedItem](state, { payload }) {
      return {
        ...state,
        selectedItem: payload,
      };
    },
    [setSelectedTab](state, { payload }) {
      return {
        ...state,
        selectedTab: payload,
        markerEnabled: false,
      };
    },
    [setMarkerEnabled](state, { payload }) {
      return {
        ...state,
        marker: payload ? state.marker : null,
        markerEnabled: payload,
      };
    },
    [setRefEl](state, { payload }) {
      return {
        ...state,
        refEl: payload,
      };
    },
    [setMarker](state, { payload }) {
      return {
        ...state,
        marker: payload,
      };
    },
    [setDraggableMode](state, { payload }) {
      return {
        ...state,
        draggableMode: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
