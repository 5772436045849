import React from 'react';
import { Route } from 'react-router-dom';
// TODO: refactoring.
import asyncComponent from 'util/asyncComponent';

const MonitorRoutes = ({ match }) =>
  <>
    <Route path={`${match.url}/activity-monitor/visit`} exact component={(asyncComponent(() => import('../pages/subpages/Visit')))}/>
    <Route path={`${match.url}/activity-monitor/tour`} exact component={(asyncComponent(() => import('../pages/subpages/Tour')))}/>
    <Route path={`${match.url}/activity-monitor/info-note-created`} exact component={(asyncComponent(() => import('../pages/subpages/InfoNote')))}/>
    <Route path={`${match.url}/activity-monitor/info-note-ack`} exact component={(asyncComponent(() => import('../pages/subpages/InfoNote')))}/>
    <Route path={`${match.url}/activity-monitor/note-created`} exact component={(asyncComponent(() => import('../pages/subpages/NoteCreated')))}/>
    <Route path={`${match.url}/activity-monitor/task-completed`} exact component={(asyncComponent(() => import('../pages/subpages/TaskCompleted')))}/>
    <Route path={`${match.url}/activity-monitor/shift-end`} exact component={(asyncComponent(() => import('../pages/subpages/ShiftEnded')))}/>
    <Route path={`${match.url}/activity-monitor/checkpoint-scanned`} exact component={(asyncComponent(() => import('../pages/subpages/CheckpointScanned')))}/>
    <Route path={`${match.url}/activity-monitor/shift-started`} component={(asyncComponent(() => import('../pages/subpages/ShiftStarted')))}/>
    <Route path={`${match.url}/activity-monitor/gps-alert`} exact component={(asyncComponent(() => import('../pages/subpages/GPSAlert')))} />
    <Route path={`${match.url}/activity-monitor/no-activity`} exact component={(asyncComponent(() => import('../pages/subpages/NoActivity')))} />
    <Route path={`${match.url}/activity-monitor/alarm`} exact component={(asyncComponent(() => import('../pages/subpages/Alarm')))} />
    <Route path={`${match.url}/activity-monitor`} exact component={(asyncComponent(() => import('../pages/monitoring/ActivityMonitor')))}/>

    {/* <Route path={`${match.url}/activity-map`} exact component={(asyncComponent(() => import('../pages/monitoring/ActivityMap')))}/> */}
    <Route path={`${match.url}/activity-map`} exact component={(asyncComponent(() => import('../pages/monitoring/ActivityMapV2')))}/>
    <Route path={`${match.url}/control-panel`} component={(asyncComponent(() => import('../pages/monitoring/ControlPanel')))}/>
  </>;

export { MonitorRoutes };
