import { handleActions } from 'redux-actions';
import { contactInfoSuccess, contactsSuccess, updateContactSuccess } from './actions';
import { logoutSuccess } from 'ducks/auth/actions';

import initialState from './states';

export default handleActions(
  {
    [contactsSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [contactInfoSuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.contactID]: payload,
        },
      };
    },
    [updateContactSuccess](state, { payload: { contactID, payload } }) {
      return {
        ...state,
        info: {
          ...state.info,
          [contactID]: {
            ...state.info[contactID],
            ...payload,
          },
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
