import React from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => createStyles(
  {
    root: {
      height: '100%',
      [theme.breakpoints.down('lg')]: {
        '& svg': {
          maxHeight: 20,
          maxWidth: 20,
        },
      },
    },
    label: {
      textTransform: 'initial',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        lineHeight: '21px',
      },
    },
    success: {
      background: '#00B400',
      boxShadow: '0px 4px 20px rgba(2, 230, 11, 0.5)',
      borderRadius: 8,
      color: theme.palette.common.white,
      padding: 12,
      minWidth: 190,

      '&:hover': {
        color: '#00B400',
        background: theme.palette.common.white,
        border: `1px solid #00B400`,
      }
    }
  }
), {
  name: 'Button'
});

export const Button = ({ children, buttonType, className, ...rest }) => {
  const classes = useStyles();

  return (
    <MaterialButton
      {...rest}
      className={clsx(className, {
        [classes.success]: buttonType === 'success',
      })}
      classes={{
        root: classes.root,
        label: classes.label,
      }}
    >
      {children}
    </MaterialButton>
  );
}
