import { handleActions } from 'redux-actions';

import {
  mobilePatrolsSuccess,
  mobilePatrolInfoSuccess,
  deselectMobilePatrols,
  updateMobilePatrolsStatusSuccess,
} from './actions';
import { logoutSuccess } from 'ducks/auth/actions';

import initialState from './states';

export default handleActions(
  {
    [mobilePatrolsSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [mobilePatrolInfoSuccess](state, { payload: { data } }) {
      return {
        ...state,
        selected: data,
      };
    },
    [deselectMobilePatrols](state) {
      return {
        ...state,
        selected: null,
      };
    },
    [updateMobilePatrolsStatusSuccess](state, { payload: { status } }) {
      return {
        ...state,
        selected: {
          ...state.selected,
          status,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
