import { handleActions } from 'redux-actions';
import { switchAdminTab, setAdminTabsList, setDisabledTabs, setPreSubmitFlag } from './actions';
import initialState from './states';
import _ from 'lodash';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [switchAdminTab](state, { payload }) {
      if (_.isEqual(state.tab, payload)) {
        return state;
      }

      return {
        ...state,
        tab: payload
      };
    },
    [setAdminTabsList](state, { payload }) {
      if (_.isEqual(state.tabsList, payload)) {
        return state;
      }

      return {
        ...state,
        tabsList: payload
      };
    },
    [setDisabledTabs](state, { payload }) {
      if (_.isEqual(state.tabsList, payload)) {
        return state;
      }

      return {
        ...state,
        disabledTabs: payload
      };
    },
    [setPreSubmitFlag](state, { payload }) {
      if (_.isEqual(state.tabsList, payload)) {
        return state;
      }

      return {
        ...state,
        isPreSubmitFlag: payload
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState,
);
