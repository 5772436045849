import axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { getPerformanceFailure, getPerformanceRequest,  getPerformanceSuccess } from './actions';
import { PERFORMANCE } from 'app/api-routes';

function* getPerformanceSaga({ payload: {
  eventType,
  entityType,
  fromDate,
  toDate,
} }) {
  try {
    const { payload } = yield axios.get(PERFORMANCE, {
      params: {
        eventType,
        entityType,
        fromDate,
        toDate,
      },
    });

    yield put(
      getPerformanceSuccess(payload),
    );
  } catch (err) {
    yield put(getPerformanceFailure(err));
  }
}

export default function*() {
  yield takeEvery(getPerformanceRequest, getPerformanceSaga);
}
