export default Object.freeze({
  termsInfo: {},
  menu: [],
  newAppMenu: [],
  permissions: [],
  userInfo: {},
  shiftInfo: {},
  sessionID: undefined,
  gpsStamping: false,
  manDownDetection: false,
  showExpired: false,
});

