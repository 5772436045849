import { combineActions, handleActions } from 'redux-actions';
import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';
import { mediaAssetDescriptionSuccess } from './actions';

export default handleActions(
  {
    [mediaAssetDescriptionSuccess](state, { payload: { mediaAssetID, description } }) {
      return {
        ...state,
        descriptions: {
          ...state.descriptions,
          [mediaAssetID]: description,
        },
      };
    },
    [combineActions(logoutSuccess)]() {
      return initialState;
    },
  },
  initialState
);
