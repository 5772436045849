import React, { useCallback } from 'react';
import * as auth from 'ducks/auth';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LOGIN_PAGE } from 'new-design/routes/common/constants';

const SecuredRoute = ({ component: Component, ...rest }) => {
  const { sessionID } = useSelector(auth.selectors);

  const renderHandler = useCallback((props) => {
    if (sessionID) {
      return <Component {...props} />;
    }
    return (
      <Redirect
        to={{
          pathname: LOGIN_PAGE,
          state: { from: props.location }
        }}
      />
    );
  }, [sessionID]);

  return <Route {...rest} render={renderHandler} />;
}

export { SecuredRoute };
