import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const tsBySiteRequest = createRequest(constants.tsBySite);

export const tsBySiteSuccess = createSuccess('TS_BY_SITE_SUCCESS');

export const tsBySiteFailure = createFailure('TS_BY_SITE_FAILURE');

export const requestTsBySite = tsBySiteRequest;
