import React from 'react';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      verticalDivider: {
        height: ({ height }) => height || 'inherit',
        width: ({ width }) => width || 1,
        background: ({ color }) => color || '#EEEEEE',
        margin: ({ margin }) => `0 ${margin}px`,
        [theme.breakpoints.down('lg')]: {
          margin: ({ margin }) => `0 ${margin / 2}px`,
        },
      },
    }),
  {
    name: 'VDivider',
  }
);

const VDivider = ({ height, color, width, margin = 16 }) => {
  const classes = useStyles({ height, color, width, margin });

  return <div className={classes.verticalDivider} />;
};

export { VDivider };
