import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import {
  siteInfoSuccess,
  updateSiteStatusSuccess,
  getRemindersSuccess,
  updateRemindersSuccess,
  updateNoteSuccess,
} from './actions';
import initialState from './states';
import _ from 'lodash';
import { updateMediaAssetSuccess } from 'ducks/mediaAssets/actions';
import { MediaAssetTables } from 'ducks/mediaAssets/types';

export default handleActions(
  {
    [logoutSuccess]() {
      return {
        ...initialState,
      };
    },
    [siteInfoSuccess](state, { payload }) {
      return {
        ...state,
        [payload.siteID]: {..._.cloneDeep(payload)},
      };
    },
    [getRemindersSuccess](state, { payload: { id, item } }) {
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [id]: item,
        },
      };
    },
    [updateRemindersSuccess](state, { payload: { id, item } }) {
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [id]: {
            ...state.reminders?.[id],
            ...item,
          },
        },
      };
    },
    [updateNoteSuccess](state, { payload: { id, item } }) {
      return {
        ...state,
        [id]: {
          ...state?.[id],
          notes: state?.[id]?.notes.map((el) => {
            if (el.typeID === item.typeID) {
              return {
                ...el,
                ...item,
              }
            } else {
              return el;
            }
          })
        }
      };
    },
    [updateSiteStatusSuccess](state, { payload: { status, siteID }}) {
      return {
        ...state,
        [siteID]: {
          ...state[siteID],
          status
        },
      };
    },
    [updateMediaAssetSuccess](state, { payload: { mediaAssetInfo }}) {
      const { entityID, tableName, mediaAssetID, description } = mediaAssetInfo;

      if (!(tableName === MediaAssetTables.SITES)) {
        return state;
      }

      return {
        ...state,
        [entityID]: {
          ...state[entityID],
          photos: state[entityID].photos.map((item) => (item.mediaAssetID === mediaAssetID ? { ...item, description } : item))
        }
      }
    }
  },
  {
    ...initialState,
  },
);
