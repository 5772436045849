import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const tourLogRequest = createRequest(constants.tourLog);
export const tourLogSuccess = createSuccess(constants.tourLog);
export const tourLogFailure = createFailure(constants.tourLog);

export const tourLogReportRequest = createRequest(constants.tourLogReport);
export const tourLogReportSuccess = createSuccess(constants.tourLogReport);
export const tourLogReportFailure = createFailure(constants.tourLogReport);

export const requestTourLog = tourLogRequest;
export const requestTourLogReport = tourLogReportRequest;
