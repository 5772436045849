import React from 'react';
import { Checkbox as MaterialCheckbox, FormControlLabel, makeStyles, createStyles } from '@material-ui/core';
import { CheckboxBorder, CheckboxChecked } from 'assets/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    disabled: {
      '& path': {
        fill: theme.palette.secondary.main,
        stroke: `${theme.palette.primary.contrastText} !important`,
      },
      '& rect': {
        fill: theme.palette.secondary.main,
      },
    },
  })
);

export const Checkbox = ({
  label,
  handleChange,
  onChange,
  className,
  checked,
  labelPlacement,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      label={label}
      className={className}
      labelPlacement={labelPlacement}
      control={
        <MaterialCheckbox
          {...rest}
          disabled={disabled}
          checked={checked}
          onChange={onChange || handleChange}
          icon={<CheckboxBorder />}
          checkedIcon={<CheckboxChecked />}
          className={clsx(rest.className, {
            [classes.disabled]: disabled && checked,
          })}
        />
      }
    />
  );
};
