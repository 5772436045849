import { handleActions } from 'redux-actions';
import initialState from './states';

import { entitiesSummarySuccess, eventsSummarySuccess, entityOverviewSuccess, eventOverviewSuccess } from './actions';

export default handleActions(
  {
    [entitiesSummarySuccess](state, { payload }) {
      return {
        ...state,
        entitiesSummary: payload,
      };
    },
    [eventsSummarySuccess](state, { payload }) {
      return {
        ...state,
        eventsSummary: payload,
      };
    },
    [entityOverviewSuccess](state, { payload }) {
      return {
        ...state,
        entityOverview: payload,
      };
    },
    [eventOverviewSuccess](state, { payload }) {
      return {
        ...state,
        eventOverview: payload,
      };
    },
  },
  initialState
);
