import { getActiveClientsSelect, getActiveSitesSelect } from "app/selectors/genericSelector";
import * as activityIR from 'ducks/activityIR';
import { createSelector } from 'reselect';
import { formatUTCInLocal } from 'util/formatting';
import colors from 'styles/themes/colors';
import { getCodes } from 'ducks/codes';
import CodeTypes from 'ducks/codes/types';
import { DATE_TIME_FORMAT, IR_STATUSES } from "app/types/constants";

export const eventTypeIconColor = function (eventType) {
  switch (eventType) {
    case IR_STATUSES.APPROVED:
      return 'green';
    case IR_STATUSES.NEW:
      return 'orange';
    default:
      return colors.primary;
  }
}

export const safeGetClient = (clients, clientId) => {
  if (clients?.find(o => o.key === clientId) !== undefined)
    return clients?.find(o => o.key === clientId).label
  else
    return "Site Unknown";
}

export const getIncidentReportItems = createSelector(
  getActiveClientsSelect,
  getActiveSitesSelect,
  state => getCodes(state, CodeTypes.INCIDENT_TYPES),
  activityIR.selectors,
  (clients, sites, codes, { items: incidentReportsMap }) => {
    const incidentReports = Object.values(incidentReportsMap);

    return incidentReports
      .sort((first, second) => first.creationDate > second.creationDate)
      .map(item => {
        return {
          irNumber: item.irNumber,
          iconName: 'clipboard',
          eventTypeIcon : 'clipboard',
          eventTypeColor : eventTypeIconColor(item.status),
          incidentTitle: item.incidentTitle,
          incidentTypeTitles: item.incidentTypeTitles,
          incidentTypeIDs: item.incidentTypeIDs,
          eventTime: formatUTCInLocal(item.incidentDate, DATE_TIME_FORMAT),
          client: safeGetClient(clients, item.clientId),
          site: sites?.find(o => o.key === item.siteId) === undefined ? 'unknown' :  sites?.find(o => o.key === item.siteId).label,
          siteId: item.siteId,
          clientId: item.clientId,
          authorName: item.authorName,
          authorId: item.authorId,
          reportId: item.reportId,
          employee: item.employee,
          status: item.status,
          approved: item.status === IR_STATUSES.APPROVED,
          new: item.status === IR_STATUSES.NEW,
        };
      });
  },
);

export const getCategories = createSelector(
  state => getCodes(state, CodeTypes.INCIDENT_TYPES),
  (codes) => {
    return codes?.map(({ id: key, description: label }) => (
      {
        key,
        label,
      }
    ));
  },
);
