import isEqual from 'react-fast-compare';
import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import { activityIRSuccess, setRequestState } from './actions';
import initialState from './states';

export default handleActions(
  {
    [setRequestState](state, { payload }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
    [activityIRSuccess](state, { payload }) {
      if (isEqual(state.items, payload)) {
        return state;
      }

      return {
        ...state,
        items: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState,
);
