import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import {  ClockIcon } from "assets/icons";
import moment from 'moment';
import { Selector } from './Selector';

const prepareNumber = (number) => ('0' + number).slice(-2)
export const CustomTimeInput = ({ date, onChange }) => {
  const hourOptions = useMemo(() => Array.from({ length: 24 }, (v, i) => ({ option: prepareNumber(i), index: i })), []);
  const mninuteOptions = useMemo(() => Array.from({ length: 60 }, (v, i) => ({ option: prepareNumber(i), index: i })), []);

  const [hours, setHours] = useState(isNaN(moment(date).hours()) ? '00' : prepareNumber(moment(date).hours()));
  const [minutes, setMinutes] = useState(isNaN(moment(date).minutes()) ? '00' : prepareNumber(moment(date).minutes()));

  useEffect(() => {
    onChange(`${hours}:${minutes}`);
  }, [hours, minutes]);

  return (
    <Box display="flex" width="100%" justifyContent="center" position="relative">
      <Box position="absolute" style={{
        left: 12,
        top: 6,
      }}>
        <ClockIcon />
      </Box>
      <Selector
        options={hourOptions}
        value={hours}
        name="hours"
        alignOptionsToBottom
        inputType="select"
        onChange={setHours}
      />
      <Box marginRight={1} marginLeft={1} display="flex" alignItems="center">
        <Typography>:</Typography>
      </Box>
      <Selector
        options={mninuteOptions}
        value={minutes}
        name="minutes"
        alignOptionsToBottom
        inputType="select"
        onChange={setMinutes}
      />
    </Box>
  );
}
