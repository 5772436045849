export default Object.freeze({
  list: null,
  refEl: null,

  selectedItem: null,
  selectedTab: null,
  markerEnabled: false,
  draggableMode: false,

  marker: null,
});
