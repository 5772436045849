import preparedReducer from './reducer';
import { requestDashboard } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'dashboard'; // activityMonitoring

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestDashboard,
};
