import preparedReducer from './reducer';
import { requestTaskLogEvent } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'taskLog';

export const selectors = (state) => state[SLICE_NAME];

export const selectTaskLogEvent = (state, taskLogID) => state[SLICE_NAME].info[taskLogID] || {};

export const actions = {
  requestTaskLogEvent,
};
