import { handleActions } from 'redux-actions';
import { activitySuccess, activityByShiftIDSuccess } from './actions';
import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [activitySuccess](state, { payload }) {
      return {
        ...state,
        list: payload || [],
      };
    },
    [activityByShiftIDSuccess](state, { payload: { shiftLogID, data} }) {
      return {
        ...state,
        [shiftLogID]: data,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState,
);
