import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const visitorsRequest = createRequest(constants.visitors);
export const visitorsSuccess = createSuccess(constants.visitors);
export const visitorsFailure = createFailure(constants.visitors);

export const visitorRequest = createRequest(constants.visitor);
export const visitorSuccess = createSuccess(constants.visitor);
export const visitorFailure = createFailure(constants.visitor);

export const createVisitorRequest = createRequest(constants.createVisitor);
export const createVisitorSuccess = createSuccess(constants.createVisitor);
export const createVisitorFailure = createFailure(constants.createVisitor);

export const updateVisitorRequest = createRequest(constants.updateVisitor);
export const updateVisitorSuccess = createSuccess(constants.updateVisitor);
export const updateVisitorFailure = createFailure(constants.updateVisitor);

export const deleteVisitorRequest = createRequest(constants.deleteVisitor);
export const deleteVisitorSuccess = createSuccess(constants.deleteVisitor);
export const deleteVisitorFailure = createFailure(constants.deleteVisitor);

export const updateVisitorStatusRequest = createRequest(constants.updateVisitorStatus);
export const updateVisitorStatusSuccess = createSuccess(constants.updateVisitorStatus);
export const updateVisitorStatusFailure = createFailure(constants.updateVisitorStatus);

export const visitorQRCodeDataRequest = createRequest(constants.visitorQRCodeData);
export const visitorQRCodeDataSuccess = createSuccess(constants.visitorQRCodeData);
export const visitorQRCodeDataFailure = createFailure(constants.visitorQRCodeData);

export const updateVisitorQRTagRequest = createRequest(constants.updateVisitorQRTag);
export const updateVisitorQRTagSuccess = createSuccess(constants.updateVisitorQRTag);
export const updateVisitorQRTagFailure = createFailure(constants.updateVisitorQRTag);

export const sendVisitorQRCodeRequest = createRequest(constants.sendVisitorQRCode);
export const sendVisitorQRCodeSuccess = createSuccess(constants.sendVisitorQRCode);
export const sendVisitorQRCodeFailure = createFailure(constants.sendVisitorQRCode);

export const requestVisitors = visitorsRequest;
export const requestVisitor = visitorRequest;
export const createVisitor = createVisitorRequest;
export const updateVisitor = updateVisitorRequest;
export const deleteVisitor = deleteVisitorRequest;
export const updateVisitorStatus = updateVisitorStatusRequest;
export const requestVisitorQRCodeData = visitorQRCodeDataRequest;
export const updateVisitorQRTag = updateVisitorQRTagRequest;
export const sendVisitorQRCode = sendVisitorQRCodeRequest;
