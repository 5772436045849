import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const noteTypesRequest = createRequest(constants.noteTypes);
export const noteTypesSuccess = createSuccess(constants.noteTypes);
export const noteTypesFailure = createFailure(constants.noteTypes);

export const noteTypesUpdateRequest = createRequest(`${constants.noteTypes}_UPDATE`);

export const requestNoteTypes = noteTypesRequest;
export const requestNoteTypesUpdate = noteTypesUpdateRequest;
