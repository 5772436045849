import { all, put, takeLatest } from 'redux-saga/effects';
import { formatDateTimeUTC, formatMonthIntervalUTC } from 'util/formatting';

import { tsByEmployeeFailure, tsByEmployeeRequest, tsByEmployeeSuccess } from './actions';
import { TIMESHEETS_BY_EMPLOYEE } from 'app/api-routes';
import axios from 'axios';
import { SERVER_DATE_TIME_FORMAT } from 'app/types/constants';

function* tsByEmployeeSaga({ payload: { date, startDate, endDate } }) {
  let fromDate, toDate;
  if (startDate != null && endDate != null) {
    fromDate = formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT);
    toDate = formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT);
  } else {
    [fromDate, toDate] = formatMonthIntervalUTC(date);
  }

  try {
    const { payload } = yield axios.get(TIMESHEETS_BY_EMPLOYEE, {
      params: {
        fromDate,
        toDate,
      }
    });

    yield put(tsByEmployeeSuccess(payload));
  } catch (err) {
    yield put(tsByEmployeeFailure(err));
  }
}

export default function* () {
    yield all([yield takeLatest(tsByEmployeeRequest, tsByEmployeeSaga)]);
}
