import messages from '../locales/ro_RO.json';

const saLang = {
    messages: {
        ...messages
    },
    locale: 'ro',
   
};
export default saLang;
