import React from 'react';
import { ArrowRightIcon, PaperIcon } from 'assets/icons';
import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      borderBottom: `2px solid #F7F7F7`,
      padding: '24px 0',
      '&:last-child': {
        borderBottom: `2px solid transparent`,
      },
      '& .name': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .label': {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '24px',
        color: '#222222',
      },
      '& .icon': {
        marginRight: 8,
        width: '24px !important',
        height: '24px !important',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '12px 0',
        '& .icon': {
          width: '20px !important',
          height: '20px !important',
        },
        '& .label': {
          fontWeight: 'normal',
          fontSize: 12,
          lineHeight: '18px',
          color: '#222222',
        },
      },
      '& .stroke path': {
        stroke: '#222',
      },
      '& .fill path': {
        fill: theme.palette.secondary.main,
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(138, 138, 138, 0.2)'
      }
    },

  },
), {
  name: 'NoteListItem',
});

const Item = ({ item, labelField, keyField, onItemClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={() => onItemClick(item[keyField])}>
      <div className="name">
        <PaperIcon className="icon fill" />
        <Typography className="label">{item[labelField]}</Typography>
      </div>
      <ArrowRightIcon className="stroke" />
    </div>
  );
}

export default Item;
