import { SITES } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import {
  siteInfoFailure,
  siteInfoRequest,
  siteInfoSuccess,

  updateSiteStatusRequest,
  updateSiteStatusSuccess,
  updateSiteStatusFailure,

  getRemindersRequest,
  getRemindersSuccess,
  getRemindersFailure,

  makeMasterCheckpointsRequest,
  makeMasterCheckpointsFailure,

  removeMasterCheckpointsRequest,
  removeMasterCheckpointsFailure,

  deleteCheckpointsRequest,
  deleteCheckpointsFailure,

  updateRemindersRequest,
  updateRemindersSuccess,
  updateRemindersFailure,

  updateNoteRequest,
  updateNoteSuccess,
  updateNoteFailure,

  deleteSiteRequest,
  deleteSiteFailure,
  deleteSiteSuccess,
} from './actions';
import { requestSiteInfoCP } from 'ducks/siteInfoCP/actions';

export function* siteInfoSaga({ payload: siteID }) {
  try {
    const { payload: siteInfo } = yield axios.get(`${SITES}/${siteID}`);

    yield put(siteInfoSuccess(siteInfo));
  } catch (err) {
    yield put(siteInfoFailure(err));
  }
}

function* updateSiteStatusSaga({ payload: { siteID, status } }) {
  try {
    yield axios.put(`${SITES}/${siteID}/status/${status}`);

    yield put(updateSiteStatusSuccess({ status, siteID }));
  } catch (err) {
    yield put(updateSiteStatusFailure(err));
  }
}

function* getRemindersSaga({ payload: id }) {
  try {
    const { payload: item } = yield axios.get(`${SITES}/${id}/reminders`);

    yield put(getRemindersSuccess({ id, item }));
  } catch (err) {
    yield put(getRemindersFailure(err));
  }
}

function* updateRemindersSaga({ payload: { id, data: item } }) {
  try {
    yield axios.put(`${SITES}/${id}/reminders`, {
      payload: item
    });

    yield put(updateRemindersSuccess({ id, item }));
  } catch (err) {
    yield put(updateRemindersFailure(err));
  }
}

function* updateNoteSaga({ payload: { id, data: item } }) {
  try {
    yield axios.put(`${SITES}/${id}/note`, {
      payload: item
    });

    yield put(updateNoteSuccess({ id, item }));
  } catch (err) {
    yield put(updateNoteFailure(err));
  }
}

function* makeAllCheckpointsMasterSaga({ payload: id }) {
  try {
    yield axios.put(`${SITES}/${id}/checkpoints-master`);
    yield put(requestSiteInfoCP(id));
  } catch (err) {
    yield put(makeMasterCheckpointsFailure(err));
  }
}

function* removeAllCheckpointsMasterSaga({ payload: id }) {
  try {
    yield axios.delete(`${SITES}/${id}/checkpoints-master`);
    yield put(requestSiteInfoCP(id));
  } catch (err) {
    yield put(removeMasterCheckpointsFailure(err));
  }
}

function* deleteSiteSaga({ payload: { id, callback } }) {
  try {
    yield axios.delete(`${SITES}/${id}`);
    callback?.();
    yield put(deleteSiteSuccess(id));
  } catch (err) {
    yield put(deleteSiteFailure(err));
  }
}

function* deleteAllCheckpointsMasterSaga({ payload: id }) {
  try {
    yield axios.delete(`${SITES}/${id}/checkpoints`);
    yield put(requestSiteInfoCP(id));
  } catch (err) {
    yield put(deleteCheckpointsFailure(err));
  }
}

export default function*() {
  yield all([
    yield takeLatest(siteInfoRequest, siteInfoSaga),
    yield takeLatest(updateSiteStatusRequest, updateSiteStatusSaga),
    yield takeLatest(getRemindersRequest, getRemindersSaga),
    yield takeLatest(makeMasterCheckpointsRequest, makeAllCheckpointsMasterSaga),
    yield takeLatest(removeMasterCheckpointsRequest, removeAllCheckpointsMasterSaga),
    yield takeLatest(deleteCheckpointsRequest, deleteAllCheckpointsMasterSaga),
    yield takeLatest(updateRemindersRequest, updateRemindersSaga),
    yield takeLatest(updateNoteRequest, updateNoteSaga),
    yield takeLatest(deleteSiteRequest, deleteSiteSaga),
  ]);
}
