import { VISITOR_LOG } from 'app/api-routes';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  visitorLogSitesSuccess,
  visitorLogSitesFailure,
  visitorLogSitesRequest,
  visitorLogVisitorsRequest,
  visitorLogVisitorsSuccess,
  visitorLogVisitorsFailure,
  createVisitRequest,
  createVisitSuccess,
  createVisitFailure,
  visitorLogEventsRequest,
  visitorLogEventsSuccess,
  visitorLogEventsFailure,
  visitorLogEventRequest,
  visitorLogEventSuccess,
  visitorLogEventFailure,
} from './actions';
import { formatDateTimeUTC } from 'util/formatting';
import { SERVER_DATE_TIME_FORMAT, TABLE_NAMES } from 'app/types/constants';
import _ from 'lodash';
import { bulkUploadMediaAssetsSaga } from 'ducks/mediaAssets/sagas';
import { MediaAssetTypes } from 'ducks/mediaAssets/types';

function* visitorLogEventsSaga({ payload: { startDate, endDate, siteID } }) {
  try {
    const params = {};

    if (startDate) {
      params.fromDate = formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT);
    }

    if (endDate) {
      params.toDate = formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT);
    }

    if (siteID) {
      params.siteID = siteID;
    }

    const { payload } = yield axios.get(VISITOR_LOG, { params });

    yield put(visitorLogEventsSuccess(payload));
  } catch (err) {
    yield put(visitorLogEventsFailure(err));
  }
}

function* visitorLogEventSaga({ payload: { eventID } }) {
  try {
    const { payload } = yield axios.get(`${VISITOR_LOG}/${eventID}`);

    yield put(visitorLogEventSuccess(payload));
  } catch (err) {
    yield put(visitorLogEventFailure(err));
  }
}

function* createVisitSaga({ payload }) {
  try {
    const { payload: eventID } = yield axios.post(VISITOR_LOG, { payload: _.omit(payload, 'photos') });

    if (!_.isEmpty(payload.photos)) {
      yield call(bulkUploadMediaAssetsSaga, {
        payload: {
          files: payload.photos.map(({ description, photo }) => ({
            fileUploaded: photo,
            mediaAssetInfo: {
              tableName: TABLE_NAMES.VISITOR_LOG,
              entityID: eventID,
              type: MediaAssetTypes.PHOTO,
              description,
            },
          })),
        },
      });
    }

    yield put(createVisitSuccess({ eventID }));
  } catch (err) {
    yield put(createVisitFailure(err));
  }
}

function* visitorLogSitesSaga() {
  try {
    const { payload } = yield axios.get(`${VISITOR_LOG}/sites`);

    yield put(visitorLogSitesSuccess(payload));
  } catch (err) {
    yield put(visitorLogSitesFailure(err));
  }
}

function* visitorLogVisitorsSaga({ payload: { siteID, eventType } }) {
  try {
    const { payload } = yield axios.get(`${VISITOR_LOG}/visitors`, { params: { siteID, eventType } });

    yield put(visitorLogVisitorsSuccess(payload));
  } catch (err) {
    yield put(visitorLogVisitorsFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(visitorLogEventsRequest, visitorLogEventsSaga),
    yield takeLatest(visitorLogEventRequest, visitorLogEventSaga),
    yield takeLatest(createVisitRequest, createVisitSaga),
    yield takeLatest(visitorLogSitesRequest, visitorLogSitesSaga),
    yield takeLatest(visitorLogVisitorsRequest, visitorLogVisitorsSaga),
  ]);
}
