import preparedReducer from './reducer';
import {
  requestSitesForVisit,
  requestVisitorsForVisit,
  createVisit,
  requestVisitorLogEvents,
  requestVisitorLogEvent,
} from './actions';
import _ from 'lodash';

export const reducer = preparedReducer;

export const SLICE_NAME = 'visitorLog';

export const selectors = (state) => state[SLICE_NAME];

export const selectSite = (state, siteID) => _.find(state[SLICE_NAME].sites, ['siteID', siteID]);

export const selectVisitor = (state, visitorID) =>
  _.find(state[SLICE_NAME].visitors, ['visitorID', visitorID]) || {};

export const selectVisitorLogEvent = (state, eventID) => state[SLICE_NAME].info[eventID];

export const actions = {
  createVisit,
  requestSitesForVisit,
  requestVisitorsForVisit,
  requestVisitorLogEvents,
  requestVisitorLogEvent,
};
