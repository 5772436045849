import React, { useCallback, useEffect, useMemo, useRef, forwardRef } from 'react';
import clsx from 'clsx';
import { SortIcon } from 'assets/icons';
import { IconButton, Pagination, Checkbox } from 'new-design/common/ui';
import Box from '@material-ui/core/Box';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTable, usePagination, useSortBy, useRowSelect, useFilters, useGlobalFilter } from 'react-table';
import IntlMessages from 'util/IntlMessages';
import { useHistory } from 'react-router-dom';

import { Waypoint } from 'react-waypoint';

// import 'react-table/react-table.css';

const TYPES = {
  CELL: 'cell',
  HEADER: 'header',
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      header: {
        // borderTopLeftRadius: 24,
        // borderTopRightRadius: 24,
        '&.sticky th': {
          top: 0,
          position: 'sticky',
        },
        '& th': {
          border: 'none',
          fontWeight: 400,
          backgroundColor: theme.palette.secondary.light,
          [theme.breakpoints.down('lg')]: {
            fontSize: 13,
            padding: 7,
            lineHeight: '15px',
          },
          '& svg': {
            width: 18,
            height: 18,
          },
          '& svg path': {
            fill: '#8A8A8A',
          },
          '& .head-checkbox svg path': {
            fill: 'inherit',
          },
        },
        '& th:first-child': {
          paddingLeft: 30,
          // borderRadius: '12px 0 0 0',
          borderRadius: 0,
        },
        '& th:last-child': {
          paddingRight: 30,
          // borderRadius: '0 12px 0 0',
          borderRadius: 0,
        },
      },
      body: {
        '& td': {
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          borderBottom: `2px solid ${theme.palette.secondary.light}`,
          [theme.breakpoints.down('lg')]: {
            fontSize: 12,
            lineHeight: '15px',
            padding: 7,
          },
        },
        '& td:first-child': {
          paddingLeft: 30,
        },
        '& td:last-child': {
          paddingRight: 30,
        },
      },
      headerCell: {
        alignItems: 'center',
        whiteSpace: 'nowrap',
        '& .MuiButton-label': {
          fontWeight: 'normal',
          fontSize: 16,
          lineHeight: '24px',
        },
      },
      textCenter: {
        textAlign: 'center',
      },
      bodyClickableCell: {},
      pointer: {
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.secondary.light,
        },
      },
      pagination: {
        marginBottom: 32,
        [theme.breakpoints.down('lg')]: {
          marginBottom: 0,
        },
      },
      noRecords: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        [theme.breakpoints.down('lg')]: {
          fontSize: 13,
          lineHeight: '15px',
        },
      },
      customTableContainer: {
        overflowX: 'initial',
      },
    }),
  {
    name: 'Table',
  }
);

const getStickyStyles = (column, type) => {
  let zIndex;
  switch (type) {
    case TYPES.CELL:
      zIndex = 1;
      break;

    case TYPES.HEADER:
      zIndex = 2;
      break;

    default:
      break;
  }

  return {
    right: column.sticky === 'right' ? -1 : undefined,
    left: column.sticky === 'left' ? -1 : undefined,
    opacity: 1,
    position: 'sticky',
    width: column.width,
    zIndex,
    backgroundColor: '#F7F7F7',
    borderColor: 'white',
  };
};

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = forwardRef(({ indeterminate, checkboxStyle, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox
      {...rest}
      className="checkbox"
      type="checkbox"
      inputRef={resolvedRef}
      style={{ ...rest.style, ...(checkboxStyle || {}) }}
    />
  );
});

const Table = ({
  columns,
  data,
  withPagination,
  onRowClick,
  onRowRightClick,
  disabledPageSize,
  withSelect,
  handleSelectCallback,
  selectedRowIds,
  onSortedChange,
  sortOptions,
  textCenter,
  update,
  globalFilter,
  customSort,
  stickyHeader,
  isLoading,
  checkboxStyle,
  headerClassName,
  bodyClassName,
  footer: Footer,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isInitUpdate = useRef(true);

  const sortBy = useMemo(
    () =>
      sortOptions && Object.keys(sortOptions).length
        ? Object.keys(sortOptions).map((el) => ({
            id: el,
            desc: sortOptions[el],
          }))
        : [],
    [sortOptions]
  );

  const props = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
      initialState: {
        pageIndex: 0,
        selectedRowIds: selectedRowIds || {},
        sortBy: customSort ? [] : sortBy,
      },
      autoResetPage: false,
      pageSize: withPagination ? 10 : data.length,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        withSelect
          ? [
              {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div className="head-checkbox">
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps?.()}
                      checkboxStyle={checkboxStyle}
                    />
                  </div>
                ),
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...row?.getToggleRowSelectedProps?.()}
                      checkboxStyle={checkboxStyle}
                    />
                  </div>
                ),
                width: '1%',
                minWidth: '1%',
                maxWidth: '1%',
              },
              ...columns,
            ]
          : columns
      );
    }
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
    selectedFlatRows,
    setGlobalFilter,
    footerGroups,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = props;

  const renderRows = useMemo(() => {
    return withPagination ? page : rows;
  }, [withPagination, page, rows]);

  const handlePageSizeChange = useCallback((size) => {
    setPageSize(size);
  }, []);

  useEffect(() => {
    if (history.location.search && !isInitUpdate.current) {
      const params = new URLSearchParams(window.location.search);
      if (+params.get('pageSize')) {
        setPageSize(+params.get('pageSize'));
      }
      if (+params.get('page')) {
        gotoPage(+params.get('page'));
      }
    }
    if (isInitUpdate.current) {
      isInitUpdate.current = false;
    }
  }, [data]);

  useEffect(() => {
    handleSelectCallback?.(selectedFlatRows);
  }, [selectedFlatRows, handleSelectCallback]);

  useEffect(() => {
    setGlobalFilter(globalFilter);
  }, [globalFilter]);

  // Render the UI for your table
  return (
    <>
      <MaUTable
        {...getTableProps()}
        striped="true"
        className={clsx(classes.root)}
        classes={{ root: classes.customTableContainer }}
      >
        <TableHead
          className={clsx(classes.header, headerClassName, {
            sticky: stickyHeader,
          })}
        >
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <TableCell
                  {...column.getHeaderProps({
                    ...(column.getSortByToggleProps && !customSort && column.getSortByToggleProps()),
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                      ...(column.sticky ? getStickyStyles(column, TYPES.HEADER) : {}),
                    },
                  })}
                >
                  <Box
                    display="flex"
                    justifyContent={textCenter ? 'center' : 'start'}
                    onClick={() => {
                      onSortedChange?.({
                        isSorted: column.isSorted,
                        isSortedDesc: column.isSorted ? !column.isSortedDesc : column.isSortedDesc,
                        id: column.id,
                      });
                    }}
                  >
                    {column.render('Header')}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody className={clsx(classes.body, bodyClassName)}>
          {!page.length && !isLoading && (
            <TableRow>
              <TableCell colSpan={headerGroups[0].headers.length}>
                <Box display="flex" justifyContent="center">
                  <Typography className={classes.noRecords}>
                    <IntlMessages id="no_records_to_display" />
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={headerGroups[0].headers.length}>
                <Box display="flex" justifyContent="center">
                  <Typography className={classes.noRecords}>
                    <IntlMessages id="loading" />
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
          {renderRows.map((row, index) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <TableRow
                {...row.getRowProps()}
                onClick={(e) => {
                  onRowClick && onRowClick({ row }, e);
                }}
                onContextMenu={(e) => {
                  onRowRightClick && onRowRightClick({ row }, e);
                }}
                className={clsx({
                  [classes.pointer]: !!onRowClick,
                })}
              >
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <TableCell
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                          maxWidth: cell.column.maxWidth,
                          ...(cell.column.sticky ? getStickyStyles(cell.column, TYPES.CELL) : {}),
                        },
                      })}
                      className={clsx({
                        [classes.bodyClickableCell]: cell.column.clickable,
                        [classes.textCenter]: textCenter,
                      })}
                    >
                      {renderRows.length - 1 === index && <Waypoint onEnter={update} />}
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        {Footer && <Footer footerGroups={footerGroups} />}
      </MaUTable>
      {!!withPagination && (
        <Box className={classes.pagination} width="100%">
          <Pagination
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            pageIndex={pageIndex}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            setPageSize={handlePageSizeChange}
            pageCount={pageCount}
            totalRows={data.length}
            disabledPageSize={disabledPageSize}
            pageSize={pageSize}
          />
        </Box>
      )}
    </>
  );
};

const SortableCell = ({ size, children }) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.headerCell}>
      {children}

      <IconButton>
        <SortIcon
          style={{
            width: size || 'inherit',
            height: size || 'inherit',
            minWidth: size || 'inherit',
            minHeight: size || 'inherit',
          }}
        />
      </IconButton>
    </Box>
  );
};

export { Table, SortableCell };
