import { all, put, takeLatest } from 'redux-saga/effects';
import { formatDateTimeUTC, formatMonthIntervalUTC } from 'util/formatting';

import { activityFailure, activityRequest, activitySuccess, resetGPSAlertRequest } from './actions';
import { activityByShiftIDFailure, activityByShiftIDRequest, activityByShiftIDSuccess } from './actions';

import axios from 'axios';
import { ACTIVITIES, SHIFT_LOG } from 'app/api-routes';
import { SERVER_DATE_TIME_FORMAT } from 'app/types/constants';

function* activitySaga({ payload: { date, startDate, endDate, shiftID } }) {
  let fromDate, toDate;

  let params = {};

  if (isNaN(shiftID) || shiftID === null || typeof shiftID === 'undefined') {
    if (startDate !== null && endDate !== null) {
      fromDate = formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT);
      toDate = formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT);

      params = {
        fromDate,
        toDate,
        includeTotals: true,
        shiftID,
      };
    } else {
      [fromDate, toDate] = formatMonthIntervalUTC(date);
    }
  } else {
    params = {
      shiftID,
    }
  }

  try {
    const { payload } = yield axios.get(ACTIVITIES, {
      params,
    });

    yield put(activitySuccess(payload));
  } catch (err) {
    yield put(activityFailure(err));
  }
}

function* getActivityByShiftIDSaga({ payload: { shiftLogID, callback } }) {
  try {
    const { payload } = yield axios.get(`${ACTIVITIES}/${shiftLogID}`);

    yield put(activityByShiftIDSuccess({ shiftLogID, data: payload}));
    callback?.();
  } catch (err) {
    yield put(activityByShiftIDFailure(err));
  }
}

function* resetGPSAlertSaga({ payload: { shiftLogID, callback } }) {
  try {
    yield axios.put(`${SHIFT_LOG}/${shiftLogID}/reset`);

    callback?.();
  } catch (err) {
    yield put(activityByShiftIDFailure(err));
  }
}

export default function* () {
    yield all([yield takeLatest(activityRequest, activitySaga)]);
    yield all([yield takeLatest(resetGPSAlertRequest, resetGPSAlertSaga)]);
    yield all([yield takeLatest(activityByShiftIDRequest, getActivityByShiftIDSaga)]);
}
