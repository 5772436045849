import { handleActions } from 'redux-actions';
import {
  setStatisticState,
  setAdminTabsState,
  toggleControls,
  setChipsStripName,
  setHiddenChips,
} from './actions';
import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [setStatisticState](state, { payload }) {
      return {
        ...state,
        statistic: payload,
      };
    },
    [setAdminTabsState](state, { payload }) {
      return {
        ...state,
        adminTabsEnabled: payload,
      };
    },
    [toggleControls](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [setChipsStripName](state, { payload }) {
      return {
        ...state,
        chipsStrip: payload,
      };
    },
    [setHiddenChips](state, { payload }) {
      return {
        ...state,
        hiddenChips: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
