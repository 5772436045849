import preparedReducer from './reducer';
import { requestAuditLog, requestAuditEvent } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'auditLog';

export const selectors = (state) => state[SLICE_NAME];
export const selectAuditEvent = (state, eventID) => state[SLICE_NAME].info[eventID];

export const actions = {
  requestAuditLog,
  requestAuditEvent,
};
