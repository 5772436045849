import { combineActions, handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import {
  visitorsSuccess,
  visitorSuccess,
  deleteVisitorSuccess,
  updateVisitorStatus,
  visitorQRCodeDataSuccess,
  updateVisitorQRTagSuccess,
} from './actions';

import initialState from './states';
import _ from 'lodash';

export default handleActions(
  {
    [visitorsSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [visitorSuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.visitorID]: payload,
        },
      };
    },
    [deleteVisitorSuccess](state, { payload: { visitorID } }) {
      return {
        ...state,
        info: _.omit(state.info, [visitorID]),
      };
    },
    [updateVisitorStatus](state, { payload: { visitorID, status } }) {
      return {
        ...state,
        info: {
          ...state.info,
          [visitorID]: {
            ...state.info[visitorID],
            status,
          },
        },
      };
    },
    [combineActions(visitorQRCodeDataSuccess, updateVisitorQRTagSuccess)](
      state,
      { payload: { visitorID, data } }
    ) {
      return {
        ...state,
        qrData: {
          ...state.qrData,
          [visitorID]: data,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
