import React, { useMemo } from 'react';
import { Box, Popper, Typography, createStyles, makeStyles } from '@material-ui/core';
import { ClockIcon } from 'assets/icons';
import { Selector } from './Selector';
import Card from 'new-design/components/Card';

const useStyles = makeStyles(
  () =>
    createStyles({
      card: {
        display: 'inline-block',
        position: 'relative',
        color: '#000',
        background: '#FFFFFF',
        boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
        borderRadius: 8,
      },
    }),
  { name: 'TimePicker' }
);

const prepareNumber = (number) => ('0' + number).slice(-2);

export const TimePicker = ({ anchorRef, opened, time, step, onChange }) => {
  const classes = useStyles();

  const hourOptions = useMemo(
    () => Array.from({ length: 24 }, (v, i) => ({ option: prepareNumber(i), index: i })),
    []
  );
  const minuteOptions = useMemo(
    () => Array.from({ length: 60 / step }, (v, i) => ({ option: prepareNumber(i * step), index: i })),
    [step]
  );

  const [hours, minutes] = useMemo(() => {
    return (time || '00:00').split(':');
  }, [time]);

  return (
    <>
      <Popper
        open={opened}
        anchorEl={anchorRef.current}
        placement="bottom"
        style={{
          zIndex: 2000,
        }}
      >
        <Card className={classes.card}>
          <Box display="flex" width="100%" justifyContent="center" position="relative" alignItems="center">
            <Box mr={1}>
              <ClockIcon />
            </Box>
            <Selector
              options={hourOptions}
              value={hours}
              name="hours"
              alignOptionsToBottom
              inputType="select"
              onChange={(value) => onChange(`${value}:${minutes}`)}
            />
            <Box marginRight={1} marginLeft={1} display="flex" alignItems="center">
              <Typography>:</Typography>
            </Box>
            <Selector
              options={minuteOptions}
              value={minutes}
              name="minutes"
              alignOptionsToBottom
              inputType="select"
              onChange={(value) => onChange(`${hours}:${value}`)}
            />
          </Box>
        </Card>
      </Popper>
    </>
  );
};
