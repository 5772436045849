import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import {
  visitorLogEventSuccess,
  visitorLogEventsSuccess,
  visitorLogSitesSuccess,
  visitorLogVisitorsSuccess,
} from './actions';

import initialState from './states';

export default handleActions(
  {
    [visitorLogSitesSuccess](state, { payload }) {
      return {
        ...state,
        sites: payload,
      };
    },
    [visitorLogVisitorsSuccess](state, { payload }) {
      return {
        ...state,
        visitors: payload,
      };
    },
    [visitorLogEventsSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [visitorLogEventSuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.eventID]: payload,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
