import { handleActions } from 'redux-actions';
import { infoNoteSuccess, getAllInfoNoteSuccess } from './actions';
import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [infoNoteSuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.infoNoteID]: payload,
        },
      };
    },
    [getAllInfoNoteSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
