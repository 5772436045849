import { handleActions } from 'redux-actions';

import { clientsSuccess, clientSuccess, deselectClient, updateClientStatusSuccess } from './actions';
import { logoutSuccess } from 'ducks/auth/actions';
import initialState from './states';

export default handleActions(
  {
    [clientsSuccess](state, { payload: list }) {
      return {
        ...state,
        list,
      };
    },
    [clientSuccess](state, { payload: selectedClient }) {
      return {
        ...state,
        selectedClient,
      };
    },
    [deselectClient](state) {
      return {
        ...state,
        selectedClient: null,
      };
    },
    [updateClientStatusSuccess](state, { payload: { status } }) {
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          status,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
