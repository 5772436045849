import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { rolesFailure, rolesRequest, rolesSuccess } from './actions';
import { ROLES } from 'app/api-routes';

function* rolesSaga({ payload: userType }) {
  try {
    const { payload } = yield axios.get(ROLES, {
      params: {
        userType
      }
    });

    yield put(
      rolesSuccess({
        userType,
        roles: payload,
      }),
    );
  } catch (err) {
    yield put(rolesFailure(err));
  }
}

export default function* () {
    yield all([yield takeLatest(rolesRequest, rolesSaga)]);
}
