import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles(
  {
    langImage: {
      borderRadius: 4,
      width: 32,
      height: 20,
      marginRight: theme.spacing(1),
    },
    arrowDown: {
      '& path': {
        stroke: '#222',
      }
    },
    itemsInCenter: {
      display: 'flex',
      alignItems: 'center',

      '& .MuiButton-startIcon': {
        margin: 0,
      },
      [theme.breakpoints.down('lg')]: {
        '& .MuiTypography-body1': {
          fontSize: 14,
          lineHeight: '19px',
        }
      }
    },
    langSwitcherBlock: {
      marginTop: 30,
    },
    icon: {
      '& path': {
        fill: theme.palette.primary.main,
      }
    }
  }
), {
  name: 'LangSwitcher'
});

export { useStyles };
