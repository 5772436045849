import preparedReducer from './reducer';
import {
  switchLanguage,
  setHorizontalMenuPosition,
  changeNavigationStyle,
  changeDirection,
  setDarkTheme,
  setThemeColor,
  updateWindowWidth,
  setDrawerType,
  toggleCollapsedNav
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'settings';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  switchLanguage,
  setHorizontalMenuPosition,
  changeNavigationStyle,
  changeDirection,
  setDarkTheme,
  setThemeColor,
  updateWindowWidth,
  setDrawerType,
  toggleCollapsedNav
};
