import { handleActions } from 'redux-actions';
import { IS_DEV } from 'util/app-info';

import initialState from './states';
import { createAction } from '../helpers';
import constants from './constants';

export default handleActions(
  {
    [createAction(constants.update)](
      _,
      {
        payload: {company, user, password, remember},
      },
    ) {
      if (!remember) {
        return {
          ...initialState,
          company,
          remember,
        };
      }

      return {
        ...initialState,
        company,
        user,
        password: IS_DEV ? password : '',
        remember,
      };
    },
  },
  {
    ...initialState,
  },
);
