import React from 'react';
import { Route, Switch } from 'react-router-dom';

// TODO: refactoring.
import asyncComponent from 'util/asyncComponent';

const OperationsRoutes = ({ match }) =>
  <>
    <Route path={`${match.url}/activity-log/visit`} exact component={(asyncComponent(() => import('../pages/subpages/Visit')))} />
    <Route path={`${match.url}/activity-log/tour`} exact component={(asyncComponent(() => import('../pages/subpages/Tour')))} />
    <Route path={`${match.url}/activity-log/info-note-created`} exact component={(asyncComponent(() => import('../pages/subpages/InfoNote')))} />
    <Route path={`${match.url}/activity-log/info-note-ack`} exact component={(asyncComponent(() => import('../pages/subpages/InfoNote')))} />
    <Route path={`${match.url}/activity-log/note-created`} exact component={(asyncComponent(() => import('../pages/subpages/NoteCreated')))} />
    <Route path={`${match.url}/activity-log/task-completed`} exact component={(asyncComponent(() => import('../pages/subpages/TaskCompleted')))} />
    <Route path={`${match.url}/activity-log/shift-end`} exact component={(asyncComponent(() => import('../pages/subpages/ShiftEnded')))} />
    <Route path={`${match.url}/activity-log/checkpoint-scanned`} exact component={(asyncComponent(() => import('../pages/subpages/CheckpointScanned')))} />
    <Route path={`${match.url}/activity-log/shift-started`} exact component={(asyncComponent(() => import('../pages/subpages/ShiftStarted')))} />
    <Route path={`${match.url}/activity-log/gps-alert`} exact component={(asyncComponent(() => import('../pages/subpages/GPSAlert')))} />
    <Route path={`${match.url}/activity-log/no-activity`} exact component={(asyncComponent(() => import('../pages/subpages/NoActivity')))} />
    <Route path={`${match.url}/activity-log/alarm`} exact component={(asyncComponent(() => import('../pages/subpages/Alarm')))} />
    <Route path={`${match.url}/activity-log`} exact component={(asyncComponent(() => import('../pages/operations/ActivityLog')))} />

    <Route path={`${match.url}/info-board/:id`} exact component={(asyncComponent(() => import('../pages/subpages/InfoNote')))} />
    <Route path={`${match.url}/info-board`} exact component={(asyncComponent(() => import('../pages/operations/InfoBoard')))} />

    <Route path={`${match.url}/incident-reports/:id/view`} exact component={(asyncComponent(() => import('../pages/subpages/incident/ViewIncident')))} />
    <Route path={`${match.url}/incident-reports/:id`} exact component={(asyncComponent(() => import('../pages/subpages/incident')))} />
    <Route path={`${match.url}/incident-reports`} exact component={(asyncComponent(() => import('../pages/operations/IncidentReports')))} />

    <Route path={`${match.url}/form-results`} exact component={(asyncComponent(() => import('../pages/operations/FormResults')))} />
    <Route path={`${match.url}/form-results/:id/fill`} exact component={(asyncComponent(() => import('../pages/subpages/form-results/FillForm')))} />
    <Route path={`${match.url}/form-results/:id/view`} exact component={(asyncComponent(() => import('../pages/subpages/form-results/ViewForm')))} />

    <Route path={`${match.url}/visitor-log`} exact component={(asyncComponent(() => import('../pages/operations/VisitorLog')))} />

    <Switch>
      <Route path={`${match.url}/visitor-log/register`} exact component={(asyncComponent(() => import('../pages/operations-subpages/RegisterVisit')))} />
      <Route path={`${match.url}/visitor-log/:id`} exact component={(asyncComponent(() => import('../pages/operations-subpages/ViewVisit')))} />
    </Switch>
  </>;

export { OperationsRoutes };
