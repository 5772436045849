import React, { useCallback, useMemo } from 'react';
import { Selector } from '../Selector';
import moment from 'moment';

export const YearPicker = ({ date, name, infinityEndDate, dateChange, changeYear }) => {
  const years = useMemo(() => {
    const year = moment().year() + (!infinityEndDate ? 0 : 10);
    const back = year - 1900;

    return Array.from({ length: back }, (v, i) => year - back + i + 1).map((option, index) => ({
      option,
      index,
      disabled: false
    }));
  }, [infinityEndDate]);

  const handleChange = useCallback((selectedYear) => {
    changeYear(selectedYear);
    dateChange(moment(date).set('year', selectedYear).toDate());
  }, [date])

  return (
    <Selector
      options={years}
      value={moment(date).year()}
      name={`${name}-year`}
      onChange={handleChange}
    />
  );
};
