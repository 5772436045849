import React, { useCallback } from 'react';
import { Menu, Button } from 'new-design/common/ui';

import { useStyles } from './styles';
import { ArrowDownIcon, ArrowUpIcon, WorldIcon } from 'assets/icons';

import * as settings from 'ducks/settings';

import LanguageItem from './LanguageItem';
import languageData from './data';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { US, ES, RU, RO, HR, TH, BR } from 'country-flag-icons/react/3x2';
import { Box, Typography } from '@material-ui/core';

export const getFlag = (locale) => {
  switch (locale) {
    case 'en':
      return <US />;
    case 'es':
      return <ES />;
    case 'ru':
      return <RU />;
    case 'ro':
      return <RO />;
    case 'hr':
      return <HR />;
    case 'th':
      return <TH />;
    case 'pt':
      return <BR />;
    default:
      return <US />;
  }
};

const LangSwitcher = () => {
  const classes = useStyles();
  const { switchLanguage } = bindActionCreators(settings.actions, useDispatch());
  const { locale } = useSelector(settings.selectors);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleModalOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleModalClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onSwitchLanguage = useCallback((lang) => {
    switchLanguage(lang);
    window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button
        aria-controls="customized-menu-langswitcher"
        aria-haspopup="true"
        variant="text"
        color="secondary"
        className={classes.itemsInCenter}
        onClick={handleModalOpen}
        startIcon={<WorldIcon className={classes.icon} />}
        endIcon={anchorEl ? <ArrowUpIcon /> : <ArrowDownIcon className={classes.arrowDown} />}
      >
        <Box ml={1}>
          <Typography>{locale?.locale?.toUpperCase()}</Typography>
        </Box>
      </Button>
      <Menu
        id="customized-menu-langswitcher"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleModalClose}
        className={classes.langSwitcherBlock}
      >
        {languageData.map((language) => (
          <LanguageItem
            key={`locale-${language.locale}`}
            language={language}
            handleRequestClose={handleModalClose}
            switchLanguage={onSwitchLanguage}
          />
        ))}
      </Menu>
    </>
  );
};

export { LangSwitcher };
