const UserTypes = {
  EMPLOYEE: 'E',
  CLIENT: 'C',
};

const UserStatus = {
  NEW: 'N',
  ACTIVE: 'A',
  SUSPENDED: 'S',
  DELETED: 'D',
};

const RoleCodes = {
  ADMIN: 'ADMIN',
  DISPATCH: 'DISPATCH',
  GUARD: 'GUARD',
  MANAGER: 'MANAGER',
  SUPERVISOR: 'SPV',
};

export {
  UserTypes,
  UserStatus,
  RoleCodes,
};
