import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  '@keyframes slideIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  '@keyframes moveUp': {
    from: {
      marginTop: 20,
    },
    to: {
      marginTop: 0,
    },
  },
  navCollapse: {
    position: 'relative',
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
    '& $navCollapseBtnInner': {
      padding: 10,
    },
    '& $navCollapseBtnInner .Cmt-icon-root path': {
      fill: theme.palette.secondary.main,
    },

    '& > .active > .active': {
      // boxShadow: '0px 4px 20px rgba(2, 44, 230, 0.5)',
    },

    '& > .Cmt-navCollapseBtn': {
      [theme.breakpoints.down('lg')]: {
        height: 46,
      }
    },
    '&.active > .Cmt-navCollapseBtn': {
      color: theme.palette.primary.main,
    },

    '&.active > .Cmt-navCollapseBtn $subCollapse .Cmt-navCollapseBtn.active': {
      color: theme.palette.common.black,
      '& $navCollapseBtnInner .Cmt-icon-root': {
        color: theme.palette.primary.main,
      },
      '&:hover, &:focus': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.gray,
        '& $navCollapseBtnInner > .Cmt-icon-root': {
          color: theme.palette.common.black,
        },
      },
    },
  },
  navCollapseItem: {
    position: 'absolute',
    display: 'none',
    top: 46,
    left: 0,
    zIndex: 2,
    opacity: 0,
    visibility: 'hidden',
    [theme.breakpoints.down('lg')]: {
      top: 36,
    },
    '& > .submenu': {
      minWidth: 200,
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.common.black,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
      marginTop: 24,
      [theme.breakpoints.down('lg')]: {
        marginTop: 32,
      },
    },

    '& $navCollapseItem': {
      top: 50,
      left: '100%',
      right: 'auto',
    },
  },
  navCollapseBtn: {
    position: 'relative',
    padding: '0px 4px',
    cursor: 'pointer',
    color: theme.palette.primary.main,

    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
    
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },

    '&:hover > $navCollapseItem': {
      display: 'block',
      opacity: 1,
      visibility: 'visible',
      animationName: '$slideIn, $moveUp',
      animationTimingFunction: 'ease, ease',
      animationDuration: '0.4s, 0.4s',
    },

    '& > $navCollapseItem $navCollapseBtn:hover > $navCollapseItem': {
      display: 'block',
      top: 0,
      opacity: 1,
      visibility: 'visible',
      animationName: '$slideIn, $moveUp',
      animationTimingFunction: 'ease, ease',
      animationDuration: '0.4s, 0.4s',
    },
  },
  navCollapseBtnInner: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 16,
    minWidth: 88,
    [theme.breakpoints.down('lg')]: {
      minWidth: 80,
    },
    '&.active': {
      color: theme.palette.primary.main,
      '& .Cmt-name': {
        color: theme.palette.primary.main,
      },
      '& .Cmt-icon-root': {
        '& path': {
          fill: theme.palette.primary.main,
        }
      },
      '& .Cmt-navIcon': {
        '& path': {
          stroke: theme.palette.primary.main,
        }
      }
    },
    '& .Cmt-navIcon path': {
      stroke: '#8A8A8A',
    },
    '&:hover': {
      '& .Cmt-name': {
        color: theme.palette.primary.main,
      },
      '& .Cmt-icon-root path': {
        fill: theme.palette.primary.main,
      },
      '& .Cmt-navIcon path': {
        stroke: theme.palette.primary.main,
      }
    },
  },
  navText: {
    flex: 1,
    fontSize: 18,
    letterSpacing: 0.012,
    lineHeight: '27px',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      lineHeight: '19px',
    },
  },
  iconRoot: {
    marginRight: 8,
    fontSize: 20,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 20,
      maxHeight: 20,
    },
  },
  subCollapse: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    '& .Cmt-navCollapseBtn': {
      color: theme.palette.primary.main,
      '&:hover, &:focus': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.gray,
        '& .Cmt-icon-root': {
          color: theme.palette.common.black,
        },
      },
    },
    '&.active .Cmt-nav-menu-link.active': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      '& .Cmt-icon-root': {
        color: theme.palette.primary.main,
      },
      '&:hover, &:focus': {
        '& .Cmt-icon-root': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  navArrow: {

  },
  betaText: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      color: theme.palette.primary.main,
      fontSize: 10,
      lineHeight: '23px',
    },
  },
}), {
  name: 'NavCollapse',
});

export default useStyles;
