import enLang from './entries/en_US';
import zhLang from './entries/zh-Hans-CN';
import arLang from './entries/ar_SA';
import itLang from './entries/it_IT';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';
import thaiLang from './entries/th-TH';
import heLang from './entries/he_IL';
import ruLang from './entries/ru_RU';
import roLang from './entries/ro_RO';
import hrLang from './entries/hr_HR';
import ptLang from './entries/pt_PT';
const AppLocale = {
  en: enLang,
  zh: zhLang,
  ar: arLang,
  it: itLang,
  es: esLang,
  fr: frLang,
  th: thaiLang,
  he: heLang,
  ru: ruLang,
  ro: roLang,
  hr: hrLang,
  pt: ptLang,
};

export default AppLocale;
