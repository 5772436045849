import React from 'react';
import clsx from 'clsx';
import { IconButton as MUIconButton, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      large: {
        height: 48,
        width: 48,
        [theme.breakpoints.down('lg')]: {
          height: 40,
          width: 40,
          padding: 10,
        },
      },
      outlined: {
        border: '1.5px solid #E5E5E5',
        borderRadius: 8,
        '&:hover': {
          border: `1.5px solid ${theme.palette.primary.main}`,
        },
      },
      contained: {
        border: '1.5px solid #022CE6',
        background: '#022CE6',
        borderRadius: 8,
      },
      padding: {
        padding: 4,
      },
    }),
  {
    name: 'IconButton',
  }
);

const IconButton = ({ variant, size, className, innerRef, ...rest }) => {
  const classes = useStyles();

  return (
    <MUIconButton
      {...rest}
      variant={variant}
      ref={innerRef}
      className={clsx(className, {
        [classes.outlined]: variant === 'outlined',
        [classes.contained]: variant === 'contained',
        [classes.large]: size === 'large',
        [classes.padding]: size !== 'large',
      })}
    />
  );
};

export { IconButton };
