import { handleActions } from 'redux-actions';
import isEqual from 'react-fast-compare';

import { employeeInfoSuccess } from './actions';

import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [employeeInfoSuccess](state, { payload }) {
      if (isEqual(state[payload.employeeID], payload)) {
        return state;
      }

      return {
        ...state,
        [payload.employeeID]: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
