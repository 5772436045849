import { SESSIONS, USERS } from 'app/api-routes';
import axios from 'axios';
import {
  login,
  logout,
  loginSuccess,
  loginFailure,
  resetLogin,
  logoutSuccess,
  logoutFailure,
  acceptAgreementRequest,
  acceptAgreementFailure,
  acceptAgreementSuccess
} from './actions';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { actions as credentialActions } from '../credentials';

import {
  getClientType,
  getTimeZone,
  getClientID,
  getLanguageCountry,
} from 'util/app-info';

const getMenuKeys = (accum, items) => {
  items.forEach(element => {
    if (element.trancode) {
      accum = {
        ...accum,
        [element.trancode]: element.trancode,
      };
    }
    if (element.menuItems) {
      const newItems = getMenuKeys(accum, element.menuItems);
      accum = {
        ...accum,
        ...newItems,
      }
    } 
  });

  return accum;
}

function* loginSaga({ payload: { user, password, company, remember, onSuccess, onFailure } }) {
  try {
    const requestPayload = {
      password,
      user,
      company,
      ...getLanguageCountry(),
      timezone: getTimeZone(),
      clientType: getClientType(),
      clientID: getClientID(),
    };
    const { payload } = yield axios.post(SESSIONS, { payload: requestPayload });

    const newAppMenu = getMenuKeys({}, payload.menu);

    yield put(loginSuccess({
      ...payload,
      newAppMenu,
    }));

    // Update credentials.
    const updateCreds = {
      company: company,
      user: remember ? user : '',
      password: remember ? password : '',
      remember,
    };
    yield put(credentialActions.updateCredentials(updateCreds));

    onSuccess?.();
  } catch (err) {
    onFailure();
    yield put(resetLogin());
    yield put(loginFailure(err));
  }
}

function* logoutSaga({ payload: force }) {
  const { sessionID } = yield select(state => state.auth);
  try {
    yield axios.delete(`${SESSIONS}/${sessionID}`);
    yield put(logoutSuccess());
  } catch (err) {
    if (force) {
      yield put(logoutSuccess(err, { warnings: JSON.parse(err.message) }));
    } else {
      yield put(logoutFailure(err));
    }
  }
}

function* acceptAgreementSaga({ payload: userID }) {
  try {
    yield axios.put(`${USERS}/${userID}/terms`, {
      payload: {
        termsChanged: true,
        acceptRequired: true
      }
    });
    yield put(acceptAgreementSuccess());
  } catch (err) {
    yield put(acceptAgreementFailure());
  }
}

export default function*() {
  yield all([
    yield takeLatest(login, loginSaga),
    yield takeLatest(logout, logoutSaga),
    yield takeLatest(acceptAgreementRequest, acceptAgreementSaga),
  ]);
}
