import preparedReducer from './reducer';
import { requestCompany, enableVisitorLog, disableVisitorLog } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'companies';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestCompany,
  enableVisitorLog,
  disableVisitorLog,
};
