import { all, put, takeLatest } from "redux-saga/effects";

import {
  taskRecordsRequest,
  taskRecordsSuccess,
  taskRecordsFailure,
  updateTaskRecordsRequest,
  updateTaskRecordsSuccess,
  updateTaskRecordsFailure,
  createTaskRecordSuccess,
  createTaskRecordFailure,
  createTaskRecordRequest,
} from "./actions";

import axios from "axios";
import { TASKS } from "app/api-routes";

function* taskRecordsSaga({ payload: { taskID } }) {
  try {
    const { payload } = yield axios.get(`${TASKS}/${taskID}/records`);

    yield put(taskRecordsSuccess({ taskID, data: payload }));
  } catch (err) {
    yield put(taskRecordsFailure(err));
  }
}

function* createTaskRecordSaga({ payload: { taskID, payload } }) {
  try {
    const { payload: responsePayload } = yield axios.post(`${TASKS}/${taskID}/record`, { payload });

    yield put(createTaskRecordSuccess({ taskID, data: responsePayload }));
  } catch (err) {
    yield put(createTaskRecordFailure(err));
  }
}

function* updateTaskRecordsSaga({ payload: { taskID, data, callback } }) {
  try {
    const { payload } = yield axios.put(`${TASKS}/${taskID}/records`, {
      payload: data,
    });

    if (callback) {
      callback(payload);
    }
    
    yield put(updateTaskRecordsSuccess({ taskID, taskRecordsIDs: data }));
  } catch (err) {
    yield put(updateTaskRecordsFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(taskRecordsRequest, taskRecordsSaga),
    yield takeLatest(createTaskRecordRequest, createTaskRecordSaga),
    yield takeLatest(updateTaskRecordsRequest, updateTaskRecordsSaga),
  ]);
}
