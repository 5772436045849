import preparedReducer from './reducer';
import { requestTsByClient } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'tsByClient';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestTsByClient,
};
