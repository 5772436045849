import React, { useCallback, useState } from 'react';
import * as auth from 'ducks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Typography, Box } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button, Menu, HDivider } from 'new-design/common/ui';
import { LogoutIcon, PasswordIcon, PersonIcon } from 'assets/icons';
import { bindActionCreators } from 'redux';
import IntlMessages, { useIntl } from 'util/IntlMessages';
import { getMediaAssetURL } from 'new-design/helpers/getMediaAssetURL';
import ChangePasswordModal from 'new-design/components/ChangePasswordModal';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      avatar: {
        width: 40,
        height: 40,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('lg')]: {
          marginRight: 0,
          marginLeft: 0,
        },
      },
      userName: {
        fontSize: 16,
        color: '#222',
        lineHeight: '16px',
        marginBottom: 4,
        [theme.breakpoints.down('lg')]: {
          fontSize: 14,
          lineHeight: '14px',
        },
      },
      position: {
        fontSize: 12,
        color: theme.palette.secondary.main,
        lineHeight: '12px',
        [theme.breakpoints.down('lg')]: {
          fontSize: 10,
          lineHeight: '10px',
        },
      },
      actionButton: {
        display: 'flex',
        justifyContent: 'flex-start',
        '& .MuiButton-label': {
          color: theme.palette.secondary.main,
          fontWeight: 'normal',
        },
        '& svg': {
          width: 24,
          height: 24,
          [theme.breakpoints.down('lg')]: {
            width: 20,
            height: 20,
          },
        },
      },
      userContainer: {
        '& svg': {
          width: 24,
          height: 24,
          [theme.breakpoints.down('lg')]: {
            width: 20,
            height: 20,
          },
        },
      },
      menuBlock: {
        marginTop: 25,
      },
      root: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
          marginLeft: theme.spacing(3),
        },
      },
      userButton: {
        width: 'auto',
        minWidth: 'auto',
        borderRadius: '100%',
        padding: 0,
      },
    }),
  {
    name: 'TopUser',
  }
);

const TopUser = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { userInfo, sessionID } = useSelector(auth.selectors);
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = bindActionCreators(auth.actions, useDispatch());
  const [modalOpened, setModalOpened] = useState(false);

  const handleModalOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleModalClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogoutClick = useCallback(() => {
    handleModalClose();
    logout();
  }, []);

  const changePasswordOpened = useCallback(() => {
    setModalOpened(true);
  }, []);

  const changePasswordClose = useCallback(() => {
    setModalOpened(false);
  }, []);

  return (
    <>
      <div>
        <Box className={classes.root}>
          <Button
            aria-controls="user-menu"
            aria-haspopup="true"
            variant="text"
            color="secondary"
            onClick={handleModalOpen}
            className={classes.userButton}
          >
            <Avatar
              alt={userInfo?.userName}
              className={classes.avatar}
              src={getMediaAssetURL(userInfo?.photoMediaAssetID, sessionID)}
            />
          </Button>
        </Box>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleModalClose}
          className={classes.menuBlock}
        >
          <Box paddingX={2} paddingY={1.5}>
            <Box display="flex" alignItems="center" className={classes.userContainer}>
              <PersonIcon />
              <Box marginLeft={1}>
                <Typography className={classes.userName}>{userInfo?.userName || 'N/A'}</Typography>
                <Typography className={classes.position}>{userInfo?.position || 'N/A'}</Typography>
              </Box>
            </Box>
            <HDivider />
            <Box>
              <Button
                fullWidth
                startIcon={<PasswordIcon />}
                className={classes.actionButton}
                onClick={changePasswordOpened}
              >
                <IntlMessages id="change_password" />
              </Button>
            </Box>
            <HDivider />
            <Box>
              <Button
                fullWidth
                startIcon={<LogoutIcon />}
                className={classes.actionButton}
                onClick={handleLogoutClick}
              >
                <IntlMessages id="logout" />
              </Button>
            </Box>
          </Box>
        </Menu>
      </div>
      <ChangePasswordModal
        modalOpened={modalOpened}
        changePasswordClose={changePasswordClose}
        oldPasswordLabel={formatMessage({ id: 'enter_your_current_password' })}
        newPasswordLabel={formatMessage({ id: 'enter_your_new_password' })}
        confirmPasswordLabel={formatMessage({ id: 'confirm_your_new_password' })}
      />
    </>
  );
};

export default TopUser;
