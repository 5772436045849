import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { getPerformanceSuccess } from './actions';

export default handleActions(
  {
    [getPerformanceSuccess](state, { payload }) {
      return payload;
    },
    [logoutSuccess]() {
      return [];
    },
  },
  []
);
