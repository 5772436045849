import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const codesRequest = createRequest(constants.codes);
export const codesSuccess = createSuccess(constants.codes);
export const codesFailure = createFailure(constants.codes);

export const codesUpdateRequest = createRequest(`${constants.codes}_UPDATE`);

export const requestCodes = codesRequest;
export const requestCodesUpdates = codesUpdateRequest;
