import { TOURS } from 'app/api-routes';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  toursSuccess,
  toursFailure,
  toursRequest,
  tourSuccess,
  tourFailure,
  tourRequest,
  createTourRequest,
  createTourSuccess,
  createTourFailure,
  enableTourRequest,
  enableTourSuccess,
  enableTourFailure,
  disableTourRequest,
  disableTourSuccess,
  disableTourFailure,
  deleteTourSuccess,
  deleteTourFailure,
  deleteTourRequest,
  updateTourRequest,
  updateTourSuccess,
  updateTourFailure,
} from './actions';

function* toursSaga({ payload: { siteID } }) {
  try {
    const { payload } = yield axios.get(TOURS, { params: { siteID: siteID || null } });

    yield put(toursSuccess(payload));
  } catch (err) {
    yield put(toursFailure(err));
  }
}

function* tourSaga({ payload: { tourID } }) {
  try {
    const { payload } = yield axios.get(`${TOURS}/${tourID}`);

    yield put(tourSuccess(payload));
  } catch (err) {
    yield put(tourFailure(err));
  }
}

function* createTourSaga({ payload: { name, siteID } }) {
  try {
    const { payload: tourID } = yield axios.post(TOURS, {
      payload: {
        name,
        siteID: +siteID,
      },
    });

    yield call(tourSaga, { payload: { tourID } });
    yield put(createTourSuccess({ tourID }));
  } catch (err) {
    yield put(createTourFailure(err));
  }
}

function* updateTourSaga({ payload: { tourID, name, random, siteID, checkpoints } }) {
  try {
    yield axios.put(`${TOURS}/${tourID}`, {
      payload: {
        tourID,
        name,
        random,
        siteID,
      },
    });
    yield axios.put(`${TOURS}/${tourID}/checkpoints`, { payload: checkpoints });

    yield call(tourSaga, { payload: { tourID } });
    yield put(updateTourSuccess({ tourID }));
  } catch (err) {
    yield put(updateTourFailure(err));
  }
}

function* deleteTourSaga({ payload: { tourID } }) {
  try {
    yield axios.delete(`${TOURS}/${tourID}`);
    yield put(deleteTourSuccess({ tourID }));
  } catch (err) {
    yield put(deleteTourFailure(err));
  }
}

function* enableTourSaga({ payload: { tourID } }) {
  try {
    yield axios.put(`${TOURS}/${tourID}/enable`);

    yield put(enableTourSuccess({ tourID }));
  } catch (err) {
    yield put(enableTourFailure(err));
  }
}

function* disableTourSaga({ payload: { tourID } }) {
  try {
    yield axios.put(`${TOURS}/${tourID}/disable`);

    yield put(disableTourSuccess({ tourID }));
  } catch (err) {
    yield put(disableTourFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(toursRequest, toursSaga),
    yield takeLatest(tourRequest, tourSaga),
    yield takeLatest(createTourRequest, createTourSaga),
    yield takeLatest(updateTourRequest, updateTourSaga),
    yield takeLatest(deleteTourRequest, deleteTourSaga),
    yield takeLatest(enableTourRequest, enableTourSaga),
    yield takeLatest(disableTourRequest, disableTourSaga),
  ]);
}
