import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles(
  {
    root: {
      width: '100%',
      backgroundColor: '#F7F8FB',
    },
    headerBlock: {
      width: '100%',
      position: 'sticky',
      top: 0,
      background: theme.palette.common.white,
      borderBottom: `2px solid ${theme.palette.secondary.light}`,
      zIndex: 100,
    },
    contentBlock: {
      backgroundColor: '#F7F8FB',
      padding: '0 30px',
      position: 'relative',
      overflowX: 'hidden',
      height: 'calc(100% - 120px)',
      [theme.breakpoints.down('lg')]: {
        height: 'calc(100% - 72px)',
      }
    },
    childrenContent: {
      // marginBottom: 20,
      // position: 'relative',
      // height: 'calc(100vh - 159px)',
    },
    breadcrumbsContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      zIndex: 2,
    },
    breadcrumbsLimitedContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      zIndex: 2,
      width: 'calc((100% - (100% / 2)) / 2 - 30px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    actionsContainer: {
      height: 95,
      display: 'flex',
      alignItems: 'center',
      position: 'sticky',
      zIndex: 2,
      background: '#F7F8FB',
      top: 0,
      paddingTop: 14,
      paddingBottom: 30,
      [theme.breakpoints.down('lg')]: {
        height: 83,
      },
      '& .main-actions': {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        left: 0,
        zIndex: 1,
        alignItems: 'center',
      }
    },
    chipsStrip: {
      zIndex: 2,
    }
  }
), {
  name: 'MainLayout',
});

export { useStyles };
