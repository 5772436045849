import isEqual from 'react-fast-compare';
import {handleActions} from 'redux-actions';
import {dropSelectedSite, setSelectedSite} from 'ducks/selectedSite/actions';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [setSelectedSite](state, { payload }) {
      if (isEqual(state, payload)) {
        return state;
      }

      return Object.freeze({
        ...payload,
      });
    },
    [dropSelectedSite]() {
      return null;
    },
    [logoutSuccess]() {
      return null;
    },
  },
  null,
);
