import React, { useEffect } from 'react';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';

const ChangeValueListener = ({ onChangeListener, children, withSubmitProps }) => {
  const { values, submitForm } = useFormikContext();

  useEffect(() => {
    onChangeListener && onChangeListener(values);
  }, [values]);

  return <>{withSubmitProps ? children(submitForm) : children}</>;
};

export const Form = ({
  initialValues,
  validationSchema,
  onSubmit = () => {},
  children,
  onChangeListener,
  withSubmitProps,
  innerRef,
  submitOnEnter,
  className,
  outerSubmit,
  validateOnChange = true,
}) => (
  <Formik
    initialValues={initialValues || {}}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    enableReinitialize
    innerRef={innerRef}
    validateOnChange={validateOnChange}
  >
    {({ values, handleSubmit, setFieldValue }) => (
      <>
        <FormikForm
          className={className}
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              !!submitOnEnter && handleSubmit();
            }
          }}
        >
          <ChangeValueListener withSubmitProps={withSubmitProps} onChangeListener={onChangeListener}>
            {withSubmitProps ? (submitForm) => children(submitForm, values) : children}
          </ChangeValueListener>
        </FormikForm>
        {outerSubmit?.(handleSubmit, setFieldValue)}
      </>
    )}
  </Formik>
);
