import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useField } from 'formik';
import { Switch } from '../Switch';

export const FormSwitchField = ({
  name,
  className,
  readOnly,
}) => {
  const [{ value },, helpers] = useField(name);

  const handleStatusChange = useCallback(() => {
    helpers.setValue(!value);
    helpers.setTouched();
  }, [value]);

  return (
    <Box className={clsx(className)}>
      <Switch
        color="#42BD5D"
        checked={value}
        onClick={handleStatusChange}
        disabled={readOnly}
      />
    </Box>
  );
};
