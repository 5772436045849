import React from 'react';
import { Box, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Typography, FormHelperText } from '@material-ui/core';
import { Field } from 'formik';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useField } from 'formik';
import { styled } from '@material-ui/styles';

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  [theme.breakpoints.down('lg')]: {
    width: 20,
    height: 20,
  },
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  boxShadow: 'inset 0 0 0 1px #022CE6, inset 0 -1px 0 #022CE6',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(#022CE6,#022CE6 28%,transparent 32%)',
    content: '""',
    [theme.breakpoints.down('lg')]: {
      width: 20,
      height: 20,
    }
  },
}));

export function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => createStyles(
  {
    root: {
      '& .MuiFormControl-root': {
        width: '100%',
      }
    },
    field: {
      '& .radio-group': {
        marginTop: 28,
        [theme.breakpoints.down('lg')]: {
          marginTop: 14, 
        },
      }
    },
    label: {
      fontSize: 16,
      lineHeight: '24px',
      [theme.breakpoints.down('lg')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    }
  }
), {
  name: 'FormRadioGroupField'
});

export const FormRadioGroupField = ({
  label,
  name,
  className,
  items,
  disabledError,
  disabled,
  row = true,
}) => {
  const classes = useStyles();
  const [{ value }, , helpers] = useField(name);
  return (
    <Box className={clsx(classes.root, className)}>
      <Field name={name} className={classes.field}>
        {({ meta }) => (
          <>
            <FormControl
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {!!label && (
                <FormLabel style={{
                  marginRight: 16,
                }}>{label}: </FormLabel>
              )}
              <RadioGroup
                className="radio-group"
                row={row}
                onChange={(e, value) => {
                  helpers.setValue(value);
                }}
                defaultValue={value}
              >
                {
                  items.map(({ value, label, icon: Icon }) => (
                    <FormControlLabel
                      key={`radio-${value}`}
                      value={value}
                      control={<BpRadio />}
                      disabled={disabled}
                      label={(
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                          {!!Icon && <Box marginRight={1}><Icon /></Box>}
                          <Typography>{label}</Typography>
                        </div>
                      )}
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
            {!disabledError && !!meta.error && (
              <Box mt={1} ml={2}>
                <FormHelperText color="error" style={{ color: 'red' }}>{meta.error}</FormHelperText>
              </Box>
            )}
          </>
        )}
      </Field>
    </Box>
  );
};
