import preparedReducer from './reducer';
import {
  requestMobilePatrols,
  requestMobilePatrolInfo,
  deselectMobilePatrols,
  updateMobilePatrolsStatus,
  createMobilePatrols,
  updateMobilePatrols,
  deleteMobileUnit,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'mobilePatrols';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestMobilePatrols,
  requestMobilePatrolInfo,
  deselectMobilePatrols,
  updateMobilePatrolsStatus,
  createMobilePatrols,
  updateMobilePatrols,
  deleteMobileUnit,
};
