import { all, put, takeLatest } from 'redux-saga/effects';

import {
  notebookNoteFailure,
  notebookNoteRequest,
  notebookNoteSuccess,
  shiftNoteRequest,
  shiftNotesSuccess,
  shiftNotesFailure,
} from './actions';
import { notebookNoteTagsRequest } from 'ducks/notebookNoteTags/actions';
import axios from 'axios';
import { NOTEBOOK, SHIFTS } from 'app/api-routes';

function* notebookNoteSaga({ payload: notebookNoteID }) {
  try {
    const { payload } = yield axios.get(`${NOTEBOOK}/${notebookNoteID}`);

    const { siteID } = payload;
    yield put(
      notebookNoteTagsRequest(siteID),
    );

    yield put(notebookNoteSuccess(payload));
  } catch (err) {
    yield put(notebookNoteFailure(err));
  }
}

function* shiftNotesSaga({ payload: shiftID }) {
  try {
    const response = yield axios.get(`${SHIFTS}/${shiftID}/notes`, {
      params: {
        sort: 'A',
      }
    });

    if (response.payload.length) {
      yield put(
        notebookNoteTagsRequest(response.payload[0].siteID),
      );

      yield put(shiftNotesSuccess(response.payload[0]));
    }
  } catch (err) {
    yield put(shiftNotesFailure(err));
  }
}

export default function*() {
  yield all([
    yield takeLatest(notebookNoteRequest, notebookNoteSaga),
    yield takeLatest(shiftNoteRequest, shiftNotesSaga),
  ]);
}
