import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const taskRecordsRequest = createRequest(constants.taskRecords);
export const taskRecordsSuccess = createSuccess(constants.taskRecords);
export const taskRecordsFailure = createFailure(constants.taskRecords);

export const createTaskRecordRequest = createRequest(constants.createTaskRecord);
export const createTaskRecordSuccess = createSuccess(constants.createTaskRecord);
export const createTaskRecordFailure = createFailure(constants.createTaskRecord);

export const updateTaskRecordsRequest = createRequest(constants.updateTaskRecords);
export const updateTaskRecordsSuccess = createSuccess(constants.updateTaskRecords);
export const updateTaskRecordsFailure = createFailure(constants.updateTaskRecords);

export const requestTaskRecords = taskRecordsRequest;
export const createTaskRecord = createTaskRecordRequest;
export const updateTaskRecords = updateTaskRecordsRequest;
