import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { QRIcon, NFCIcon, StandardTourIcon, RandomTourIcon } from 'assets/icons';

export const contactClientTitle = [
  {
    key: '78',
    label: 'Mr.',
  },
  {
    key: '79',
    label: 'Mrs.',
  },
  {
    key: '80',
    label: 'Ms.',
  },
];

export const employeeStates = [
  {
    label: 'On',
    key: 'on',
  },
  {
    label: 'Off',
    key: 'off',
  },
  {
    label: 'N/A',
    key: 'n_a',
  },
];

export const statuses = (params) => {
  let items = [
    {
      key: 'A',
      label: <IntlMessages id="active" />,
    },
    {
      key: 'S',
      label: <IntlMessages id="suspended" />,
    },
    {
      key: 'D',
      label: <IntlMessages id="deleted" />,
    },
  ];

  if (params?.withNew) {
    items.unshift({
      label: <IntlMessages id="new" />,
      key: 'N',
    });
  }

  if (params?.withAll) {
    items = [
      {
        label: <IntlMessages id="all" />,
        key: 'all',
      },
      ...items,
    ];
  }

  return items;
};

export const radioItems = [
  {
    value: 'qr_code',
    label: <IntlMessages id="qr_tags" />,
    icon: QRIcon,
  },
  {
    value: 'nfc',
    label: <IntlMessages id="nfc_tags" />,
    icon: NFCIcon,
  },
  {
    value: 'none',
    label: <IntlMessages id="no_tags" />,
  },
];

export const tourTypeItems = [
  {
    value: 'static',
    label: <IntlMessages id="static" />,
    icon: StandardTourIcon,
  },
  {
    value: 'dynamic',
    label: <IntlMessages id="dynamic" />,
    icon: RandomTourIcon,
  },
];
