import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import { auditLogSuccess, auditEventSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [auditLogSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [auditEventSuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.eventID]: payload,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
