import {
  CalendarIcon,
  CameraIcon,
  CategoryIcon,
  CheckboxChecked,
  ClientAddressIcon,
  ClientsIcon,
  CommentIcon,
  DividerIcon,
  DropdownIcon,
  HashIcon,
  LabelsIcon,
  OneSiteIcon,
  RadioButtonIcon,
  SignatureIcon,
  SiteAddressIcon,
  SpacerIcon,
  TextBoxIcon,
  TextLineIcon,
  UserIcon,
} from 'assets/icons';
import { DATE_FORMAT, DATE_TIME_FORMAT_SHORT } from './constants';

// Inputs
export const TEXT_LINE_INPUT = 'text_line_input';
export const TEXT_BOX_INPUT = 'text_box_input';
export const CATEGORY_INPUT = 'category_input';
export const LABEL_INPUT = 'label_input';

export const SPACE_LAYOUT = 'space_layout';
export const DIVIDER_LAYOUT = 'divider_layout';
export const COMMENT_LAYOUT = 'comment_layout';

export const DATE_INPUT = 'date_input';
export const DATE_TIME_INPUT = 'date_time_input';

export const CLIENT_NAME_INPUT = 'client_name_input';
export const CLIENT_ADDRESS_INPUT = 'client_address_input';
export const SITE_NAME_INPUT = 'site_name_input';
export const SITE_ADDRESS_INPUT = 'site_address_input';

export const FORM_ID_INPUT = 'form_id_input';
export const CREATED_BY_INPUT = 'created_by_input';
export const APPROVED_BY_INPUT = 'approved_by_input';

export const CHECKBOXES_INPUT = 'checkboxes_input';
export const RADIO_BUTTONS_INPUT = 'radio_buttons_input';
export const DROPDOWN_INPUT = 'dropdown_input';

export const PHOTOS_INPUT = 'photos_input';

export const SIGNATURE_INPUT = 'signature_input';

// Groups
export const TEXT_GROUP_NAME = 'text_group';
export const ORGANAZING_GROUP_NAME = 'organazing_group';
export const LAYOUT_GROUP_NAME = 'layout_group';
export const DATE_TIME_GROUP = 'date_time_group';
export const DATA_GROUP = 'data_group';
export const AUTOFILL_GROUP = 'autofill_group';
export const SELECTORS_GROUP = 'selectors_group';
export const ATTACHMENTS_GROUP = 'attachments_group';
export const OTHER_GROUP = 'other_group';

// Inputs description
export const INPUTS_DESCRIPTION = {
  [CATEGORY_INPUT]: {
    icon: CategoryIcon,
    name: CATEGORY_INPUT,
    disabled: false,

    placeholderKey: CATEGORY_INPUT,
    iconFillType: 'fill',
    limitPerForm: 1,
  },
  [LABEL_INPUT]: {
    icon: LabelsIcon,
    name: LABEL_INPUT,
    disabled: false,

    placeholderKey: LABEL_INPUT,
    iconFillType: 'fill',
    limitPerForm: 1,
  },
  [TEXT_LINE_INPUT]: {
    icon: TextLineIcon,
    name: TEXT_LINE_INPUT,
    disabled: false,

    placeholderKey: TEXT_LINE_INPUT,
    iconFillType: 'fill',
  },
  [TEXT_BOX_INPUT]: {
    icon: TextBoxIcon,
    name: TEXT_BOX_INPUT,
    disabled: false,

    placeholderKey: TEXT_BOX_INPUT,
    iconFillType: 'stroke',
  },

  [SPACE_LAYOUT]: {
    icon: SpacerIcon,
    name: SPACE_LAYOUT,
    disabled: false,
    iconFillType: 'stroke',
  },
  [DIVIDER_LAYOUT]: {
    icon: DividerIcon,
    name: DIVIDER_LAYOUT,
    disabled: false,
    iconFillType: 'stroke',
  },
  [COMMENT_LAYOUT]: {
    icon: CommentIcon,
    name: COMMENT_LAYOUT,
    disabled: false,

    placeholderKey: COMMENT_LAYOUT,
    iconFillType: 'stroke',
  },
  [DATE_INPUT]: {
    icon: CalendarIcon,
    name: DATE_INPUT,
    disabled: false,

    placeholder: DATE_FORMAT,
    iconFillType: 'fill',
  },
  [DATE_TIME_INPUT]: {
    icon: CalendarIcon,
    name: DATE_TIME_INPUT,
    disabled: false,

    placeholder: DATE_TIME_FORMAT_SHORT,
    iconFillType: 'fill',
  },
  [CLIENT_NAME_INPUT]: {
    icon: ClientsIcon,
    name: CLIENT_NAME_INPUT,
    disabled: false,

    iconFillType: 'fill',
    defaultLabelKey: CLIENT_NAME_INPUT,
    placeholderKey: CLIENT_NAME_INPUT,
    limitPerForm: 1,
  },
  [CLIENT_ADDRESS_INPUT]: {
    icon: ClientAddressIcon,
    name: CLIENT_ADDRESS_INPUT,
    disabled: false,

    defaultLabelKey: CLIENT_ADDRESS_INPUT,
    placeholderKey: CLIENT_ADDRESS_INPUT,
    iconFillType: 'fill',
    limitPerForm: 1,
  },
  [SITE_NAME_INPUT]: {
    icon: OneSiteIcon,
    name: SITE_NAME_INPUT,
    disabled: false,

    iconFillType: 'fill',
    placeholderKey: SITE_NAME_INPUT,
    defaultLabelKey: SITE_NAME_INPUT,
    limitPerForm: 1,
  },
  [SITE_ADDRESS_INPUT]: {
    icon: SiteAddressIcon,
    name: SITE_ADDRESS_INPUT,
    disabled: false,

    defaultLabelKey: SITE_ADDRESS_INPUT,
    placeholderKey: SITE_ADDRESS_INPUT,
    iconFillType: 'fill',
    limitPerForm: 1,
  },
  [FORM_ID_INPUT]: {
    icon: HashIcon,
    name: FORM_ID_INPUT,
    disabled: false,
    hideRequired: true,

    iconFillType: 'fill',
    placeholderKey: FORM_ID_INPUT,
  },
  [CREATED_BY_INPUT]: {
    icon: UserIcon,
    name: CREATED_BY_INPUT,
    disabled: false,
    hideRequired: true,

    iconFillType: 'fill',
    placeholderKey: CREATED_BY_INPUT,
  },
  [APPROVED_BY_INPUT]: {
    icon: UserIcon,
    name: APPROVED_BY_INPUT,
    disabled: false,
    hideRequired: true,

    iconFillType: 'fill',
    placeholderKey: APPROVED_BY_INPUT,
  },
  [CHECKBOXES_INPUT]: {
    icon: CheckboxChecked,
    name: CHECKBOXES_INPUT,
    disabled: false,

    iconFillType: 'fill',
  },
  [RADIO_BUTTONS_INPUT]: {
    icon: RadioButtonIcon,
    name: RADIO_BUTTONS_INPUT,
    disabled: false,

    iconFillType: 'fill',
  },
  [PHOTOS_INPUT]: {
    icon: CameraIcon,
    name: PHOTOS_INPUT,
    disabled: false,

    iconFillType: 'fill',
  },
  [SIGNATURE_INPUT]: {
    icon: SignatureIcon,
    name: SIGNATURE_INPUT,
    disabled: false,

    iconFillType: 'fill',
  },
  [DROPDOWN_INPUT]: {
    icon: DropdownIcon,
    name: DROPDOWN_INPUT,
    disabled: false,

    iconFillType: 'fill',
  },
};

export const FIELDS_WITHOUT_VALIDATION = [
  CREATED_BY_INPUT,
  APPROVED_BY_INPUT,
  SPACE_LAYOUT,
  DIVIDER_LAYOUT,
  COMMENT_LAYOUT,
  FORM_ID_INPUT,
  CLIENT_NAME_INPUT,
  SITE_NAME_INPUT,
];
