import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { siteInfoToursSuccess } from './actions';
import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return {
        ...initialState,
      };
    },
    [siteInfoToursSuccess](state, { payload: { siteID, siteInfoTours } }) {
      return {
        ...state,
        [siteID]: [
          ...siteInfoTours,
        ],
      };
    },
  },
  {
    ...initialState,
  },
);
