import { createRequest } from 'ducks/helpers';
import constants from './constants';

export const switchAdminTab = createRequest(constants.adminTab);
export const setDisabledTabs = createRequest(`${constants.adminTab}_DISABLED_TABS`);
export const setAdminTabsList = createRequest(`${constants.adminTab}_TABS_LIST`);
export const setPreSubmitFlag = createRequest(`${constants.adminTab}_PRE_SUBMIT_FLAG`);

export const switchTab = switchAdminTab;
export const setTabsList = setAdminTabsList;
