import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const tsByEmployeeRequest = createRequest(constants.tsByEmployee);

export const tsByEmployeeSuccess = createSuccess(constants.tsByEmployee);

export const tsByEmployeeFailure = createFailure(constants.tsByEmployee);

export const requestTsByEmployee = tsByEmployeeRequest;
