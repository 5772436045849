import ptMessages from '../locales/pt_PT.json';

const ptLang = {
    messages: {
        ...ptMessages
    },
    locale: 'pt',

};
export default ptLang;
