import React, { useEffect, useMemo } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import AppLocale from '../../lngProvider';
import { SignInPage } from 'new-design/pages/SignIn/SignIn';
import RTL from 'util/RTL';
import { SecuredRoute } from 'new-design/routes/common/SecuredRoute';
import { BASE_APP_PATH, LOGIN_PAGE, ACTIONS_PAGE } from 'new-design/routes/common/constants';
import { ExpiredSessionModal } from 'new-design/components/ExpiredSesionModal';
import { LoginRoute } from 'new-design/routes/common/Route';
import { Route, Redirect } from 'react-router-dom';

import { NotificationContainer } from 'react-notifications';
import NewAppRoutes from 'new-design/routes';
import PublicRoutes from 'new-design/routes/public';
import StuffRoutes from 'new-design/routes/stuff';

import amberTheme from 'styles/themes/amberTheme';
import 'react-notifications/lib/notifications.css';
import 'assets/vendors/style';
import 'core-js/es/promise';
import 'core-js/es/set';
import 'core-js/es/map';

const theme = createTheme(amberTheme);

const App = () => {
  const { locale, isDirectionRTL } = useSelector(({ settings }) => settings);

  useEffect(() => {
    if (isDirectionRTL) {
      theme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      theme.direction = 'ltr';
    }
  }, [isDirectionRTL]);

  const mergedMessages = useMemo(() =>
    Object.assign({}, AppLocale['en'].messages, AppLocale[locale.locale].messages)
  , [locale]);
  
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        defaultLocale="en"
        locale={locale.locale}
        messages={mergedMessages}
      >
        <RTL>
          <div className="app-main">
            <LoginRoute path={LOGIN_PAGE} component={SignInPage}/>
            <PublicRoutes />
            <SecuredRoute path={ACTIONS_PAGE} component={StuffRoutes} />
            <SecuredRoute path={BASE_APP_PATH} component={NewAppRoutes} />
            <Route exact path="/">
              <Redirect to="/app/control-panel" />
            </Route>
            <ExpiredSessionModal />
          </div>
          <NotificationContainer />
        </RTL>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
