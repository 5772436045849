import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  iconRoot: {
    padding: 0,
    margin: -3,
    width: 24,
    height: 24,
    [theme.breakpoints.up('lg')]: {
      width: 20,
      height: 20,
    },

    '& svg': {
      '& path': {
        fill: ({ isEmpty }) => isEmpty ? theme.palette.primary.main : theme.palette.secondary.main,
      }
    }
  },
  badge: {
    '& .MuiBadge-badge': {
      border: '2px solid white',
      width: 10,
      height: 10,
      borderRadius: '100%',

      background: ({ pointColor }) => pointColor || 'transparent',
    }
  },

  menu: {
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenu-paper': {
      width: 655,
      top: '83px !important',
      right: '170px !important',
      left: 'auto !important',
      [theme.breakpoints.down('lg')]: {
        width: 455,
      }
    }
  },
  notificationsPopup: {
    width: 655,
    maxHeight: 335,
    // padding: 30,

    background: '#FFFFFF',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
      width: 455,
      // padding: 15,
    },

    '& .no_notifications': {
      color: theme.palette.secondary.main,
      fontSize: 16,
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
      },
    }
  },
  leftWaveBig: {
    width: 10,
    height: 20,
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderTopLeftRadius: 22,
    borderBottomLeftRadius: 22,
    borderColor: ({ pointColor }) => pointColor,
    borderStyle: 'solid',
    borderRightWidth: 0,
  },
  leftWaveSmall: {
    width: 5,
    height: 10,
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    borderColor: ({ pointColor }) => pointColor,
    borderStyle: 'solid',
    borderRightWidth: 0,
  },
  rightWaveBig: {
    width: 10,
    height: 20,
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderTopRightRadius: 22,
    borderBottomRightRadius: 22,
    borderColor: ({ pointColor }) => pointColor,
    borderStyle: 'solid',
    borderLeftWidth: 0,
  },
  rightWaveSmall: {
    width: 5,
    height: 10,
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderColor: ({ pointColor }) => pointColor,
    borderStyle: 'solid',
    borderLeftWidth: 0,
  },
}), {
  name: 'Notifications'
});