import { createAction } from 'redux-actions';

const requestPostfix = 'REQUEST';
const successPostfix = 'SUCCESS';
const failurePostfix = 'FAILURE';

export const createRequest = (prefix) => createAction(`${prefix}_${requestPostfix}`);
export const createSuccess = (prefix) => createAction(`${prefix}_${successPostfix}`);
export const createFailure = (prefix) => createAction(`${prefix}_${failurePostfix}`);
export { createAction };

export const isRequests = (actionName) => actionName.includes(requestPostfix);
export const isSuccess = (actionName) => actionName.includes(successPostfix);
export const isFailure = (actionName) => actionName.includes(failurePostfix);
