import preparedReducer from './reducer';
import {
  requestShiftInfo,
  requestShiftStats,
  requestShiftStatsBySiteID,
  shiftStatsBySiteIDSuccess,
  checkpointInfoNew,
  getShiftReportLink,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'shiftInfo';

export const selectors = state => state[SLICE_NAME];
export const getShiftInfo = (state, shiftID) => state[SLICE_NAME].info[shiftID];

export const actions = {
  requestShiftInfo,
  requestShiftStats,
  requestShiftStatsBySiteID,
  shiftStatsBySiteIDSuccess,
  checkpointInfoNew,
  getShiftReportLink,
};
