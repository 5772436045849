import constants from './constants';

export default Object.freeze({
  navCollapsed: false,
  drawerType: constants.fixedDrawer,
  themeColor: 'amber',
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: constants.horizontalNavigation,
  horizontalNavPosition: constants.insideTheHeader,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
  }
});

