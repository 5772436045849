import { createAction } from '../helpers';
import constants from './constants';

export const switchLanguage = createAction(constants.switchLanguage);
export const setHorizontalMenuPosition = createAction(constants.horizontalMenuPosition);
export const changeNavigationStyle = createAction(constants.changeNavigationStyle);
export const changeDirection = createAction(constants.changeDirection);
export const setDarkTheme = createAction(constants.darkTheme);
export const setThemeColor = createAction(constants.themeColor);
export const updateWindowWidth = createAction(constants.windowWidth);
export const setDrawerType = createAction(constants.drawerType);
export const toggleCollapsedNav = createAction(constants.toggleCollapsedNav);
