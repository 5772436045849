import preparedReducer from './reducer';
import { switchTab, setTabsList, setDisabledTabs, setPreSubmitFlag } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'adminTab';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  switchTab,
  setTabsList,
  setDisabledTabs,
  setPreSubmitFlag,
};
