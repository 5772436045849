import preparedReducer from './reducer';
import { requestEntitiesSummary, requestEventsSummary, requestEntityOverview, requestEventOverview } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'analytics';

export const selectors = (state) => state[SLICE_NAME];
export const selectEventsSummary = (state) => state[SLICE_NAME].eventsSummary;
export const selectEventOverview = (state) => state[SLICE_NAME].eventOverview;
export const selectEntitiesSummary = (state) => state[SLICE_NAME].entitiesSummary;
export const selectEntityOverview = (state) => state[SLICE_NAME].entityOverview;

export const actions = {
  requestEntitiesSummary,
  requestEventsSummary,
  requestEntityOverview,
  requestEventOverview,
};
