import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const infoNoteRequest = createRequest(constants.infoNote);
export const infoNoteSuccess = createSuccess(constants.infoNote);
export const infoNoteFailure = createFailure(constants.infoNote);

export const requestInfoNote = infoNoteRequest;

export const postMessageRequest = createRequest(`${constants.infoNote}_POST_MESSAGE`);
export const postMessageSuccess = createSuccess(`${constants.infoNote}_POST_MESSAGE`);
export const postMessageFailure = createFailure(`${constants.infoNote}_POST_MESSAGE`);

export const postMessage = postMessageRequest;

export const getAllInfoNoteRequest = createRequest(`${constants.infoNote}_GET_ALL_INFO_NOTES`);
export const getAllInfoNoteSuccess = createSuccess(`${constants.infoNote}_GET_ALL_INFO_NOTES`);
export const getAllInfoNoteFailure = createFailure(`${constants.infoNote}_GET_ALL_INFO_NOTES`);

export const getAllInfoNote = getAllInfoNoteRequest;

export const createInfoNoteRequest = createRequest(`${constants.infoNote}_CREATE_INFO_NOTE`);
export const createInfoNoteSuccess = createSuccess(`${constants.infoNote}_CREATE_INFO_NOTE`);
export const createInfoNoteFailure = createFailure(`${constants.infoNote}_CREATE_INFO_NOTE`);

export const createInfoNote = createInfoNoteRequest;

export const updateInfoNoteRequest = createRequest(`${constants.infoNote}_UPDATE_INFO_NOTE`);
export const updateInfoNoteSuccess = createSuccess(`${constants.infoNote}_UPDATE_INFO_NOTE`);
export const updateInfoNoteFailure = createFailure(`${constants.infoNote}_UPDATE_INFO_NOTE`);

export const updateInfoNote = updateInfoNoteRequest;

export const ackInfoNoteRequest = createRequest(`${constants.infoNote}_ACK_INFO_NOTE`);
export const ackInfoNoteSuccess = createSuccess(`${constants.infoNote}_ACK_INFO_NOTE`);
export const ackInfoNoteFailure = createFailure(`${constants.infoNote}_ACK_INFO_NOTE`);

export const ackInfoNote = ackInfoNoteRequest;

export const toggleInfoNoteRequest = createRequest(`${constants.infoNote}_TOGGLE_INFO_NOTE`);
export const toggleInfoNoteSuccess = createSuccess(`${constants.infoNote}_TOGGLE_INFO_NOTE`);
export const toggleInfoNoteFailure = createFailure(`${constants.infoNote}_TOGGLE_INFO_NOTE`);

export const toggleInfoNote = toggleInfoNoteRequest;
