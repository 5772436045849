import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const irPerDateRequest = createRequest(constants.irPerDate);

export const irPerDateSuccess = createSuccess(constants.irPerDate);

export const irPerDateFailure = createFailure(constants.irPerDate);

export const requestIrPerDate = irPerDateRequest;
