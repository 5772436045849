export  const SHIFT_START = 'start';
export  const SHIFT_END = 'end';
export  const CHECKPOINT_SCANNED = 'scan';
export  const TASK_COMPLETED = 'task_completed';
export  const NOTE_CREATED = 'note';
export  const INFO_NOTE_CREATED = 'info_note_created';
export  const INFO_NOTE_ACK = 'info_note_ack';
export  const TOUR_STARTED = 'tour_started';
export  const TOUR_ENDED = 'tour_ended';
export  const VISIT_STARTED = 'visit_started';
export  const VISIT_ENDED = 'visit_ended';
export  const GPS_ALERT = 'gps_alert';
export  const INACTIVITY = 'inactivity';
export  const ALARM = 'alarm';
export  const IR_CREATED = 'ir_created';
export  const CALL_REQUESTED = 'call';
export  const CALL_RECEIVED = 'received_call';
export  const CALL_PROCESSED = 'processed_call';
export  const FORM_CREATED = 'form_created';
