import React, { useEffect, useState, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import {
  Select as MUSelect,
  MenuItem,
  Box,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ArrowDownIcon, InfoIcon } from 'assets/icons';
import { Checkbox } from '.';
import IntlMessages from 'util/IntlMessages';
import _ from 'lodash';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      formControl: {
        minWidth: 87,
        maxWidth: '100%',
        width: '100%',
      },
      arrow: {
        '& path': {
          stroke: theme.palette.common.black,
        },
      },
      checkbox: {
        margin: 0,
        '& .MuiButtonBase-root': {
          padding: 0,
        },
      },
      menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.secondary.main,
        paddingTop: 12,
        paddingBottom: 12,
        '&.Mui-selected': {
          background: theme.palette.common.white,
          color: theme.palette.primary.main,
        },
      },
      controlLabel: {
        fontSize: 16,
        color: theme.palette.secondary.main,
        lineHeight: '24px',
        fontWeight: 'normal',
        marginBottom: 10,
        [theme.breakpoints.down('lg')]: {
          fontSize: 14,
          lineHeight: '21px',
          marginBottom: 5,
        },
      },
      label: {
        color: theme.palette.common.black,
      },
      icon: {
        width: 24,
        height: 24,
        '& path': {
          fill: '#8A8A8A',
        },
        '& circle': {
          fill: '#8A8A8A',
        },
        '& .only-white': {
          fill: 'white',
        },
        [theme.breakpoints.down('lg')]: {
          width: 20,
          height: 20,
        },
      },
      placeholder: {
        fontSize: 16,
        lineHeight: '18px',
        [theme.breakpoints.down('lg')]: {
          fontSize: 14,
          lineHeight: '16px',
        },
      },
      smallSelect: {
        borderRadius: 8,
        height: 48,
        '& .MuiSelect-root': {
          paddingTop: '14.5px !important',
          paddingBottom: '14.5px !important',
          paddingLeft: '12px !important',
        },
        [theme.breakpoints.down('lg')]: {
          height: 40,
          '& .MuiSelect-root': {
            paddingTop: '10.5px !important',
            paddingBottom: '10.5px !important',
            paddingLeft: '8px !important',
          },
        },
      },
    }),
  {
    name: 'MultiSelect',
  }
);

export const ALL = '__all__';

const ValueAll = () => {
  return (
    <Box display="flex" alignItems="center">
      <IntlMessages id="cp.ir.select_all" />
    </Box>
  );
};

const ValueSome = ({ length }) => {
  return (
    <Box display="flex" alignItems="center">
      {length} <IntlMessages id="selected" />
    </Box>
  );
};

const ValueMultiple = ({ options, selected }) => {
  const selectedLabels = (selected || []).map((id) => options.find((el) => el.key === id)?.label).join('; ');

  return (
    <Box display="flex" alignItems="center">
      {selectedLabels}
    </Box>
  );
};

const ValueOne = ({ item }) => {
  const { label: itemLabel } = item;

  return (
    <Box display="flex" alignItems="center">
      {itemLabel}
    </Box>
  );
};

const ValueNoOne = ({ placeholder }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Typography color="secondary" className={classes.placeholder}>
        {placeholder || ''}
      </Typography>
    </Box>
  );
};

const determineIcon = (selected, options, inputStartIcon, multiple) => {
  if (!multiple) {
    const item = options?.find((el) => el.key === selected);
    return item?.icon;
  }

  switch (selected?.length) {
    case 0:
      return inputStartIcon;

    case 1: {
      if (options.length === 1) {
        return inputStartIcon;
      }
      const item = options?.find((el) => _.isEqual(el.key, selected[0]));
      return item?.icon || inputStartIcon;
    }

    case options.length: {
      return inputStartIcon;
    }

    default: {
      return inputStartIcon;
    }
  }
};

const defaultRenderValue = (selected, options, placeholder, multiple, showSelected) => {
  if (!multiple) {
    return <ValueOne item={options?.find((el) => el.key === selected) || {}} />;
  }

  switch (selected?.length) {
    case 0:
      return <ValueNoOne placeholder={placeholder} />;

    case 1: {
      if (options.length === 1) {
        return <ValueAll />;
      }
      const item = options?.find((el) => _.isEqual(el.key, selected[0])) || {};
      return <ValueOne item={item} />;
    }

    case options.length: {
      if (showSelected) {
        return <ValueMultiple options={options} selected={selected} />;
      } else {
        return <ValueAll />;
      }
    }

    default: {
      if (showSelected) {
        return <ValueMultiple options={options} selected={selected} />;
      } else {
        return <ValueSome length={selected?.length} />;
      }
    }
  }
};

const MultiSelect = ({
  name,
  label,
  variant,
  options,
  onChange,
  placeholder,
  defaultValue,
  multiple,
  inputStartIcon = InfoIcon,
  withoutAll,
  withoutIcon,
  checkboxPlace = 'end',
  size,
  className,
  showSelected,
  renderValue,
  ...rest
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(defaultValue || []);
  const InputStartIcon = withoutIcon ? undefined : inputStartIcon;

  const onSetValue = useCallback(
    (values) => {
      setSelected(values);
      onChange && onChange(values);
    },
    [onChange]
  );

  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  const _handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === ALL) {
      onSetValue(selected.length === options.length ? [] : options.map(({ key }) => key));
      return;
    }
    onSetValue(value);
  };

  const isAllSelected = options?.length > 0 && selected?.length === options?.length;

  const inputId = useMemo(() => {
    if (!name) return uuidv4();

    return `input_${name.replace(' ', '_').toLowerCase()}`;
  }, [name]);

  if (!options?.length) return null;

  const Icon = determineIcon(selected, options, InputStartIcon, multiple, showSelected);

  return (
    <FormControl variant={variant || 'outlined'} className={clsx(classes.formControl)}>
      <InputLabel htmlFor={inputId}>{label}</InputLabel>
      <MUSelect
        {...rest}
        className={clsx(className, {
          [classes.smallSelect]: size === 'small',
        })}
        id={inputId}
        label={label}
        name={name}
        fullWidth
        displayEmpty
        variant="outlined"
        onChange={_handleChange}
        IconComponent={ArrowDownIcon}
        multiple={multiple}
        value={selected}
        defaultValue={defaultValue ? defaultValue : []}
        renderValue={() => renderValue
            ? renderValue({ options, selected })
            : defaultRenderValue(selected, options, placeholder, multiple, showSelected)}
        MenuProps={{
          disablePortal: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        startAdornment={
          Icon && (
            <InputAdornment>
              <Icon className={clsx(classes.icon)} />
            </InputAdornment>
          )
        }
      >
        {!!multiple && !withoutAll && (
          <MenuItem className={classes.menuItem} value={ALL}>
            <Box width={24} height={24} minHeight={24} minWidth={24}>
              {InputStartIcon && <InputStartIcon className={classes.icon} />}
            </Box>
            <Box textAlign="left" width="100%" marginLeft={2}>
              <Typography className={classes.label}>
                <IntlMessages id="cp.ir.select_all" />
              </Typography>
            </Box>
            <Checkbox className={classes.checkbox} checked={isAllSelected} />
          </MenuItem>
        )}
        {options?.map(({ key, label, icon: Icon, iconColorClassName }) => (
          <MenuItem className={classes.menuItem} key={`menu-item-${inputId}-${key}-${name}`} value={key}>
            {!!multiple && checkboxPlace === 'start' && (
              <Checkbox
                className={classes.checkbox}
                checked={_.flatten(selected).indexOf(_.isArray(key) ? key[0] : key) > -1}
              />
            )}
            <Box width={24} height={24} minHeight={24} minWidth={24}>
              {Icon && <Icon className={clsx(classes.icon, iconColorClassName)} />}
            </Box>
            <Box textAlign="left" width="100%" marginLeft={2}>
              <Typography className={classes.label}>{label}</Typography>
            </Box>
            {!!multiple && checkboxPlace === 'end' && (
              <Checkbox
                className={classes.checkbox}
                checked={_.flatten(selected).indexOf(_.isArray(key) ? key[0] : key) > -1}
              />
            )}
          </MenuItem>
        ))}
      </MUSelect>
    </FormControl>
  );
};

export { MultiSelect };
