import preparedReducer from './reducer';
import {
  initPlanList,
  addCheckpoint,
  addArea,
  setRefEl,
  setAreaActive,
  deleteArea,
  renameCheckpoint,
  updateNode,
  setSelectedTab,
  setSelectedItem,
  setMarkerEnabled,
  setMarker,
  setDraggableMode,
} from './actions';

import initState from './states';

export { initState };

export const reducer = preparedReducer;

export const SLICE_NAME = 'planList';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  initPlanList,
  addCheckpoint,
  addArea,
  setRefEl,
  setAreaActive,
  deleteArea,
  renameCheckpoint,
  updateNode,
  setSelectedTab,
  setSelectedItem,
  setMarkerEnabled,
  setMarker,
  setDraggableMode,
};
