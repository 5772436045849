// Constants for different requests for current reducer
export default {
  toggleCollapsedNav: 'TOGGLE_COLLAPSED_NAV',
  drawerType: 'DRAWER_TYPE',
  fixedDrawer: 'FIXED_DRAWER',
  collapsedDrawer: 'COLLAPSED_DRAWER',
  miniDrawer: 'MINI_DRAWER',
  themeColor: 'THEME_COLOR',
  darkTheme: 'DARK_THEME',
  windowWidth: 'WINDOW_WIDTH',
  switchLanguage: 'SWITCH_LANGUAGE',
  changeDirection: 'CHANGE_DIRECTION',
  changeNavigationStyle: 'CHANGE_NAVIGATION_STYLE',
  horizontalNavigation: 'HORIZONTAL_NAVIGATION',
  verticalNavgation: 'VERTICAL_NAVIGATION',
  horizontalMenuPosition: 'HORIZONTAL_MENU_POSITION',
  aboveTheHeader: 'ABOVE_THE_HEADER',
  insideTheHeader: 'INSIDE_THE_HEADER',
  belowTheHeader: 'BELOW_THE_HEADER',
};
