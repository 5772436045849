import React from 'react';
import {
  withStyles,
  Menu as MUMenu,
} from '@material-ui/core';

export const Menu = withStyles({
  paper: {
    width: props => props.width,
    minWidth: 206,
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
  },
})(({ width = 187, ...props }) => (
  <MUMenu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
    width={width}
  />
));