import { handleActions } from 'redux-actions';
import {
  setType,
  setEmployees,
  setActivity,
  setSelectedSite,
  setSelectedShift,
  setActiveOnly,
  mapActivitySitesSuccess,
  mapActivityPatrolsSuccess,
  setSelectedMU,
} from './actions';
import initialState from './states';
import _ from 'lodash';
import { logoutSuccess } from 'ducks/auth/actions';

const objectsEqual = (o1, o2) => {
  if (_.isObject(o1) && Object.keys(o1).length > 0) {
    let isEqual = true;
    Object.keys(o1).forEach((key) => {
      const objectEqual = o2 !== undefined ? objectsEqual(o1[key], o2[key]) : false;
      isEqual = isEqual && objectEqual;
    });
    return isEqual;
  } else {
    if (_.isArray(o1)) {
      return arraysEqual(o1, o2);
    } else if (_.isObject(o1)) {
      return objectsEqual(o1, o2);
    } else {
      return o1 === o2;
    }
  }
};

const arraysEqual = (a1, a2) => {
  if (a1.length === a2.length) {
    return a1.every((o, idx) => objectsEqual(o, a2[idx]));
  }
  return false;
};

export default handleActions(
  {
    [setType](state, { payload }) {
      return {
        ...state,
        type: payload,
      };
    },
    [setEmployees](state, { payload }) {
      return {
        ...state,
        employees: payload,
      };
    },
    [setActivity](state, { payload }) {
      return {
        ...state,
        activity: payload,
      };
    },
    [setSelectedSite](state, { payload }) {
      if (_.isEqual(state.selectedSite, payload)) {
        return state;
      }

      return {
        ...state,
        selectedSite: payload,
      };
    },
    [setSelectedShift](state, { payload }) {
      if (_.isEqual(state.selectedShift, payload)) {
        return state;
      }

      return {
        ...state,
        selectedShift: payload,
      };
    },
    [setActiveOnly](state, { payload }) {
      return {
        ...state,
        activeOnly: payload,
      };
    },
    [mapActivitySitesSuccess](state, { payload }) {
      if (arraysEqual(state.mapSites, payload)) {
        return state;
      }

      return {
        ...state,
        mapSites: payload,
      };
    },
    [mapActivityPatrolsSuccess](state, { payload }) {
      if (arraysEqual(state.mapPatrols, payload)) {
        return state;
      }

      return {
        ...state,
        mapPatrols: payload,
      };
    },
    [setSelectedMU](state, { payload }) {
      return {
        ...state,
        selectedMU: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
