import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  infoNoteFailure, infoNoteRequest, infoNoteSuccess,
  postMessageFailure, postMessageRequest, postMessageSuccess,
  getAllInfoNoteFailure, getAllInfoNoteRequest, getAllInfoNoteSuccess,
  createInfoNoteFailure, createInfoNoteRequest, createInfoNoteSuccess,
  updateInfoNoteFailure, updateInfoNoteRequest, updateInfoNoteSuccess,
  ackInfoNoteFailure, ackInfoNoteRequest, ackInfoNoteSuccess,
  toggleInfoNoteFailure, toggleInfoNoteRequest, toggleInfoNoteSuccess,
} from './actions';
import axios from 'axios';
import { INFO_NOTES, INFO_NOTES_V2 } from 'app/api-routes';
import { getUserSaga } from 'ducks/employees/sagas';
import { store } from "../../MainApp";
import { getMediaAssetURL } from 'new-design/helpers/getMediaAssetURL';
import moment from 'moment';
import _ from 'lodash';
import { formatLocalDateTime, formatUTCInLocal } from 'util/formatting';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'app/types/constants';

function* getInfoNoteSaga({ payload: { infoNoteID } }) {
  try {
    const { payload } = yield axios.get(`${INFO_NOTES}/${infoNoteID}`);

    const allUserIDs = _.uniq([...(payload.noteRecords || []), ...(payload.photos || []), ...payload.voiceMemos].map(({ userCreated }) => userCreated));
    const userPromises = [];
    allUserIDs.forEach((id) => {
      if (typeof +id === 'number' && !isNaN(+id)) {
        userPromises.push(call(getUserSaga, { payload: { userID: id }}))
      }
    });

    yield all(userPromises);

    const users = store.getState().employees.users;
    const sessionID = store.getState().auth.sessionID;
    const { userID: currentUserID } = store.getState().auth.userInfo || {};

    let userPayload = users?.[payload?.userCreated] || null;
    let userReopened = users?.[payload?.userReopened] || null;

    if (userPayload === null) {
      userPayload = yield call(getUserSaga, { payload: { userID: payload?.userCreated }});
    }
    if (userReopened === null && payload?.userReopened !== null) {
      userReopened = yield call(getUserSaga, { payload: { userID: payload?.userReopened }});
    }

    let chat = [];
    const today = formatLocalDateTime(moment(), DATE_FORMAT);

    (payload.noteRecords || []).forEach((el) => {
      chat.push({
        ...el,
        type: 'text',
        isCurrentUser: currentUserID === el.userCreated,
        creatorPosition: users?.[el?.userCreated]?.position,
        creatorAvatar: users?.[el?.userCreated]?.photoID ? getMediaAssetURL(users?.[el?.userCreated]?.photoID, sessionID) : null,
        localDateCreate: moment.utc(el.dateCreated).utcOffset((new Date()).getTimezoneOffset()).local().format(DATE_TIME_FORMAT)
      });
    });
    (payload.photos || []).forEach((el) => {
      chat.push({
        ...el,
        type: 'img',
        url: getMediaAssetURL(el.id, sessionID),
        creatorPosition: users?.[el?.userCreated]?.position,
        creatorAvatar: users?.[el?.userCreated]?.photoID ? getMediaAssetURL(users?.[el?.userCreated]?.photoID, sessionID) : null,
        isCurrentUser: currentUserID === el.userCreated,
        localDateCreate: moment.utc(el.dateCreated).utcOffset((new Date()).getTimezoneOffset()).local().format(DATE_TIME_FORMAT)
      });
    });
    (payload.voiceMemos || []).forEach((el) => {
      chat.push({
        ...el,
        type: 'audio',
        creatorPosition: users?.[el?.userCreated]?.position,
        isCurrentUser: currentUserID === el.userCreated,
        creatorAvatar: users?.[el?.userCreated]?.photoID ? getMediaAssetURL(users?.[el?.userCreated]?.photoID, sessionID) : null,
        localDateCreate: moment.utc(el.dateCreated).utcOffset((new Date()).getTimezoneOffset()).local().format(DATE_TIME_FORMAT),
        url: getMediaAssetURL(el.id, sessionID),
      });
    });

    chat.sort((el1, el2) => moment(el1.dateCreated).unix() >= moment(el2.dateCreated).unix() ? 1 : -1);

    let prevDate = null;
    const finalChat = [];
    chat.forEach((el) => {
      const date = el.dateCreated.split(' ')[0];
      if (date !== prevDate) {
        finalChat.push({
          type: 'date',
          date: date === today ? 'today' : date,
        });
        prevDate = date; 
      }
      finalChat.push(el);
    });

    yield put(infoNoteSuccess({
      ...payload,
      creator: userPayload,
      reopenedBy: userReopened,
      chat: finalChat,
    }));
  } catch (err) {
    yield put(infoNoteFailure(err));
  }
}

function* getAllInfoNotesSaga({ payload: {
  fromDate,
  toDate,
  siteID,
  userID,
  categoryID,
}}) {
  try {
    const { payload } = yield axios.get(INFO_NOTES_V2, {
      params: {
        fromDate,
        toDate,
        siteID,
        userID,
        categoryID,
      }
    });
    yield put(getAllInfoNoteSuccess(payload.map((el) => ({
      ...el,
      dateCreated: formatUTCInLocal(el.dateCreated),
      expiryDate: formatUTCInLocal(el.expiryDate),
      dateLastModified: formatUTCInLocal(el.dateLastModified),
    }))));
  } catch (err) {
    yield put(getAllInfoNoteFailure(err));
  }
}

function* postMessageSaga({ payload: { infoNoteID, contents, callback } }) {
  try {
    yield axios.post(`${INFO_NOTES}/${infoNoteID}/records`, {
      payload: {
        contents,
      }
    });
    yield call(getInfoNoteSaga, { payload: { infoNoteID }});
    yield put(postMessageSuccess());
    callback?.();
  } catch (err) {
    yield put(postMessageFailure(err));
  }
}

function* createInfoNoteSaga({ payload: { data, callback, onFailure } }) {
  try {
    const { payload: infoNoteID } = yield axios.post(`${INFO_NOTES}`, {
      payload: data
    });
    callback?.();
    yield put(createInfoNoteSuccess(infoNoteID));
  } catch (err) {
    onFailure?.();
    yield put(createInfoNoteFailure(err));
  }
}

function* updateInfoNoteSaga({ payload: { infoNoteID, data, callback, onFailure } }) {
  try {
    yield axios.put(`${INFO_NOTES}/${infoNoteID}`, {
      payload: data
    });
    yield call(getInfoNoteSaga, { payload: { infoNoteID }});
    yield put(updateInfoNoteSuccess());
    callback?.();
  } catch (err) {
    onFailure?.();
    yield put(updateInfoNoteFailure(err));
  }
}

function* ackInfoNoteSaga({ payload: { infoNoteID, callback, onFailure } }) {
  try {
    yield axios.post(`${INFO_NOTES}/${infoNoteID}/ack`);
    yield call(getInfoNoteSaga, { payload: { infoNoteID }});
    yield put(ackInfoNoteSuccess());
    callback?.();
  } catch (err) {
    onFailure?.();
    yield put(ackInfoNoteFailure(err));
  }
}

function* toggleInfoNoteSaga({ payload: { infoNoteID, closed, callback, onFailure } }) {
  try {
    yield axios.put(`${INFO_NOTES}/${infoNoteID}/closed/${closed ? 'true' : 'false'}`);
    yield call(getInfoNoteSaga, { payload: { infoNoteID }});
    yield put(toggleInfoNoteSuccess());
    callback?.();
  } catch (err) {
    onFailure?.();
    yield put(toggleInfoNoteFailure(err));
  }
}

export default function* () {
  yield all([yield takeLatest(infoNoteRequest, getInfoNoteSaga)]);
  yield all([yield takeLatest(postMessageRequest, postMessageSaga)]);
  yield all([yield takeLatest(getAllInfoNoteRequest, getAllInfoNotesSaga)]);
  yield all([yield takeLatest(createInfoNoteRequest, createInfoNoteSaga)]);
  yield all([yield takeLatest(updateInfoNoteRequest, updateInfoNoteSaga)]);
  yield all([yield takeLatest(ackInfoNoteRequest, ackInfoNoteSaga)]);
  yield all([yield takeLatest(toggleInfoNoteRequest, toggleInfoNoteSaga)]);
}
