import { CHECKPOINTS, SITES_V2 } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { addCheckpoint, renameCheckpoint, deleteArea, updateNode } from '../plan-list/actions';
import {
  checkpointInfoFailure,
  checkpointInfoRequest,
  checkpointInfoSuccess,
  createCheckpointRequest,
  createCheckpointFailure,
  renameCheckpointRequest,
  renameCheckpointFailure,
  makeMasterCheckpointRequest,
  makeMasterCheckpointFailure,
  removeMasterCheckpointRequest,
  removeMasterCheckpointFailure,
  enableGPSCheckpointRequest,
  enableGPSCheckpointFailure,
  removeGPSCheckpointRequest,
  removeGPSCheckpointFailure,
  enableCheckpointRequest,
  enableCheckpointFailure,
  disableCheckpointRequest,
  disableCheckpointFailure,
  deleteCheckpointRequest,
  deleteCheckpointFailure,
  updateNoteRequest,
  updateNoteSuccess,
  updateNoteFailure,
  updateRemindersRequest,
  updateRemindersSuccess,
  updateRemindersFailure,
  addCheckpointMarkerRequest,
  addCheckpointMarkerFailure,
  deleteCheckpointMarkerRequest,
  deleteCheckpointMarkerFailure,
  enablePhotoOnScanCheckpointRequest,
  enablePhotoOnScanCheckpointFailure,
  deletePhotoOnScanCheckpointRequest,
  deletePhotoOnScanCheckpointFailure,
  checkpointsSuccess,
  checkpointsFailure,
  checkpointsRequest,
  checkpointQRCodeDataRequest,
  updateCheckpointQRTagRequest,
  checkpointQRCodeDataSuccess,
  checkpointQRCodeDataFailure,
  updateCheckpointQRTagSuccess,
  updateCheckpointQRTagFailure,
} from './actions';

function* checkpointsSaga({ payload: siteID }) {
  try {
    const { payload } = yield axios.get(`${SITES_V2}/${siteID}/checkpoints`);

    yield put(checkpointsSuccess(payload));
  } catch (err) {
    yield put(checkpointsFailure(err));
  }
}

function* checkpointInfoSaga({ payload: { checkpointID, shiftID } }) {
  try {
    const { payload: checkpointInfo } = yield axios.get(`${CHECKPOINTS}/${checkpointID}`, {
      params: {
        shiftID,
      },
    });

    yield put(checkpointInfoSuccess(checkpointInfo));
  } catch (err) {
    yield put(checkpointInfoFailure(err));
  }
}

function* createCheckpointSaga({ payload: { requestPayload, parentID } }) {
  try {
    const { payload: areaID } = yield axios.post(CHECKPOINTS, {
      payload: requestPayload,
    });

    yield put(
      addCheckpoint({
        parentID,
        name: requestPayload.name,
        id: areaID,
      })
    );
  } catch (err) {
    yield put(createCheckpointFailure(err));
  }
}

function* renameCheckpointSaga({ payload: { id, name } }) {
  try {
    yield axios.put(`${CHECKPOINTS}/${id}/name`, {
      payload: name,
    });
    yield put(renameCheckpoint({ id: `checkpoint-${id}`, name }));
  } catch (err) {
    yield put(renameCheckpointFailure(err));
  }
}

function* makeMasterCheckpointSaga({ payload: id }) {
  try {
    yield axios.put(`${CHECKPOINTS}/${id}/master`);
    yield put(updateNode({ id: `checkpoint-${id}`, settings: { master: true } }));
  } catch (err) {
    yield put(makeMasterCheckpointFailure(err));
  }
}

function* removeMasterCheckpointSaga({ payload: id }) {
  try {
    yield axios.delete(`${CHECKPOINTS}/${id}/master`);
    yield put(updateNode({ id: `checkpoint-${id}`, settings: { master: false } }));
  } catch (err) {
    yield put(removeMasterCheckpointFailure(err));
  }
}

function* enableGPSCheckpointSaga({ payload: id }) {
  try {
    yield axios.put(`${CHECKPOINTS}/${id}/gps`);
    yield put(updateNode({ id: `checkpoint-${id}`, settings: { gps: true } }));
  } catch (err) {
    yield put(enableGPSCheckpointFailure(err));
  }
}

function* removeGPSCheckpointSaga({ payload: id }) {
  try {
    yield axios.delete(`${CHECKPOINTS}/${id}/gps`);
    yield put(updateNode({ id: `checkpoint-${id}`, settings: { gps: false } }));
  } catch (err) {
    yield put(removeGPSCheckpointFailure(err));
  }
}

function* enableCheckpointSaga({ payload: id }) {
  try {
    yield axios.put(`${CHECKPOINTS}/${id}/enable`);
    yield put(updateNode({ id: `checkpoint-${id}`, settings: { active: true } }));
  } catch (err) {
    yield put(enableCheckpointFailure(err));
  }
}

function* disableCheckpointSaga({ payload: id }) {
  try {
    yield axios.put(`${CHECKPOINTS}/${id}/disable`);
    yield put(updateNode({ id: `checkpoint-${id}`, settings: { active: false } }));
  } catch (err) {
    yield put(disableCheckpointFailure(err));
  }
}

// TODO (olex): work on names
function* deleteCheckpointSaga({ payload: id }) {
  try {
    yield axios.delete(`${CHECKPOINTS}/${id}`);
    yield put(deleteArea({ id: `checkpoint-${id}` }));
  } catch (err) {
    yield put(deleteCheckpointFailure(err));
  }
}

function* updateNoteSaga({ payload: { id, data: item } }) {
  try {
    yield axios.put(`${CHECKPOINTS}/${id}/note`, {
      payload: item,
    });

    yield put(updateNoteSuccess({ id, item }));
  } catch (err) {
    yield put(updateNoteFailure(err));
  }
}

function* updateRemindersSaga({ payload: { id, data: item } }) {
  try {
    yield axios.put(`${CHECKPOINTS}/${id}/reminder`, {
      payload: item,
    });

    yield put(updateRemindersSuccess({ id, item }));
  } catch (err) {
    yield put(updateRemindersFailure(err));
  }
}

function* addCheckpointMarkerSaga({ payload: { checkpointID, point, assetID } }) {
  try {
    yield axios.put(
      `${CHECKPOINTS}/${checkpointID}/marker`,
      {
        payload: point,
      },
      {
        params: {
          assetID,
        },
      }
    );

    yield put(checkpointInfoRequest({ checkpointID }));
  } catch (err) {
    yield put(addCheckpointMarkerFailure(err));
  }
}

function* deleteCheckpointMarkerSaga({ payload: { checkpointID, assetID } }) {
  try {
    yield axios.delete(`${CHECKPOINTS}/${checkpointID}/marker?assetID=${assetID}`);

    yield put(checkpointInfoRequest({ checkpointID }));
  } catch (err) {
    yield put(deleteCheckpointMarkerFailure(err));
  }
}

function* enablePhotoOnScanCheckpointSaga({ payload: { checkpointID } }) {
  try {
    yield axios.put(`${CHECKPOINTS}/${checkpointID}/photo-on-scan`);

    yield put(checkpointInfoRequest({ checkpointID }));
  } catch (err) {
    yield put(enablePhotoOnScanCheckpointFailure(err));
  }
}

function* deletePhotoOnScanCheckpointSaga({ payload: { checkpointID } }) {
  try {
    yield axios.delete(`${CHECKPOINTS}/${checkpointID}/photo-on-scan`);

    yield put(checkpointInfoRequest({ checkpointID }));
  } catch (err) {
    yield put(deletePhotoOnScanCheckpointFailure(err));
  }
}

function* checkpointQRCodeDataSaga({ payload: { checkpointID } }) {
  try {
    const { payload: qrData } = yield axios.get(`${CHECKPOINTS}/${checkpointID}/qr-code`);
    yield put(checkpointQRCodeDataSuccess({ checkpointID, data: qrData }));
  } catch (err) {
    yield put(checkpointQRCodeDataFailure(err));
  }
}

function* updateCheckpointQRTagSaga({ payload: { checkpointID } }) {
  try {
    const { payload: qrData } = yield axios.put(`${CHECKPOINTS}/${checkpointID}/qr-code`);
    yield put(updateCheckpointQRTagSuccess({ checkpointID, data: qrData }));
  } catch (err) {
    yield put(updateCheckpointQRTagFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(checkpointsRequest, checkpointsSaga),
    yield takeLatest(checkpointInfoRequest, checkpointInfoSaga),
    yield takeLatest(createCheckpointRequest, createCheckpointSaga),
    yield takeLatest(renameCheckpointRequest, renameCheckpointSaga),
    yield takeLatest(makeMasterCheckpointRequest, makeMasterCheckpointSaga),
    yield takeLatest(removeMasterCheckpointRequest, removeMasterCheckpointSaga),
    yield takeLatest(enableGPSCheckpointRequest, enableGPSCheckpointSaga),
    yield takeLatest(removeGPSCheckpointRequest, removeGPSCheckpointSaga),
    yield takeLatest(enableCheckpointRequest, enableCheckpointSaga),
    yield takeLatest(disableCheckpointRequest, disableCheckpointSaga),
    yield takeLatest(deleteCheckpointRequest, deleteCheckpointSaga),
    yield takeLatest(updateNoteRequest, updateNoteSaga),
    yield takeLatest(updateRemindersRequest, updateRemindersSaga),
    yield takeLatest(addCheckpointMarkerRequest, addCheckpointMarkerSaga),
    yield takeLatest(deleteCheckpointMarkerRequest, deleteCheckpointMarkerSaga),
    yield takeLatest(enablePhotoOnScanCheckpointRequest, enablePhotoOnScanCheckpointSaga),
    yield takeLatest(deletePhotoOnScanCheckpointRequest, deletePhotoOnScanCheckpointSaga),
    yield takeLatest(checkpointQRCodeDataRequest, checkpointQRCodeDataSaga),
    yield takeLatest(updateCheckpointQRTagRequest, updateCheckpointQRTagSaga),
  ]);
}
