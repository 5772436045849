import { Grid, Hidden } from '@material-ui/core';
import React, { useRef } from 'react';
import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import devicesPng from 'assets/images/Devices.png';
import backgroundImage from 'assets/images/Background.jpg';
import { FooterLayout } from './footer';
import { Copyright } from 'assets/icons';
import IntlMessages from 'util/IntlMessages';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        overflow: 'auto',
      },
      left: {
        paddingRight: 15,
        overflowY: 'auto',
        background: theme.palette.common.white,
        [theme.breakpoints.down('lg')]: {
          paddingRight: 6,
        },
        [theme.breakpoints.down('md')]: {
          paddingRight: 0,
        },
      },
      right: {
        paddingLeft: 15,
        background: theme.palette.common.white,
        [theme.breakpoints.down('lg')]: {
          paddingLeft: 6,
        },
      },
      verticalDivider: {
        height: '100%',
        width: 1,
        background: '#EEEEEE',
        margin: '0 12px',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      bgRoot: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      promo: {
        maxHeight: '100vh',
        overflow: 'hidden',
        '& img': {
          maxWidth: '100%',
          width: '100%',
          height: '100%',
        },
      },
      deviceBlock: {
        width: '100%',
        padding: '0 10%',
      },
      textBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',

        '& .title': {
          fontWeight: 600,
          fontSize: 32,
          lineHeight: '48px',
          textAlign: 'center',
          color: '#FFFFFF',
          marginBottom: 16,
          [theme.breakpoints.down('lg')]: {
            fontSize: 24,
            lineHeight: '36px',
          },
          '& .colored': {
            color: '#F78F1E',
          },
        },
        '& .description': {
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '36px',
          textAlign: 'center',
          color: '#FFFFFF',
          [theme.breakpoints.down('lg')]: {
            fontSize: 18,
            lineHeight: '24px',
          },
        },
      },
      devices: {
        marginTop: 40,
        zIndex: 9,
        width: '100%',
      },
      rightSection: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        height: '100vh',
        alignItems: 'center',
        '& .copyright': {
          position: 'fixed',
          bottom: 30,
          display: 'flex',
          alignItems: 'center',
        },
      },
      copyrightLabel: {
        lineHeight: '13px',
        color: theme.palette.common.white,
      },
    }),
  {
    name: 'LoginLayout',
  }
);

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const submit = useRef(null);
  const updateField = useRef(null);

  const setSubmit = (fun, setField) => {
    submit.current = fun;
    updateField.current = setField;
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} lg={5} className={classes.left}>
        <div className={classes.content}>
          {children({ setSubmit })}
          <FooterLayout />
        </div>
      </Grid>
      <Hidden mdDown>
        <Grid item lg={7} className={classes.right}>
          <div className={classes.rightSection}>
            <div className={classes.textBlock}>
              <Typography className="title">
                Take Your Company to the <span className="colored">Next Level!</span>
              </Typography>
              <Typography className="description">Manage Your Mobile Workforce in Real Time!</Typography>
              <Typography style={{ marginTop: 16 }} className="description">
                Monitor, Optimize, Improve and Grow Your Business!
              </Typography>
            </div>
            <div className={classes.deviceBlock}>
              <img src={devicesPng} alt="Devices" className={classes.devices} />
            </div>
            <div className="copyright">
              <Copyright />
              &nbsp;
              <Typography className={classes.copyrightLabel}>
                {new Date().getFullYear()}&nbsp;
                <span
                  onClick={() => {
                    updateField.current('legacy', true);
                    submit.current?.();
                  }}
                >
                  <IntlMessages id="app_name" />
                </span>
              </Typography>
            </div>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export { LoginLayout };
