import preparedReducer from './reducer';
import { switchApp } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'dev';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  switchApp,
};
