import { createAction, createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const activeIRsGroupedByTypeRequest = createRequest(constants.activeIRsGroupedByType);
export const activeIRsGroupedByTypeSuccess = createSuccess(constants.activeIRsGroupedByType);
export const activeIRsGroupedByTypeFailure = createFailure(constants.activeIRsGroupedByType);

export const irsGroupedByDateAndTypeRequest = createRequest(constants.irsGroupedByDateAndType);
export const irsGroupedByDateAndTypeSuccess = createSuccess(constants.irsGroupedByDateAndType);
export const irsGroupedByDateAndTypeFailure = createFailure(constants.irsGroupedByDateAndType);

export const irsGroupedByDateRequest = createRequest(constants.irsGroupedByDate);
export const irsGroupedByDateSuccess = createSuccess(constants.irsGroupedByDate);
export const irsGroupedByDateFailure = createFailure(constants.irsGroupedByDate);

export const setLoadingState = createAction(constants.setLoadingState);

export const requestActiveIRsGroupedByType = activeIRsGroupedByTypeRequest;
export const requestIRsGroupedByDateAndType = irsGroupedByDateAndTypeRequest;
export const requestIRsGroupedByDate = irsGroupedByDateRequest;
