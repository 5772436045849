import preparedReducer from './reducer';
import { requestAttachments } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'attachments';

export const selectors = (state) => state[SLICE_NAME];

export const actions = {
  requestAttachments,
};
