import { ACTIVITIES_BY_VISITS, VISITS } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import appConfig from 'config';

import {
  visitLogFailure,
  visitLogRequest,
  visitLogSuccess,
  visitReportLinkFailure,
  visitReportLinkRequest,
  visitReportLinkSuccess,
} from './actions';

function* visitLogRequestSaga({ payload: visitID }) {
  try {
    const { payload: log } = yield axios.get(ACTIVITIES_BY_VISITS, {
      params: {
        visitID,
      },
    });

    yield put(visitLogSuccess(log));
  } catch (err) {
    yield put(visitLogFailure(err));
  }
}

function* visitReportLinkSaga({ payload: visitID }) {
  try {
    const { payload } = yield axios.get(`${VISITS}/${visitID}/report`);

    yield put(visitReportLinkSuccess({ visitID, link: `${appConfig.serverURL}/api/mediaassets/stream/${payload}`}));
  } catch (err) {
    yield put(visitReportLinkFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(visitLogRequest, visitLogRequestSaga),
    yield takeLatest(visitReportLinkRequest, visitReportLinkSaga),
  ]);
}
