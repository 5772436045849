import preparedReducer from './reducer';
import { requestTourLog, requestTourLogReport } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'tourLog';

export const selectors = state => state[SLICE_NAME];
export const getTourLog = (state, tourLogID) => state.tourLog[tourLogID];

export const actions = {
  requestTourLog,
  requestTourLogReport,
};
