import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Link, VDivider } from 'new-design/common/ui';
import IntlMessages from 'util/IntlMessages';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles(
  {
    root: {
      overflow: 'auto',
    },
    left: {
      paddingRight: 15,
      overflowY: 'auto',
      [theme.breakpoints.down('lg')]: {
        paddingRight: 6,
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    },
    right: {
      paddingLeft: 15,
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 6,
      },
    },
    linksBlock: {
      display: 'flex',
      marginLeft: -30,
      marginBottom: 30,
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        marginLeft: -12,
        marginTop: 24,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: -16,
        flexDirection: 'column',
      },
    },
  }
), {
  name: 'FooterLayout',
});

const FooterLayout = () => {
  const classes = useStyles();

  return (
    <Box className={classes.linksBlock}>
      <Box display="flex" justifyContent="center">
        <Link color="primary" href="/terms">
          <Typography>
            <IntlMessages id="terms" />
          </Typography>
        </Link>
        <VDivider />
        <Link color="primary" href="/policy">
          <Typography>
            <IntlMessages id="privacy_policy" />
          </Typography>
        </Link>
      </Box>
    </Box>
  )
};

export { FooterLayout };
