import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import {
  deleteMediaAssetSuccess,
  updateMediaAssetSuccess,
  uploadMediaAssetSuccess,
} from 'ducks/mediaAssets/actions';

import { MediaAssetTables, MediaAssetTypes } from 'ducks/mediaAssets/types';
import {
  createIncidentReportSuccess,
  editIncidentReportSuccess,
  incidentReportsSuccess,
  incidentReportSuccess,
  incidentReportLinkSuccess,
} from './actions';

import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return {
        ...initialState,
      };
    },
    [incidentReportsSuccess](state, { payload }) {
      return payload.reduce((res, item) => {
        res[item.reportId] = item;
        return res;
      }, {});
    },

    [incidentReportSuccess](state, { payload: item }) {
      const res = {
        ...state,
      };
      res[item.reportId] = item;
      return res;
    },
    [createIncidentReportSuccess](state, { payload: report }) {
      const res = {
        ...state,
      };
      res[report.reportId] = {
        ...report,
      };
      return res;
    },
    [editIncidentReportSuccess](
      state,
      {
        payload: { report, reportId },
      },
    ) {
      const res = {
        ...state,
      };
      res[reportId] = {
        ...res[reportId],
        ...report,
      };
      return res;
    },
    [uploadMediaAssetSuccess](
      state,
      {
        payload: { mediaAssetInfo },
      },
    ) {
      const { reportId, type, tableName, dateCreated, userCreatedName, mediaAssetID, uri } = mediaAssetInfo;
      
      if (tableName !== MediaAssetTables.INCIDENT_REPORTS) {
        return state;
      }
      const res = {
        ...state,
      };
      const incidentReport = res[reportId];
      res[reportId] = {
        ...incidentReport,
        dateLastModified: dateCreated,
      };

      if (type === MediaAssetTypes.PHOTO) {
        incidentReport.photos.push({
          id: mediaAssetID,
          dateCreated,
          userCreatedName,
          uri,
        });
      }

      return res;
    },
    [updateMediaAssetSuccess](
      state,
      {
        payload: { mediaAssetInfo },
      },
    ) {
      const { mediaAssetID, reportId, tableName, description } = mediaAssetInfo;

      if (tableName !== MediaAssetTables.INCIDENT_REPORTS) {
        return state;
      }

      const res = {
        ...state,
      };

      const incidentReport = {
        ...res[reportId],
      };
      res[reportId] = incidentReport;

      const index = incidentReport.photos?.findIndex(item => item.id === mediaAssetID);
      incidentReport.photos[index] = {
        ...incidentReport.photos[index],
        description,
      };

      return res;
    },
    [deleteMediaAssetSuccess](state, { payload: mediaAssetInfo }) {
      if (!mediaAssetInfo) {
        return state;
      }
      
      const {mediaAssetID, entityID: reportId, tableName, type} = mediaAssetInfo;

      if (tableName !== MediaAssetTables.INCIDENT_REPORTS || type !== MediaAssetTypes.PHOTO) {
        return state;
      }

      const res = {
        ...state,
      };

      const incidentReport = {
        ...res[reportId],
      };
      res[reportId] = incidentReport;

      const index = incidentReport.photos?.findIndex(item => item.id === mediaAssetID);

      incidentReport.photos = incidentReport.photos?.filter((item, i) => i !== index) || [];

      return res;
    },
    [incidentReportLinkSuccess](state, { payload }) {
      return {
        ...state,
        reportLinks: {
          ...state.reportLinks,
          [payload.reportId]: payload.link
        }
      };
    },
  },
  initialState,
);
