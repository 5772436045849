import React from 'react';
import { Badge as MUIBadge, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    badge: {
      cursor: ({ clickable }) => (clickable ? 'pointer' : 'auto'),
      '& .MuiBadge-badge': {
        backgroundColor: ({ color }) => color || theme.palette.primary.orange,
        color: 'white',
        '&:hover': {
          backgroundColor: ({ clickable, color, hoverColor }) =>
            clickable ? hoverColor || '#FFB442' : color,
        },
      },
    },
  })
);

export const Badge = ({ color, className, onClick, ...other }) => {
  const classes = useStyles({ color, clickable: !!onClick });

  return <MUIBadge {...other} className={clsx(classes.badge, className)} onClick={onClick} />;
};
