import _ from 'lodash';

export const isLoginDetailsChanged = ({ prev, updated }) => {
  const prevUserSiteIDs = (prev.userSites?.userSite || []).map((item) => item.siteID);

  return (
    prev.loginName !== updated.loginName ||
    prev.roleID !== updated.roleID ||
    prevUserSiteIDs.length !== updated.siteIDs?.length ||
    !_.isEqual(_.sortBy(prevUserSiteIDs), _.sortBy(updated.siteIDs)) ||
    prev.accessAllSites !== updated.accessAllSites ||
    prev.shiftSelfie !== updated.shiftSelfie ||
    prev.endShiftSelfie !== updated.endShiftSelfie
  );
};