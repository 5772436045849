import { updateMediaAssetSuccess } from 'ducks/mediaAssets/actions';
import { MediaAssetTables } from 'ducks/mediaAssets/types';
import { handleActions } from 'redux-actions';
import { getAreaSuccess, updateNoteSuccess, areasSuccess } from './actions';
import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [areasSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [getAreaSuccess](state, { payload: { id, item } }) {
      return {
        ...state,
        info: {
          ...state.info,
          [id]: item,
        },
      };
    },
    [updateNoteSuccess](state, { payload: { id, item } }) {
      return {
        ...state,
        info: {
          ...state.info,
          [id]: {
            ...state.info[id],
            notes: state.info[id]?.notes.map((el) => {
              if (el.typeID === item.typeID) {
                return {
                  ...el,
                  ...item,
                };
              } else {
                return el;
              }
            }),
          },
        },
      };
    },
    [updateMediaAssetSuccess](state, { payload: { mediaAssetInfo } }) {
      const { entityID, tableName, mediaAssetID, description } = mediaAssetInfo;

      if (!(tableName === MediaAssetTables.AREAS)) {
        return state;
      }

      return {
        ...state,
        info: {
          ...state.info,
          [entityID]: {
            ...state.info[entityID],
            photos: state.info[entityID].photos.map((item) =>
              item.mediaAssetID === mediaAssetID ? { ...item, description } : item
            ),
          },
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
