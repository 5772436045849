import preparedReducer from './reducer';
import { requestSiteInfoAddress } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'siteInfoAddress';

export const selectors = state => state[SLICE_NAME];
export const getSiteInfoAddress = (state, siteID) => state.siteInfoAddress[siteID];

export const actions = {
  requestSiteInfoAddress,
};
