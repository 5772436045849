import preparedReducer from './reducer';
import {
  requestTaskRecords,
  updateTaskRecords,
  createTaskRecord,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'taskRecords';

export const selectors = state => state[SLICE_NAME];
export const selectTaskRecords = (taskID) => (state) => state.taskRecords[taskID];

export const actions = {
  requestTaskRecords,
  updateTaskRecords,
  createTaskRecord,
};
