import preparedReducer from './reducer';
import { requestTsByEmployee } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'tsByEmployee';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestTsByEmployee,
};
