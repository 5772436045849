import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Switch } from 'react-router-dom';

import configureStore, { history } from 'ducks/store';
import App from 'new-design/containers/App';

import './util/axios-interceptors';

export const { store, persistor } = configureStore();

const MainApp = () =>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <Switch>
          <App />
        </Switch>
      </ConnectedRouter>
    </PersistGate>
  </Provider>;

export default MainApp;