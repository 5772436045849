import preparedReducer from './reducer';
import {
  requestTask,
  requestTasks,
  createTask,
  updateTask,
  enableTask,
  disableTask,
  deleteTask,
  getTaskLogs,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'tasks';

export const selectors = state => state[SLICE_NAME];
export const selectTaskInfo = (state, taskID) => state.tasks.info[taskID];

export const actions = {
  requestTask,
  requestTasks,
  createTask,
  updateTask,
  enableTask,
  disableTask,
  deleteTask,
  getTaskLogs,
};
