import constants from './constants';
import { createFailure, createRequest, createSuccess } from 'ducks/helpers';


export const siteEmailSettingsRequest = createRequest(constants.SITE_EMAIL_SETTINGS);
export const siteEmailSettingsSuccess = createSuccess(constants.SITE_EMAIL_SETTINGS);
export const siteEmailSettingsFailure = createFailure(constants.SITE_EMAIL_SETTINGS);

export const enableSiteEmailSettingRequest = createRequest(constants.ENABLE_SITE_EMAIL_SETTING);
export const enableSiteEmailSettingSuccess = createSuccess(constants.ENABLE_SITE_EMAIL_SETTING);
export const enableSiteEmailSettingFailure = createFailure(constants.ENABLE_SITE_EMAIL_SETTING);

export const disableSiteEmailSettingRequest = createRequest(constants.DISABLE_SITE_EMAIL_SETTING);
export const disableSiteEmailSettingSuccess = createSuccess(constants.DISABLE_SITE_EMAIL_SETTING);
export const disableSiteEmailSettingFailure = createFailure(constants.DISABLE_SITE_EMAIL_SETTING);

export const siteEmailRecipientsRequest = createRequest(constants.SITE_EMAIL_RECIPIENTS);
export const siteEmailRecipientsSuccess = createSuccess(constants.SITE_EMAIL_RECIPIENTS);
export const siteEmailRecipientsFailure = createFailure(constants.SITE_EMAIL_RECIPIENTS);

export const updateSiteEmailRecipientsRequest = createRequest(constants.UPDATE_SITE_EMAIL_RECEPIENTS);
export const updateSiteEmailRecipientsSuccess = createSuccess(constants.UPDATE_SITE_EMAIL_RECEPIENTS);
export const updateSiteEmailRecipientsFailure = createFailure(constants.UPDATE_SITE_EMAIL_RECEPIENTS);

export const requestSiteEmailSettings = siteEmailSettingsRequest;
export const enableSiteEmailSetting = enableSiteEmailSettingRequest;
export const disableSiteEmailSetting = disableSiteEmailSettingRequest;
export const requestSiteEmailRecipients = siteEmailRecipientsRequest;
export const updateSiteEmailRecipients = updateSiteEmailRecipientsRequest;
