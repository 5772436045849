import React, { useMemo } from 'react';

import { Box } from '@material-ui/core';
import { useStyles } from './styles';

import Header from './components/Header';
import { LimitedBreadCrumbs } from './components/Breadcrumbs';
import { PageStatistic } from './components/PageStatistic';
import { AdminTabs } from './components/AdminTabs';
import { FilterChips } from './components/FilterChips';

import { useSelector } from 'react-redux';
import * as auth from 'ducks/auth';
import * as adminTab from 'ducks/adminTab';
import * as mainLayout from 'ducks/main-layout';
import _ from 'lodash';

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const { userInfo } = useSelector(auth.selectors);
  const { tabsList } = useSelector(adminTab.selectors);
  const { adminTabsEnabled } = useSelector(mainLayout.selectors);

  const maxBreadcrumbsWidth = useMemo(() => {
    if (_.isEmpty(tabsList) || !adminTabsEnabled) return;

    return window.innerWidth >= 1280 ? window.innerWidth / 4 : (window.innerWidth - 757) / 2;
  }, [tabsList, adminTabsEnabled]);

  if (!userInfo) return null;

  return (
    <div className={classes.root}>
      <div className={classes.headerBlock}>
        <Header />
      </div>
      <div className={classes.contentBlock}>
        <div className={classes.actionsContainer}>
          <LimitedBreadCrumbs maxWidth={maxBreadcrumbsWidth} />
          <Box display="flex" alignItems="center">
            <PageStatistic />
            <div className="main-actions">
              <AdminTabs />
            </div>
          </Box>
        </div>
        <div className={classes.chipsStrip}>
          <FilterChips />
        </div>
        <div className={classes.childrenContent}>{children}</div>
      </div>
    </div>
  );
};

export { MainLayout };
