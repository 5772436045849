import { handleActions } from "redux-actions";
import { logoutSuccess } from "ducks/auth/actions";

import initialState from "./states";
import { createTaskRecordSuccess, taskRecordsSuccess, updateTaskRecordsSuccess } from "./actions";

export default handleActions(
  {
    [logoutSuccess]() {
      return initialState;
    },
    [taskRecordsSuccess](state, { payload: { taskID, data } }) {
      return {
        ...state,
        [taskID]: data,
      };
    },
    [createTaskRecordSuccess](state, { payload: { taskID, data } }) {
      return {
        ...state,
        [taskID]: [...state[taskID], data],
      };
    },
    [updateTaskRecordsSuccess](state, { payload: { taskID, taskRecordsIDs }}) {
      const taskRecords = state[taskID];

      return {
        ...state,
        [taskID]: taskRecordsIDs.map((taskRecordID) => taskRecords.find((item) => item.taskRecordID === taskRecordID)),
      }
    }
  },
  initialState
);
