import { STATISTICS } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import {
  activeIRsGroupedByTypeRequest,
  activeIRsGroupedByTypeSuccess,
  activeIRsGroupedByTypeFailure,
  irsGroupedByDateAndTypeRequest,
  irsGroupedByDateAndTypeSuccess,
  irsGroupedByDateAndTypeFailure,
  irsGroupedByDateRequest,
  irsGroupedByDateSuccess,
  irsGroupedByDateFailure,
  setLoadingState,
} from './actions';
import _ from 'lodash';
import moment from 'moment';

function* activeIRsGroupedByTypeSaga() {
  try {
    const { payload } = yield axios.get(`${STATISTICS}/incident-reports/active/grouped-by-type`);

    yield put(activeIRsGroupedByTypeSuccess(payload));
  } catch (err) {
    yield put(activeIRsGroupedByTypeFailure(err));
  }
}

function* irsGroupedByDateAndTypeSaga({ payload: { month, year, siteID, userID, typeIDs, status } }) {
  try {
    yield put(setLoadingState(true));

    const params = { month, year, timeZoneOffset: moment().utcOffset() };

    if (siteID) {
      params.siteID = siteID;
    }

    if (userID) {
      params.userID = userID;
    }

    if (!_.isEmpty(typeIDs)) {
      params.typeIDs = typeIDs;
    }

    if (status) {
      params.status = status;
    }

    const { payload } = yield axios.get(`${STATISTICS}/incident-reports/grouped-by-date-and-type`, {
      params,
    });

    yield put(irsGroupedByDateAndTypeSuccess(payload));
  } catch (err) {
    yield put(irsGroupedByDateAndTypeFailure(err));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* irsGroupedByDateSaga({ payload: { month, year, siteID, userID, typeIDs, status } }) {
  try {
    yield put(setLoadingState(true));

    const params = { month, year, timeZoneOffset: moment().utcOffset() };

    if (siteID) {
      params.siteID = siteID;
    }

    if (userID) {
      params.userID = userID;
    }

    if (!_.isEmpty(typeIDs)) {
      params.typeIDs = typeIDs;
    }

    if (status) {
      params.status = status;
    }

    const { payload } = yield axios.get(`${STATISTICS}/incident-reports/grouped-by-date`, {
      params,
    });

    yield put(irsGroupedByDateSuccess(payload));
  } catch (err) {
    yield put(irsGroupedByDateFailure(err));
  } finally {
    yield put(setLoadingState(false));
  }
}

export default function* () {
  yield all([
    yield takeLatest(activeIRsGroupedByTypeRequest, activeIRsGroupedByTypeSaga),
    yield takeLatest(irsGroupedByDateAndTypeRequest, irsGroupedByDateAndTypeSaga),
    yield takeLatest(irsGroupedByDateRequest, irsGroupedByDateSaga),
  ]);
}
