import { createAction } from 'ducks/helpers';
import constants from './constants';

export const setType = createAction(`${constants.activity}_SET_TYPE`);
export const setEmployees = createAction(`${constants.activity}_SET_EMPLOYEES`);
export const setActivity = createAction(`${constants.activity}_SET_ACTIVITY`);

export const setSelectedSite = createAction(`${constants.activity}_SET_SELECTED_SITE`);
export const setSelectedShift = createAction(`${constants.activity}_SET_SELECTED_SHIFT`);
export const setSelectedMU = createAction(`${constants.activity}_SET_SELECTED_MU`);

export const setActiveOnly = createAction(`${constants.activity}_SET_ACTIVE_ONLY`);

export const mapActivityRequest = createAction(`${constants.activity}_REQUEST`);
export const mapActivitySitesSuccess = createAction(`${constants.activity}_REQUEST_SITE_SUCCESS`);
export const mapActivityPatrolsSuccess = createAction(`${constants.activity}_REQUEST_PATROL_SUCCESS`);
export const mapActivitySuccess = createAction(`${constants.activity}_SUCCESS`);
export const mapActivityFailure = createAction(`${constants.activity}_FAILURE`);