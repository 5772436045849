import { SHIFTS, SHIFTS_VIEWER } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest, takeEvery, select } from 'redux-saga/effects';

import {
  shiftInfoRequest,
  shiftInfoSuccess,
  shiftInfoFailure,

  shiftStatsRequest,
  shiftStatsSuccess,
  shiftStatsFailure,

  shiftStatsBySiteIDRequest,
  shiftStatsBySiteIDSuccess,

  checkpointInfoNewRequest,
  checkpointInfoNewFailure,

  getShiftReportLinkRequest,
  getShiftReportLinkSuccess,
  getShiftReportLinkFailure,
  
} from './actions';
import { setSelectedSite } from 'ducks/activity-map/actions';
import appConfig from 'config';

function* shiftInfoSaga({ payload: shiftID }) {
  try {
    const { payload } = yield axios.get(`${SHIFTS}/${shiftID}`);

    yield put(shiftInfoSuccess(payload));
  } catch (err) {
    yield put(shiftInfoFailure(err));
  }
}

function* getShiftReportLinkSaga({ payload: shiftID }) {
  try {
    const { payload } = yield axios.get(`${SHIFTS}/${shiftID}/report`);

    yield put(getShiftReportLinkSuccess({ shiftID, link: `${appConfig.serverURL}/api/mediaassets/stream/${payload}`}));
  } catch (err) {
    yield put(getShiftReportLinkFailure(err));
  }
}

function* checkpointInfoNewSaga({ payload: { checkpointID, scanID, shiftID }}) {
  try {
    let params = {};
    if (scanID) {
      params = {
        ...params,
        scanID,
      };
    } else {
      params = {
        ...params,
        checkpointID,
      };
    }
    const { payload: checkpointInfo } = yield axios.get(`${SHIFTS}/${shiftID}/scan-info`, {
      params
    });

    yield put(shiftInfoSuccess({
      ...checkpointInfo,
      shiftID
    }));
  } catch (err) {
    yield put(checkpointInfoNewFailure(err));
  }
}

export function* shiftStatsSaga({ payload: shiftID }) {
  try {
    const { payload } = yield axios.get(`${SHIFTS}/${shiftID}/stats`);

    yield put(shiftStatsSuccess(payload));
  } catch (err) {
    yield put(shiftStatsFailure(err));
  }
}

function* shiftStatsBySiteIDSaga({ payload: siteID }) {
  try {
    yield put(shiftStatsBySiteIDSuccess(null));
    const { payload: shiftsViewerPayload } = yield axios.get(`${SHIFTS_VIEWER}`, {
      params: { siteID }
    });
    const lastShift = shiftsViewerPayload.pop();
    if (lastShift) {
      const currentSelectedSite = yield select(state => state.activityMap.selectedSite);
      yield put(shiftStatsBySiteIDSuccess(lastShift?.shiftID));
      const { payload } = yield axios.get(`${SHIFTS}/${lastShift?.shiftID}/stats`);
      
      const prevData = currentSelectedSite.siteID === payload.siteID ? currentSelectedSite : {};
      yield put(setSelectedSite({
        title: payload.siteName,
        siteID: payload.siteID,
        ...prevData,
        employees: [
          {
            fullName: payload.fullName,
            userID: payload.userID,
            position: payload.position,
            shiftID: lastShift?.shiftID,
          }
        ],
        isActive: false,
      }));
      yield put(shiftStatsSuccess(payload));
    }
    
  } catch (err) {
    yield put(shiftStatsFailure(err));
  }
}

export default function*() {
  yield all([yield takeLatest(shiftInfoRequest, shiftInfoSaga)]);
  yield all([yield takeEvery(shiftStatsRequest, shiftStatsSaga)]);
  yield all([yield takeLatest(shiftStatsBySiteIDRequest, shiftStatsBySiteIDSaga)]);
  yield takeLatest(checkpointInfoNewRequest, checkpointInfoNewSaga);
  yield takeLatest(getShiftReportLinkRequest, getShiftReportLinkSaga);
}
