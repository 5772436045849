import React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
// TODO: refactoring.
import asyncComponent from 'util/asyncComponent';

const Routes = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/print/incident-reports/:id`} exact component={(asyncComponent(() => import('../../pages/subpages/print/PrintIncident')))}/>
      <Route path={`${match.url}/table-print/activity-log`} exact component={(asyncComponent(() => import('../../pages/subpages/print/ActivityLog')))}/>
      <Route path={`${match.url}/table-print/activity-monitor`} exact component={(asyncComponent(() => import('../../pages/subpages/print/ActivityMonitor')))}/>
      <Route path={`${match.url}/table-print/incident-report`} exact component={(asyncComponent(() => import('../../pages/subpages/print/IncidentReport')))}/>
      <Route path={`${match.url}/table-print/timesheets`} exact component={(asyncComponent(() => import('../../pages/subpages/print/Timesheets')))}/>
      <Route path={`${match.url}/table-print/work-history`} exact component={(asyncComponent(() => import('../../pages/subpages/print/WorkHistory')))}/>
    </Switch>
  );
}

export default Routes;

