import { handleActions } from 'redux-actions';
import { attachmentsSuccess } from './actions';
import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [attachmentsSuccess](state, { payload: { tableName, entityID, data } }) {
      return {
        ...state,
        [tableName]: {
          ...(state[tableName] || {}),
          [entityID]: data,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
