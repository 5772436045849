import React from 'react';
import { StatisticItem } from 'new-design/components/StatisticItem';
import { useURLStore } from 'new-design/hooks/useURLStore';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Box } from '@material-ui/core';
import {
  ClientsIcon,
  CheckMarkIcon,
  StopIcon,
  CloseIcon,
  OneSiteIcon,
  PersonIcon,
  OneCarIcon,
  InfoNoteIcon,
  ContactIcon,
  CheckboxChecked,
  TourIcon,
  StopwatchIcon,
  IncidentReportIcon,
  FormIcon,
  VisitorsIcon,
  VisitorEntranceIcon,
  VisitorExitIcon,
} from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as pageStore from 'ducks/page_store';
import * as mainLayout from 'ducks/main-layout';
import IntlMessages, { useIntl } from 'util/IntlMessages';
import { bindActionCreators } from 'redux';
import { useCallback } from 'react';
import { ALL, FORM_RESULTS_STATUSES, ENTITY_STATUS, IR_STATUSES, SHIFT_STATUSES } from 'app/types/constants';
import { VISITOR_LOG_EVENT_TYPES } from 'ducks/visitorLog/types';
import { VDivider } from 'new-design/common/ui';

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        position: 'relative',
      },
      noMargin: {
        margin: 0,
      },
    }),
  {
    name: 'PageStatistic',
  }
);

const PageStatistic = () => {
  const classes = useStyles();
  const { updateURL } = useURLStore();
  const { formatMessage } = useIntl();
  const { statistic } = useSelector(mainLayout.selectors);
  const {
    adminSiteFilters,
    adminClientFilters,
    adminContactFilters,
    adminTasksFilters,
    adminToursFilters,
    adminMobileUnitsFilters,
    adminEmployeeFilters,
    infoBoardFilters,
    workHistoryFilters,
    irFilters,
    formResultsFilters,
    formsFilters,
    visitorsFilters,
    visitorLogFilters,
  } = useSelector(pageStore.selectors);
  const {
    updateAdminSiteFilter,
    updateAdminClientFilter,
    updateAdminContactFilter,
    updateTasksFilter,
    updateToursFilter,
    updateMobileUnitsFilter,
    updateAdminEmployeeFilter,
    updateInfoBoardFilter,
    updateWorkHistoryFilter,
    updateIRFilter,
    updateFormResultsFilter,
    updateFormsFilter,
    updateVisitorsFilter,
    updateVisitorLogFilter,
  } = bindActionCreators(pageStore.actions, useDispatch());

  const updateStatus = useCallback(
    (status, action, filters) => (selected) => {
      let statuses = [];
      if (selected) {
        if (status === ALL || status === ENTITY_STATUS.DELETED) {
          statuses = [status];
        } else {
          statuses = [...filters.statuses.filter((el) => el !== ALL && el !== ENTITY_STATUS.DELETED), status];
        }
      } else {
        const updatedStatuses = filters.statuses.filter((el) => el !== status);
        statuses = updatedStatuses.length ? updatedStatuses : [ALL];
      }
      action({ statuses });
      updateURL('statuses', statuses);
    },
    []
  );

  if (!statistic) return null;
  return (
    <Box display="flex" marginLeft={5} className={classes.root}>
      {statistic.infoNotes && (
        <>
          <StatisticItem
            icon={<InfoNoteIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateInfoBoardFilter, infoBoardFilters)}
            selected={infoBoardFilters.statuses.includes(ALL)}
            number={statistic.infoNotes.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<InfoNoteIcon className="fill" />}
            color="success"
            onSelect={updateStatus('acknowledged', updateInfoBoardFilter, infoBoardFilters)}
            selected={infoBoardFilters.statuses.includes('acknowledged')}
            number={statistic.infoNotes.acknowledged}
            tooltip={<IntlMessages id="acknowledged" />}
          />
          <StatisticItem
            icon={<InfoNoteIcon className="fill" />}
            color="orange"
            onSelect={updateStatus('not_acknowledged', updateInfoBoardFilter, infoBoardFilters)}
            selected={infoBoardFilters.statuses.includes('not_acknowledged')}
            number={statistic.infoNotes.notAcknowledged}
            tooltip={<IntlMessages id="not_acknowledged" />}
          />
          <StatisticItem
            icon={<InfoNoteIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus('closed', updateInfoBoardFilter, infoBoardFilters)}
            selected={infoBoardFilters.statuses.includes('closed')}
            number={statistic.infoNotes.closed}
            tooltip={<IntlMessages id="closed" />}
          />
        </>
      )}
      {statistic.sites && (
        <>
          <StatisticItem
            icon={<OneSiteIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateAdminSiteFilter, adminSiteFilters)}
            selected={adminSiteFilters.statuses.includes(ALL)}
            number={statistic.sites.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="success"
            onSelect={updateStatus('A', updateAdminSiteFilter, adminSiteFilters)}
            selected={adminSiteFilters.statuses.includes('A')}
            number={statistic.sites.active}
            tooltip={<IntlMessages id="active" />}
          />
          <StatisticItem
            icon={<StopIcon className="fill" />}
            color="error"
            onSelect={updateStatus('S', updateAdminSiteFilter, adminSiteFilters)}
            selected={adminSiteFilters.statuses.includes('S')}
            number={statistic.sites.inactive}
            tooltip={<IntlMessages id="suspended" />}
          />
          <VDivider width={2} />
          <StatisticItem
            icon={<CloseIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus('D', updateAdminSiteFilter, adminSiteFilters)}
            selected={adminSiteFilters.statuses.includes('D')}
            number={statistic.sites.deleted}
            tooltip={<IntlMessages id="deleted" />}
            className={classes.noMargin}
          />
        </>
      )}
      {statistic.incidentReports && (
        <>
          <StatisticItem
            icon={<IncidentReportIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateIRFilter, irFilters)}
            selected={irFilters.statuses.includes(ALL)}
            number={statistic.incidentReports.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<IncidentReportIcon className="fill" />}
            color="success"
            onSelect={updateStatus(IR_STATUSES.APPROVED, updateIRFilter, irFilters)}
            selected={irFilters.statuses.includes(IR_STATUSES.APPROVED)}
            number={statistic.incidentReports.approved}
            tooltip={<IntlMessages id="approved" />}
          />
          <StatisticItem
            icon={<IncidentReportIcon className="fill" />}
            color="orange"
            onSelect={updateStatus(IR_STATUSES.NEW, updateIRFilter, irFilters)}
            selected={irFilters.statuses.includes(IR_STATUSES.NEW)}
            number={statistic.incidentReports.new}
            tooltip={<IntlMessages id="new" />}
          />
        </>
      )}
      {statistic.formResults && (
        <>
          <StatisticItem
            icon={<FormIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateFormResultsFilter, formResultsFilters)}
            selected={formResultsFilters.statuses.includes(ALL)}
            number={statistic.formResults.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<FormIcon className="fill" />}
            color="success"
            onSelect={updateStatus(
              FORM_RESULTS_STATUSES.SUBMITTED,
              updateFormResultsFilter,
              formResultsFilters
            )}
            selected={formResultsFilters.statuses.includes(FORM_RESULTS_STATUSES.SUBMITTED)}
            number={statistic.formResults.submitted}
            tooltip={`${formatMessage({ id: 'submitted' })} / ${formatMessage({ id: 'approved' })}`}
          />
          <StatisticItem
            icon={<FormIcon className="fill" />}
            color="orange"
            onSelect={updateStatus(
              FORM_RESULTS_STATUSES.UNSUBMITTED,
              updateFormResultsFilter,
              formResultsFilters
            )}
            selected={formResultsFilters.statuses.includes(FORM_RESULTS_STATUSES.UNSUBMITTED)}
            number={statistic.formResults.unsubmitted}
            tooltip={`${formatMessage({ id: 'unsubmitted' })} / ${formatMessage({ id: 'unapproved' })}`}
          />
        </>
      )}
      {statistic.clients && (
        <>
          <StatisticItem
            icon={<ClientsIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateAdminClientFilter, adminClientFilters)}
            selected={adminClientFilters.statuses.includes(ALL)}
            number={statistic.clients.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="success"
            onSelect={updateStatus('A', updateAdminClientFilter, adminClientFilters)}
            selected={adminClientFilters.statuses.includes('A')}
            number={statistic.clients.active}
            tooltip={<IntlMessages id="active" />}
          />
          <StatisticItem
            icon={<StopIcon className="fill" />}
            color="error"
            onSelect={updateStatus('S', updateAdminClientFilter, adminClientFilters)}
            selected={adminClientFilters.statuses.includes('S')}
            number={statistic.clients.inactive}
            tooltip={<IntlMessages id="suspended" />}
          />
          <VDivider width={2} />
          <StatisticItem
            icon={<CloseIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus('D', updateAdminClientFilter, adminClientFilters)}
            selected={adminClientFilters.statuses.includes('D')}
            number={statistic.clients.deleted}
            tooltip={<IntlMessages id="deleted" />}
            className={classes.noMargin}
          />
        </>
      )}
      {statistic.contacts && (
        <>
          <StatisticItem
            icon={<ContactIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateAdminContactFilter, adminContactFilters)}
            selected={adminContactFilters.statuses.includes(ALL)}
            number={statistic.contacts.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="success"
            onSelect={updateStatus('A', updateAdminContactFilter, adminContactFilters)}
            selected={adminContactFilters.statuses.includes('A')}
            number={statistic.contacts.active}
            tooltip={<IntlMessages id="active" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="primary"
            onSelect={updateStatus('N', updateAdminContactFilter, adminContactFilters)}
            selected={adminContactFilters.statuses.includes('N')}
            number={statistic.contacts.new}
            tooltip={<IntlMessages id="new" />}
          />
          <StatisticItem
            icon={<StopIcon className="fill" />}
            color="error"
            onSelect={updateStatus('S', updateAdminContactFilter, adminContactFilters)}
            selected={adminContactFilters.statuses.includes('S')}
            number={statistic.contacts.inactive}
            tooltip={<IntlMessages id="suspended" />}
          />
          <VDivider width={2} />
          <StatisticItem
            icon={<CloseIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus('D', updateAdminContactFilter, adminContactFilters)}
            selected={adminContactFilters.statuses.includes('D')}
            number={statistic.contacts.deleted}
            tooltip={<IntlMessages id="deleted" />}
            className={classes.noMargin}
          />
        </>
      )}
      {statistic.mobilePatrols && (
        <>
          <StatisticItem
            icon={<OneCarIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateMobileUnitsFilter, adminMobileUnitsFilters)}
            selected={adminMobileUnitsFilters.statuses.includes(ALL)}
            number={statistic.mobilePatrols.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="success"
            onSelect={updateStatus('A', updateMobileUnitsFilter, adminMobileUnitsFilters)}
            selected={adminMobileUnitsFilters.statuses.includes('A')}
            number={statistic.mobilePatrols.active}
            tooltip={<IntlMessages id="active" />}
          />
          <StatisticItem
            icon={<StopIcon className="fill" />}
            color="error"
            onSelect={updateStatus('S', updateMobileUnitsFilter, adminMobileUnitsFilters)}
            selected={adminMobileUnitsFilters.statuses.includes('S')}
            number={statistic.mobilePatrols.inactive}
            tooltip={<IntlMessages id="suspended" />}
          />
          <VDivider width={2} />
          <StatisticItem
            icon={<CloseIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus('D', updateMobileUnitsFilter, adminMobileUnitsFilters)}
            selected={adminMobileUnitsFilters.statuses.includes('D')}
            number={statistic.mobilePatrols.deleted}
            tooltip={<IntlMessages id="deleted" />}
            className={classes.noMargin}
          />
        </>
      )}
      {statistic.employees && (
        <>
          <StatisticItem
            icon={<PersonIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateAdminEmployeeFilter, adminEmployeeFilters)}
            selected={adminEmployeeFilters.statuses.includes(ALL)}
            number={statistic.employees.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="success"
            onSelect={updateStatus('A', updateAdminEmployeeFilter, adminEmployeeFilters)}
            selected={adminEmployeeFilters.statuses.includes('A')}
            number={statistic.employees.active}
            tooltip={<IntlMessages id="active" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="primary"
            onSelect={updateStatus('N', updateAdminEmployeeFilter, adminEmployeeFilters)}
            selected={adminEmployeeFilters.statuses.includes('N')}
            number={statistic.employees.new}
            tooltip={<IntlMessages id="new" />}
          />
          <StatisticItem
            icon={<StopIcon className="fill" />}
            color="error"
            onSelect={updateStatus('S', updateAdminEmployeeFilter, adminEmployeeFilters)}
            selected={adminEmployeeFilters.statuses.includes('S')}
            number={statistic.employees.inactive}
            tooltip={<IntlMessages id="suspended" />}
          />
          <VDivider width={2} />
          <StatisticItem
            icon={<CloseIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus('D', updateAdminEmployeeFilter, adminEmployeeFilters)}
            selected={adminEmployeeFilters.statuses.includes('D')}
            number={statistic.employees.deleted}
            tooltip={<IntlMessages id="deleted" />}
            className={classes.noMargin}
          />
        </>
      )}
      {statistic.tasks && (
        <>
          <StatisticItem
            icon={<CheckboxChecked className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateTasksFilter, adminTasksFilters)}
            selected={adminTasksFilters.statuses.includes(ALL)}
            number={statistic.tasks.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="success"
            onSelect={updateStatus('task_active', updateTasksFilter, adminTasksFilters)}
            selected={adminTasksFilters.statuses.includes('task_active')}
            number={statistic.tasks.active}
            tooltip={<IntlMessages id="active" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="orange"
            onSelect={updateStatus('task_expired', updateTasksFilter, adminTasksFilters)}
            selected={adminTasksFilters.statuses.includes('task_expired')}
            number={statistic.tasks.expired}
            tooltip={<IntlMessages id="expired" />}
          />
          <StatisticItem
            icon={<StopIcon className="fill" />}
            color="error"
            onSelect={updateStatus('task_inactive', updateTasksFilter, adminTasksFilters)}
            selected={adminTasksFilters.statuses.includes('task_inactive')}
            number={statistic.tasks.inactive}
            tooltip={<IntlMessages id="inactive" />}
          />
        </>
      )}
      {statistic.tours && (
        <>
          <StatisticItem
            icon={<TourIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateToursFilter, adminToursFilters)}
            selected={adminToursFilters.statuses.includes(ALL)}
            number={statistic.tours.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="success"
            onSelect={updateStatus('tour_active', updateToursFilter, adminToursFilters)}
            selected={adminToursFilters.statuses.includes('tour_active')}
            number={statistic.tours.active}
            tooltip={<IntlMessages id="active" />}
          />
          <StatisticItem
            icon={<StopIcon className="fill" />}
            color="error"
            onSelect={updateStatus('tour_inactive', updateToursFilter, adminToursFilters)}
            selected={adminToursFilters.statuses.includes('tour_inactive')}
            number={statistic.tours.inactive}
            tooltip={<IntlMessages id="inactive" />}
          />
        </>
      )}
      {statistic.shifts && (
        <>
          <StatisticItem
            icon={<StopwatchIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateWorkHistoryFilter, workHistoryFilters)}
            selected={workHistoryFilters.statuses.includes(ALL)}
            number={statistic.shifts.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<StopwatchIcon className="fill" />}
            color="success"
            onSelect={updateStatus(SHIFT_STATUSES.IN_PROGRESS, updateWorkHistoryFilter, workHistoryFilters)}
            selected={workHistoryFilters.statuses.includes(SHIFT_STATUSES.IN_PROGRESS)}
            number={statistic.shifts.inProgress}
            tooltip={<IntlMessages id="cp.shift.in_progress" />}
          />
          <StatisticItem
            icon={<StopwatchIcon className="fill" />}
            color="error"
            onSelect={updateStatus(SHIFT_STATUSES.TERMINATED, updateWorkHistoryFilter, workHistoryFilters)}
            selected={workHistoryFilters.statuses.includes(SHIFT_STATUSES.TERMINATED)}
            number={statistic.shifts.terminated}
            tooltip={<IntlMessages id="terminated" />}
          />
          <StatisticItem
            icon={<StopwatchIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus(SHIFT_STATUSES.ENDED, updateWorkHistoryFilter, workHistoryFilters)}
            selected={workHistoryFilters.statuses.includes(SHIFT_STATUSES.ENDED)}
            number={statistic.shifts.ended}
            tooltip={<IntlMessages id="ended" />}
          />
        </>
      )}
      {statistic.visitors && (
        <>
          <StatisticItem
            icon={<VisitorsIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateVisitorsFilter, visitorsFilters)}
            selected={visitorsFilters.statuses.includes(ALL)}
            number={statistic.visitors.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="success"
            onSelect={updateStatus(ENTITY_STATUS.ACTIVE, updateVisitorsFilter, visitorsFilters)}
            selected={visitorsFilters.statuses.includes(ENTITY_STATUS.ACTIVE)}
            number={statistic.visitors.active}
            tooltip={<IntlMessages id="active" />}
          />
          <StatisticItem
            icon={<StopIcon className="fill" />}
            color="error"
            onSelect={updateStatus(ENTITY_STATUS.SUSPENDED, updateVisitorsFilter, visitorsFilters)}
            selected={visitorsFilters.statuses.includes(ENTITY_STATUS.SUSPENDED)}
            number={statistic.visitors.inactive}
            tooltip={<IntlMessages id="inactive" />}
          />
          <VDivider width={2} />
          <StatisticItem
            icon={<CloseIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus(ENTITY_STATUS.DELETED, updateVisitorsFilter, visitorsFilters)}
            selected={visitorsFilters.statuses.includes(ENTITY_STATUS.DELETED)}
            number={statistic.visitors.deleted}
            tooltip={<IntlMessages id="deleted" />}
            className={classes.noMargin}
          />
        </>
      )}
      {statistic.visitorLog && (
        <>
          <StatisticItem
            icon={<VisitorsIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateVisitorLogFilter, visitorLogFilters)}
            selected={visitorLogFilters.statuses.includes(ALL)}
            number={statistic.visitorLog.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<VisitorEntranceIcon className="fill" />}
            color="success"
            onSelect={updateStatus(
              VISITOR_LOG_EVENT_TYPES.ENTRANCE,
              updateVisitorLogFilter,
              visitorLogFilters
            )}
            selected={visitorLogFilters.statuses.includes(VISITOR_LOG_EVENT_TYPES.ENTRANCE)}
            number={statistic.visitorLog.entrance}
            tooltip={<IntlMessages id={VISITOR_LOG_EVENT_TYPES.ENTRANCE} />}
          />
          <StatisticItem
            icon={<VisitorExitIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus(VISITOR_LOG_EVENT_TYPES.EXIT, updateVisitorLogFilter, visitorLogFilters)}
            selected={visitorLogFilters.statuses.includes(VISITOR_LOG_EVENT_TYPES.EXIT)}
            number={statistic.visitorLog.exit}
            tooltip={<IntlMessages id={VISITOR_LOG_EVENT_TYPES.EXIT} />}
          />
        </>
      )}
      {statistic.forms && (
        <>
          <StatisticItem
            icon={<FormIcon className="fill" />}
            color="primary"
            onSelect={updateStatus(ALL, updateFormsFilter, formsFilters)}
            selected={formsFilters.statuses.includes(ALL)}
            number={statistic.forms.all}
            tooltip={<IntlMessages id="all" />}
          />
          <StatisticItem
            icon={<CheckMarkIcon className="stroke" />}
            color="success"
            onSelect={updateStatus(ENTITY_STATUS.ACTIVE, updateFormsFilter, formsFilters)}
            selected={formsFilters.statuses.includes(ENTITY_STATUS.ACTIVE)}
            number={statistic.forms.active}
            tooltip={<IntlMessages id="active" />}
          />
          <StatisticItem
            icon={<CloseIcon className="fill" />}
            color="neutral"
            onSelect={updateStatus(ENTITY_STATUS.DELETED, updateFormsFilter, formsFilters)}
            selected={formsFilters.statuses.includes(ENTITY_STATUS.DELETED)}
            number={statistic.forms.deleted}
            tooltip={<IntlMessages id="disabled" />}
          />
        </>
      )}
      <StatisticItem icon={<CheckMarkIcon className="stroke" />} color="success" number={statistic.scanned} />
      <StatisticItem icon={<StopIcon className="fill" />} color="error" number={statistic.alarms} />
      <StatisticItem icon={<CloseIcon className="fill" />} color="neutral" number={statistic.missed} />
    </Box>
  );
};

export { PageStatistic };
