import isEqual from 'react-fast-compare';
import { handleActions } from 'redux-actions';

import { logoutSuccess } from 'ducks/auth/actions';

import { statsPerDateSuccess } from './actions';
import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return {
        ...initialState,
      };
    },
    [statsPerDateSuccess](
      state,
      {
        payload: {siteID, statsPerDate},
      },
    ) {
      if (isEqual(state[siteID], statsPerDate)) {
        return state;
      }

      return {
        ...state,
        [siteID]: Object.freeze(statsPerDate),
      };
    },
  },
  {
    ...initialState,
  },
);
