import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const siteInfoRequest = createRequest(constants.siteInfo);
export const siteInfoSuccess = createSuccess(constants.siteInfo);
export const siteInfoFailure = createFailure(constants.siteInfo);

export const updateSiteStatusRequest = createRequest(`${constants.siteInfo}_UPDATE_STATUS`);
export const updateSiteStatusSuccess = createSuccess(`${constants.siteInfo}_UPDATE_STATUS`);
export const updateSiteStatusFailure = createFailure(`${constants.siteInfo}_UPDATE_STATUS`);

export const getRemindersRequest = createRequest(constants.reminders);
export const getRemindersSuccess = createSuccess(constants.reminders);
export const getRemindersFailure = createFailure(constants.reminders);

export const makeMasterCheckpointsRequest = createRequest(`${constants.siteInfo}_ALL_MASTER`);
export const makeMasterCheckpointsSuccess = createSuccess(`${constants.siteInfo}_ALL_MASTER`);
export const makeMasterCheckpointsFailure = createFailure(`${constants.siteInfo}_ALL_MASTER`);

export const removeMasterCheckpointsRequest = createRequest(`${constants.siteInfo}_REMOVE_ALL_MASTER`);
export const removeMasterCheckpointsSuccess = createSuccess(`${constants.siteInfo}_REMOVE_ALL_MASTER`);
export const removeMasterCheckpointsFailure = createFailure(`${constants.siteInfo}_REMOVE_ALL_MASTER`);

export const deleteCheckpointsRequest = createRequest(`${constants.siteInfo}_DELETE`);
export const deleteCheckpointsSuccess = createSuccess(`${constants.siteInfo}_DELETE`);
export const deleteCheckpointsFailure = createFailure(`${constants.siteInfo}_DELETE`);

export const updateRemindersRequest = createRequest(`${constants.siteInfo}_UPDATE_REMINDERS`);
export const updateRemindersSuccess = createSuccess(`${constants.siteInfo}_UPDATE_REMINDERS`);
export const updateRemindersFailure = createFailure(`${constants.siteInfo}_UPDATE_REMINDERS`);

export const updateNoteRequest = createRequest(`${constants.siteInfo}_UPDATE_NOTE`);
export const updateNoteSuccess = createSuccess(`${constants.siteInfo}_UPDATE_NOTE`);
export const updateNoteFailure = createFailure(`${constants.siteInfo}_UPDATE_NOTE`);

export const deleteSiteRequest = createRequest(`${constants.siteInfo}_DELETE_SITE`);
export const deleteSiteSuccess = createSuccess(`${constants.siteInfo}_DELETE_SITE`);
export const deleteSiteFailure = createFailure(`${constants.siteInfo}_DELETE_SITE`);

export const requestSiteInfo = siteInfoRequest;
export const requestReminders = getRemindersRequest;
export const requestUpdateSiteStatus = updateSiteStatusRequest;
export const requestMakeAllMasterCheckpoint = makeMasterCheckpointsRequest;
export const requestRemoveAllMasterCheckpoint = removeMasterCheckpointsRequest;
export const requestDeleteAllCheckpoints = deleteCheckpointsRequest;
export const updateReminders = updateRemindersRequest;
export const updateNote = updateNoteRequest;
export const deleteSite = deleteSiteRequest;
