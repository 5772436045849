import { Typography, Box, createStyles, Tooltip } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { matchPath } from 'react-router-dom';
import * as sites from 'ducks/sites';
import * as employees from 'ducks/employees';
import * as auth from 'ducks/auth';
import * as clients from 'ducks/clients';
import * as mobilePatrols from 'ducks/mobile-patrols';
import * as contacts from 'ducks/contacts';
import * as forms from 'ducks/forms';
import * as tasks from 'ducks/tasks';
import * as tours from 'ducks/tours';
import * as auditLog from 'ducks/auditLog';
import * as visitors from 'ducks/visitors';
import * as visitorLog from 'ducks/visitorLog';
import IntlMessages, { useIntl } from 'util/IntlMessages';
import { useTopMenu, topClientMenu } from '../Header/menus';
import { CLIENT_TYPE, NEW } from 'app/types/constants';
import { usePageId } from 'new-design/hooks/usePageId';
import { useStyles as useMainStyles } from '../../styles';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      item: {
        fontSize: 16,
        lineHeight: '24px',
        [theme.breakpoints.down('lg')]: {
          fontSize: 14,
          lineHeight: '20px',
        },
      },
      activeItem: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '24px',
        marginLeft: 2,
        [theme.breakpoints.down('lg')]: {
          fontSize: 18,
          lineHeight: '20px',
        },
      },
      pointer: {
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      idLabel: {
        marginLeft: 4,
        fontSize: 16,
        color: '#000',
        fontWeight: 600,
      },
    }),
  {
    name: 'Breadcrumbs',
  }
);

const getElements = (menu, els, isFound, searchedLink) => {
  if (isFound) {
    return [els, isFound];
  }

  const response = [];
  menu.forEach((menuItem) => {
    if (menuItem?.disabled) {
      return [];
    }
    const match = matchPath(searchedLink, {
      path: menuItem.link?.split('#')[0],
      exact: true,
      strict: false,
    });
    if (menuItem.children?.length || menuItem.subpages?.length) {
      response.push(
        getElements(
          menuItem.children || menuItem.subpages,
          [
            ...els,
            {
              name: menuItem.name,
              link: menuItem.link,
              historyBack: menuItem.historyBack,
            },
          ],
          match,
          searchedLink
        )
      );
    } else {
      response.push([
        [
          ...els,
          {
            name: menuItem.name,
            historyBack: menuItem.historyBack,
            link: menuItem.link.replace('null', ''),
          },
        ],
        match,
      ]);
    }
  });

  return response?.find((el) => !!el && !!el[1]);
};

const Breadcrumbs = ({ setIsBigger, parentRef, limited, maxWidth }) => {
  const classes = useStyles();
  const history = useHistory();
  const topMenu = useTopMenu();
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const [elements, setElements] = useState([]);
  const { list: sitesList } = useSelector(sites.selectors);
  const { list: employeesList } = useSelector(employees.selectors);
  const { list: clientsList } = useSelector(clients.selectors);
  const { list: mobilePatrolsList } = useSelector(mobilePatrols.selectors);
  const { info: contactInfo } = useSelector(contacts.selectors);
  const { info: formInfo } = useSelector(forms.selectors);
  const { info: taskInfo } = useSelector(tasks.selectors);
  const { info: tourInfo } = useSelector(tours.selectors);
  const { info: auditLogInfo } = useSelector(auditLog.selectors);
  const { info: visitorsInfo } = useSelector(visitors.selectors);
  const { info: visitorLogInfo } = useSelector(visitorLog.selectors);
  const { userInfo } = useSelector(auth.selectors);

  const menu = userInfo.userType === CLIENT_TYPE ? topClientMenu : topMenu;

  const { id: pathId, hashId: id } = usePageId();

  const checkElements = () => {
    const [items] = getElements(menu, [], false, history.location.pathname) ?? [];
    setElements(items ?? []);
  };

  const addListeners = () => {
    history.listen((location) => {
      const [_items] = getElements(menu, [], false, location.pathname) ?? [];
      setElements(_items ?? []);
    });
  };

  useEffect(() => {
    checkElements();
    addListeners();
  }, [history.location]);

  const getName = useCallback(() => {
    if (pathId === NEW) {
      return <IntlMessages id="new" />;
    }
    if (history.location.pathname.includes('sites')) {
      return sitesList?.find((el) => el.siteID === +pathId)?.siteName;
    } else if (history.location.pathname.includes('employees')) {
      return employeesList.find((el) => el.employeeID === +pathId)?.fullName;
    } else if (history.location.pathname.includes('clients')) {
      return clientsList.find((el) => el.clientID === +pathId)?.clientName;
    } else if (history.location.pathname.includes('mobile-units')) {
      return mobilePatrolsList.find((el) => el.mobilePatrolID === +pathId)?.name;
    } else if (history.location.pathname.includes('contacts')) {
      return contactInfo[+pathId]?.fullName;
    } else if (history.location.pathname.includes('forms')) {
      return formInfo[+pathId]?.name;
    } else if (history.location.pathname.includes('tasks')) {
      return taskInfo[+pathId]?.title;
    } else if (history.location.pathname.includes('tours')) {
      return tourInfo[+pathId]?.tourName;
    } else if (history.location.pathname.includes('system-log')) {
      return (
        auditLogInfo[+pathId]?.eventID &&
        `${formatMessage({ id: 'event_id' })} - #${auditLogInfo[+pathId]?.eventID}`
      );
    } else if (history.location.pathname.includes('visitors')) {
      const { firstName, lastName } = visitorsInfo[+pathId] || {};
      return visitorsInfo[+pathId] ? `${firstName} ${lastName}` : `#${pathId}`;
    } else if (history.location.pathname.includes('visitor-log')) {
      return visitorLogInfo[+pathId]?.eventType
        ? formatMessage({ id: visitorLogInfo[+pathId]?.eventType })
        : `#${pathId}`;
    }
    return '';
  }, [
    sitesList,
    employeesList,
    clientsList,
    mobilePatrolsList,
    contactInfo,
    formInfo,
    taskInfo,
    tourInfo,
    auditLogInfo,
    visitorsInfo,
    visitorLogInfo,
    pathId,
  ]);

  useEffect(() => {
    if (!limited) return;
    setIsBigger(parentRef.current?.offsetWidth < parentRef.current?.children[0]?.offsetWidth);
  }, [ref.current?.offsetWidth, limited]);

  const onPageNameClick = useCallback((index, historyBack, link) => {
    if (index > 0) {
      if (historyBack) {
        history.goBack();
      } else {
        history.push(link);
      }
    }
  }, []);

  return (
    <Box display="flex" alignItems="center" ref={ref} width={maxWidth ? maxWidth - 16 : undefined}>
      {elements.map(({ name, link, historyBack }, index) =>
        elements.length - 1 !== index ? (
          <React.Fragment key={`${link}-${name?.props?.id}`}>
            <Typography
              color="secondary"
              className={clsx(classes.item, {
                [classes.pointer]: index > 0,
              })}
              onClick={() => onPageNameClick(index, historyBack, link)}
            >
              {name ? name : getName()}
            </Typography>
            <Typography color="secondary" className={classes.item}>
              {' '}
              /{' '}
            </Typography>
          </React.Fragment>
        ) : (
          <Tooltip title={name ? name : getName() || ''} key={`${link}-${name?.props?.id}`}>
            <Typography noWrap color="textPrimary" className={classes.activeItem}>
              {name ? name : getName()}
            </Typography>
          </Tooltip>
        )
      )}
      {!!id && <Typography className={classes.idLabel}>{`(#${id})`}</Typography>}
    </Box>
  );
};

export const LimitedBreadCrumbs = ({ maxWidth }) => {
  const classes = useMainStyles();
  const [isChildBigger, setIsChildBigger] = useState(false);
  const breadCrumbsContainerRef = useRef();
  const history = useHistory();

  useEffect(() => {
    setIsChildBigger(false);
  }, [history.location.pathname]);

  return (
    <>
      <div
        className={clsx({
          [classes.breadcrumbsLimitedContainer]: false,
          [classes.breadcrumbsContainer]: true,
        })}
        ref={breadCrumbsContainerRef}
      >
        <Breadcrumbs
          parentRef={breadCrumbsContainerRef}
          setIsBigger={setIsChildBigger}
          limited={false}
          maxWidth={maxWidth}
        />
      </div>
      {isChildBigger && (
        <Box marginLeft={0.5}>
          <Typography style={{ fontSize: 20, color: 'black' }}>...</Typography>
        </Box>
      )}
    </>
  );
};

export default Breadcrumbs;
