import { createAction, createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const biToursSummaryRequest = createRequest(constants.biToursSummary);
export const biToursSummarySuccess = createSuccess(constants.biToursSummary);
export const biToursSummaryFailure = createFailure(constants.biToursSummary);

export const requestBIToursSummary = biToursSummaryRequest;


export const biToursOverviewRequest = createRequest(constants.biToursOverview);
export const biToursOverviewSuccess = createSuccess(constants.biToursOverview);
export const biToursOverviewFailure = createFailure(constants.biToursOverview);

export const requestBIToursOverview = biToursOverviewRequest;

export const setLoadingState = createAction(constants.setLoadingState);
