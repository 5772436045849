import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  darken,
  Hidden,
  Toolbar,
  IconButton,
  Paper,
  Avatar,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LangSwitcher } from '../LangSwitcher';
import User from '../TopUser';
import { topClientMenu, useTopMenu } from './menus';
import { useSelector } from 'react-redux';
import * as auth from 'ducks/auth';
import HorizontalTopMenu from '../HorizontalTopMenu';
import Notifications from 'new-design/components/Notifications';
import { BurgerIcon } from 'assets/icons';
import { getMediaAssetURL } from 'new-design/helpers/getMediaAssetURL';
import MenuTreeView from './treeMenu';
import { CLIENT_TYPE } from 'app/types/constants';
import IntlMessages from 'util/IntlMessages';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '20px 30px',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 72,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    '& .Cmt-appIcon': {
      color: theme.palette.text.secondary,
      '&:hover, &:focus': {
        color: darken(theme.palette.text.secondary, 0.2),
      },
      [theme.breakpoints.down('xs')]: {
        padding: 7,
      },
    },
    '& .Cmt-horizontalNavMenu': {
      position: 'static',
    },
  },
  rightActions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: theme.palette.common.dark,
    },
  },
  logo: {
    marginRight: 15,
    height: 46,

    '&.default': {
      height: 33,
    },
    [theme.breakpoints.down('md')]: {
      height: 30,
      minWidth: 105,
      marginRight: 0,
    },
  },
  langSwitcherContainer: {
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(-1),
    },
  },
  searchIcon: {
    [theme.breakpoints.down('xs')]: {
      padding: 9,
    },
  },

  mobileMenuContainer: {
    height: 'calc(100% - 74px)',
    position: 'fixed',
    background: 'rgba(34, 34, 34, .5)',
    width: '0',

    '& .custom-paper': {
      left: '-85%',
      height: 'calc(100% - 74px)',
      width: '85%',
      position: 'fixed',
      transition: 'left .5s ease-in-out',
      overflow: 'hidden',

      '& .avatar': {
        width: theme.spacing(9),
        height: theme.spacing(9),
      },
      '& .userName': {
        marginTop: theme.spacing(1),
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
      },
      '& .position': {
        marginTop: theme.spacing(0.5),
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '18px',
        color: '#8A8A8A',
      },
    },

    '& .logout-container': {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      height: 65,
    },

    '&.opened': {
      width: '100%',

      '& .custom-paper': {
        width: '85%',
        left: 0,
        transition: 'left .5s ease-in-out',
      },
    },
  },
}));

const MainHeader = () => {
  const classes = useStyles();
  const topMenu = useTopMenu();

  const [isDefault, setIsDefault] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { companyName, userInfo, companyInfo, sessionID } = useSelector(auth.selectors);
  const logoUrl = useMemo(
    () => `${process.env.PUBLIC_URL}/logos/${companyName.toLowerCase()}.png`,
    [companyName]
  );
  const logoErrorHandler = useCallback((e) => {
    setIsDefault(true);
    e.target.onerror = null;
    e.target.src = `${process.env.PUBLIC_URL}/logos/default.png`;
  }, []);

  const handleToggleMobile = () => setShowMobileMenu((state) => !state);
  // const handleOpenMobile = () => setShowMobileMenu(true);
  const handleCloseMobile = () => setShowMobileMenu(false);

  const menuItems = useMemo(() => {
    return userInfo?.userType === CLIENT_TYPE
      ? topClientMenu.filter(
          ({ trancode }) => companyInfo?.visitorLogEnabled || trancode !== 'visitor_log_menu'
        )
      : topMenu;
  }, [userInfo?.userType, companyInfo?.visitorLogEnabled]);

  return (
    <>
      <Toolbar className={classes.root}>
        <Box display="flex">
          <Hidden mdUp>
            <Box marginLeft={-1}>
              <IconButton onClick={handleToggleMobile}>
                <BurgerIcon />
              </IconButton>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <img
              src={logoUrl}
              className={clsx(classes.logo, {
                default: isDefault,
              })}
              onError={logoErrorHandler}
              alt="logo"
            />
            <HorizontalTopMenu menuItems={menuItems} />
          </Hidden>
        </Box>
        <Hidden mdUp>
          <img src={logoUrl} className={classes.logo} onError={logoErrorHandler} alt="logo" />
        </Hidden>
        <div className={classes.rightActions}>
          <Hidden mdDown>
            <Box mr={2.5}>
              <Notifications />
            </Box>
          </Hidden>
          <div className={classes.langSwitcherContainer}>
            <LangSwitcher />
          </div>
          <Hidden mdDown>
            <User />
          </Hidden>
        </div>
      </Toolbar>
      <Box
        className={clsx(classes.mobileMenuContainer, {
          opened: showMobileMenu,
        })}
        onClick={handleCloseMobile}
      >
        <Paper square className="custom-paper" onClick={(e) => e.stopPropagation()}>
          <Box display="flex" flexDirection="column" alignItems="center" marginTop={2} paddingBottom={1}>
            <Avatar
              className="avatar"
              alt={userInfo?.userName}
              src={getMediaAssetURL(userInfo?.photoMediaAssetID, sessionID)}
            />
            <Typography className="userName">{userInfo?.userName}</Typography>
            <Typography className="position">{userInfo?.position}</Typography>
          </Box>
          <Divider />
          <Box marginTop={4}>
            <MenuTreeView handleCloseMobile={handleCloseMobile} />
          </Box>
          <Box padding={2} className="logout-container">
            <Button color="secondary" fullWidth>
              <IntlMessages id="log_out" />
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default MainHeader;
