import { createRequest, createSuccess, createFailure } from '../helpers';
import constants from './constants';

export const checkpointsRequest = createRequest(constants.checkpoints);
export const checkpointsSuccess = createSuccess(constants.checkpoints);
export const checkpointsFailure = createFailure(constants.checkpoints);

export const checkpointInfoRequest = createRequest(constants.checkpointInfo);
export const checkpointInfoSuccess = createSuccess(constants.checkpointInfo);
export const checkpointInfoFailure = createFailure(constants.checkpointInfo);

export const createCheckpointRequest = createRequest(`${constants.checkpointInfo}_CREATE`);
export const createCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_CREATE`);
export const createCheckpointFailure = createFailure(`${constants.checkpointInfo}_CREATE`);

export const renameCheckpointRequest = createRequest(`${constants.checkpointInfo}_RENAME`);
export const renameCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_RENAME`);
export const renameCheckpointFailure = createFailure(`${constants.checkpointInfo}_RENAME`);

export const makeMasterCheckpointRequest = createRequest(`${constants.checkpointInfo}_MAKE_MASTER`);
export const makeMasterCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_MAKE_MASTER`);
export const makeMasterCheckpointFailure = createFailure(`${constants.checkpointInfo}_MAKE_MASTER`);

export const removeMasterCheckpointRequest = createRequest(`${constants.checkpointInfo}_REMOVE_MASTER`);
export const removeMasterCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_REMOVE_MASTER`);
export const removeMasterCheckpointFailure = createFailure(`${constants.checkpointInfo}_REMOVE_MASTER`);

export const enableGPSCheckpointRequest = createRequest(`${constants.checkpointInfo}_GPS_ENABLE`);
export const enableGPSCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_GPS_ENABLE`);
export const enableGPSCheckpointFailure = createFailure(`${constants.checkpointInfo}_GPS_ENABLE`);

export const removeGPSCheckpointRequest = createRequest(`${constants.checkpointInfo}_GPS_REMOVE`);
export const removeGPSCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_GPS_REMOVE`);
export const removeGPSCheckpointFailure = createFailure(`${constants.checkpointInfo}_GPS_REMOVE`);

export const enableCheckpointRequest = createRequest(`${constants.checkpointInfo}_ENABLE`);
export const enableCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_ENABLE`);
export const enableCheckpointFailure = createFailure(`${constants.checkpointInfo}_ENABLE`);

export const disableCheckpointRequest = createRequest(`${constants.checkpointInfo}_DISABLE`);
export const disableCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_DISABLE`);
export const disableCheckpointFailure = createFailure(`${constants.checkpointInfo}_DISABLE`);

export const deleteCheckpointRequest = createRequest(`${constants.checkpointInfo}_DELETE`);
export const deleteCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_DELETE`);
export const deleteCheckpointFailure = createFailure(`${constants.checkpointInfo}_DELETE`);

export const updateNoteRequest = createRequest(`${constants.checkpointInfo}_UPDATE_NOTE`);
export const updateNoteSuccess = createSuccess(`${constants.checkpointInfo}_UPDATE_NOTE`);
export const updateNoteFailure = createFailure(`${constants.checkpointInfo}_UPDATE_NOTE`);

export const updateRemindersRequest = createRequest(`${constants.checkpointInfo}_REMINDERS`);
export const updateRemindersSuccess = createSuccess(`${constants.checkpointInfo}_REMINDERS`);
export const updateRemindersFailure = createFailure(`${constants.checkpointInfo}_REMINDERS`);

export const addCheckpointMarkerRequest = createRequest(`${constants.checkpointInfo}_ADD_MARKER`);
export const addCheckpointMarkerSuccess = createSuccess(`${constants.checkpointInfo}_ADD_MARKER`);
export const addCheckpointMarkerFailure = createFailure(`${constants.checkpointInfo}_ADD_MARKER`);

export const deleteCheckpointMarkerRequest = createRequest(`${constants.checkpointInfo}_DELETE_MARKER`);
export const deleteCheckpointMarkerSuccess = createSuccess(`${constants.checkpointInfo}_DELETE_MARKER`);
export const deleteCheckpointMarkerFailure = createFailure(`${constants.checkpointInfo}_DELETE_MARKER`);

export const enablePhotoOnScanCheckpointRequest = createRequest(`${constants.checkpointInfo}_ENABLE_PHOTO_ON_SCAN`);
export const enablePhotoOnScanCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_ENABLE_PHOTO_ON_SCAN`);
export const enablePhotoOnScanCheckpointFailure = createFailure(`${constants.checkpointInfo}_ENABLE_PHOTO_ON_SCAN`);

export const deletePhotoOnScanCheckpointRequest = createRequest(`${constants.checkpointInfo}_DELETE_PHOTO_ON_SCAN`);
export const deletePhotoOnScanCheckpointSuccess = createSuccess(`${constants.checkpointInfo}_DELETE_PHOTO_ON_SCAN`);
export const deletePhotoOnScanCheckpointFailure = createFailure(`${constants.checkpointInfo}_DELETE_PHOTO_ON_SCAN`);

export const checkpointQRCodeDataRequest = createRequest(`${constants.checkpointInfo}_QR_CODE_DATA`);
export const checkpointQRCodeDataSuccess = createSuccess(`${constants.checkpointInfo}_QR_CODE_DATA`);
export const checkpointQRCodeDataFailure = createFailure(`${constants.checkpointInfo}_QR_CODE_DATA`);

export const updateCheckpointQRTagRequest = createRequest(`${constants.checkpointInfo}_UPDATE_QR_TAG`);
export const updateCheckpointQRTagSuccess = createSuccess(`${constants.checkpointInfo}_UPDATE_QR_TAG`);
export const updateCheckpointQRTagFailure = createFailure(`${constants.checkpointInfo}_UPDATE_QR_TAG`);

export const requestCheckpoints = checkpointsRequest;
export const requestCheckpointInfo = checkpointInfoRequest;
export const requestCreateCheckpoint = createCheckpointRequest;
export const renameCheckpoint = renameCheckpointRequest;
export const makeMasterCheckpoint = makeMasterCheckpointRequest;
export const removeMasterCheckpoint = removeMasterCheckpointRequest;
export const enableGPSCheckpoint = enableGPSCheckpointRequest;
export const removeGPSCheckpoint = removeGPSCheckpointRequest;
export const enableCheckpoint = enableCheckpointRequest;
export const disableCheckpoint = disableCheckpointRequest;
export const deleteCheckpoint = deleteCheckpointRequest;
export const updateNote = updateNoteRequest;
export const updateReminders = updateRemindersRequest;
export const addCheckpointMarker = addCheckpointMarkerRequest;
export const deleteCheckpointMarker = deleteCheckpointMarkerRequest;
export const enablePhotoOnScanCheckpoint = enablePhotoOnScanCheckpointRequest;
export const deletePhotoOnScanCheckpoint = deletePhotoOnScanCheckpointRequest;
export const requestCheckpointQRCodeData = checkpointQRCodeDataRequest;
export const updateCheckpointQRTag = updateCheckpointQRTagRequest;
