import isEqual from 'react-fast-compare';
import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import { visitLogSuccess, visitReportLinkSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [visitLogSuccess](state, { payload }) {
      if (isEqual(state.log[payload.visitID], payload)) {
        return state;
      }
      return {
        ...state,
        log: {
          ...state.log,
          [payload.visitID]: Object.freeze(payload),
        },
      };
    },
    [visitReportLinkSuccess](state, { payload: { visitID, link } }) {
      return {
        ...state,
        reportLinks: {
          ...state.reportLinks,
          [visitID]: link,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
