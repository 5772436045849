import React, { useMemo, useEffect, useState, useRef } from 'react';
import Nprogress from 'nprogress';
// import ReactPlaceholder from 'react-placeholder';

import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import CircularProgress from 'new-design/components/CircularProgress/index';

export default function asyncComponent(importComponent) {
  const AsyncFunc = (props) => {
    const [component, setComponent] = useState(null);
    const mounted = useRef(null);

    const loadComponent = async () => {
      mounted.current = true;
      const { default: Component } = await importComponent();
      Nprogress.done();
      if (mounted.current) {
        setComponent(<Component {...props} />);
      }
    }

    useEffect(() => {
      Nprogress.start();
      loadComponent();

      return () => {
        mounted.current = false;
      }
    }, []);

    const Component = useMemo(() => component ||
      (
        <div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
          <CircularProgress/>
        </div>
      ), [component]
    );

    return Component;
  }

  return AsyncFunc;
}
