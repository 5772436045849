import preparedReducer from './reducer';
import { requestSiteInfoCP, updateSitePlanOrder } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'siteInfoCP';

export const selectors = state => state[SLICE_NAME];
export const getSiteInfoCP = (state, siteID) => state.siteInfoCP[siteID];

export const actions = {
  requestSiteInfoCP,
  updateSitePlanOrder,
};
