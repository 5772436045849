import preparedReducer from './reducer';
import {
  requestActivity,
  requestByShiftIDActivity,
  resetGPSAlertRequest,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'activity';

export const selectors = state => state[SLICE_NAME];

export const selectActivityEvent = (state, shiftLogID) => state[SLICE_NAME][shiftLogID] || {}

export const actions = {
  requestActivity,
  requestByShiftIDActivity,
  resetGPSAlertRequest,
};
