import isEqual from 'react-fast-compare';
import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { hoursPerDateSuccess } from './actions';
import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return initialState;
    },
    [hoursPerDateSuccess](state, { payload: { siteID, hoursPerDate } }) {
      if (isEqual(state[siteID], hoursPerDate)) {
        return state;
      }

      return {
        ...state,
        [siteID]: Object.freeze(hoursPerDate),
      };
    },
  },
  {
    ...initialState,
  }
);
