import { createAction } from 'ducks/helpers';
import constants from './constants';

export const initPlanList = createAction(`${constants.planList}_INIT`);
export const addCheckpoint = createAction(`${constants.planList}_ADD_CHECKPOINT`);
export const addArea = createAction(`${constants.planList}_ADD_AREA`);
export const clearPlanList = createAction(`${constants.planList}_CLEAR`);
export const setRefEl = createAction(`${constants.planList}_SET_REF_EL`);
export const renameArea = createAction(`${constants.planList}_RENAME_AREA`);
export const renameCheckpoint = createAction(`${constants.planList}_RENAME_CHECKPOINT`);
export const setAreaActive = createAction(`${constants.planList}_SET_ACTIVE_AREA`);
export const deleteArea = createAction(`${constants.planList}_DELETE_AREA`);
export const updateNode = createAction(`${constants.planList}_UPDATE_NODE`);
export const setSelectedItem = createAction(`${constants.planList}_SELECT_ITEM`);
export const setSelectedTab = createAction(`${constants.planList}_SELECT_TAB`);
export const setMarkerEnabled = createAction(`${constants.planList}_MARKER_ENABLE`);
export const setMarker = createAction(`${constants.planList}_MARKER`);
export const setDraggableMode = createAction(`${constants.planList}_DRAGGABLE_MODE`);
