import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getUserInfo } from 'ducks/auth';
import { formatDateTimeUTC, newPendingID } from 'util/formatting';

import axios from 'axios';
import { uploadMediaAssets, mediaAssets } from 'app/api-routes';
import {
  bulkUploadMediaAssetsFailure,
  bulkUploadMediaAssetsRequest,
  bulkUploadMediaAssetsSuccess,
  deleteMediaAssetFailure,
  deleteMediaAssetRequest,
  deleteMediaAssetSuccess,
  mediaAssetDescriptionFailure,
  mediaAssetDescriptionRequest,
  mediaAssetDescriptionSuccess,
  updateMediaAssetFailure,
  updateMediaAssetRequest,
  updateMediaAssetSuccess,
  uploadMediaAssetFailure,
  uploadMediaAssetRequest,
  uploadMediaAssetSuccess,
} from './actions';

import { MediaAssetTables } from 'ducks/mediaAssets/types';

export function* uploadMediaAssetSaga({ payload: { fileUploaded, mediaAssetInfo, callback } }) {
  const { userName, userID } = yield select((state) => getUserInfo(state));
  const { reportId, type, size, tableName, entityID, description, category } = mediaAssetInfo || {};

  const newMediaAssetInfo = mediaAssetInfo
    ? {
        ...mediaAssetInfo,
        dateCreated: formatDateTimeUTC(),
        userCreated: userID,
        userCreatedName: userName,
        mediaAssetID: newPendingID(),
      }
    : {};

  try {
    const formData = new FormData();
    formData.append('file', fileUploaded.file);

    const { payload } = yield axios.post(uploadMediaAssets, formData, {
      params: {
        entityID: reportId || entityID,
        tableName,
        type,
        size,
        description,
        category,
      },
    });
    const mediaAssetID = payload;

    callback?.(mediaAssetID);

    yield put(
      uploadMediaAssetSuccess({
        mediaAssetInfo: {
          ...newMediaAssetInfo,
          mediaAssetID,
        },
      })
    );

    return mediaAssetID;
  } catch (err) {
    if (tableName === MediaAssetTables.NOTEBOOK_NOTES) {
      yield put(
        uploadMediaAssetSuccess({
          mediaAssetInfo: newMediaAssetInfo,
        })
      );
    } else {
      yield put(uploadMediaAssetFailure(err));
    }
  }
}

export function* bulkUploadMediaAssetsSaga({ payload: { files, callback } }) {
  try {
    if (!files) {
      return [];
    }
    
    const mediaAssetIDs = [];

    for (let index = 0; index < files.length; index++) {
      const { fileUploaded, mediaAssetInfo } = files[index];
      const { type, size, tableName, entityID, description } = mediaAssetInfo || {};

      const formData = new FormData();
      formData.append('file', fileUploaded.file);

      const { payload } = yield axios.post(uploadMediaAssets, formData, {
        params: {
          entityID: entityID,
          tableName,
          type,
          size,
          description,
        },
      });

      mediaAssetIDs.push(payload);
    }

    callback?.(mediaAssetIDs);

    yield put(bulkUploadMediaAssetsSuccess(mediaAssetIDs));

    return mediaAssetIDs;
  } catch (err) {
    yield put(bulkUploadMediaAssetsFailure(err));
  }
}

function* updateMediaAssetDescriptionSaga({ payload }) {
  const { mediaAssetID, description, tableName, reportId, entityID, callback } = payload;
  try {
    yield axios.put(`${mediaAssets}/${mediaAssetID}/description`, {
      payload: description,
    });

    if (callback) {
      callback();
    }

    yield put(
      updateMediaAssetSuccess({
        mediaAssetID,
        mediaAssetInfo: {
          tableName,
          mediaAssetID,
          description,
          reportId,
          entityID,
        },
      })
    );
  } catch (err) {
    yield put(updateMediaAssetFailure(err));
  }
}

function* deleteMediaAsset({ payload: { payload, callback } }) {
  const { mediaAssetInfo, id } = payload;
  try {
    yield axios.delete(`${mediaAssets}/${id}`);
    if (callback) {
      yield callback();
    }

    yield put(
      deleteMediaAssetSuccess({
        ...mediaAssetInfo,
        mediaAssetID: id,
      })
    );
  } catch (err) {
    yield put(deleteMediaAssetFailure(err));
  }
}

function* mediaAssetDescriptionSaga({ payload: mediaAssetID }) {
  try {
    const { payload: description } = yield axios.get(`${mediaAssets}/${mediaAssetID}/description`);

    yield put(mediaAssetDescriptionSuccess({ mediaAssetID, description }));
  } catch (err) {
    yield put(mediaAssetDescriptionFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(uploadMediaAssetRequest, uploadMediaAssetSaga),
    yield takeLatest(updateMediaAssetRequest, updateMediaAssetDescriptionSaga),
    yield takeLatest(deleteMediaAssetRequest, deleteMediaAsset),
    yield takeLatest(bulkUploadMediaAssetsRequest, bulkUploadMediaAssetsSaga),
    yield takeEvery(mediaAssetDescriptionRequest, mediaAssetDescriptionSaga),
  ]);
}
