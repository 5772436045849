import preparedReducer from './reducer';
import {
  requestSiteEmailSettings,
  enableSiteEmailSetting,
  disableSiteEmailSetting,
  requestSiteEmailRecipients,
  updateSiteEmailRecipients,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'siteEmailSettings';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestSiteEmailSettings,
  enableSiteEmailSetting,
  disableSiteEmailSetting,
  requestSiteEmailRecipients,
  updateSiteEmailRecipients,
};
