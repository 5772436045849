import { createAction, createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const mobilePatrolsRequest = createRequest(constants.mobilePatrols);
export const mobilePatrolsSuccess = createSuccess(constants.mobilePatrols);
export const mobilePatrolsFailure = createFailure(constants.mobilePatrols);

export const mobilePatrolInfoRequest = createRequest(`${constants.mobilePatrols}_GET_PATROL`);
export const mobilePatrolInfoSuccess = createSuccess(`${constants.mobilePatrols}_GET_PATROL`);
export const mobilePatrolInfoFailure = createFailure(`${constants.mobilePatrols}_GET_PATROL`);

export const updateMobilePatrolsStatusRequest = createRequest(`${constants.mobilePatrols}_UPDATE_STATUS`);
export const updateMobilePatrolsStatusSuccess = createSuccess(`${constants.mobilePatrols}_UPDATE_STATUS`);
export const updateMobilePatrolsStatusFailure = createFailure(`${constants.mobilePatrols}_UPDATE_STATUS`);

export const createMobilePatrolsRequest = createRequest(`${constants.mobilePatrols}_CREATE`);
export const createMobilePatrolsSuccess = createSuccess(`${constants.mobilePatrols}_CREATE`);
export const createMobilePatrolsFailure = createFailure(`${constants.mobilePatrols}_CREATE`);

export const updateMobilePatrolsRequest = createRequest(`${constants.mobilePatrols}_UPDATE`);
export const updateMobilePatrolsSuccess = createSuccess(`${constants.mobilePatrols}_UPDATE`);
export const updateMobilePatrolsFailure = createFailure(`${constants.mobilePatrols}_UPDATE`);

export const deleteMobileUnitRequest = createRequest(`${constants.mobilePatrols}_DELETE`);
export const deleteMobileUnitSuccess = createSuccess(`${constants.mobilePatrols}_DELETE`);
export const deleteMobileUnitFailure = createFailure(`${constants.mobilePatrols}_DELETE`);

export const deselectMobilePatrols = createAction(`${constants.mobilePatrols}_DESELECT`);

export const requestMobilePatrols = mobilePatrolsRequest;
export const requestMobilePatrolInfo = mobilePatrolInfoRequest;
export const updateMobilePatrolsStatus = updateMobilePatrolsStatusRequest;
export const createMobilePatrols = createMobilePatrolsRequest;
export const updateMobilePatrols = updateMobilePatrolsRequest;
export const deleteMobileUnit = deleteMobileUnitRequest;
