import preparedReducer from './reducer';
import { requestNotebookNoteTags, updateNotebookNoteTags } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'notebookNoteTags';

export const selectors = state => state[SLICE_NAME];
export const getSiteNotebookNoteTags = (state, siteID) => state.notebookNoteTags[siteID];

export const actions = {
  requestNotebookNoteTags,
  updateNotebookNoteTags,
};
