import preparedReducer from './reducer';
import { requestActivityIR } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'activityIR';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestActivityIR,
};
