import {all, put, takeLatest} from 'redux-saga/effects';

import { dashboardFailure, dashboardRequest, dashboardSuccess } from './actions';
import axios from 'axios';
import { DASHBOARD_V2 } from 'app/api-routes';
import { DATE_TIME_FORMAT } from 'app/types/constants';
import moment from 'moment';
import _ from 'lodash';

const isLocalTest = false;

function* dashboardSaga() {
  try {
    const { payload, warnings } = yield axios.get(DASHBOARD_V2);

    yield put(dashboardSuccess(_.cloneDeep({ ...payload, ...(isLocalTest ? {
      "mobilePatrolActivities": [
        {
          "mobilePatrolID": 1,
          "name": "MP #1",
          "latitude": 32.1231231 + Math.random(),
          "longitude": 41.13445123 + Math.random(),
          "dateCreated": moment().format(DATE_TIME_FORMAT),
        }
      ],
    } : {})}), { warnings }));
  } catch (err) {
    yield put(dashboardFailure(err));
  }
}

export default function*() {
  yield all([yield takeLatest(dashboardRequest, dashboardSaga)]);
}
