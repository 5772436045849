import { CODES } from 'app/api-routes';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { codesFailure, codesRequest, codesSuccess, codesUpdateRequest } from './actions';

function* codesSaga({ payload: type }) {
  try {
    const { payload } = yield axios.get(CODES, {
      params: {
        type,
        sortingMethod: 'D',
      },
    });

    yield put(
      codesSuccess({
        type,
        codes: payload,
      }),
    );
  } catch (err) {
    yield put(codesFailure(err));
  }
}

function* codesUpdateSaga({ payload: { type, data } }) {
  try {
    yield axios.put(CODES, { payload: data }, {
      params: {
        type,
      }
    });

    yield call(codesSaga, { payload: type });
  } catch (err) {
    yield put(codesFailure(err));
  }
}

export default function*() {
  yield takeEvery(codesRequest, codesSaga);
  yield takeEvery(codesUpdateRequest, codesUpdateSaga);
}
