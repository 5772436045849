import preparedReducer from './reducer';
import { requestEmployeeInfo } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'employeeInfo';

export const selectors = state => state[SLICE_NAME];
export const getEmployeeInfo = id => state => state[SLICE_NAME][id];

export const actions = {
  requestEmployeeInfo,
};
