import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const uploadMediaAssetRequest = createRequest(constants.uploadMediaAsset);
export const uploadMediaAssetSuccess = createSuccess(constants.uploadMediaAsset);
export const uploadMediaAssetFailure = createFailure(constants.uploadMediaAsset);
export const uploadMediaAsset = uploadMediaAssetRequest;

export const bulkUploadMediaAssetsRequest = createRequest(constants.bulkUploadMediaAssets);
export const bulkUploadMediaAssetsSuccess = createSuccess(constants.bulkUploadMediaAssets);
export const bulkUploadMediaAssetsFailure = createFailure(constants.bulkUploadMediaAssets);
export const bulkUploadMediaAssets = bulkUploadMediaAssetsRequest;

export const updateMediaAssetRequest = createRequest(constants.updateMediaAsset);
export const updateMediaAssetSuccess = createSuccess(constants.updateMediaAsset);
export const updateMediaAssetFailure = createFailure(constants.updateMediaAsset);
export const updateMediaAsset = updateMediaAssetRequest;

export const deleteMediaAssetRequest = createRequest(constants.deleteMediaAsset);
export const deleteMediaAssetSuccess = createSuccess(constants.deleteMediaAsset);
export const deleteMediaAssetFailure = createFailure(constants.deleteMediaAsset);
export const deleteMediaAsset = deleteMediaAssetRequest;

export const mediaAssetDescriptionRequest = createRequest(constants.mediaAssetDescription);
export const mediaAssetDescriptionSuccess = createSuccess(constants.mediaAssetDescription);
export const mediaAssetDescriptionFailure = createFailure(constants.mediaAssetDescription);
export const requestMediaAssetDescription = mediaAssetDescriptionRequest;

