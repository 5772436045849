import React, { useCallback, useMemo } from 'react';
import { Selector } from '../Selector';
import moment from 'moment';
import { useSelector } from 'react-redux';
import * as settings from 'ducks/settings';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const MonthPicker = ({ date, name, infinityEndDate, dateChange, changeMonth }) => {
  const { locale } = useSelector(settings.selectors);

  const m = moment().locale(locale.locale);
  const months = useMemo(
    () =>
      (m.localeData()._months?.standalone || m.localeData()._months)
        .map(capitalizeFirstLetter)
        .map((option, index) => ({
          option,
          index,
          disabled: !infinityEndDate
            ? moment(date).year() === moment().year() && index > moment().month()
            : false,
        })),
    [infinityEndDate, date]
  );

  const handleChange = useCallback(
    (selectedMonth) => {
      const tMonth = months.find(({ option }) => option === selectedMonth).index;
      changeMonth(tMonth);
      dateChange(moment(date).set('month', tMonth).toDate());
    },
    [months, infinityEndDate, date]
  );

  return (
    <Selector
      options={months}
      value={months[moment(date).month()].option}
      name={`${name}-month`}
      onChange={handleChange}
    />
  );
};
