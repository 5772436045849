import React, { useCallback } from 'react';
import { Box, InputLabel } from '@material-ui/core';
import { Field } from 'formik';
import { TextInput } from '..';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      inputRoot: {
        '& .error': {
          fontSize: 12,
        },
      },
      boxRoot: {
        width: '100%',
      },
      smallInput: {
        '& .MuiInputBase-root': {
          height: 48,
          [theme.breakpoints.down('lg')]: {
            maxHeight: 40,
            minHeight: 40,
          },
          '& input': {
            padding: '2.5px 4px !important',
          },
        },
      },
    }),
  {
    name: 'FormTextField',
  }
);

const FormTextField = ({ label, topLabel, name, type, className, onBlur, size, ...rest }) => {
  const classes = useStyles();

  const handleBlur = useCallback(
    (fieldOnBlur) => (e) => {
      fieldOnBlur(e);
      onBlur?.(e);
    },
    []
  );

  return (
    <Box className={clsx(classes.boxRoot, className)}>
      {!!topLabel && (
        <Box mb={1}>
          <InputLabel htmlFor={name}>{topLabel}</InputLabel>
        </Box>
      )}
      <Field name={name}>
        {({ field, meta }) => (
          <Box
            className={clsx({
              [classes.inputRoot]: label,
            })}
          >
            <TextInput
              type={type}
              {...field}
              {...rest}
              onBlur={handleBlur(field.onBlur)}
              error={!!meta.error}
              helperText={meta.error}
              label={label}
              className={clsx({
                [classes.smallInput]: size === 'small',
              })}
            />
          </Box>
        )}
      </Field>
    </Box>
  );
};

export default FormTextField;
