import React from 'react';
import * as Yup from 'yup';
import IntlMessages from 'util/IntlMessages';

export const SignInSchema = Yup.object().shape({
  company: Yup.string()
    .min(2, <IntlMessages id="form_short_string" />)
    .max(50, <IntlMessages id="form_long_string" />)
    .required(<IntlMessages id="form_required" />),
  user: Yup.string()
    .min(2, <IntlMessages id="form_short_string" />)
    .max(50, <IntlMessages id="form_long_string" />)
    .required(<IntlMessages id="form_required" />),
  password: Yup.string()
    .required(<IntlMessages id="form_required" />)
});
