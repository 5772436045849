import {createSelector} from 'reselect/lib/index';

import {initialState, STATES} from './states';

const cache = [];
export const getActionStatus = actions => {
  actions = normalizeActions(actions);
  const single = actions.length === 1;

  const cacheItem = cache.find(item => arraysMatch(item.actions, actions));
  if (cacheItem) {
    return cacheItem.selector;
  }

  const newCacheItem = {
    actions,
    filterRequests: [],
  };

  const getRequests = state => {
    const result = filterRequests(state.requests, actions);
    if (arraysMatch(result, newCacheItem.filterRequests)) {
      return newCacheItem.filterRequests;
    }

    newCacheItem.filterRequests = result;

    return result;
  };

  newCacheItem.selector = createSelector(
    getRequests,
    requests =>
      single ? extendRequest(requests.slice(0)[0], actions) : extendRequest(mergeRequests(requests.slice(0)), actions),
  );

  cache.push(newCacheItem);

  return newCacheItem.selector;
};

const arraysMatch = (array1, array2) =>
  array1.length === array2.length && array1.every((element, index) => element === array2[index]);

const filterRequests = (requestsStore, keys) => keys.map(key => (key && requestsStore[key]) || initialState);

const normalizeActions = actions => {
  actions = Array.isArray(actions) ? actions : [actions];
  return actionsToStr(actions);
};

function actionsToStr(actions) {
  return actions.map(action => {
    const {actionID} = parseAction(action) || {};
    if (!actionID) {
      console.error(`Wrong action: ${action}`);
    }
    return actionID;
  });
}

const extendRequest = (request, actions) => ({
  ...request,
  action: actions,
  requested: STATES.enumValueOf(request.requestState) === STATES.REQUEST,
  hasErrors: request.errors.length > 0,
  hasWarnings: request.warnings.length > 0,
});

const mergeRequests = requests => {
  const result = {
    ...requests.shift(),
  };

  requests.forEach(item => {
    if (STATES.enumValueOf(item.requestState).ordinal < STATES.enumValueOf(result.requestState).ordinal) {
      result.requestState = item.requestState;
    }
    result.errors = result.errors.concat(item.errors);
    result.warnings = result.warnings.concat(item.warnings);
  });

  return result;
};

const states = STATES.enumValues.map(item => item.name);
states.shift();
const regex = new RegExp(`(.*)_(${states.join('|')})`);

export const parseAction = action => {
  const type = `${action}`;
  const matches = regex.exec(type);

  if (!matches) {
    return undefined;
  }

  const [, actionID, requestState] = matches;

  return {actionID, requestState};
};
