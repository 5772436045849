const Types = {
  EVENT_TYPES: 'EVENT TYPES',
  INCIDENT_TYPES: 'INCIDENT TYPES',
  INFO_NOTE_CATEGORIES: 'INFO NOTE CATEGORIES',
  NOTEBOOK_TAGS: 'NOTEBOOK TAGS',
  ALARM_CATEGORIES: 'ALARM CATEGORIES',
  COUNTRIES: 'COUNTRIES',
  CONTACT_TYPES: 'CONTACT TYPES',
  POSITIONS: 'POSITIONS',
  TITLES: 'TITLES',
  END_SHIFT_REASONS: 'END SHIFT REASONS',
  CALL_CATEGORIES: 'CALL CATEGORIES',
  CATEGORIES: 'CATEGORIES',
  LABELS: 'LABELS',
  VISIT_PURPOSES: 'VISIT PURPOSES',
};

export default Types;
