import { all, put, takeLatest } from 'redux-saga/effects';
import { formatDateTimeUTC, formatMonthIntervalUTC } from 'util/formatting';
import { tsByMobilePatrolFailure, tsByMobilePatrolRequest, tsByMobilePatrolSuccess } from './actions';
import { TIMESHEETS_BY_MOBILE_PATROL } from 'app/api-routes';
import axios from 'axios';
import { SERVER_DATE_TIME_FORMAT } from 'app/types/constants';

function* tsByMobilePatrolSaga({ payload: { date, startDate, endDate } }) {
  let fromDate, toDate;
  if (startDate != null && endDate != null) {
    fromDate = formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT);
    toDate = formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT);
  } else {
    [fromDate, toDate] = formatMonthIntervalUTC(date);
  }

  try {
    const { payload } = yield axios.get(TIMESHEETS_BY_MOBILE_PATROL, {
      params: {
        fromDate,
        toDate,
      }
    });

    yield put(tsByMobilePatrolSuccess(payload));
  } catch (err) {
    yield put(tsByMobilePatrolFailure(err));
  } 
}

export default function* () {
    yield all([yield takeLatest(tsByMobilePatrolRequest, tsByMobilePatrolSaga)]);
}
