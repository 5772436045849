import { AUDIT } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import {
  auditLogRequest,
  auditLogSuccess,
  auditLogFailure,
  auditEventRequest,
  auditEventSuccess,
  auditEventFailure,
} from './actions';
import { formatDateTimeUTC } from 'util/formatting';
import { ALL, SERVER_DATE_TIME_FORMAT } from 'app/types/constants';
import { ENTITY_TYPES } from './types';

const mapTableNameToList = (tableName) => {
  if (tableName === ENTITY_TYPES.EMPLOYEES) {
    return [ENTITY_TYPES.EMPLOYEES, ENTITY_TYPES.EMPLOYEE_USERS];
  } else if (tableName === ENTITY_TYPES.CONTACTS) {
    return [ENTITY_TYPES.CONTACTS, ENTITY_TYPES.CONTACT_USERS];
  } else {
    return [tableName];
  }
};

function* auditLogSaga({ payload: { fromDate, toDate, tableName, entityID, userID } }) {
  try {
    const params = {};

    if (fromDate && toDate) {
      params.fromDate = formatDateTimeUTC(fromDate, SERVER_DATE_TIME_FORMAT);
      params.toDate = formatDateTimeUTC(toDate, SERVER_DATE_TIME_FORMAT);
    }

    if (tableName && tableName !== ALL) {
      params.tableNames = mapTableNameToList(tableName);
    }

    if (entityID) {
      params.entityID = entityID;
    }

    if (userID) {
      params.userID = userID;
    }

    const { payload } = yield axios.get(AUDIT, { params });

    yield put(auditLogSuccess(payload));
  } catch (err) {
    yield put(auditLogFailure(err));
  }
}

function* auditEventSaga({ payload: eventID }) {
  try {
    const { payload } = yield axios.get(`${AUDIT}/${eventID}`);

    yield put(auditEventSuccess(payload));
  } catch (err) {
    yield put(auditEventFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(auditLogRequest, auditLogSaga),
    yield takeLatest(auditEventRequest, auditEventSaga),
  ]);
}
