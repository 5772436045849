import preparedReducer from './reducer';
import {
  requestFormResults,
  requestFormResultInfo,
  submitFormResult,
  approveFormResult,
  updateFormResult,
  deleteFormResult,
  requestFormResultReport,
  requestExcelFormResults,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'formResults';

export const selectors = (state) => state[SLICE_NAME];

export const selectFormResultInfo = (state, formResultID) => state[SLICE_NAME].info[formResultID];

export const actions = {
  requestFormResults,
  requestFormResultInfo,
  submitFormResult,
  approveFormResult,
  updateFormResult,
  deleteFormResult,
  requestFormResultReport,
  requestExcelFormResults,
};
