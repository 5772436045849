import preparedReducer from './reducer';
import { 
  requestContacts,
  createContact,
  updateContact,
  deleteContact,
  getContact,
  updateContactForm,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'contacts';

export const selectors = state => state[SLICE_NAME];

export const getContactInfoById = (id) => (state) => state[SLICE_NAME]?.info[id];

export const actions = {
  requestContacts,
  createContact,
  updateContact,
  deleteContact,
  getContact,
  updateContactForm,
};
