import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    color: '#42BD5D',
    fontSize: 16,
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    }
  }
}), {
  name: 'InProgressText'
});

const InProgressText = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} style={{  }}>
      <IntlMessages id="cp.shift.in_progress" />
    </Typography>
  );
}

export { InProgressText };
