import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const visitLogRequest = createRequest(constants.visitLog);
export const visitLogSuccess = createSuccess(constants.visitLog);
export const visitLogFailure = createFailure(constants.visitLog);
export const requestVisitLog = visitLogRequest;

export const visitReportLinkRequest = createRequest(constants.visitReportLink);
export const visitReportLinkSuccess = createSuccess(constants.visitReportLink);
export const visitReportLinkFailure = createFailure(constants.visitReportLink);
export const requestVisitReportLink = visitReportLinkRequest;
