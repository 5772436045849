import constants from './constants';
import { handleActions } from 'redux-actions';
import { createAction } from '../helpers';
import initialSettings from './states';

const rltLocale = ['ar'];

export default handleActions(
  {
    [createAction('@@router/LOCATION_CHANGE')](state) {
      return {
        ...state,
        navCollapsed: false
      };
    },
    [createAction(constants.toggleCollapsedNav)](state, { payload }) {
      return {
        ...state,
        navCollapsed: payload //   navCollapsed: action.isNavCollapsed
      };
    },
    [createAction(constants.themeColor)](state, { payload }) {
      return {
        ...state,
        darkTheme: false,
        themeColor: payload // action.color
      };
    },
    [createAction(constants.darkTheme)](state) {
      return {
        ...state,
        darkTheme: !state.darkTheme
      };
    },
    [createAction(constants.windowWidth)](state, { payload }) {
      return {
        ...state,
        width: payload // action.width
      };
    },
    [createAction(constants.switchLanguage)](state, { payload }) {
      return {
        ...state,
        locale: payload,
        isDirectionRTL: rltLocale.includes(payload.locale)
      };
    },
    [createAction(constants.changeDirection)](state) {
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL
      };
    },
    [createAction(constants.changeNavigationStyle)](state, { payload }) {
      return {
        ...state,
        navigationStyle: payload
      };
    },
    [createAction(constants.horizontalMenuPosition)](state, { payload }) {
      return {
        ...state,
        horizontalNavPosition: payload
      };
    },
  },
  initialSettings
);
