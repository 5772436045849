import isEqual from 'react-fast-compare';
import { handleActions } from 'redux-actions';


import { tsByClientSuccess } from './actions';
import { logoutSuccess } from 'ducks/auth/actions';
import initialState from './states';

export default handleActions(
  {
    [tsByClientSuccess](state, { payload }) {
      if (isEqual(state, payload)) {
        return state;
      }

      return Object.freeze(payload);
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
