import preparedReducer from './reducer';
import {
  setType,
  setEmployees,
  setActivity,
  setSelectedSite,
  setSelectedShift,
  setActiveOnly,
  mapActivityRequest,
  setSelectedMU,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'activityMap';

export const TAB_SITES = 'sites';
export const TAB_MOBILE_PATROLS = 'mobilePatrols';
export const TAB_EMPLOYEES = 'employees';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  setType,
  setEmployees,
  setActivity,
  setSelectedSite,
  setSelectedShift,
  setActiveOnly,
  setSelectedMU,

  mapActivityRequest,
};
