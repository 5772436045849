import { all, put, takeLatest } from 'redux-saga/effects';
import { attachmentsRequest, attachmentsSuccess, attachmentsFailure } from './actions';
import axios from 'axios';
import { ATTACHMENTS } from 'app/api-routes';

function* attachmentsSaga({ payload: { entityID, tableName } }) {
  try {
    const { payload } = yield axios.get(`${ATTACHMENTS}/${tableName}/${entityID}`);

    yield put(attachmentsSuccess({ entityID, tableName, data: payload }));
  } catch (err) {
    yield put(attachmentsFailure(err));
  }
}

export default function* () {
  yield all([yield takeLatest(attachmentsRequest, attachmentsSaga)]);
}
