import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { Field } from 'formik';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { MultiSelect } from '../MultiSelect';
import { useField } from 'formik';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      inputRoot: {
        marginTop: ({ disabledError }) => (disabledError ? 0 : theme.spacing(1.25)),
        '& .MuiFormControl-root': {
          width: '100%',
        },
      },
      root: {
        '& .MuiFormControl-root': {
          width: '100%',
        },
      },
    }),
  {
    name: 'FormMultiSelectField',
  }
);

export const FormMultiSelectField = ({
  label,
  name,
  dynamicClassName,
  placeholder,
  className,
  fullWidth,
  icon: Icon,
  onBlur,
  options,
  variant,
  readOnly,
  disabled,
  showSelected,
  size,
  withoutIcon,
  renderValue,
}) => {
  const classes = useStyles();
  const [{ value }, , helpers] = useField(name);

  const handleBlur = useCallback(
    (fieldOnBlur) => (e) => {
      fieldOnBlur(e);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  return (
    <Box className={clsx(classes.root, className, dynamicClassName && dynamicClassName(value))}>
      <Field name={name} className={classes.field}>
        {({ field, meta }) => (
          <MultiSelect
            {...field}
            multiple
            label={label}
            variant={variant}
            disabled={disabled}
            fullWidth={fullWidth}
            placeholder={placeholder}
            inputStartIcon={Icon}
            options={options}
            readOnly={readOnly}
            onChange={(value) => {
              helpers.setValue(value);
              helpers.setTouched();
            }}
            onBlur={handleBlur(field.onBlur)}
            defaultValue={value}
            error={!!meta.error}
            helperText={meta.error}
            showSelected={showSelected}
            size={size}
            withoutIcon={withoutIcon}
            renderValue={renderValue}
          />
        )}
      </Field>
    </Box>
  );
};
