import preparedReducer from './reducer';
import {
  requestSiteInfo,
  requestUpdateSiteStatus,
  requestReminders,
  requestMakeAllMasterCheckpoint,
  requestRemoveAllMasterCheckpoint,
  requestDeleteAllCheckpoints,
  updateReminders,
  updateNote,
  deleteSite,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'siteInfo';

export const selectors = (state) => state[SLICE_NAME];
export const selectSiteInfo = (state, siteID) => state[SLICE_NAME][siteID];

export const actions = {
  requestSiteInfo,
  requestReminders,
  requestUpdateSiteStatus,
  requestMakeAllMasterCheckpoint,
  requestRemoveAllMasterCheckpoint,
  requestDeleteAllCheckpoints,
  updateReminders,
  updateNote,
  deleteSite,
};
