import preparedReducer from './reducer';
import constants from './constants';
import { requestAccountInfo } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = constants.accountInfo;

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestAccountInfo,
};
