// import request from 'util/request';

export default class HelpService {
  static getHelp() {
    return Promise.reject();
    //  request.get('/api/common/help', {
    //   sessionID,
    // });
  }
}
