import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const notebookNoteTagsRequest = createRequest(constants.notebookNoteTags);
export const notebookNoteTagsSuccess = createSuccess(constants.notebookNoteTags);
export const notebookNoteTagsFailure = createFailure(constants.notebookNoteTags);

export const updateNotebookNoteTagsRequest = createRequest(constants.updateNotebookNoteTags);
export const updateNotebookNoteTagsSuccess = createSuccess(constants.updateNotebookNoteTags);
export const updateNotebookNoteTagsFailure = createFailure(constants.updateNotebookNoteTags);

export const requestNotebookNoteTags = notebookNoteTagsRequest;
export const updateNotebookNoteTags = updateNotebookNoteTagsRequest;
