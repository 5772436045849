import React from 'react';
import { Route } from 'react-router-dom';
// import { Switch } from 'react-router';
// TODO: refactoring.
import asyncComponent from 'util/asyncComponent';

const InformationRoutes = ({ match }) =>
  <>
    <Route path={`${match.url}/user-manual`} exact component={(asyncComponent(() => import('../pages/information/UserManual')))}/>
    <Route path={`${match.url}/terms`} exact component={(asyncComponent(() => import('../pages/information/Terms')))}/>
    <Route path={`${match.url}/policy`} exact component={(asyncComponent(() => import('../pages/information/Privacy')))}/>
  </>;

export { InformationRoutes };
