import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const activityRequest = createRequest(constants.activity);
export const activitySuccess = createSuccess(constants.activity);
export const activityFailure = createFailure(constants.activity);

export const requestActivity = activityRequest;

export const activityByShiftIDRequest = createRequest(`${constants.activity}_GET_BY_SHIFT_ID`);
export const activityByShiftIDSuccess = createSuccess(`${constants.activity}_GET_BY_SHIFT_ID`);
export const activityByShiftIDFailure = createFailure(`${constants.activity}_GET_BY_SHIFT_ID`);

export const resetGPSAlertRequest = createRequest(`${constants.activity}_RESET_GPS_ALERT`);

export const requestByShiftIDActivity = activityByShiftIDRequest;
