import {put, select, takeLatest} from 'redux-saga/effects';

import service from './help.service';

import {helpFailure, helpRequest, helpSuccess} from './help.actions';

function* helpSaga() {
  const {sessionID} = yield select(state => state.auth);

  try {
    const {payload, warnings} = yield service.getHelp({sessionID});

    yield put(helpSuccess(payload, {warnings}));
  } catch (err) {
    yield put(helpFailure(err));
  }
}

export default function*() {
  yield takeLatest(helpRequest, helpSaga);
}
