import {handleActions} from 'redux-actions';
import isEqual from 'react-fast-compare';

import {helpSuccess} from './help.actions';
import initialState from './help.states';

export default handleActions(
  {
    [helpSuccess](state, {payload}) {
      if (isEqual(state, payload)) {
        return state;
      }

      return Object.freeze(payload);
    },
  },
  initialState,
);
