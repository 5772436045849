import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  clientEmailSettingsRequest,
  clientEmailSettingsSuccess,
  clientEmailSettingsFailure,
  enableClientEmailSettingRequest,
  enableClientEmailSettingFailure,
  disableClientEmailSettingRequest,
  disableClientEmailSettingFailure,
  clientEmailRecipientsSuccess,
  clientEmailRecipientsFailure,
  clientEmailRecipientsRequest,
  updateClientEmailRecipientsRequest,
  updateClientEmailRecipientsSuccess,
  updateClientEmailRecipientsFailure,
} from './actions';
import axios from 'axios';
import { CLIENTS } from 'app/api-routes';

function* clientEmailSettingsSaga({ payload: clientID }) {
  try {
    const { payload } = yield axios.get(`${CLIENTS}/${clientID}/email-settings`);
    yield put(clientEmailSettingsSuccess({ clientID, settings: payload }));
  } catch (err) {
    yield put(clientEmailSettingsFailure(err));
  }
}

function* enableClientEmailSettingSaga({ payload: { clientID, type } }) {
  try {
    yield axios.put(`${CLIENTS}/${clientID}/email-settings/${type}/enable`);
    yield call(clientEmailSettingsSaga, { payload: clientID });
  } catch (err) {
    yield put(enableClientEmailSettingFailure(err));
  }
}

function* disableClientEmailSettingSaga({ payload: { clientID, type } }) {
  try {
    yield axios.put(`${CLIENTS}/${clientID}/email-settings/${type}/disable`);
    yield call(clientEmailSettingsSaga, { payload: clientID });
  } catch (err) {
    yield put(disableClientEmailSettingFailure(err));
  }
}

function* clientEmailRecipientsSaga({ payload: { clientID, type } }) {
  try {
    const { payload } = yield axios.get(`${CLIENTS}/${clientID}/email-recipients`, { params: { type } });
    yield put(clientEmailRecipientsSuccess({ recipients: payload }));
  } catch (err) {
    yield put(clientEmailRecipientsFailure(err));
  }
}

function* updateClientEmailRecipientsSaga({ payload: { clientID, type, values } }) {
  try {
    yield axios.post(
      `${CLIENTS}/${clientID}/email-recipients`,
      { payload: values },
      {
        params: { type },
      }
    );

    yield call(clientEmailSettingsSaga, { payload: clientID });
    yield put(updateClientEmailRecipientsSuccess({ clientID, type, payload: values }));
  } catch (err) {
    yield put(updateClientEmailRecipientsFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(clientEmailSettingsRequest, clientEmailSettingsSaga),
    yield takeLatest(enableClientEmailSettingRequest, enableClientEmailSettingSaga),
    yield takeLatest(disableClientEmailSettingRequest, disableClientEmailSettingSaga),
    yield takeLatest(clientEmailRecipientsRequest, clientEmailRecipientsSaga),
    yield takeLatest(updateClientEmailRecipientsRequest, updateClientEmailRecipientsSaga),
  ]);
}
