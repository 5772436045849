import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import isEqual from 'react-fast-compare';

import { shiftInfoSuccess, shiftStatsSuccess, shiftStatsBySiteIDSuccess, getShiftReportLinkSuccess } from './actions';
import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return initialState;
    },
    [shiftInfoSuccess](state, { payload }) {
      if (isEqual(state.info[payload.shiftID], payload)) {
        return state;
      }

      return {
        ...state,
        info: {
          ...state.info,
          [payload.shiftID]: Object.freeze(payload),
        }
      };
    },
    [shiftStatsSuccess](state, { payload }) {
      return {
        ...state,
        stats: {
          ...state.stats,
          [payload.shiftID]: Object.freeze(payload),
        }
      };
    },
    [shiftStatsBySiteIDSuccess](state, { payload }) {

      return {
        ...state,
        activeMapLastShiftID: payload
      };
    },
    [getShiftReportLinkSuccess](state, { payload }) {
      return {
        ...state,
        shiftReportLinks: {
          ...state.shiftReportLinks,
          [payload.shiftID]: payload.link
        }
      };
    },
  },
  initialState,
);
