import React, { useCallback, useMemo, useState } from 'react';
import MUAutocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';
import { TextInput } from '.';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      inputRoot: {
        '& .MuiInputBase-root': {
          paddingRight: '35px !important',
          background: theme.palette.common.white,
          '& svg path': {
            fill: '#8A8A8A',
          },
          '& svg': {
            [theme.breakpoints.down('lg')]: {
              width: 20,
              height: 20,
            },
          },
          [theme.breakpoints.down('lg')]: {
            paddingRight: '24px !important',
            paddingLeft: '14px !important',
          },
        },
      },
      smallInput: {
        '& .MuiInputBase-root': {
          paddingTop: '9px !important',
          paddingBottom: '9px !important',
          paddingLeft: '12px !important',
          paddingRight: '24px !important',
          minHeight: 48,
          [theme.breakpoints.down('lg')]: {
            maxHeight: 40,
            minHeight: 40,
          },
        },
        '& .MuiInputBase-root input': {
          padding: '2.5px 4px !important',
        },
      },
      label: {
        fontSize: 16,
        color: theme.palette.secondary.main,
        lineHeight: '24px',
        fontWeight: 'normal',
        marginBottom: 10,
        [theme.breakpoints.down('lg')]: {
          fontSize: 14,
          lineHeight: '21px',
          marginBottom: 5,
        },
      },
    }),
  {
    name: 'Autocomplete',
  }
);

const Autocomplete = ({
  options,
  defaultValue,
  label,
  placeholder,
  icon,
  onChange,
  readOnly,
  error,
  helperText,
  value,
  size,
  ...rest
}) => {
  const classes = useStyles();
  const _defaultValue = useMemo(() => options?.find((el) => el.key === defaultValue) || null, [options]);
  const _value = useMemo(() => options?.find((el) => el.key === value) || null, [options, value]);
  const [selectedValue, setSelectedValue] = useState(_defaultValue);

  const _handleChange = useCallback(
    (e, newValue) => {
      onChange?.(newValue?.key ?? '');
      setSelectedValue?.(newValue ?? '');
    },
    [onChange]
  );

  if (!options?.length) {
    return null;
  }

  return (
    <>
      <MUAutocomplete
        className={classes.root}
        getOptionLabel={(el) => el?.label}
        renderInput={(params) => (
          <TextInput
            {...params}
            label={label}
            className={clsx(classes.inputRoot, {
              [classes.smallInput]: size === 'small',
            })}
            icon={icon}
            placeholder={placeholder}
            inputProps={{ ...params.inputProps, readOnly }}
            error={error}
            helperText={helperText}
          />
        )}
        {...rest}
        // _value - value from outside
        // _defaultValue - after reload page
        // selectedValue - when selecting smth
        value={_value || selectedValue || _defaultValue}
        clearOnBlur={false}
        defaultValue={_defaultValue}
        options={options || []}
        onChange={_handleChange}
        popupIcon={null}
      />
    </>
  );
};

export { Autocomplete };
