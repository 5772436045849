import preparedReducer from './reducer';
import { requestVisitLog, requestVisitReportLink } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'visits';

export const selectors = state => state[SLICE_NAME];
export const getVisitLog = (state, visitID) => state.visits.log[visitID];

export const actions = {
  requestVisitLog,
  requestVisitReportLink,
};
