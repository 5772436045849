import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const statsPerDateRequest = createRequest(constants.statPerDate);

export const statsPerDateSuccess = createSuccess(constants.statPerDate);

export const statsPerDateFailure = createFailure(constants.statPerDate);

export const requestStatsPerDate = statsPerDateRequest;
