import React, { forwardRef } from 'react';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => createStyles(
  {
    input: {
      cursor: 'pointer',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: '#222',
    },
  }
), {
  name: 'CustomDateInput',
});

// eslint-disable-next-line react/display-name
export const CustomInput = forwardRef(({ value, onClick }, ref) => {
  const classes = useStyles();

  return (
  <div className={classes.input} onClick={onClick} ref={ref}>
    {value}
  </div>
)});
