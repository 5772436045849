import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles(
  {
    root: {
      overflow: 'auto',
      width: '100%',
      backgroundColor: '#F7F8FB',
    },
    headerBlock: {
      width: '100%',
      position: 'sticky',
      top: 0,
      background: theme.palette.common.white,
      backgroundColor: '#F7F8FB',
      zIndex: 999,
    },
    contentBlock: {
      backgroundColor: '#F7F8FB',
      padding: '0 30px',
      position: 'relative',
      paddingTop: 12,
      overflowX: 'hidden',
      height: 'calc(100% - 97px)',
      [theme.breakpoints.down('lg')]: {
        height: 'calc(100% - 74px)',
      }
    },
    childrenContent: {
      // height: '100%',
      marginBottom: 20,
    }
  }
), {
  name: 'PublicLayout',
});

export { useStyles };
