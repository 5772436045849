import React from 'react';
import { ConfirmationModal } from 'new-design/components/ConfirmationModal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as auth from 'ducks/auth';
import { useEffect } from 'react';
import IntlMessages from 'util/IntlMessages';
import { bindActionCreators } from 'redux';
import { CLIENT_TYPE } from 'app/types/constants';
import { useHistory, useLocation } from 'react-router';
import config from 'config';

export const TermsAgreement = () => {
  const history = useHistory();
  const location = useLocation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { termsInfo, userInfo, sessionID } = useSelector(auth.selectors);
  const { logout, acceptAgreement } = bindActionCreators(auth.actions, useDispatch());

  useEffect(() => {
    if (sessionID) {
      if (termsInfo?.acceptRequired && !termsInfo?.termsChanged) {
        setShowConfirmModal(true);
      } else {
        setShowConfirmModal(false);
        if (localStorage.getItem('app') === 'legacy' && userInfo.userType !== CLIENT_TYPE) {
          window.location.href = `/legacy/index.html#session?sessionID=${sessionID}&serverURL=${config.serverURL}`;
        } else {
          history.push(location.state?.from ? `${location.state?.from?.pathname}${location.state?.from?.search || ''}${location.state?.from?.hash || ''}` : '/app/control-panel');
        }
      }
    }
  }, [termsInfo, sessionID, location.state]);

  return (
    <>
      <ConfirmationModal
        modalOpened={showConfirmModal}
        title={<IntlMessages id="terms_agreement_title" />}
        customCancelMessage={<IntlMessages id="decline" />}
        customConfirmMessage={<IntlMessages id="accept" />}
        description={
          <IntlMessages
            id="terms_agreement_description"
            values={
              {
                terms_link: (
                  <a href='/terms' target="blank">
                    <IntlMessages id="terms" />
                  </a>
                ),
                policy_link: (
                  <a href='/policy' target="blank">
                    <IntlMessages id="privacy_policy" />
                  </a>
                )
              }
            }
          />
        }
        onModalCancel={() => {
          setShowConfirmModal();
          logout();
        }}
        onModalConfirm={() => {
          acceptAgreement(userInfo?.userID);
        }}
      />
    </>
  )
}
