/* eslint-disable no-unused-vars */
import { all, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import {
  notificationsFailure,
  notificationsRequest,
  notificationsSuccess,
} from './actions';
import axios from 'axios';
import { NOTIFICATIONS } from 'app/api-routes';

const mockData = [
  {
    notificationId: 1,
    siteId: 1,
    siteName: "Test",
    userId: 1,
    fullName: "Peter Parker",
    position: "Guard",
    type: "inactivity",
    tableName: "shift_log",
    entityId: 1234,
    description: "Alarm Fire",
    dateCreated: "2022-01-01 00:00:00",
  },
  {
    notificationId: 2,
    siteId: 1,
    siteName: "Test",
    userId: 1,
    fullName: "Peter Parker",
    position: "Guard",
    type: "alarm",
    tableName: "shift_log",
    entityId: 1234,
    description: "Alarm Fire",
    dateCreated: "2022-01-01 00:00:00",
  },
  {
    notificationId: 3,
    siteId: 1,
    siteName: "Test",
    userId: 1,
    fullName: "Peter Parker",
    position: "Guard",
    type: "alarm",
    tableName: "shift_log",
    entityId: 1234,
    description: "Alarm Fire",
    dateCreated: "2022-01-01 00:00:00",
  },
  {
    notificationId: 4,
    siteId: 1,
    siteName: "Test",
    userId: 1,
    fullName: "Peter Parker",
    position: "Guard",
    type: "alarm",
    tableName: "shift_log",
    entityId: 1234,
    description: "Alarm Fire",
    dateCreated: "2022-01-01 00:00:00",
  }
];

const currentNotifications = {
  current: [],
};

function* notificationsSaga() {
  try {
    const { payload } = yield axios.get(`${NOTIFICATIONS}`);
    const ids = payload.map(({ notificationId }) => notificationId);

    if (ids.reduce((acc, id) => acc || !currentNotifications.current.includes(id), false)) {
      document.getElementById('sound_beep').play();
    }

    if (ids.length) {
      currentNotifications.current = [...(_.uniq([...currentNotifications.current, ...ids]))];
    } else {
      currentNotifications.current = [];
    }

    yield put(notificationsSuccess(payload));

    // yield put(notificationsSuccess(mockData));
  } catch (err) {
    yield put(notificationsFailure(err));
  }
}

export default function*() {
  yield all([
    yield takeLatest(notificationsRequest, notificationsSaga),
  ]);
}
