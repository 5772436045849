import { handleActions } from 'redux-actions';
import { companySuccess, enableVisitorLogSuccess, disableVisitorLogSuccess } from './actions';
import { logoutSuccess } from 'ducks/auth/actions';

import initialState from './states';

export default handleActions(
  {
    [companySuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.companyID]: payload,
        },
      };
    },
    [enableVisitorLogSuccess](state, { payload: companyID }) {
      return {
        ...state,
        info: {
          ...state.info,
          [companyID]: {
            ...state.info[companyID],
            visitorLogEnabled: true,
          },
        },
      };
    },
    [disableVisitorLogSuccess](state, { payload: companyID }) {
      return {
        ...state,
        info: {
          ...state.info,
          [companyID]: {
            ...state.info[companyID],
            visitorLogEnabled: false,
          },
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
