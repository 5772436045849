/* eslint-disable react/display-name */
import React, { useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useStyles } from '../styles';

const LanguageItem = React.forwardRef(({ language, switchLanguage, handleRequestClose }, ref) => {
  const classes = useStyles();
  const { name } = language;

  const handleLangChange = useCallback(() => {
    handleRequestClose();
    switchLanguage(language);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, switchLanguage]);

  return (
    <MenuItem onClick={handleLangChange} className={classes.itemsInCenter} innerRef={ref}>
      <ListItemText primary={name} />
    </MenuItem>
  );
});

export default LanguageItem;
