import { ANALYTICS_ENTITIES_SUMMARY, ANALYTICS_EVENTS_SUMMARY, ANALYTICS_ENTITY_OVERVIEW, ANALYTICS_EVENT_OVERVIEW } from 'app/api-routes';
import axios from 'axios';
import { put, all, takeLatest } from 'redux-saga/effects';
import {
  entitiesSummaryFailure,
  entitiesSummaryRequest,
  entitiesSummarySuccess,
  eventsSummaryFailure,
  eventsSummaryRequest,
  eventsSummarySuccess,
  entityOverviewRequest,
  entityOverviewFailure,
  entityOverviewSuccess,
  eventOverviewFailure,
  eventOverviewRequest,
  eventOverviewSuccess,
} from './actions';

function* entitiesSummarySaga() {
  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_ENTITIES_SUMMARY);
    yield put(entitiesSummarySuccess(payload, { warnings }));
  } catch (err) {
    yield put(entitiesSummaryFailure(err));
  }
}

function* eventsSummarySaga({ payload: { startDate, endDate, siteID } }) {
  const params = { startDate, endDate };

  if (siteID) {
    params.siteID = siteID;
  }

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_EVENTS_SUMMARY, {
      params,
    });
    yield put(eventsSummarySuccess(payload, { warnings }));
  } catch (err) {
    yield put(eventsSummaryFailure(err));
  }
}

function* entityOverviewSaga({ payload: { startDate, endDate, groupBy, entityName } }) {
  const params = { startDate, endDate, groupBy, entityName };

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_ENTITY_OVERVIEW, {
      params,
    });
    yield put(entityOverviewSuccess(payload, { warnings }));
  } catch (err) {
    yield put(entityOverviewFailure(err));
  }
}

function* eventOverviewSaga({ payload: { startDate, endDate, eventType, groupBy, siteID } }) {
  const params = { startDate, endDate, eventType, groupBy };

  if (siteID) {
    params.siteID = siteID;
  }

  try {
    const { payload, warnings } = yield axios.get(ANALYTICS_EVENT_OVERVIEW , {
      params,
    });
    yield put(eventOverviewSuccess(payload, { warnings }));
  } catch (err) {
    yield put(eventOverviewFailure(err));
  }
}

export default function* () {
  yield all([
    takeLatest(eventsSummaryRequest, eventsSummarySaga),
    takeLatest(entitiesSummaryRequest, entitiesSummarySaga),
    takeLatest(entityOverviewRequest, entityOverviewSaga),
    takeLatest(eventOverviewRequest, eventOverviewSaga),
  ]);
}
