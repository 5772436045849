import React from 'react';
import IntlMessages from 'util/IntlMessages';

export const requiredField = (fieldId) => {
  return (
    <span
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {fieldId ? (
        <>
          <IntlMessages id={fieldId} />: <IntlMessages id="required_field" />
        </>
      ) : (
        <IntlMessages id="form_required" />
      )}
    </span>
  );
};
