import preparedReducer from './reducer';
import { requestSchedule, createSchedule, updateSchedule } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'schedules';

export const selectors = (state) => state[SLICE_NAME];

export const actions = {
  requestSchedule,
  createSchedule,
  updateSchedule,
};
