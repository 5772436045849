import preparedReducer from './reducer';
import { requestIrPerDate } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'irPerDate';

export const selectors = state => state[SLICE_NAME];
export const getIrPerDate = (state, siteID) => state.irPerDate[siteID];

export const actions = {
  requestIrPerDate,
};
