import React from 'react';
import { Box, Button } from '@material-ui/core';
import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons";
import { YearPicker } from './header-components/YearPicker';
import { MonthPicker } from './header-components/MonthPicker';

export const CustomHeader = (e) => {
  const {
    name,
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    dateChange,
    changeMonth,
    changeYear,

    infinityEndDate,
  } = e;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1.5}>
      <Button className="month-change-action" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <ArrowLeftIcon />
      </Button>

      <Box display="flex">
        <MonthPicker
          date={date}
          name={name}
          dateChange={dateChange}
          changeMonth={changeMonth}
          infinityEndDate={infinityEndDate}
        />
        <YearPicker
          date={date}
          name={name}
          dateChange={dateChange}
          changeYear={changeYear}
          infinityEndDate={infinityEndDate}
        />
      </Box>

      <Button className="month-change-action" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <ArrowRightIcon />
      </Button>
    </Box>
  );
};
