import storage from 'redux-persist/lib/storage';

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: []
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'settings',
    'credentials',
  ],
};

export { authPersistConfig, persistConfig };
