import React, { cloneElement, isValidElement, useCallback, useEffect, useMemo } from 'react';
import { List, ListItem, Typography, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import NavMenuItem from './NavMenuItem';
import useStyles from './NavCollapse.style';
import clsx from 'clsx';
import { isUrlInChildren } from 'new-design/components/CmtHelpers/JssHelper';
import { ArrowDownIcon } from 'assets/icons';
import { useIntl } from 'util/IntlMessages';
import { Button } from 'new-design/common/ui';

const NavCollapse = props => {
  const history = useHistory();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { name, icon, className, link, children = [], beta = false, } = props;
  const isExpandable = useMemo(() => children.length, [children]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isUrlInChildren(props, history.location.pathname));

    const removeHistoryListening = history.listen(location => {
      setOpen(isUrlInChildren(props, location.pathname));
    });

    return () => {
      removeHistoryListening();
    };
  }, [props, history]);

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  const MenuItemChildren = !!isExpandable && (
    <List component="div" disablePadding className={classes.navCollapseItem}>
      <Box className="submenu">
        {children.map((item, index) => {
          switch (item.type) {
            case 'collapse':
              return <NavCollapse {...item} key={index} className={classes.subCollapse} />;
            case 'item':
              return <NavMenuItem {...item} key={index} />;
            default:
              return null;
          }
        })}
      </Box>
    </List>
  );

  const goToLink = useCallback(() => {
    if (!children.length) {
      history.push(link);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, link]);

  const MenuCollapse = (
    <ListItem
      component="div"
      disableGutters
      className={clsx(classes.navCollapseBtn, 'Cmt-navCollapseBtn', {
        'active': open,
      })}>
      <Button
        component="span"
        className={clsx(classes.navCollapseBtnInner, {
          'active': open
        })}
        onClick={goToLink}
      >
        {renderIcon()}
        <Typography component="span" className={clsx(classes.navText, 'Cmt-name')}>
          {name}
        </Typography>
        {beta && (
          <Typography className={clsx(classes.betaText, 'Cmt-nav-text')}>
            {formatMessage({ id: 'beta' })}
          </Typography>
        )}
        {!!isExpandable && <ArrowDownIcon className={clsx(classes.navArrow, 'Cmt-navIcon')} />}
      </Button>
      {MenuItemChildren}
    </ListItem>
  );

  return (
    <Box
      className={clsx(classes.navCollapse, 'Cmt-navCollapse', {
        [className]: !!className,
        'active': open,
      })}
    >
      {MenuCollapse}
    </Box>
  );
};

export default NavCollapse;
