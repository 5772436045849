import _ from 'lodash';
import * as Yup from 'yup';
import { requiredField } from 'new-design/hooks/useFormErrorHelper';
import {
  TEXT_LINE_INPUT,
  CATEGORY_INPUT,
  CHECKBOXES_INPUT,
  CLIENT_NAME_INPUT,
  COMMENT_LAYOUT,
  DATE_INPUT,
  DATE_TIME_INPUT,
  DROPDOWN_INPUT,
  LABEL_INPUT,
  PHOTOS_INPUT,
  RADIO_BUTTONS_INPUT,
  SIGNATURE_INPUT,
  SITE_NAME_INPUT,
  TEXT_BOX_INPUT,
} from 'app/types/form-builder';
import { v4 as uuidv4 } from 'uuid';
import { getMediaAssetURL } from 'new-design/helpers/getMediaAssetURL';
import moment from 'moment';

export const serializeFields = (fields, idField) =>
  fields.map((field, index) => ({
    ...field,
    [idField]: _.isNumber(field[idField]) ? field[idField] : null,
    sequence: index + 1,
    config: JSON.stringify(field.config),
  }));

export const deserializeFields = (fields) =>
  fields.map((field) => ({
    ...field,
    config: JSON.parse(field.config),
  }));

export const deserializeFieldValue = (value, type, params = {}) => {
  const { sessionID } = params;

  switch (type) {
    case TEXT_LINE_INPUT:
    case TEXT_BOX_INPUT:
    case RADIO_BUTTONS_INPUT:
    case COMMENT_LAYOUT:
    case CATEGORY_INPUT:
    case DROPDOWN_INPUT:
      return value;

    case DATE_INPUT:
    case DATE_TIME_INPUT:
      return value ? moment.utc(value).local().toDate() : undefined;

    case LABEL_INPUT:
      return _.split(value, ';').map((value) => _.toNumber(value));

    case CHECKBOXES_INPUT:
      return _.isEmpty(value) ? [] : _.split(value, ';');

    case PHOTOS_INPUT:
      if (_.isEmpty(value)) {
        return [];
      }

      return _.split(value, ';').map((mediaAssetID) => ({
        mediaAssetID,
        tempID: uuidv4(),
        photo: {
          data_url: getMediaAssetURL(mediaAssetID, sessionID),
        },
      }));

    case SIGNATURE_INPUT: {
      return value ? { dataURL: getMediaAssetURL(Number(value), sessionID) } : {};
    }

    case SITE_NAME_INPUT:
    case CLIENT_NAME_INPUT:
      return Number(value);

    default:
      return null;
  }
};

export const getFieldValidationSchema = ({ type, config }) => {
  switch (type) {
    case CHECKBOXES_INPUT:
      return config.required
        ? Yup.array().required(requiredField(config.label)).min(1, requiredField(config.label))
        : Yup.array();

    default:
      return config.required ? Yup.mixed().required(requiredField(config.label)) : Yup.mixed();
  }
};
