import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Select as MUSelect,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ArrowDownIcon } from 'assets/icons';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      formControlSmall: {
        minWidth: 74,
      },
      arrow: {
        '& path': {
          stroke: theme.palette.common.black,
        },
      },
      smallSelect: {
        borderRadius: 8,
        height: 48,
        '& .MuiSelect-root': {
          paddingTop: '14.5px !important',
          paddingBottom: '14.5px !important',
          paddingLeft: '12px !important',
        },
        [theme.breakpoints.down('lg')]: {
          height: 40,
          '& .MuiSelect-root': {
            paddingTop: '10.5px !important',
            paddingBottom: '10.5px !important',
            paddingLeft: '8px !important',
          },
        },
      },
      menuItem: {
        color: theme.palette.common.black,
        '&.Mui-selected': {
          background: theme.palette.common.white,
          color: theme.palette.primary.main,

          '& path, rect, circle': {
            fill: theme.palette.primary.main,
          },
          '& .only-white': {
            fill: 'white',
          },
        },
      },
      placeholder: {
        fontSize: 16,
        lineHeight: '18px',
        [theme.breakpoints.down('lg')]: {
          fontSize: 14,
        },
      },
      select: {
        backgroundColor: theme.palette.common.white,
      },
      icon: {
        width: 24,
        height: 24,
        '& path, rect, circle': {
          fill: theme.palette.secondary.main,
        },
        '& .only-white': {
          fill: 'white',
        },
        [theme.breakpoints.down('lg')]: {
          width: 20,
          height: 20,
        },
      },
      ml1: {
        marginRight: theme.spacing(1),
      },
    }),
  {
    name: 'Select',
  }
);

const Placeholder = ({ children }) => {
  const classes = useStyles();
  return <Typography className={classes.placeholder}>{children}</Typography>;
};

const Select = ({
  name,
  label,
  size,
  variant,
  options = [],
  onChange,
  defaultValue,
  value,
  placeholder,
  className,
  disabledError,
  error,
  helperText,
  icon: Icon,
  ...rest
}) => {
  const classes = useStyles({ disabledError });

  const inputId = useMemo(() => {
    if (!name) return '';
    return name.replace(' ', '_').toLowerCase();
  }, [name]);

  const _handleChange = useCallback(
    (event) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange]
  );

  const { icon: SelectedIcon, label: selectedLabel } = useMemo(
    () => options?.find((option) => option.key === defaultValue) || {},
    [options, defaultValue]
  );

  const StartAdornmentIcon = useMemo(() => SelectedIcon || Icon, [Icon, SelectedIcon]);

  return (
    <FormControl variant={variant} error={error} className={clsx(className)}>
      {label && (
        <InputLabel error={error} htmlFor={inputId}>
          {label}
        </InputLabel>
      )}
      <MUSelect
        {...rest}
        error={error}
        label={label}
        name={name}
        id={inputId}
        value={defaultValue || value}
        onChange={_handleChange}
        IconComponent={ArrowDownIcon}
        className={clsx(classes.select, {
          [classes.smallSelect]: size === 'small',
        })}
        displayEmpty
        renderValue={
          defaultValue !== '' || value !== ''
            ? () => selectedLabel
            : () => <Placeholder>{placeholder}</Placeholder>
        }
        startAdornment={
          StartAdornmentIcon && (
            <InputAdornment>
              <StartAdornmentIcon className={clsx(classes.icon)} />
            </InputAdornment>
          )
        }
      >
        {options.map(({ key, label, icon: Icon }) => (
          <MenuItem className={classes.menuItem} key={`menu-item-${inputId}-${key}-${name}`} value={key}>
            {Icon && <Icon className={clsx(classes.icon, classes.ml1)} />}
            {label}
          </MenuItem>
        ))}
      </MUSelect>
      {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export { Select };
