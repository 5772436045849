import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { taskLogEventSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return initialState;
    },
    [taskLogEventSuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.taskLogID]: payload,
        },
      };
    },
  },
  initialState
);
