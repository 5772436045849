import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { ArrowDownIcon, ArrowUpIcon } from 'assets/icons';
import { topClientMenu, useTopMenu } from './menus';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import * as auth from 'ducks/auth';
import { isUrlInChildren } from 'new-design/components/CmtHelpers/JssHelper';
import { CLIENT_TYPE } from 'app/types/constants';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: 'transparent', // theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: 'transparent', // `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected.sub-item .MuiTreeItem-label svg path': {
      fill: '#022CE6'
    },
    '&.Mui-selected.sub-item .MuiTreeItem-label svg rect': {
      fill: '#022CE6'
    },
    '& .active': {
      color: '#022CE6',
      '& svg path': {
        fill: '#022CE6'
      },
      '& svg rect': {
        fill: '#022CE6'
      },

      '& svg': {
        '& path.stroke': {
          stroke: '#022CE6',
        },
        '& path.fill': {
          fill: '#022CE6',
        },
      }
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'row-reverse',
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(2),
    '& svg': {
      width: 24,
      height: 24,
      '& path': {
        fill: '#8A8A8A',
      },
      '& rect': {
        fill: '#8A8A8A',
      },
      '& path.stroke': {
        stroke: '#8A8A8A',
      },
      '& path.fill': {
        fill: '#8A8A8A',
      },
    }
  },
  labelText: {
    flexGrow: 1,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
  },
  iconContainer: {
    width: 24,
    height: 24,
    marginRight: 0,
    '& svg path': {
      stroke: '#8A8A8A',
    }
  }
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon, color, bgColor, active, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={clsx(classes.labelRoot, {
          active,
        })}>
          <div className={classes.labelIcon}>
            {labelIcon}
          </div>
          {/* <LabelIcon color="inherit" className={classes.labelIcon} /> */}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': `${color}`,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'auto',
    height: 'calc(100vh - 280px)',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function MenuTreeView({ handleCloseMobile }) {
  const classes = useStyles();
  const topMenu = useTopMenu();
  const history = useHistory();

  const { userInfo } = useSelector(auth.selectors);

  const onItemClick = (link) => {
    history.push(link);
    handleCloseMobile();
  }

  const renderMenu = () => {
    const menu = [];

    const items = userInfo?.userType === CLIENT_TYPE ? topClientMenu : topMenu;

    items.forEach((item, index) => {
      if (item.children.length) {
        menu.push(
          <StyledTreeItem
            key={`mobile-parent-${item.name.props.id}`}
            nodeId={index.toString()}
            labelText={item.name}
            labelIcon={item.icon}
          >
            {
              item.children.map((child, childIndex) => (
                <StyledTreeItem
                  className="sub-item"
                  key={`mobile-child-${child.name}`}
                  nodeId={`${index}-${childIndex}`}
                  labelText={child.name}
                  labelIcon={child.icon}
                  labelInfo="90"
                  color="#022CE6"
                  bgColor="#e8f0fe"
                  active={isUrlInChildren(child, history.location.pathname)}
                  onClick={() => onItemClick(child.link)}
                />
              ))
            }
          </StyledTreeItem>
        )
      } else {
        menu.push(
          <StyledTreeItem
            className="sub-item"
            key={`mobile-parent-${item.name.props.id}`}
            nodeId={`${index}`}
            labelText={item.name}
            labelIcon={item.icon}
            color="#022CE6"
            bgColor="#e8f0fe"
            active={isUrlInChildren(item, history.location.pathname)}
            onClick={() => onItemClick(item.link)}
          />
        )
      }
    });
    
    return menu.map((el) => el);
  }

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ArrowUpIcon />}
      defaultExpandIcon={<ArrowDownIcon />}
    >
      {renderMenu()}
    </TreeView>
  );
}