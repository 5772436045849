import { all, put, takeLatest } from 'redux-saga/effects';
import { formatDateTimeUTC, formatMonthIntervalUTC } from 'util/formatting';

import { tsBySiteFailure, tsBySiteRequest, tsBySiteSuccess } from './actions';
import axios from 'axios';
import { TIMESHEETS_BY_SITE } from 'app/api-routes';
import { SERVER_DATE_TIME_FORMAT } from 'app/types/constants';

function* tsBySiteSaga({ payload: {date, startDate, endDate } }) {
  let fromDate, toDate;
  if (startDate != null && endDate != null) {
    fromDate = formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT);
    toDate = formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT);
  }

  else {
    [fromDate, toDate] = formatMonthIntervalUTC(date);
  }

  try {
    const { payload } = yield axios.get(TIMESHEETS_BY_SITE, {
      params: {
        fromDate,
        toDate,
      }
    });

    yield put(tsBySiteSuccess(payload));
  } catch (err) {
    yield put(tsBySiteFailure(err));
  }
}

export default function* () {
    yield all([yield takeLatest(tsBySiteRequest, tsBySiteSaga)]);
}
