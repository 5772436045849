import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const notebookNoteRequest = createRequest(constants.notebookNote);
export const notebookNoteSuccess = createSuccess(constants.notebookNote);
export const notebookNoteFailure = createFailure(constants.notebookNote);

export const shiftNoteRequest = createRequest(constants.shiftNote);
export const shiftNotesSuccess = createSuccess(constants.shiftNote);
export const shiftNotesFailure = createFailure(constants.shiftNote);

export const requestNotebookNote = notebookNoteRequest;
export const requestShiftNote = shiftNoteRequest;
