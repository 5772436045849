import preparedReducer from './reducer';
import {
  requestProfileFields,
  requestProfileFieldResults,
  updateProfileFields,
  addField,
  updateBuilderFields,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'profiles';

export const selectors = (state) => state[SLICE_NAME];

export const actions = {
  requestProfileFields,
  requestProfileFieldResults,
  updateProfileFields,
  addField,
  updateBuilderFields,
};
