// Constants for different requests for current reducer
export default {
  visitors: 'VISITORS',
  visitor: 'VISITOR',
  createVisitor: 'CREATE_VISITOR',
  updateVisitor: 'UPDATE_VISITOR',
  deleteVisitor: 'DELETE_VISITOR',
  updateVisitorStatus: 'UPDATE_VISITOR_STATUS',
  visitorQRCodeData: 'VISITOR_QR_CODE_DATA',
  updateVisitorQRTag: 'UPDATE_VISITOR_QR_TAG',
  sendVisitorQRCode: 'SEND_VISITOR_QR_CODE', 
};
