import preparedReducer from './reducer';
import { requestSiteInfoTasks } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'siteInfoTasks';

export const selectors = state => state[SLICE_NAME];
export const getSiteInfoTasks = (state, siteID) => state.siteInfoTasks[siteID];

export const actions = {
  requestSiteInfoTasks,
};
