import { SITES } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import {siteInfoToursFailure, siteInfoToursRequest, siteInfoToursSuccess} from './actions';

function* siteInfoToursSaga({ payload: siteID }) {
  try {
    const { payload: siteInfoTours } = yield axios.get(`${SITES}/${siteID}/tours?includeInactive=true`);

    yield put(siteInfoToursSuccess({ siteID, siteInfoTours }));
  } catch (err) {
    yield put(siteInfoToursFailure(err));
  }
}

export default function*() {
  yield all([yield takeLatest(siteInfoToursRequest, siteInfoToursSaga)]);
}
