import { ACTIVITIES_BY_TOUR, TOUR_LOGS } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import appConfig from 'config';
import {
  tourLogFailure,
  tourLogRequest,
  tourLogSuccess,
  tourLogReportFailure,
  tourLogReportRequest,
  tourLogReportSuccess,
} from './actions';

function* tourLogRequestSaga({ payload: tourLogID }) {
  try {
    const { payload: checkpointInfo } = yield axios.get(ACTIVITIES_BY_TOUR, {
      params: {
        tourLogID,
      },
    });

    yield put(tourLogSuccess(checkpointInfo));
  } catch (err) {
    yield put(tourLogFailure(err));
  }
}

function* tourLogReportSaga({ payload: tourLogID }) {
  try {
    const { payload: reportID } = yield axios.get(`${TOUR_LOGS}/${tourLogID}/report`);

    yield put(
      tourLogReportSuccess({ tourLogID, link: `${appConfig.serverURL}/api/mediaassets/stream/${reportID}` })
    );
  } catch (err) {
    yield put(tourLogReportFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(tourLogRequest, tourLogRequestSaga),
    yield takeLatest(tourLogReportRequest, tourLogReportSaga),
  ]);
}
