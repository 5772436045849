import preparedReducer from './reducer';
import { createSelector } from 'reselect';
import {
  login,
  logout,
  resetLogin,
  logoutSuccess,
  showExpiredModel,
  hideExpiredModel,
  acceptAgreement,
} from './actions';
import {
  EMPLOYEE_ID_ACCOUNTS,
  SUPERUSER_LOGIN_NAME,
} from 'app/types/constants';

export const reducer = preparedReducer;

export const SLICE_NAME = 'auth';

// SELECTORS

export const selectors = (state) => state[SLICE_NAME];

export const getSessionID = createSelector(selectors, ({ sessionID } = {}) => sessionID);

export const hasAccess = createSelector(selectors, ({ userInfo }) => {
  if (!userInfo) return false;
  return (position) => position === userInfo.position;
});

export const hasPermission = createSelector(selectors, ({ permissions }) => {
  return (permission) => (permissions || []).includes(permission);
});

export const hasEmployeeID = createSelector(selectors, ({ companyInfo }) => {
  return EMPLOYEE_ID_ACCOUNTS.includes(companyInfo.loginName);
});

export const getUserInfo = (state) => selectors(state).userInfo;

export const isLoggedIn = createSelector(selectors, (authInfo) => !!authInfo.sessionID);

export const isLoggedOut = createSelector(isLoggedIn, (loggedIn) => !loggedIn);

export const isSuperuser = createSelector(
  selectors,
  ({ userInfo }) => userInfo.loginName === SUPERUSER_LOGIN_NAME
);

export const canUseShifts = createSelector(getUserInfo, (userInfo) => userInfo.useShifts);

// SELECTORS

export const actions = {
  login,
  logout,
  reset: resetLogin,
  logoutSuccess,
  showExpiredModel,
  hideExpiredModel,
  acceptAgreement,
};
