import React, { useCallback } from 'react';
import { Button } from 'new-design/common/ui/Button';
import { useFormikContext } from 'formik';

export const Submit = ({ isLegacy, children, ...rest }) => {
  const { isSubmitting, errors, submitForm, setFieldValue } = useFormikContext();

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    setFieldValue('legacy', isLegacy);
    submitForm();
  }, [submitForm, setFieldValue, isLegacy]);

  return (
    <Button
      {...rest}
      disabled={isSubmitting || Object.values(errors).length > 0}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};
