// Constants for different requests for current reducer
export default {
  tours: 'TOURS',
  tour: 'TOUR',
  createTour: 'CREATE_TOUR',
  updateTour: 'UPDATE_TOUR',
  deleteTour: 'DELETE_TOUR',
  enableTour: 'ENABLE_TOUR',
  disableTour: 'DISABLE_TOUR',
};
