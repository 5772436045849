import { SITES } from 'app/api-routes';
import axios from 'axios';
import {all, put, takeLatest} from 'redux-saga/effects';
import {siteInfoTasksFailure, siteInfoTasksRequest, siteInfoTasksSuccess} from './actions';

function* siteInfoTasksSaga({ payload: siteID }) {
  try {
    const {payload: siteInfoTasks} = yield axios(`${SITES}/${siteID}/tasks`);

    yield put(siteInfoTasksSuccess({ siteID, data: siteInfoTasks }));
  } catch (err) {
    yield put(siteInfoTasksFailure(err));
  }
}

export default function*() {
  yield all([yield takeLatest(siteInfoTasksRequest, siteInfoTasksSaga)]);
}
