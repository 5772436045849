import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const siteInfoAddressRequest = createRequest(constants.siteInfoAddress);

export const siteInfoAddressSuccess = createSuccess(constants.siteInfoAddress);

export const siteInfoAddressFailure = createFailure(constants.siteInfoAddress);

export const requestSiteInfoAddress = siteInfoAddressRequest;
