import React from 'react';
import { SignInForm } from '../../containers/SignIn';
import { LoginLayout } from '../../layouts';

export const SignInPage = () => {
  return (
    <LoginLayout>
      {({ setSubmit }) => <SignInForm setSubmit={setSubmit} />}
    </LoginLayout>
  );
}
