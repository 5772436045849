import { handleActions } from 'redux-actions';
import initialState from './states';
import { loginDetailsSuccess, getExcludedEventTypesSuccess } from './actions';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [loginDetailsSuccess](state, { payload: { id, data } }) {
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          [id]: data,
        },
      };
    },
    [getExcludedEventTypesSuccess](state, { payload: { id, data } }) {
      return {
        ...state,
        excludedTypes: {
          ...state.excludedTypes,
          [id]: data,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
