import {Enum} from 'enumify';

export class STATES extends Enum {}

STATES.NONE = undefined;
STATES.REQUEST = undefined;
STATES.FAILURE = undefined;
STATES.SUCCESS = undefined;
STATES.initEnum(Object.keys(STATES));

export const initialState = Object.freeze({
  requestState: STATES.NONE.name,
  requestParams: undefined,
  payload: undefined,
  errors: [],
  warnings: [],
});
