import { INCIDENT_REPORTS } from 'app/api-routes';
import axios from 'axios';
import {all, put, takeLatest} from 'redux-saga/effects';
import {formatMonthIntervalUTC} from 'util/formatting';

import { irPerDateFailure, irPerDateRequest, irPerDateSuccess } from './actions';

function* irPerDateSaga({ payload: { date, selectedSite } }) {
  const siteID = selectedSite;
  const [fromDate, toDate] = formatMonthIntervalUTC(date);

  try {
    const { payload } = yield axios.get(INCIDENT_REPORTS, {
      fromDate,
      toDate,
      siteID,
    });

    yield put(
      irPerDateSuccess({
        siteID,
        irPerDate: payload,
      }),
    );
  } catch (err) {
    yield put(irPerDateFailure(err));
  }
}

export default function*() {
  yield all([yield takeLatest(irPerDateRequest, irPerDateSaga)]);
}
