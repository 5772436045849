import preparedReducer from './reducer';
import {
  requestIncidentReports,
  createIncidentReport,
  editIncidentReport,
  deleteIncidentReport,
  requestIncidentReport,
  requestIncidentReportLink,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'incidentReports';

export const selectors = state => state[SLICE_NAME];
export const getIncidentReport = (state, reportID) => state.incidentReports[reportID];

export const actions = {
  requestIncidentReports,
  createIncidentReport,
  editIncidentReport,
  requestIncidentReport,
  requestIncidentReportLink,
  deleteIncidentReport,
};
