import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { noteTypesFailure, noteTypesRequest, noteTypesSuccess, noteTypesUpdateRequest } from './actions';
import { NOTE_TYPES } from 'app/api-routes';

function* noteTypesSaga({ payload: table }) {
  try {
    const { payload } = yield axios.get(NOTE_TYPES, {
      params: {
        table,
      },
    });

    yield put(
      noteTypesSuccess({
        table,
        noteTypes: payload,
      }),
    );
  } catch (err) {
    yield put(noteTypesFailure(err));
  }
}

function* noteTypesUpdateSaga({ payload: { table, data } }) {
  try {
    yield axios.put(NOTE_TYPES, { payload: data }, {
      params: {
        table,
      },
    });

    yield call(noteTypesSaga, { payload: table });
  } catch (err) {
    yield put(noteTypesFailure(err));
  }
}

export default function*() {
  yield takeEvery(noteTypesRequest, noteTypesSaga);
  yield takeEvery(noteTypesUpdateRequest, noteTypesUpdateSaga);
}
