import { put, select, takeLatest } from 'redux-saga/effects';

import { codesRequest } from 'ducks/codes/actions';
import { getCodes } from 'ducks/codes';
import CodeTypes from 'ducks/codes/types';

import {
  notebookNoteTagsFailure,
  notebookNoteTagsSuccess,
  notebookNoteTagsRequest,
  updateNotebookNoteTagsFailure,
  updateNotebookNoteTagsRequest,
  updateNotebookNoteTagsSuccess,
} from './actions';
import axios from 'axios';
import { SITES } from 'app/api-routes';

export function* notebookNoteTagsSaga({ payload: siteID }) {
  // HOT FIX: replace in new flow.
  if (!siteID) return;

  const hasCodes = yield select(
    (state) => getCodes(state, CodeTypes.NOTEBOOK_TAGS) != null
  );

  try {
    if (!hasCodes) {
      yield put(codesRequest(CodeTypes.NOTEBOOK_TAGS));
    }
    const { payload } = yield axios.get(`${SITES}/${siteID}/notebook-tags`);

    yield put(
      notebookNoteTagsSuccess({
        notebookNoteTags: payload,
        siteID,
      })
    );
  } catch (err) {
    yield put(notebookNoteTagsFailure(err));
  }
}

function* updateNotebookNoteTagsSaga({ payload: { siteID, data, onSuccess } }) {
  // HOT FIX: replace in new flow.
  if (!siteID) return;

  try {
    yield axios.put(`${SITES}/${siteID}/notebook-tags`, {
      payload: data,
    });

    yield put(
      updateNotebookNoteTagsSuccess({ siteID, notebookNoteTags: data })
    );
    onSuccess?.(siteID);
  } catch (err) {
    yield put(updateNotebookNoteTagsFailure(err));
  }
}

export default function* () {
  yield takeLatest(notebookNoteTagsRequest, notebookNoteTagsSaga);
  yield takeLatest(updateNotebookNoteTagsRequest, updateNotebookNoteTagsSaga);
}
