import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const visitLocationsRequest = createRequest(constants.visitLocations);
export const visitLocationsSuccess = createSuccess(constants.visitLocations);
export const visitLocationsFailure = createFailure(constants.visitLocations);

export const createVisitLocationRequest = createRequest(constants.createVisitLocation);
export const createVisitLocationSuccess = createSuccess(constants.createVisitLocation);
export const createVisitLocationFailure = createFailure(constants.createVisitLocation);

export const updateVisitLocationRequest = createRequest(constants.updateVisitLocation);
export const updateVisitLocationSuccess = createSuccess(constants.updateVisitLocation);
export const updateVisitLocationFailure = createFailure(constants.updateVisitLocation);

export const updateVisitLocationStatusRequest = createRequest(constants.updateVisitLocationStatus);
export const updateVisitLocationStatusSuccess = createSuccess(constants.updateVisitLocationStatus);
export const updateVisitLocationStatusFailure = createFailure(constants.updateVisitLocationStatus);

export const deleteVisitLocationRequest = createRequest(constants.deleteVisitLocation);
export const deleteVisitLocationSuccess = createSuccess(constants.deleteVisitLocation);
export const deleteVisitLocationFailure = createFailure(constants.deleteVisitLocation);

export const requestVisitLocations = visitLocationsRequest;
export const createVisitLocation = createVisitLocationRequest;
export const updateVisitLocation = updateVisitLocationRequest;
export const updateVisitLocationStatus = updateVisitLocationStatusRequest;
export const deleteVisitLocation = deleteVisitLocationRequest;
