import { handleActions } from 'redux-actions';
import { employeesSuccess, getUserSuccess } from './actions';
import { logoutSuccess } from 'ducks/auth/actions';

import initialState from './states';

export default handleActions(
  {
    [employeesSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [getUserSuccess](state, { payload }) {
      return {
        ...state,
        users: {
          ...state.users,
          [payload.userID]: payload,
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
