import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const shiftsRequest = createRequest(constants.shifts);
export const shiftsSuccess = createSuccess(constants.shifts);
export const shiftsFailure = createFailure(constants.shifts);

export const endShiftRequest = createRequest(constants.endShift);
export const endShiftSuccess = createSuccess(constants.endShift);
export const endShiftFailure = createFailure(constants.endShift);

export const requestShifts = shiftsRequest;
export const endShift = endShiftRequest;
