import React, { cloneElement, isValidElement, useCallback, useMemo } from 'react';
import { List, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageStore from 'ducks/page_store';
import { useIntl } from 'util/IntlMessages';

const useStyles = makeStyles(
  (theme) => ({
    navMenuLink: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 10px 10px 20px',
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('lg')]: {
        padding: '8px 8px 8px 16px',
      },
      '&.Cmt-nav-menu-link': {
        textDecoration: 'none',
      },
      '&:hover, &:focus': {
        color: theme.palette.common.black,
        backgroundColor: 'rgba(2, 44, 230, 0.05)',

        '& .Cmt-icon-root .stroke-same': {
          stroke: theme.palette.common.black,
        },
        '& .Cmt-icon-root .fill-white': {
          fill: 'white !important',
        },
      },
      '&.active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        '& .Cmt-icon-root path': {
          fill: theme.palette.primary.main,
        },
        '& .Cmt-icon-root .fill': {
          fill: theme.palette.primary.main,
        },
        '& .Cmt-icon-root .stroke': {
          stroke: theme.palette.primary.main,
          fill: 'transparent',
        },
        '& .Cmt-icon-root .stroke-same': {
          stroke: theme.palette.primary.main,
        },
        '& .Cmt-icon-root .fill-white': {
          fill: 'white',
        },
        '& .Cmt-icon-root rect': {
          fill: theme.palette.primary.main,
        },
        '& .Cmt-nav-text': {
          color: theme.palette.primary.main,
        },
        '&.Cmt-nav-menu-link:hover svg path': {
          fill: theme.palette.primary.main,
        },
        '&.Cmt-nav-menu-link:hover svg .fill': {
          fill: theme.palette.primary.main,
        },
        '&.Cmt-nav-menu-link:hover svg .stroke': {
          stroke: theme.palette.primary.main,
          fill: 'transparent',
        },
        '&:hover .Cmt-icon-root rect': {
          fill: theme.palette.primary.main,

          '& .Cmt-icon-root .stroke-same': {
            stroke: theme.palette.primary.main,
          },
          '& .Cmt-icon-root .fill-white': {
            fill: 'white !important',
          },
        },
      },
      '&.disabled': {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.common.white,
        '& .Cmt-icon-root path': {
          fill: theme.palette.secondary.main,
        },
        '& .Cmt-icon-root rect': {
          fill: theme.palette.secondary.main,
        },
        '& .Cmt-nav-text': {
          color: theme.palette.secondary.main,
        },
        '&:hover, &:focus': {
          color: theme.palette.secondary.main,
          backgroundColor: 'rgba(2, 44, 230, 0.05)',
          '& .Cmt-icon-root path': {
            fill: theme.palette.secondary.main,
          },
        },
      },
      '&.Cmt-nav-menu-link svg path': {
        fill: theme.palette.secondary.main,
      },
      '&.Cmt-nav-menu-link svg .fill': {
        fill: theme.palette.secondary.main,
      },
      '&.Cmt-nav-menu-link svg .stroke': {
        stroke: theme.palette.secondary.main,
        fill: 'transparent !important',
      },

      '& .Cmt-icon-root .stroke-same': {
        stroke: theme.palette.secondary.main,
      },
      '& .Cmt-icon-root .fill-white': {
        fill: 'white',
      },

      '&.Cmt-nav-menu-link:hover svg path': {
        fill: theme.palette.common.black,
      },
      '&.Cmt-nav-menu-link:hover svg .fill': {
        fill: theme.palette.common.black,
      },
      '&.Cmt-nav-menu-link:hover svg .stroke': {
        stroke: theme.palette.common.black,
        fill: 'transparent',
      },
      '& .Cmt-icon-root rect': {
        fill: theme.palette.secondary.main,
      },
      '&:hover .Cmt-icon-root rect': {
        fill: theme.palette.common.black,
      },
    },
    iconRoot: {
      marginRight: 16,
      fontSize: 20,
      maxWidth: 24,
      maxHeight: 24,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 20,
        maxHeight: 20,
      },
    },
    navText: {
      // color: theme.palette.secondary.main,
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        lineHeight: '23px',
      },
    },
    betaText: {
      marginLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('lg')]: {
        color: theme.palette.primary.main,
        fontSize: 10,
        lineHeight: '23px',
      },
    },
  }),
  {
    name: 'NavMenuItem',
  }
);

const NavMenuItem = (props) => {
  const { name, icon, link, disabled, handleClick, beta } = props;
  const { reset } = bindActionCreators(pageStore.actions, useDispatch());
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  const onClick = useCallback(
    (e) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      if (handleClick) {
        handleClick(e);
      }
    },
    [disabled, handleClick]
  );

  const Component = useMemo(() => (disabled ? Box : NavLink), [disabled]);

  return (
    <List component="div" disablePadding onClick={onClick}>
      <Component
        className={clsx({
          [classes.navMenuLink]: true,
          'Cmt-nav-menu-link': true,
          disabled: disabled,
        })}
        to={link}
        onClick={reset}
      >
        {renderIcon()}
        <Typography className={clsx(classes.navText, 'Cmt-nav-text')}>{name}</Typography>
        {beta && (
          <Typography className={clsx(classes.betaText, 'Cmt-nav-text')}>
            {formatMessage({ id: 'beta' })}
          </Typography>
        )}
      </Component>
    </List>
  );
};

export default NavMenuItem;
