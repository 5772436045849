export const ENTITY_TYPES = {
  CLIENTS: 'clients',
  SITES: 'sites',
  MOBILE_PATROLS: 'mobile_patrols',
  EMPLOYEES: 'employees',
  EMPLOYEE_USERS: 'employee_users',
  CONTACTS: 'contacts',
  CONTACT_USERS: 'contact_users',
  AREAS: 'areas',
  CHECKPOINTS: 'checkpoints',
};

export const EVENT_TYPES = {
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED',
  STATUS_CHANGED: 'STATUS_CHANGED',
};
