import { VISITORS } from 'app/api-routes';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  visitorsSuccess,
  visitorsFailure,
  visitorsRequest,
  visitorSuccess,
  visitorFailure,
  visitorRequest,
  createVisitorRequest,
  createVisitorSuccess,
  createVisitorFailure,
  deleteVisitorSuccess,
  deleteVisitorFailure,
  deleteVisitorRequest,
  updateVisitorRequest,
  updateVisitorSuccess,
  updateVisitorFailure,
  updateVisitorStatusRequest,
  updateVisitorStatusSuccess,
  updateVisitorStatusFailure,
  visitorQRCodeDataRequest,
  visitorQRCodeDataSuccess,
  visitorQRCodeDataFailure,
  updateVisitorQRTagRequest,
  updateVisitorQRTagSuccess,
  updateVisitorQRTagFailure,
  sendVisitorQRCodeSuccess,
  sendVisitorQRCodeFailure,
  sendVisitorQRCodeRequest,
} from './actions';
import { uploadMediaAssetSaga } from 'ducks/mediaAssets/sagas';
import { TABLE_NAMES } from 'app/types/constants';
import { MediaAssetCategories, MediaAssetTypes } from 'ducks/mediaAssets/types';

function* visitorsSaga({ payload: { siteID } }) {
  try {
    const { payload } = yield axios.get(VISITORS, { params: { siteID: siteID || null } });

    yield put(visitorsSuccess(payload));
  } catch (err) {
    yield put(visitorsFailure(err));
  }
}

function* visitorSaga({ payload: { visitorID } }) {
  try {
    const { payload } = yield axios.get(`${VISITORS}/${visitorID}`);

    yield put(visitorSuccess(payload));
  } catch (err) {
    yield put(visitorFailure(err));
  }
}

function* createVisitorSaga({ payload: { payload, photo } }) {
  try {
    const { payload: visitorID } = yield axios.post(VISITORS, {
      payload,
    });

    if (photo) {
      yield call(uploadMediaAssetSaga, {
        payload: {
          fileUploaded: {
            file: photo,
          },
          mediaAssetInfo: {
            entityID: visitorID,
            tableName: TABLE_NAMES.VISITORS,
            category: MediaAssetCategories.AVATAR,
            type: MediaAssetTypes.PHOTO,
          },
        },
      });
    }

    yield put(createVisitorSuccess({ visitorID }));
  } catch (err) {
    yield put(createVisitorFailure(err));
  }
}

function* updateVisitorSaga({ payload: { visitorID, values } }) {
  try {
    yield axios.put(`${VISITORS}/${visitorID}`, { payload: values });

    yield call(visitorSaga, { payload: { visitorID } });
    yield put(updateVisitorSuccess({ visitorID }));
  } catch (err) {
    yield put(updateVisitorFailure(err));
  }
}

function* deleteVisitorSaga({ payload: visitorID }) {
  try {
    yield axios.delete(`${VISITORS}/${visitorID}`);
    yield put(deleteVisitorSuccess({ visitorID }));
  } catch (err) {
    yield put(deleteVisitorFailure(err));
  }
}

function* updateVisitorStatusSaga({ payload: { visitorID, status } }) {
  try {
    yield axios.put(`${VISITORS}/${visitorID}/status/${status}`);
    yield put(updateVisitorStatusSuccess({ visitorID }));
  } catch (err) {
    yield put(updateVisitorStatusFailure(err));
  }
}

function* visitorQRCodeDataSaga({ payload: { visitorID } }) {
  try {
    const { payload: qrData } = yield axios.get(`${VISITORS}/${visitorID}/qr-code`);
    yield put(visitorQRCodeDataSuccess({ visitorID, data: qrData }));
  } catch (err) {
    yield put(visitorQRCodeDataFailure(err));
  }
}

function* updateVisitorQRTagSaga({ payload: { visitorID } }) {
  try {
    const { payload: qrData } = yield axios.put(`${VISITORS}/${visitorID}/qr-code`);
    yield put(updateVisitorQRTagSuccess({ visitorID, data: qrData }));
  } catch (err) {
    yield put(updateVisitorQRTagFailure(err));
  }
}

function* sendVisitorQRCodeSaga({ payload: { visitorID, email, qrCode } }) {
  try {
    yield axios.post(`${VISITORS}/${visitorID}/qr-code/send`, qrCode, {
      params: { email },
    });
    yield put(sendVisitorQRCodeSuccess({ visitorID }));
  } catch (err) {
    yield put(sendVisitorQRCodeFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(visitorsRequest, visitorsSaga),
    yield takeLatest(visitorRequest, visitorSaga),
    yield takeLatest(createVisitorRequest, createVisitorSaga),
    yield takeLatest(updateVisitorRequest, updateVisitorSaga),
    yield takeLatest(deleteVisitorRequest, deleteVisitorSaga),
    yield takeLatest(updateVisitorStatusRequest, updateVisitorStatusSaga),
    yield takeLatest(visitorQRCodeDataRequest, visitorQRCodeDataSaga),
    yield takeLatest(updateVisitorQRTagRequest, updateVisitorQRTagSaga),
    yield takeLatest(sendVisitorQRCodeRequest, sendVisitorQRCodeSaga),
  ]);
}
