import React from 'react';
import { Link as MaterialLink } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => createStyles(
  {
    root: {
      color: '#222222',
    },
  }
), {
  name: 'Link'
});

const Link = (props) => {
  const classes = useStyles();

  return <MaterialLink {...props} classes={{ root: classes.root }} />;
};

export { Link };
