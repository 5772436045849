import React, { useCallback, useEffect } from 'react';
import { IconButton } from 'new-design/common/ui';
import clsx from 'clsx';
import { createStyles, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { bindActionCreators } from 'redux';
import * as adminTab from 'ducks/adminTab';
import * as mainLayout from 'ducks/main-layout';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      btn: {
        border: '1.5px solid transparent',
        margin: '0 8px',
        padding: 12,
        '&.fill path': {
          fill: theme.palette.secondary.main,
        },
        '&.checkbox': {
          '& rect': {
            fill: theme.palette.secondary.main,
          },
        },
        '&.contact': {
          '& rect': {
            fill: theme.palette.secondary.main,
          },
          '& path': {
            fill: theme.palette.secondary.main,
          },
        },
        '&:hover': {
          '&.fill path': {
            fill: theme.palette.primary.main,
          },
          '&.checkbox': {
            '& rect': {
              fill: theme.palette.primary.main,
            },
          },
          '&.contact': {
            '& rect': {
              fill: theme.palette.primary.main,
            },
            '& path': {
              fill: theme.palette.primary.main,
            },
          },
        },
        [theme.breakpoints.down('lg')]: {
          padding: 8,
        },
        '&.active': {
          background: theme.palette.primary.main,
          '&.fill path': {
            fill: theme.palette.common.white,
          },
          '&.checkbox': {
            '& rect': {
              fill: theme.palette.common.white,
            },
            '& path': {
              stroke: theme.palette.primary.main,
            },
            '&:hover': {
              '& path': {
                stroke: theme.palette.common.white,
              },
            },
          },
          '&.contact': {
            '& rect': {
              fill: theme.palette.common.white,
            },
            '& path': {
              fill: theme.palette.common.white,
            },
          },
          '&:hover': {
            background: 'transparent',
          },
        },
      },
    }),
  {
    name: 'AdminTabs',
  }
);

const AdminTabs = () => {
  const classes = useStyles();

  const { tab, tabsList, disabledTabs } = useSelector(adminTab.selectors);
  const { adminTabsEnabled } = useSelector(mainLayout.selectors);
  const { switchTab } = bindActionCreators(adminTab.actions, useDispatch());

  const handleSwitchTab = useCallback(
    (tabName) => () => {
      switchTab(tabName);
    },
    []
  );

  useEffect(() => {
    if (tabsList?.[0]?.key) {
      switchTab(tabsList[0].key);
    }
  }, [tabsList]);

  if (!adminTabsEnabled) return null;

  return (
    <div>
      {tabsList
        .filter(({ hidden }) => !hidden)
        .map(({ key, icon: Icon, className, tooltip }) => (
          <IconButton
            key={`tab-admin-item-${key}`}
            variant="outlined"
            className={clsx(classes.btn, className || 'fill', {
              active: tab === key,
            })}
            disabled={disabledTabs}
            onClick={handleSwitchTab(key)}
          >
            <Tooltip title={tooltip}>
              <Icon />
            </Tooltip>
          </IconButton>
        ))}
    </div>
  );
};

export { AdminTabs };
