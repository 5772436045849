import { all, put, select, takeLatest } from 'redux-saga/effects';
import { formatDateTimeUTC, formatMonthIntervalUTC } from 'util/formatting';

import { activityIRRequest, activityIRSuccess, setRequestState } from './actions';

import axios from 'axios';
import { INCIDENT_REPORTS_V2 } from 'app/api-routes';
import { SERVER_DATE_TIME_FORMAT } from 'app/types/constants';

function* activityIRSaga({ payload: { date, startDate, endDate, siteFilter, employeeFilter } }) {
  const { userInfo } = yield select(state => state.auth);
  yield put(setRequestState(true));
  let statusFilter = null;                       
  if (userInfo.userType === "C"){
    statusFilter = 'Approved';
  }
  let fromDate, toDate;
  if (startDate != null && endDate != null) {
    fromDate = formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT);
    toDate = formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT);
  } else {
    [fromDate, toDate] = formatMonthIntervalUTC(date);
  }

  try {
    const { payload } = yield axios.get(INCIDENT_REPORTS_V2, {
      params: {
        fromDate,
        toDate,
        status: statusFilter ? statusFilter : null,
        siteID: siteFilter ? siteFilter : null,
        userID: employeeFilter ? employeeFilter : null,
      }
    });

    yield put(activityIRSuccess(payload));
  } catch (err) {
    // yield put(activityIRFailure(err));
  } finally {
    yield put(setRequestState(false));
  }
}

export default function* () {
  yield all([yield takeLatest(activityIRRequest, activityIRSaga)]);
}
