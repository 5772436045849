import { handleActions } from 'redux-actions';
import { switchAppType } from './actions';
import initialState from './states';
import _ from 'lodash';
import { logoutSuccess } from 'ducks/auth/actions';

export default handleActions(
  {
    [switchAppType](state, { payload }) {
      if (_.isEqual(state, payload)) {
        return state;
      }

      return {
        ...state,
        type: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
