import { all, put, takeLatest, select, takeEvery } from 'redux-saga/effects';

import axios from 'axios';
import { USERS } from 'app/api-routes';
import {
  changePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  loginDetailsFailure,
  loginDetailsRequest,
  loginDetailsSuccess,
  updateLoginDetailsFailure,
  updateLoginDetailsRequest,
  updateUserStatusFailure,
  updateUserStatusRequest,
  getExcludedEventTypesFailure,
  getExcludedEventTypesRequest,
  getExcludedEventTypesSuccess,
  updateExcludedEventTypesFailure,
  updateExcludedEventTypesRequest,
  createLoginDetailsFailure,
  createLoginDetailsRequest,
} from './actions';
import * as auth from 'ducks/auth';

function* changePasswordSaga({ payload: { userID, field, callback } }) {
  try {
    const { userInfo } = yield select(auth.selectors);
    const { payload } = yield axios.put(`${USERS}/${userID || userInfo?.userID}/password`, {
      payload: field,
    });
    if (callback) {
      callback();
    }
    yield put(changePasswordSuccess(payload));
  } catch (err) {
    yield put(changePasswordFailure(err));
  }
}

export function* getLoginDetailsSaga({ payload: id }) {
  try {
    const { payload } = yield axios.get(`${USERS}/${id}/login-details`);

    yield put(loginDetailsSuccess({ id, data: payload }));
  } catch (err) {
    yield put(loginDetailsFailure(err));
  }
}

export function* updateLoginDetailsSaga({ payload: { userID, payload, callback } }) {
  try {
    yield axios.put(`${USERS}/${userID}/login-details`, {
      payload,
    });

    if (callback) {
      callback();
    }
  } catch (err) {
    yield put(updateLoginDetailsFailure(err));
  }
}

export function* updateUserStatusSaga({ payload: { userID, status, callback } }) {
  try {
    yield axios.put(`${USERS}/${userID}/status/${status}`);

    if (callback) {
      callback();
    }
  } catch (err) {
    yield put(updateUserStatusFailure(err));
  }
}

function* getExcludedEventTypesSaga({ payload: { userID } }) {
  try {
    const { payload } = yield axios.get(`${USERS}/${userID}/event-type-exclusions`);
    yield put(getExcludedEventTypesSuccess({ id: userID, data: payload }));
  } catch (err) {
    yield put(getExcludedEventTypesFailure(err));
  }
}

export function* updateExcludedEventTypesSaga({ payload: { userID, data, callback } }) {
  try {
    yield axios.put(`${USERS}/${userID}/event-type-exclusions`, {
      payload: data,
    });
    if (callback) {
      callback();
    }
  } catch (err) {
    yield put(updateExcludedEventTypesFailure(err));
  }
}

export function* createLoginDetailsSaga({ payload: { payload, callback } }) {
  try {
    const { payload: userID } = yield axios.post(`${USERS}/login-details`, {
      payload,
    });

    callback?.();
    return userID;
  } catch (err) {
    yield put(createLoginDetailsFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(changePasswordRequest, changePasswordSaga),
    yield takeLatest(updateUserStatusRequest, updateUserStatusSaga),
    yield takeLatest(getExcludedEventTypesRequest, getExcludedEventTypesSaga),
    yield takeEvery(updateExcludedEventTypesRequest, updateExcludedEventTypesSaga),
    yield takeLatest(loginDetailsRequest, getLoginDetailsSaga),
    yield takeEvery(updateLoginDetailsRequest, updateLoginDetailsSaga),
    yield takeEvery(createLoginDetailsRequest, createLoginDetailsSaga),
  ]);
}
