import { ACTIVITIES_BY_SITE } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { formatMonthIntervalUTC } from 'util/formatting';
import { hoursPerDateFailure, hoursPerDateRequest, hoursPerDateSuccess } from './actions';

function* hoursPerDateSaga({ payload: { date, selectedSite } }) {
  const siteID = selectedSite;
  const [fromDate, toDate] = formatMonthIntervalUTC(date);

  try {
    const { payload } = yield axios.get(ACTIVITIES_BY_SITE, {
      params: {
        fromDate,
        toDate,
        siteID,
      }
    });

    yield put(
      hoursPerDateSuccess({
        siteID,
        hoursPerDate: payload,
      }),
    );
  } catch (err) {
    yield put(hoursPerDateFailure(err));
  }
}

export default function*() {
  yield all([yield takeLatest(hoursPerDateRequest, hoursPerDateSaga)]);
}
