import { all, call, put, takeLatest } from 'redux-saga/effects';
import { formatDateTimeUTC, formatMonthIntervalUTC } from 'util/formatting';

import {
  endShiftFailure,
  endShiftRequest,
  endShiftSuccess,
  shiftsFailure,
  shiftsRequest,
  shiftsSuccess,
} from './actions';

import axios from 'axios';
import { SHIFTS, SHIFTS_VIEWER } from 'app/api-routes';
import { shiftStatsSaga } from 'ducks/shiftInfo/sagas';
import { SERVER_DATE_TIME_FORMAT } from 'app/types/constants';

function* shiftsSaga({ payload: { date, startDate, endDate } }) {
  let fromDate, toDate;
  if (startDate != null && endDate != null) {
    fromDate = formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT);
    toDate = formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT);
  } else {
    [fromDate, toDate] = formatMonthIntervalUTC(date);
  }

  try {
    const { payload } = yield axios.get(SHIFTS_VIEWER, {
      params: {
        fromDate,
        toDate,
      },
    });

    yield put(shiftsSuccess(payload));
  } catch (err) {
    yield put(shiftsFailure(err));
  }
}

function* endShiftSaga({ payload: { shiftID, reasonID } }) {
  try {
    yield axios.delete(`${SHIFTS}/${shiftID}`, { params: { reasonID } });
    yield put(endShiftSuccess(shiftID));
    yield call(shiftStatsSaga, { payload: shiftID });
  } catch (err) {
    yield put(endShiftFailure(err));
  }
}

export default function* () {
  yield all([yield takeLatest(shiftsRequest, shiftsSaga), yield takeLatest(endShiftRequest, endShiftSaga)]);
}
