import preparedReducer from './reducer';
import { resetActionStatus } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'requests';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  resetActionStatus,
};
