import preparedReducer from './reducer';
import {
  requestClientEmailSettings,
  enableClientEmailSetting,
  disableClientEmailSetting,
  requestClientEmailRecipients,
  updateClientEmailRecipients,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'clientEmailSettings';

export const selectors = (state) => state[SLICE_NAME];

export const actions = {
  requestClientEmailSettings,
  enableClientEmailSetting,
  disableClientEmailSetting,
  requestClientEmailRecipients,
  updateClientEmailRecipients,
};
