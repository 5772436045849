import React, { useCallback } from 'react';
import { List } from '@material-ui/core';
import NavCollapse from './NavCollapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import * as auth from 'ducks/auth';

const useStyles = makeStyles((theme) => ({
  horizontalNavMenu: {
    position: 'relative',
    display: 'flex',
    marginLeft: 46,
    marginRight: -14,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 15,
      marginRight: 0,
    },
  },
}));

const HorizontalTopMenu = ({ menuItems }) => {
  const classes = useStyles();
  const { newAppMenu } = useSelector(auth.selectors);

  const preparedItems = useCallback((items) => {
    const newItems = items.map((el) => {
      const subitems = [];
      el.children.forEach((element) => {
        if (newAppMenu[element.trancode] !== undefined) {
          subitems.push(element);
        }
      });

      return {
        ...el,
        children: subitems,
      };
    });

    return newItems;
  }, [newAppMenu]);

  return (
    <List component="nav" disablePadding className={clsx(classes.horizontalNavMenu, 'Cmt-horizontalNavMenu')}>
      {preparedItems(menuItems).map((item, index) => <NavCollapse {...item} key={index} />)}
    </List>
  );
};

export default HorizontalTopMenu;
