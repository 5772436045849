import preparedReducer from './reducer';
import {
  requestActiveIRsGroupedByType,
  requestIRsGroupedByDateAndType,
  requestIRsGroupedByDate,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'statistics';

export const selectors = (state) => state[SLICE_NAME];

export const actions = {
  requestActiveIRsGroupedByType,
  requestIRsGroupedByDateAndType,
  requestIRsGroupedByDate,
};
