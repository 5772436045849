import preparedReducer from './reducer';
import {
  userManualRequest,
  setSelected,
} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'userManual';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  userManualRequest,
  setSelected
};
