import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const hoursPerDateRequest = createRequest(constants.hoursPerDate);

export const hoursPerDateSuccess = createSuccess(constants.hoursPerDate);

export const hoursPerDateFailure = createFailure(constants.hoursPerDate);

export const requestHoursPerDate = hoursPerDateRequest;
