import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import {
  enableTourSuccess,
  disableTourSuccess,
  tourSuccess,
  toursSuccess,
  deleteTourSuccess,
} from './actions';

import initialState from './states';
import _ from 'lodash';

export default handleActions(
  {
    [toursSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [tourSuccess](state, { payload }) {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.tourID]: payload,
        },
      };
    },
    [deleteTourSuccess](state, { payload: { tourID } }) {
      return {
        ...state,
        info: _.omit(state.info, [tourID]),
      };
    },
    [enableTourSuccess](state, { payload: { tourID } }) {
      return {
        ...state,
        info: {
          ...state.info,
          [tourID]: {
            ...state.info[tourID],
            tourActive: true,
          },
        },
      };
    },
    [disableTourSuccess](state, { payload: { tourID } }) {
      return {
        ...state,
        info: {
          ...state.info,
          [tourID]: {
            ...state.info[tourID],
            tourActive: false,
          },
        },
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
