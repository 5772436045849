function getBrowserVersion() {
  let ua = navigator.userAgent, tem, M = ua
      .match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)
    || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/)
    if (tem != null)
      return 'Opera ' + tem[1];
  }
  M = M[2] ? [ M[1], M[2] ]
    : [ navigator.appName, navigator.appVersion, '-?' ];
  if ((tem = ua.match(/version\/(\d+)/i)) != null)
    M.splice(1, 1, tem[1]);
  return M;
}

export function getClientType() {
  const browser = getBrowserVersion();
  return 'webapp.' + browser[0] + '.' + browser[1];
}

export function getTimeZone() {
  const split = new Date().toString().split(' ');
  return split[5];
}

function setCookie(cname, cvalue, exdays) {
  let cookie = cname + '=' + cvalue + ';';
  if (exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();

    cookie += ' ' + expires;
  }
  document.cookie = cookie;
}

function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ')
      c = c.substring(1);
    if (c.indexOf(name) !== -1)
      return c.substring(name.length, c.length);
  }
  return '';
}

export function getClientID() {
  let cookie = getCookie('clientID');
  if (!cookie) {
    cookie = Math.random().toString(36).substring(2);
    setCookie('clientID', cookie, 90);
  }

  return cookie;
}

function getDefaultLanguageCountry() {
  return {
    language: 'en',
    country: 'CA',
  };
}

export function getLanguageCountry() {
  const locale = window.navigator.userLanguage || window.navigator.language;
  const result = locale.split('-');
  if (!result.length) {
    return getDefaultLanguageCountry();
  }
  return {
    language: result[0],
    country:  result[1] ? result[1].toUpperCase(): null,
  }
}

export const IS_DEV = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');