import React from 'react';
import { Route } from 'react-router-dom';
// TODO: refactoring.
import asyncComponent from 'util/asyncComponent';

const AnalyticsRoutes = ({ match }) =>
  <>
    <Route path={`${match.url}/work-history/shift/logs`} exact component={(asyncComponent(() => import('../pages/operations/ActivityLog')))}/>
    <Route path={`${match.url}/work-history/shift`} exact component={(asyncComponent(() => import('../pages/subpages/WorkHistoryShift')))}/>
    <Route path={`${match.url}/work-history`} exact component={(asyncComponent(() => import('../pages/analytics/WorkHistory')))}/>

    <Route path={`${match.url}/timesheets`} exact component={(asyncComponent(() => import('../pages/analytics/Timesheets')))}/>

    <Route path={`${match.url}/performance`} exact component={(asyncComponent(() => import('../pages/analytics/Performance')))} />

    <Route path={`${match.url}/bi/tours-detailed`} exact component={(asyncComponent(() => import('../pages/analytics/BITours')))} />
    <Route path={`${match.url}/bi`} exact component={(asyncComponent(() => import('../pages/analytics/BIAndStatistics')))} />
  </>;

export { AnalyticsRoutes };
