import React from 'react';
import * as auth from 'ducks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal } from 'new-design/components/ConfirmationModal';
import { bindActionCreators } from 'redux';
import { useIntl } from 'util/IntlMessages';

export const ExpiredSessionModal = () => {
  const { showExpired } = useSelector(auth.selectors);
  const { formatMessage } = useIntl();
  const { hideExpiredModel } = bindActionCreators(auth.actions, useDispatch());

  return (
    <ConfirmationModal
      modalOpened={showExpired}
      title={formatMessage({ id: 'notification' })}
      description={formatMessage({ id: 'expired_session' })}
      onModalConfirm={() => hideExpiredModel()}
    />
  )
};
