import {all, put, call, takeLatest} from 'redux-saga/effects';
import {
  createIncidentReportFailure,
  createIncidentReportRequest,
  createIncidentReportSuccess,
  editIncidentReportFailure,
  editIncidentReportRequest,
  incidentReportFailure,
  incidentReportRequest,
  incidentReportsFailure,
  incidentReportsRequest,
  incidentReportsSuccess,
  incidentReportSuccess,
  incidentReportLinkRequest,
  incidentReportLinkSuccess,
  incidentReportLinkFailure,
  deleteIncidentReportSuccess,
  deleteIncidentReportFailure,
  deleteIncidentReportRequest,
} from './actions';
import axios from 'axios';
import { INCIDENT_REPORTS, INCIDENT_REPORTS_V2 } from 'app/api-routes';
import appConfig from 'config';

function* getIncidentReportLinkSaga({ payload: reportId }) {
  try {
    const { payload } = yield axios.get(`${INCIDENT_REPORTS}/${reportId}/report`);

    if (payload) {
      yield put(incidentReportLinkSuccess({ reportId, link: `${appConfig.serverURL}/api/mediaassets/stream/${payload}`}));
    }
  } catch (err) {
    yield put(incidentReportLinkFailure(err));
  }
}


function* incidentReportsSaga() {
  try {
    const { payload } = yield axios.get(INCIDENT_REPORTS);

    yield put(incidentReportsSuccess(payload));
  } catch (err) {
    yield put(incidentReportsFailure(err));
  }
}

export function* incidentReportSaga({ payload: { reportId, errorCallback }}) {
  try {
    const { payload } = yield axios.get(`${INCIDENT_REPORTS}/${reportId}`);

    yield put(incidentReportSuccess(payload));
  } catch (err) {
    yield put(incidentReportFailure(err));
    errorCallback && errorCallback();
  }
}

function* createIncidentReportSaga({ payload: { report, callback } }) {
  try {
    const { payload: response } = yield axios.post(INCIDENT_REPORTS, {
      payload: report,
    });
    yield put(createIncidentReportSuccess({ ...response }));
    if (callback) {
      callback(response.reportId);
    }
  } catch (err) {
    yield put(createIncidentReportFailure(err));
  }
}

function* editIncidentReportSaga({ payload: { report, reportId, callback } }) {
  try {
    yield axios.put(`${INCIDENT_REPORTS}/${reportId}`, { payload: report });

    yield call(incidentReportSaga, { payload: { reportId } });
    if (callback) {
      callback(reportId);
    }
  } catch (err) {
    yield put(editIncidentReportFailure(err));
  }
}

function* deleteIncidentReportSaga({ payload: { reportID, password } }) {
  try {
    yield axios.delete(`${INCIDENT_REPORTS_V2}/${reportID}`, { data: { payload: password } });

    yield put(deleteIncidentReportSuccess(reportID))
  } catch (err) {
    yield put(deleteIncidentReportFailure(err));
  }
}

export default function*() {
  yield all([
    yield takeLatest(incidentReportsRequest, incidentReportsSaga),
    yield takeLatest(incidentReportRequest, incidentReportSaga),
    yield takeLatest(createIncidentReportRequest, createIncidentReportSaga),
    yield takeLatest(editIncidentReportRequest, editIncidentReportSaga),
    yield takeLatest(deleteIncidentReportRequest, deleteIncidentReportSaga),
    yield takeLatest(incidentReportLinkRequest, getIncidentReportLinkSaga),
  ]);
}
