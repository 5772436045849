import React, { useCallback } from 'react';
import * as auth from 'ducks/auth';
import { Redirect, Route as ReactRoute } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BASE_APP_PATH } from './constants';
import { TermsAgreement } from 'new-design/components/TermsAgreement';

const LoginRoute = ({ component: Component, ...rest }) => {
  const { sessionID, termsInfo } = useSelector(auth.selectors);

  const renderHandler = useCallback((props) => {
    return sessionID && termsInfo?.acceptRequired && termsInfo?.termsChanged
    ? <Redirect
        to={{
          pathname: BASE_APP_PATH,
          state: {
            from: props.location
        }}}
      />
    : <>
        <Component {...props} />
        <TermsAgreement />
      </>;
  }, [sessionID]);

  return <ReactRoute {...rest} render={renderHandler} />;
}

export { LoginRoute };
