import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { notificationsSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return initialState;
    },
    [notificationsSuccess](state, { payload }) {
      return payload;
    },
  },
  initialState,
);
