import { SITES } from 'app/api-routes';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  siteInfoCPFailure,
  siteInfoCPRequest,
  siteInfoCPSuccess,
  updateSitePlanOrderFailure,
  updateSitePlanOrderRequest,
  updateSitePlanOrderSuccess,
} from './actions';

function* siteInfoCPSaga({ payload: siteID }) {
  try {
    const { payload: siteInfoCP } = yield axios.get(`${SITES}/${siteID}/checkpoints`, {
      params: {
        includeInactive: true,
      },
    });

    yield put(siteInfoCPSuccess(siteInfoCP));
  } catch (err) {
    yield put(siteInfoCPFailure(err));
  }
}

function* updateSitePlanOrderSaga({ payload: { siteID, entities } }) {
  try {
    yield axios.put(`${SITES}/${siteID}/site-plan/reorder`, { payload: entities });

    yield call(siteInfoCPSaga, { payload: siteID });
    yield put(updateSitePlanOrderSuccess(siteID));
  } catch (err) {
    yield put(updateSitePlanOrderFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(siteInfoCPRequest, siteInfoCPSaga),
    yield takeLatest(updateSitePlanOrderRequest, updateSitePlanOrderSaga),
  ]);
}
