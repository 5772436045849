import isEqual from 'react-fast-compare';
import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { noteTypesSuccess } from './actions';

import initialState from './states';

export default handleActions(
  {
    [noteTypesSuccess](
      state,
      {
        payload: { table, noteTypes },
      },
    ) {
      if (isEqual(state[table], noteTypes)) {
        return state;
      }

      return {
        ...state,
        [table]: Object.freeze(noteTypes),
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  {
    initialState,
  },
);
