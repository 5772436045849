import preparedReducer from './reducer';
import {
  requestVisitLocations,
  createVisitLocation,
  updateVisitLocation,
  updateVisitLocationStatus,
  deleteVisitLocation,
} from './actions';
import { VISIT_LOCATION_TYPES } from './types';
import { ENTITY_STATUS } from 'app/types/constants';

export const reducer = preparedReducer;

export const SLICE_NAME = 'visitLocations';

export const selectors = (state) => state[SLICE_NAME];

export const selectVisitLocationStatistics = (state) =>
  state[SLICE_NAME].list.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: {
        all: acc[curr.type].all + 1,
        active: acc[curr.type].active + (curr.status === ENTITY_STATUS.ACTIVE ? 1 : 0),
        inactive: acc[curr.type].inactive + (curr.status === ENTITY_STATUS.SUSPENDED ? 1 : 0),
      },
    }),
    {
      [VISIT_LOCATION_TYPES.AREA]: {
        all: 0,
        active: 0,
        inactive: 0,
      },
      [VISIT_LOCATION_TYPES.LOCATION]: {
        all: 0,
        active: 0,
        inactive: 0,
      },
    }
  );

export const actions = {
  requestVisitLocations,
  createVisitLocation,
  updateVisitLocation,
  updateVisitLocationStatus,
  deleteVisitLocation,
};
