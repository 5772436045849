import React, { useEffect, useState } from 'react';
import DateTimePickerNew from 'react-datetime-picker';
import { createStyles, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ArrowDownIcon, ArrowUpIcon, CalendarIcon } from "assets/icons";
import moment from 'moment';
import { formatLocalDateTime } from 'util/formatting';
import { DATE_TIME_FORMAT_SHORT } from 'app/types/constants';

const useStyles = makeStyles((theme) => createStyles(
  {
    label: {
      fontSize: 16,
      color: theme.palette.secondary.main,
      lineHeight: '24px',
      fontWeight: 'normal',
      marginBottom: 10,
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        lineHeight: '21px',
        marginBottom: 5,
      },
    },
    calendarWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: 8,
      border: `1px solid rgba(0, 0, 0, 0.23)`,
      padding: 4,
      paddingLeft: 13,
      paddingRight: 0,
      height: 43,
  
      '& .react-datetime-picker--disabled': {
        background: 'transparent !important',
      },

      '& .react-datetime-picker__inputGroup__leadingZero': {
        color: 'black',
        fontSize: 14,
        fontFamily: 'Poppins',
      },
  
      '& .react-datetime-picker__inputGroup__input': {
        minWidth: 0,
      },
  
      '& input': {
        fontSize: 14,
        fontFamily: 'Poppins',
      },
  
      '& svg.pre-icon': {
        width: 20,
        minWidth: 20,
        height: 20,
        marginRight: 6,
  
        '& path': {
          fill: '#8A8A8A',
        }
      },
    },
    calendar: {
      width: '100%',
      '& .react-datetime-picker__wrapper': {
        border: 'none',
      },
    },
    calendarModal: {
      border: 'none',
      borderRadius: 4,
      boxShadow: '0px 4px 30px rgb(0 0 0 / 8%)',
    },
  }
), {
  name: 'DateTimePicker',
});

function DateTimePicker({
  onChange,
  label,
  defaultValue,
  infinityEndDate,
  dateOnly,
  readOnly,
  emptyInput,
}) {
  const classes = useStyles();
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const [value, setValue] = useState(emptyInput ? '' : moment(defaultValue).toDate());

  useEffect(() => {
    if (!emptyInput) {
      setValue(moment(defaultValue).toDate());
    }
  }, [defaultValue])

  return (
    <>
      {!!label && <Typography className={classes.label}>{label}</Typography>}
      <Box className={classes.calendarWrapper}>
        <CalendarIcon className="pre-icon" />
        <DateTimePickerNew
          disableClock
          calendarIcon={isCalendarOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}
          clearIcon={null}
          onChange={(value) => {
            setValue(value);
            onChange?.(formatLocalDateTime(value, DATE_TIME_FORMAT_SHORT));
          }}
          value={value}
          disabled={readOnly}
          format={dateOnly ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'}
          className={classes.calendar}
          calendarClassName={classes.calendarModal}
          onCalendarOpen={() => setIsCalendarOpened(true)}
          onCalendarClose={() => setIsCalendarOpened(false)}
          maxDate={infinityEndDate ? null : new Date()}
        />
      </Box>
    </>
  );
}

export { DateTimePicker };
