import { all, put, select, takeLatest } from 'redux-saga/effects';

import {
  companyFailure,
  companyRequest,
  companySuccess,
  disableVisitorLogFailure,
  disableVisitorLogRequest,
  disableVisitorLogSuccess,
  enableVisitorLogFailure,
  enableVisitorLogRequest,
  enableVisitorLogSuccess,
} from './actions';
import axios from 'axios';
import { COMPANIES } from 'app/api-routes';

function* companySaga() {
  try {
    const { companyInfo } = yield select((state) => state.auth);

    const { payload } = yield axios.get(`${COMPANIES}/${companyInfo.companyID}`);

    yield put(companySuccess(payload));
  } catch (err) {
    yield put(companyFailure(err));
  }
}

function* enableVisitorLogSaga() {
  try {
    const { companyInfo } = yield select((state) => state.auth);

    yield axios.put(`${COMPANIES}/${companyInfo.companyID}/visitor-log/enable`);

    yield put(enableVisitorLogSuccess(companyInfo.companyID));
  } catch (err) {
    yield put(enableVisitorLogFailure(err));
  }
}

function* disableVisitorLogSaga() {
  try {
    const { companyInfo } = yield select((state) => state.auth);

    yield axios.put(`${COMPANIES}/${companyInfo.companyID}/visitor-log/disable`);

    yield put(disableVisitorLogSuccess(companyInfo.companyID));
  } catch (err) {
    yield put(disableVisitorLogFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(companyRequest, companySaga),
    yield takeLatest(enableVisitorLogRequest, enableVisitorLogSaga),
    yield takeLatest(disableVisitorLogRequest, disableVisitorLogSaga),
  ]);
}
