/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { createStyles, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ArrowDownIcon, ArrowUpIcon } from "assets/icons";

const useStyles = makeStyles(() => createStyles(
  {
    container: {
      position: 'relative',
      display: 'inline-block',
    },
    inputItem: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      padding: '0 4px',
      minWidth: 'auto',
    },
    optionsContainer: {
      border: 1,
      backgroundColor: 'white',
      maxHeight: 200,
      overflow: 'auto',
      position: 'absolute',
      padding: '4px 0',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
      borderRadius: 8,

      '&.hidden': {
        display: 'none',
      },

      '&.bottom': {
        bottom: 25,
      },
      '&.bottomSelect': {
        bottom: 35,
      }
    },
    optionItem: {
      width: '100%',
      padding: '8px',
      minWidth: 'auto',
      borderRadius: 0,
      '&.active': {
        backgroundColor: 'lightgray',
      },
      '& .MuiButton-label': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '18px',
      }
    },
    selectItem: {
      border: '1px solid #E5E5E5',

      '& .MuiButton-label': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '18px',
      }
    }
  }
), {
  name: 'DateTimePickerSelector',
});

export const Selector = ({ value, options, name, onChange, alignOptionsToBottom, inputType = 'label' }) => {
  const classes = useStyles();

  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    if (!open) return;
    const elm = ref.current?.querySelector(`button[data-id="${name}-${value}"]`);
    ref.current.scrollTo(0, elm.offsetTop - 105);
  }, [value, open])

  return (
    <ClickAwayListener onClickAway={() => {
      setAnchorEl(null);
    }}>
      <div className={classes.container}>
        {
          inputType === 'label' && (
            <Button onClick={handleClick} className={classes.inputItem}>
              {value}
            </Button>
          )
        }
        {
          inputType === 'select' && (
            <Button
              onClick={handleClick}
              className={classes.selectItem}
              endIcon={open ? <ArrowUpIcon /> : <ArrowDownIcon />}
            >
              {value}
            </Button>
          )
        }
        <Box ref={ref} className={clsx(classes.optionsContainer, {
          hidden: !open,
          bottom: alignOptionsToBottom,
          bottomSelect: alignOptionsToBottom && inputType === 'select'
        })}>
          {
            options.map(({ option, disabled, index }) => (
              <Button
                disabled={disabled}
                className={clsx(classes.optionItem, {
                  active: value === option
                })}
                data-id={`${name}-${option}`}
                key={`${name}-${option}`}
                onClick={() => {
                  onChange?.(option);
                  setAnchorEl(null);
                }}
              >
                {option}
              </Button>
            ))
          }
        </Box>
      </div>
    </ClickAwayListener>
  );
};
