import React from 'react';
import Modal from 'new-design/components/Modal';
import { Button } from 'new-design/common/ui';
import { useIntl } from 'util/IntlMessages';
import { Box, Typography } from '@material-ui/core';

import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles(
  {
    modalTitle: {
      fontSize: 32,
      fontWeight: 600,
      textAlign: 'center',
      color: theme.palette.common.black,
      [theme.breakpoints.down('lg')]: {
        fontSize: 24,
      }
    },
    modalDescription: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'center',
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
      }
    },
    actions: {
      display: 'flex',
      marginTop: 48,
      justifyContent: 'center'
    },
    actionLeft: {
      width: '50%',
      marginRight: 8,
    },
    actionRight: {
      width: '50%',
      marginLeft: 8,
      minHeight: 47,
    },
  }
), {
  name: 'ConfirmationModal',
});

export const ConfirmationModal = ({
  title,
  description,

  customConfirmMessage,
  customCancelMessage,

  modalOpened,
  onModalCancel: handleModalCancel,
  onModalConfirm: handleModalConfirm,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Modal open={modalOpened}>
      <Box>
        <Typography className={classes.modalTitle}>{title}</Typography>
        {!!description && (
          <Box marginTop={2}>
            <Typography className={classes.modalDescription}>{description}</Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.actions}>
        {!!handleModalCancel && <Box className={classes.actionLeft}>
          <Button 
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleModalCancel}
            className={classes.actionButton}
          >
            {customCancelMessage || formatMessage({ id: 'cancel' })}
          </Button>
        </Box>}
        {!!handleModalConfirm && <Box className={classes.actionRight}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.actionButton}
            onClick={handleModalConfirm}
          >
            {customConfirmMessage || formatMessage({ id: 'confirm' })}
          </Button>
        </Box>}
      </Box>
    </Modal>
  );
}
