import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useField } from 'formik';
import { Autocomplete } from '../Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      smallSelect: {
        borderRadius: 8,
        '& .MuiInputBase-root': {
          height: 48,
          [theme.breakpoints.down('lg')]: {
            maxHeight: 40,
            minHeight: 40,
          },
          '& input': {
            padding: '2.5px 4px !important',
            '&::placeholder': {
              fontSize: 16,
              [theme.breakpoints.down('lg')]: {
                fontSize: 14,
              },
            },
          },
        },
      },
    }),
  {
    name: 'FormAutocompleteField ',
  }
);

export const FormAutocompleteField = ({
  label,
  name,
  options,
  icon,
  placeholder,
  fullWidth,
  className,
  onBlur,
  readOnly,
  size,
}) => {
  const [{ value }, meta, helpers] = useField(name);
  const classes = useStyles();

  const handleBlur = useCallback(
    (fieldOnBlur) => (e) => {
      fieldOnBlur(e);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  return (
    <Box className={className}>
      <Autocomplete
        name={name}
        options={options}
        icon={icon}
        fullWidth={fullWidth}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={(value) => {
          helpers.setValue(value);
        }}
        disabled={readOnly}
        readOnly={readOnly}
        defaultValue={value}
        error={!!meta.error}
        helperText={meta.error}
        label={label}
        className={clsx({
          [classes.smallSelect]: size === 'small',
        })}
      />
    </Box>
  );
};
