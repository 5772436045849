import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles(
  {
    root: {
      padding: 30,
      height: ({ fullHeight }) => fullHeight ? '100%' : 'initial',
      background: theme.palette.common.white,
      borderRadius: 24,
      display: 'flex',
      flexDirection: 'column',
      alignItems: ({ itemPosition }) => itemPosition || 'center',
      [theme.breakpoints.down('lg')]: {
        padding: 15,
        borderRadius: 12,
      },
      '& .default-icon path': {
        fill: theme.palette.secondary.main,
      },
      '& .custom-icon-rect rect': {
        fill: theme.palette.secondary.main,
      },
      '& .custom-icon-fill path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
), {
  name: 'Card'
});

export { useStyles };
