import React from 'react';
import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Card from '../../../../components/Card';
import FormTextField from '../FormTextField';
import { ArrowLeftIcon } from 'assets/icons';
import { IconButton } from 'new-design/common/ui/IconButton';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => createStyles(
  {
    titleCard: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',

      '& svg': {
        [theme.breakpoints.down('lg')]: {
          width: 20,
          height: 20,
        },
        '&.stroke path': {
          stroke: theme.palette.secondary.main,
        },
      },

      '& .name': {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '36px',
        color: theme.palette.common.black,
        [theme.breakpoints.down('lg')]: {
          fontSize: 18,
          lineHeight: '24px',
        }
      },

      '& .checkbox .MuiFormControlLabel-label': {
        [theme.breakpoints.down('lg')]: {
          fontSize: 12,
        }
      }
    },
    inputsContainer: {
      width: '100%',
      padding: 30,
      [theme.breakpoints.down('lg')]: {
        padding: 15,
      },
      minHeight: 350,
      '& .f-input': {
        width: '100%',
      },
    },
    back: {
      marginRight: 8,
    }
  },
), {
  name: 'NoteListItemField',
});

const ItemField = ({ label, name, withNavigation, onClose, className, placeholder, readOnly }) => {
  const classes = useStyles();

  return (
    <>
      {
        withNavigation &&
        <Card className={classes.titleCard}>
          <IconButton onClick={onClose} className={classes.back}>
            <ArrowLeftIcon className="stroke" />
          </IconButton>
          <Typography className="name">{label}</Typography>
        </Card>
      }
      <Card className={classes.inputsContainer} itemPosition="start">
        <FormTextField name={name}
                       placeholder={placeholder}
                       multiline
                       minRows={10}
                       className={clsx(className)}
                       fullWidth
                       readOnly={readOnly}
        />
      </Card>
    </>
  );
}

export default ItemField;
