import preparedReducer from './reducer';
import { requestNotebookNote, requestShiftNote } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'notebookNotes';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestNotebookNote,
  requestShiftNote,
};

export const getNotebookNote = (state, notebookNoteID) => state.notebookNotes[notebookNoteID];
