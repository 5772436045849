import React, { useCallback, useEffect } from 'react';
import { Menu } from 'new-design/common/ui';
import NotificationsList from './NotificationsList';
import { bindActionCreators } from 'redux';
import * as notifications from 'ducks/notifications';
import * as auth from 'ducks/auth';
import { UserTypes } from 'ducks/roles/types';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { BellComponent } from './BellComponent';

const GET_NOTIFICATIONS_INTERVAL = 5000;
const Notifications = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { requestNotifications } = bindActionCreators(notifications.actions, useDispatch());
  const { userInfo } = useSelector(auth.selectors);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userInfo?.userType !== UserTypes.CLIENT) {
        requestNotifications();
      }
    }, GET_NOTIFICATIONS_INTERVAL);

    return () => clearInterval(interval);
  }, [userInfo]);

  const handleModalOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleModalClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      {userInfo?.userType !== UserTypes.CLIENT && (
        <BellComponent handleModalOpen={handleModalOpen} isOpened={anchorEl} />
      )}
      <Menu
        id="customized-menu-notifications"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleModalClose}
        className={classes.menu}
        style={{
          background: 'transparent',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.notificationsPopup}>
          <NotificationsList onClose={handleModalClose} />
        </div>
      </Menu>
    </>
  );
};

export default Notifications;
