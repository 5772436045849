import preparedReducer from './reducer';
import { requestHoursPerDate } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'hoursPerDate';

export const selectors = state => state[SLICE_NAME];
export const getHoursPerDate = (state, siteID) => state.hoursPerDate[siteID];

export const actions = {
  requestHoursPerDate,
};
