import { ACTIVITIES } from 'app/api-routes';
import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { formatMonthIntervalUTC } from 'util/formatting';
import { statsPerDateFailure, statsPerDateRequest, statsPerDateSuccess } from './actions';

function* statsPerDateSaga({ payload: { date, startDate, endDate, selectedSite } }) {
  const siteID = selectedSite;
  let fromDate, toDate;
  if (startDate != null && endDate != null) {
    fromDate = startDate;
    toDate = endDate;
  } else {
    [fromDate, toDate] = formatMonthIntervalUTC(date);
  }
  try {
    const { payload } = yield axios.get(ACTIVITIES, {
      params: {
        fromDate,
        toDate,
        siteID,
        includeTotals: true,
      },
    });

    yield put(
      statsPerDateSuccess({
        siteID,
        statsPerDate: payload,
      }),
    );
  } catch (err) {
    yield put(statsPerDateFailure(err));
  }
}

export default function* () {
  yield all([yield takeLatest(statsPerDateRequest, statsPerDateSaga)]);
}
