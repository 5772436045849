import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import {
  excelFormResultsSuccess,
  formResultInfoSuccess,
  formResultReportSuccess,
  formResultsSuccess,
} from './actions';
import initialState from './states';

export default handleActions(
  {
    [formResultsSuccess](state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    [formResultInfoSuccess](state, { payload: { formResultID, payload } }) {
      return {
        ...state,
        info: {
          ...state.info,
          [formResultID]: payload,
        },
      };
    },
    [formResultReportSuccess](state, { payload: { formResultID, link } }) {
      return {
        ...state,
        reportLinks: {
          ...state.reportLinks,
          [formResultID]: link,
        },
      };
    },
    [excelFormResultsSuccess](state, { payload }) {
      return {
        ...state,
        excelData: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
