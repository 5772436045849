import preparedReducer from './reducer';
import { requestEmployees, createEmployee, updateEmployee, deleteEmployee, updateEmployeeForm, getUser } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'employees';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestEmployees,
  createEmployee,
  updateEmployee,
  deleteEmployee,
  updateEmployeeForm,
  getUser,
};
