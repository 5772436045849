import React from 'react';
import { Route } from 'react-router-dom';
// TODO: refactoring.
import asyncComponent from 'util/asyncComponent';

const AdminRoutes = ({ match }) =>
  <>
    <Route path={`${match.url}/settings`} exact component={(asyncComponent(() => import('../pages/admin/Settings')))}/>
    <Route path={`${match.url}/my-account`} exact component={(asyncComponent(() => import('../pages/admin/MyAccount')))}/>

    <Route path={`${match.url}/system-log`} exact component={(asyncComponent(() => import('../pages/admin/SystemLog')))}/>
    <Route path={`${match.url}/system-log/:id`} exact component={(asyncComponent(() => import('../pages/admin-subpages/SystemLog')))}/>

    <Route path={`${match.url}/forms`} exact component={(asyncComponent(() => import('../pages/admin/Forms')))}/>
    <Route path={`${match.url}/forms/:id`} exact component={(asyncComponent(() => import('../pages/admin-subpages/Forms')))}/>
  </>;

export { AdminRoutes };
