import { all, put, takeLatest } from 'redux-saga/effects';

import { taskLogEventRequest, taskLogEventSuccess, taskLogEventFailure } from './actions';

import axios from 'axios';
import { TASK_LOG } from 'app/api-routes';

function* taskLogEventSaga({ payload: taskLogID }) {
  try {
    const { payload } = yield axios.get(`${TASK_LOG}/${taskLogID}`, {});

    yield put(taskLogEventSuccess(payload));
  } catch (err) {
    yield put(taskLogEventFailure(err));
  }
}

export default function* () {
  yield all([yield takeLatest(taskLogEventRequest, taskLogEventSaga)]);
}
