import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const formResultsRequest = createRequest(constants.formResults);
export const formResultsSuccess = createSuccess(constants.formResults);
export const formResultsFailure = createFailure(constants.formResults);

export const requestFormResults = formResultsRequest;

export const excelFormResultsRequest = createRequest(constants.excelFormResults);
export const excelFormResultsSuccess = createSuccess(constants.excelFormResults);
export const excelFormResultsFailure = createFailure(constants.excelFormResults);

export const requestExcelFormResults = excelFormResultsRequest;

export const formResultInfoRequest = createRequest(constants.formResultInfo);
export const formResultInfoSuccess = createSuccess(constants.formResultInfo);
export const formResultInfoFailure = createFailure(constants.formResultInfo);

export const requestFormResultInfo = formResultInfoRequest;

export const submitFormResultRequest = createRequest(constants.submitFormResult);
export const submitFormResultSuccess = createSuccess(constants.submitFormResult);
export const submitFormResultFailure = createFailure(constants.submitFormResult);

export const submitFormResult = submitFormResultRequest;

export const approveFormResultRequest = createRequest(constants.approveFormResult);
export const approveFormResultSuccess = createSuccess(constants.approveFormResult);
export const approveFormResultFailure = createFailure(constants.approveFormResult);

export const approveFormResult = approveFormResultRequest;

export const updateFormResultRequest = createRequest(constants.updateFormResult);
export const updateFormResultSuccess = createSuccess(constants.updateFormResult);
export const updateFormResultFailure = createFailure(constants.updateFormResult);

export const updateFormResult = updateFormResultRequest;

export const deleteFormResultRequest = createRequest(constants.deleteFormResult);
export const deleteFormResultSuccess = createSuccess(constants.deleteFormResult);
export const deleteFormResultFailure = createFailure(constants.deleteFormResult);

export const deleteFormResult = deleteFormResultRequest;

export const formResultReportRequest = createRequest(constants.formResultReport);
export const formResultReportSuccess = createSuccess(constants.formResultReport);
export const formResultReportFailure = createFailure(constants.formResultReport);

export const requestFormResultReport = formResultReportRequest;
