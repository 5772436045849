import React from 'react';
import { Route } from 'react-router-dom';
// TODO: refactoring.
import asyncComponent from 'util/asyncComponent';

const ManagementRoutes = ({ match }) =>
  <>
    <Route path={`${match.url}/employees/:id`} exact component={(asyncComponent(() => import('../pages/management-subpages/Employee')))}/>
    <Route path={`${match.url}/employees`} exact component={(asyncComponent(() => import('../pages/management/Employees')))}/>
    
    <Route path={`${match.url}/clients/:id`} exact component={(asyncComponent(() => import('../pages/management-subpages/Client')))} />
    <Route path={`${match.url}/clients`} exact component={(asyncComponent(() => import('../pages/management/Clients')))}/>

    <Route path={`${match.url}/contacts/:id`} exact component={(asyncComponent(() => import('../pages/management-subpages/Contacts')))} />
    <Route path={`${match.url}/contacts`} exact component={(asyncComponent(() => import('../pages/management/Contacts')))}/>

    <Route path={`${match.url}/sites/:id`} exact component={(asyncComponent(() => import('../pages/management-subpages/Site')))} />
    <Route path={`${match.url}/sites`} exact component={(asyncComponent(() => import('../pages/management/Sites')))}/>

    <Route path={`${match.url}/mobile-units/:id`} exact component={(asyncComponent(() => import('../pages/management-subpages/MobileUnits')))} />
    <Route path={`${match.url}/mobile-units`} exact component={(asyncComponent(() => import('../pages/management/MobileUnits')))}/>

    <Route path={`${match.url}/tasks`} exact component={(asyncComponent(() => import('../pages/management/Tasks')))}/>
    <Route path={`${match.url}/tasks/:id`} exact component={(asyncComponent(() => import('../pages/management-subpages/Tasks')))}/>

    <Route path={`${match.url}/tours`} exact component={(asyncComponent(() => import('../pages/management/Tours')))}/>
    <Route path={`${match.url}/tours/:id`} exact component={(asyncComponent(() => import('../pages/management-subpages/Tours')))}/>

    <Route path={`${match.url}/visitors`} exact component={(asyncComponent(() => import('../pages/management/Visitors')))}/>
    <Route path={`${match.url}/visitors/:id`} exact component={(asyncComponent(() => import('../pages/management-subpages/Visitors')))}/>
  </>;

export { ManagementRoutes };
