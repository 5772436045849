import preparedReducer from './reducer';
import { requestNoteTypes, requestNoteTypesUpdate } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'noteTypes';

export const selectors = state => state[SLICE_NAME];

export const getNoteTypes = (type) => (state) => state[SLICE_NAME][type];

export const actions = {
  requestNoteTypes,
  requestNoteTypesUpdate,
};
