import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const incidentReportsRequest = createRequest(constants.incidentReports);
export const incidentReportsSuccess = createSuccess(constants.incidentReports);
export const incidentReportsFailure = createFailure(constants.incidentReports);
export const requestIncidentReports = incidentReportsRequest;

export const createIncidentReportRequest = createRequest(constants.createIncidentReport);
export const createIncidentReportSuccess = createSuccess(constants.createIncidentReport);
export const createIncidentReportFailure = createFailure(constants.createIncidentReport);
export const createIncidentReport = createIncidentReportRequest;

export const editIncidentReportRequest = createRequest(constants.editIncidentReport);
export const editIncidentReportSuccess = createSuccess(constants.editIncidentReport);
export const editIncidentReportFailure = createFailure(constants.editIncidentReport);
export const editIncidentReport = editIncidentReportRequest;

export const deleteIncidentReportRequest = createRequest(constants.deleteIncidentReport);
export const deleteIncidentReportSuccess = createSuccess(constants.deleteIncidentReport);
export const deleteIncidentReportFailure = createFailure(constants.deleteIncidentReport);
export const deleteIncidentReport = deleteIncidentReportRequest;

export const incidentReportRequest = createRequest(constants.incidentReport);
export const incidentReportSuccess = createSuccess(constants.incidentReport);
export const incidentReportFailure = createFailure(constants.incidentReport);
export const requestIncidentReport = incidentReportRequest;

export const incidentReportLinkRequest = createRequest(constants.reportLink);
export const incidentReportLinkSuccess = createSuccess(constants.reportLink);
export const incidentReportLinkFailure = createFailure(constants.reportLink);
export const requestIncidentReportLink = incidentReportLinkRequest;
