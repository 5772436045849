import { ALARM, GPS_ALERT, INACTIVITY } from 'app/types/events';
import config from 'config';
import _ from 'lodash';
import './infobubble';

const GOOGLE_SCRIPT_ID = 'google-map-script';

const createMarker = (location, map) => (
  new window.google.maps.Marker({
    position: { lat: location.lat, lng: location.lng },
    icon: location.icon ? {
      ...location.icon,
      scaledSize: 
        new window.google.maps.Size(
          location?.iconSize ? location?.iconSize?.w : 48,
          location?.iconSize ? location.iconSize?.h : 48
        ),
    } : null,
    moreData: {
      title: location.siteName || location.name,
      siteID: location.siteID,
      employees: location.employees,
    },
    map,
  })
);

// eslint-disable-next-line no-unused-vars
export const tooltips = {
  current: {},
};

const onHoverListeners = (marker, map) => {
  // eslint-disable-next-line no-undef
  const infoBubble = new InfoBubble({
    map: map,
    content: `<div class="g-tooltip">${marker.moreData.title}</div>`,
    shadowStyle: 0,
    padding: '5px',
    backgroundColor: 'transparent',
    arrowSize: 0,
    borderWidth: 0,
    borderColor: 'transparent',
    disableAutoPan: true,
    hideCloseButton: true,
    arrowPosition: 50,
    backgroundClassName: '_google-map-tooltip',
    maxWidth: 200,
  });
  if (marker.moreData.title) {
    tooltips.current[marker.moreData.title] = infoBubble;
  }
  // const infoWindow = new window.google.maps.InfoWindow({
  //   content: marker.moreData.title,
  // });
  window.google.maps.event.addListener(marker, 'mouseover', () => {
    if (!marker.moreData.title) return null;
    tooltips.current[marker.moreData.title]?.open(map, marker);
  });
  window.google.maps.event.addListener(marker, 'mouseout', () => {
    if (!marker.moreData.title) return null;
    tooltips.current[marker.moreData.title]?.close();
  });
}

const ZOOM_IN_DEFAULT = 18;
const markerListeners = (marker, map, { handleSelect }) => {
  onHoverListeners(marker, map);
  window.google.maps.event.addListener(marker, 'click', function() {
    if (!marker.moreData.title) return null;
    if (handleSelect) {
      handleSelect({
        ...marker.moreData,
        lat: marker.internalPosition.lat(),
        lng: marker.internalPosition.lng(),
      });
    }
    map.setCenter({
      lat: marker.internalPosition.lat(),
      lng: marker.internalPosition.lng() - (marker.moreData.employees.length ? 0.0015 : 0),
    });
    map.setZoom(ZOOM_IN_DEFAULT);
    tooltips.current[marker.moreData.title]?.close();
  });
}

const CAR_ZOOM_IN_DEFAULT = 15;
const carMarkerListeners = (marker, map, { handleMobileSelect }) => {
  onHoverListeners(marker, map);
  window.google.maps.event.addListener(marker, 'click', function() {
    if (handleMobileSelect) {
      handleMobileSelect({
        ...marker.moreData,
        lat: marker.internalPosition.lat(),
        lng: marker.internalPosition.lng(),
      });
    }
    map.setCenter({
      lat: marker.internalPosition.lat(),
      lng: marker.internalPosition.lng(),
    });
    map.setZoom(CAR_ZOOM_IN_DEFAULT);
  });
}

const showItem = ({ lat, lng }, map) => {
  setTimeout(() => {
    map.setCenter({
      lat,
      lng,
    });
    map.setZoom(ZOOM_IN_DEFAULT);
  }, 100);
}

export const NOT_ACTIVE_SITE = 'not_active';
export const ACTIVE_SITE = 'active';
export const INACTIVITY_ON_SITE = INACTIVITY;
export const GPS_ALERT_ON_SITE = GPS_ALERT;
export const ALARM_ON_SITE = ALARM;

export const getColor = type => {
  switch (type) {
    case ACTIVE_SITE:
      return '#42BD5D';
    case INACTIVITY_ON_SITE:
      return '#F78F1E';
    case GPS_ALERT_ON_SITE:
      return '#FF32AD';
    case ALARM_ON_SITE:
      return '#FF4C4C';
    default:
      return '#022CE6';
  }
}

// Set weight of the activity. 
export const getSiteWeight = (type) => {
  switch (type) {
    case ALARM_ON_SITE: return 4;
    case GPS_ALERT_ON_SITE: return 3;
    case INACTIVITY_ON_SITE: return 2;
    case ACTIVE_SITE: return 1;
    default: return 0;
  }
}

const rendererCluster = ({ count, position, markers }) => {
  // use d3-interpolateRgb to interpolate between red and blue
  // 0 - not active 
  // 1 - active 
  // 2 - inactivity
  // 3 - GPS alert
  // 4 - alarm
  let highestIconType = NOT_ACTIVE_SITE;
  markers.forEach(el => {
    if (getSiteWeight(el.icon.metaData.state) > getSiteWeight(highestIconType)) {
      highestIconType = el.icon.metaData.state;
    }
  });
  // create svg url with fill color
  const svg = window.btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" width="71" height="71" viewBox="0 0 71 71" fill="none">
      <circle opacity="0.4" cx="35.5" cy="35.5" r="35.5" fill="${getColor(highestIconType)}"/>
      <circle opacity="0.5" cx="36" cy="35" r="31" fill="${getColor(highestIconType)}"/>
      <rect x="11" y="10" width="50" height="50" rx="24" fill="${getColor(highestIconType)}"/>
    </svg>`
  );
  // create marker using svg icon
  return new window.google.maps.Marker({
      position,
      icon: {
          url: `data:image/svg+xml;base64,${svg}`,
          scaledSize: new window.google.maps.Size(45, 45),
      },
      label: {
          text: String(count),
          color: "rgba(255,255,255,0.9)",
          fontSize: "12px",
      },
      // adjust zIndex to be above other markers
      zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
  });
}

const addGoogleMapsScript = (afterLoadScriptCallback) => {
  if (document.getElementById(GOOGLE_SCRIPT_ID) !== null && afterLoadScriptCallback) {
    afterLoadScriptCallback();
    return;
  }

  const rootEl = document.getElementById('app-site');
  const script = document.createElement('script');
  script.src=`https://maps.googleapis.com/maps/api/js?key=${config.googleMapApiKey}`;
  script.async = true;
  script.id = GOOGLE_SCRIPT_ID;
  rootEl.parentNode.appendChild(script);
}

const setArea = (item, map) =>
  new window.google.maps.Circle({
    strokeColor: item.radiusColor || "#0000FF",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: item.radiusColor || "#0000FF",
    fillOpacity: 0.35,
    map,
    center: {
      lat: item.lat,
      lng: item.lng,
    },
    radius: item.radius,
  });

const setZoom = (map, zoom) => {
  map.setZoom(zoom);
}

const setZoomAndCenter = (items, map, zoom) => {
  if (items.length === 1) {
    if (zoom) {
      map.setZoom(zoom);
    }
    map.setCenter({
      lat: items[0].lat,
      lng: items[0].lng,
    });
  } else {
    const minLng = _.minBy(items, 'lng');
    const maxLng = _.maxBy(items, 'lng');
  
    const minLat = _.minBy(items, 'lat');
    const maxLat = _.maxBy(items, 'lat');
  
    const cLng = ((maxLng?.lng + minLng?.lng) || 0) / 2;
    map.setCenter({
      lat: ((maxLat?.lat + minLat?.lat) || 0) / 2,
      lng: cLng,
    });
    if (Math.abs(cLng) < 10) {
      map.setZoom(8);
    } else if (Math.abs(cLng) >= 10 && Math.abs(cLng) < 20) {
      map.setZoom(5);
    } else {
      map.setZoom(zoom || 2);
    }
  }  
}

export {
  createMarker,
  markerListeners,
  rendererCluster,
  addGoogleMapsScript,
  setZoomAndCenter,
  setArea,
  showItem,
  setZoom,
  carMarkerListeners,
}
