import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as sites from 'ducks/sites';
import * as mobilePatrols from 'ducks/mobile-patrols';
import * as employees from 'ducks/employees';
import * as clients from 'ducks/clients';
import { useMemo } from 'react';
import activityStatuses from 'app/types/status';
import {
  CheckpointIcon,
  StopwatchIcon,
  CheckboxIcon,
  TourIcon,
  DoorIcon,
  IncidentReportIcon,
  NoteIcon,
  AlarmTypeIcon,
  InfoIcon,
  GPSAlertIcon,
  NoActivityIcon,
  InfoNoteIcon,
  FormIcon,
  VisitorProfileIcon,
  OneSiteIcon,
  GroupIcon,
  MobileUnitsIcon,
  ClientsIcon,
} from 'assets/icons';
import IntlMessages, { useIntl } from 'util/IntlMessages';
import { formatFullName } from 'util/formatting';
import CodeTypes from 'ducks/codes/types';
import * as codes from 'ducks/codes';

const infoNoteEventTypeOptions = [
  {
    key: ['info_note_ack'],
    label: <IntlMessages id="cp.shift.info_note_ack" />,
  },
  {
    key: ['info_note_closed'],
    label: <IntlMessages id="cp.shift.info_note_closed" />,
  },
  {
    key: ['info_note_created'],
    label: <IntlMessages id="cp.shift.info_note_created" />,
  },
  {
    key: ['info_note_reopened'],
    label: <IntlMessages id="cp.shift.info_note_reopened" />,
  },
];

const groupedEventTypesOptions = [
  {
    key: ['alarm', 'processed_alarm', 'received_alarm'],
    label: <IntlMessages id="alarms" />,
    icon: AlarmTypeIcon,
  },
  {
    key: ['gps_alert'],
    label: <IntlMessages id="gps_alerts" />,
    icon: GPSAlertIcon,
  },
  {
    key: ['inactivity'],
    label: <IntlMessages id="inactivity" />,
    icon: NoActivityIcon,
  },
  {
    key: ['checkpoint', 'scan'],
    label: <IntlMessages id="cp_scans" />,
    icon: CheckpointIcon,
  },
  {
    key: ['note'],
    label: <IntlMessages id="cp.shift.notes" />,
    icon: NoteIcon,
  },
  {
    key: ['info_note_ack', 'info_note_closed', 'info_note_created', 'info_note_reopened'],
    label: <IntlMessages id="info_notes" />,
    icon: InfoNoteIcon,
  },
  {
    key: ['ir_created'],
    label: <IntlMessages id="irs" />,
    icon: IncidentReportIcon,
  },
  {
    key: ['form_created'],
    label: <IntlMessages id="forms" />,
    icon: FormIcon,
  },
  {
    key: ['start'],
    label: <IntlMessages id="shift_started" />,
    icon: StopwatchIcon,
  },
  {
    key: ['end'],
    label: <IntlMessages id="shift_ended" />,
    icon: StopwatchIcon,
  },
  {
    key: ['task_completed'],
    label: <IntlMessages id="cp.shift.tasks" />,
    icon: CheckboxIcon,
  },
  {
    key: ['tour_started'],
    label: <IntlMessages id="tour_started" />,
    icon: TourIcon,
  },
  {
    key: ['tour_ended'],
    label: <IntlMessages id="tour_ended" />,
    icon: TourIcon,
  },
  {
    key: ['visit_started'],
    label: <IntlMessages id="visit_started" />,
    icon: DoorIcon,
  },
  {
    key: ['visit_ended'],
    label: <IntlMessages id="visit_ended" />,
    icon: DoorIcon,
  },
];

const eventTypesAutocompleteOptions = [
  {
    key: ['alarm', 'gps_alert', 'processed_alarm', 'received_alarm'].join(','),
    label: 'Alarms', // <IntlMessages id="alarms" />,
  },
  {
    key: ['checkpoint', 'scan'].join(','),
    label: 'Checkpoints', // <IntlMessages id="cp.dashboard.checkpoints" />,
  },
  {
    key: ['note', 'info_note_ack', 'info_note_closed', 'info_note_created', 'info_note_reopened'].join(','),
    label: 'Notes', // <IntlMessages id="cp.shift.notes" />,
  },
  {
    key: ['ir_created'].join(','),
    label: 'IRs', // <IntlMessages id="irs" />,
  },
  {
    key: ['shift', 'start', 'end'].join(','),
    label: 'Shifts', // <IntlMessages id="cp.dashboard.shifts" />,
  },
  {
    key: ['task_completed'].join(','),
    label: 'Tasks', // <IntlMessages id="cp.shift.tasks" />,
  },
  {
    key: ['tour_ended', 'tour_started'].join(','),
    label: 'Tours', // <IntlMessages id="cp.shift.tours" />,
  },
  {
    key: ['visit_ended', 'visit_started'].join(','),
    label: 'Visits', // <IntlMessages id="cp.dashboard.visits" />,
  },
];

const eventTypesAllOptions = [
  {
    key: 'alarm',
    label: 'Alarms', // <IntlMessages id="alarms" />,
  },
  {
    key: 'gps_alert',
    label: 'GPS alert', // <IntlMessages id="gps_alert" />,
  },
  {
    key: 'processed_alarm',
    label: 'Processed alarm', // <IntlMessages id="processed_alarm" />,
  },
  {
    key: 'received_alarm',
    label: 'Received alarm', // <IntlMessages id="received_alarm" />,
  },
  {
    key: 'checkpoint',
    label: 'Checkpoint', // <IntlMessages id="checkpoint" />,
  },
  {
    key: 'scan',
    label: 'Scan', // <IntlMessages id="scan" />,
  },

  {
    key: 'note',
    label: 'Note', // <IntlMessages id="note" />,
  },
  {
    key: 'info_note_ack',
    label: 'Info note acknowledge', // <IntlMessages id="info_note_ack" />,
  },
  {
    key: 'info_note_closed',
    label: 'Info note closed', // <IntlMessages id="info_note_closed" />,
  },
  {
    key: 'info_note_created',
    label: 'Info note created', // <IntlMessages id="info_note_created" />,
  },
  {
    key: 'info_note_reopened',
    label: 'Info note reopened', // <IntlMessages id="info_note_reopened" />,
  },
  {
    key: 'ir_created',
    label: 'IRs', // <IntlMessages id="irs" />,
  },
  {
    key: 'shift',
    label: 'Shift', // <IntlMessages id="shift" />,
  },
  {
    key: 'start',
    label: 'Start', // <IntlMessages id="start" />,
  },
  {
    key: 'end',
    label: 'End', // <IntlMessages id="end" />,
  },
  {
    key: 'task_completed',
    label: 'Task complete', // <IntlMessages id="cp.shift.tasks" />,
  },
  {
    key: 'tour_ended',
    label: 'Tour ended', // <IntlMessages id="tour_ended" />,
  },
  {
    key: 'tour_started',
    label: 'Tour started', // <IntlMessages id="tour_started" />,
  },
  {
    key: 'visit_ended',
    label: 'Visit started', // <IntlMessages id="visit_ended" />,
  },
  {
    key: 'visit_started',
    label: 'Visit ended', // <IntlMessages id="visit_started" />,
  },
];

const eventTypesPerformanceOptions = [
  {
    key: 'alarm',
    label: <IntlMessages id="alarms" />,
    icon: AlarmTypeIcon,
  },
  {
    key: 'gps_alert',
    label: <IntlMessages id="gps_alerts" />,
    icon: GPSAlertIcon,
  },
  {
    key: 'scan',
    label: <IntlMessages id="cp_scans" />,
    icon: CheckpointIcon,
  },
  {
    key: 'note',
    label: <IntlMessages id="notes" />,
    icon: NoteIcon,
  },
  {
    key: 'info_note_created',
    label: <IntlMessages id="info_notes" />,
    icon: InfoNoteIcon,
  },
  {
    key: 'ir_created',
    label: <IntlMessages id="irs" />,
    icon: IncidentReportIcon,
  },
  {
    key: 'end',
    label: <IntlMessages id="shifts" />,
    icon: StopwatchIcon,
  },
  {
    key: 'task_completed',
    label: <IntlMessages id="cp.shift.tasks" />,
    icon: CheckboxIcon,
  },
  {
    key: 'tour_ended',
    label: <IntlMessages id="tours" />,
    icon: TourIcon,
  },
  {
    key: 'visit_ended',
    label: <IntlMessages id="visits" />,
    icon: DoorIcon,
  },
];

export const statuses = [
  {
    key: 'New',
    label: 'New',
    icon: InfoIcon,
  },
  {
    key: 'Approved',
    label: 'Approved',
    icon: InfoIcon,
  },
];

const groupedEventTypesForCharts = [
  {
    key: 'end',
    label: <IntlMessages id="shifts" />,
    icon: StopwatchIcon,
  },
  {
    key: 'visit_ended',
    label: <IntlMessages id="visits" />,
    icon: DoorIcon,
  },
  {
    key: 'tour_ended',
    label: <IntlMessages id="tours" />,
    icon: TourIcon,
  },
  {
    key: 'scan',
    label: <IntlMessages id="cp_scans" />,
    icon: CheckpointIcon,
  },
  {
    key: 'task_completed',
    label: <IntlMessages id="cp.shift.tasks" />,
    icon: CheckboxIcon,
  },
  {
    key: 'ir_created',
    label: <IntlMessages id="irs" />,
    icon: IncidentReportIcon,
  },
  {
    key: 'form_created',
    label: <IntlMessages id="forms" />,
    icon: FormIcon,
  },
  {
    key: 'note',
    label: <IntlMessages id="notes" />,
    icon: NoteIcon,
  },
  {
    key: 'visitor_entrance',
    label: <IntlMessages id="visits" />,
    icon: VisitorProfileIcon,
  },
  {
    key: 'alarm',
    label: <IntlMessages id="alarms" />,
    icon: AlarmTypeIcon,
  },
];

const groupedEntitiesTypesForCharts = [
  {
    key: 'sites',
    label: <IntlMessages id="sites" />,
    icon: OneSiteIcon,
  },
  {
    key: 'employee_users',
    label: <IntlMessages id="employees" />,
    icon: GroupIcon,
  },
  {
    key: 'clients',
    label: <IntlMessages id="clients" />,
    icon: ClientsIcon,
  },
  {
    key: 'mobile_patrols',
    label: <IntlMessages id="mobilePatrols" />,
    icon: MobileUnitsIcon,
  },
];

const predefinedOptions = [
  {
    key: 'this_month',
    label: <IntlMessages id="this_month" />,
  },
  {
    key: 'last_month',
    label: <IntlMessages id="last_month" />,
  },
  {
    key: 'this_year',
    label: <IntlMessages id="this_year" />,
  },
  {
    key: 'last_year',
    label: <IntlMessages id="last_year" />,
  },
];

export const useOptions = () => {
  const { formatMessage } = useIntl();
  const { list: sitesItems } = useSelector(sites.selectors);
  const { list: employeesItems } = useSelector(employees.selectors);
  const { list: clientsItems } = useSelector(clients.selectors);
  const { list: mobilePatrolsItems } = useSelector(mobilePatrols.selectors);
  const codesItems = useSelector(codes.selectors);

  const siteStatusOptions = useCallback(({ withAll }) => {
    const options = [
      {
        label: formatMessage({ id: 'active' }),
        key: 'A',
      },
      {
        label: formatMessage({ id: 'inactive' }),
        key: 'S',
      },
    ];

    if (withAll) {
      options.unshift({
        label: formatMessage({ id: 'all' }),
        key: 'all',
      });
    }
    return options;
  }, []);

  const infoNotesStatusOptions = [
    {
      key: 'all',
      label: formatMessage({ id: 'all' }),
    },
    {
      key: 'acknowledged',
      label: formatMessage({ id: 'acknowledged' }),
    },
    {
      key: 'not_acknowledged',
      label: formatMessage({ id: 'not_acknowledged' }),
    },
    {
      key: 'closed',
      label: formatMessage({ id: 'closed' }),
    },
  ];

  const getClientStatusOptions = useCallback(({ withAll }) => {
    const options = [
      {
        key: 'A',
        label: formatMessage({ id: 'active' }),
      },
      {
        key: 'S',
        label: formatMessage({ id: 'suspended' }),
      },
      {
        key: 'D',
        label: formatMessage({ id: 'deleted' }),
      },
    ];

    if (withAll) {
      options.unshift({
        label: formatMessage({ id: 'all' }),
        key: 'all',
      });
    }
    return options;
  }, []);

  const mpCityOptions = useMemo(
    () =>
      mobilePatrolsItems.map((item) => ({
        label: item.city,
        key: item.city,
      })),
    [mobilePatrolsItems]
  );

  const mpStatesOptions = useMemo(
    () =>
      mobilePatrolsItems.map((item) => ({
        label: item.province,
        key: item.province,
      })),
    [mobilePatrolsItems]
  );

  const sitesOptions = useMemo(
    () =>
      sitesItems
        .filter((el) => el.status === activityStatuses.ACTIVE)
        .map((item) => ({
          label: item.siteName,
          key: item.siteID,
          clientID: item.clientID,
        })),
    [sitesItems]
  );

  const employeesOptions = useMemo(
    () =>
      employeesItems.map((item) => ({
        label: formatFullName(item.firstName, item.lastName),
        key: item.userID,
      })),
    [employeesItems]
  );

  const clientsOptions = useMemo(
    () =>
      clientsItems.map((item) => ({
        label: item.clientName,
        key: item.clientID,
      })),
    [clientsItems]
  );

  const infoNotesCategoryOptions = useMemo(
    () =>
      (codesItems?.[CodeTypes.INFO_NOTE_CATEGORIES] || []).map((item) => ({
        label: item.description,
        key: item.id,
      })),
    [codesItems?.[CodeTypes.INFO_NOTE_CATEGORIES]]
  );

  const eventTypesOptions = useMemo(() => {
    const eventTypeCodes = (codesItems?.[CodeTypes.EVENT_TYPES] || []).map((item) => item.code);

    return groupedEventTypesOptions.filter((eventType) =>
      eventType.key.some((el) => eventTypeCodes.includes(el))
    );
  }, [codesItems?.[CodeTypes.EVENT_TYPES]]);

  return {
    mpCityOptions,
    mpStatesOptions,
    sitesOptions,
    employeesOptions,
    clientsOptions,
    statusesOptions: statuses,
    eventTypesOptions,
    infoNoteEventTypeOptions,
    eventTypesAutocompleteOptions,
    siteStatusOptions,
    getClientStatusOptions,
    infoNotesCategoryOptions,
    infoNotesStatusOptions,
    eventTypesAllOptions,
    eventTypesPerformanceOptions,
    groupedEventTypesForCharts,
    predefinedOptions,
    groupedEntitiesTypesForCharts,
  };
};
