import { handleActions } from 'redux-actions';
import { userManualSuccess, setIsLoading, setSelected } from './actions';
import { logoutSuccess } from 'ducks/auth/actions';
import initialState from './states';

export default handleActions(
  {
    [userManualSuccess](state, { payload }) {
      return {
        ...state,
        tree: payload,
      };
    },
    [setIsLoading](state, { payload }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
    [setSelected](state, { payload }) {
      return {
        ...state,
        selected: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
