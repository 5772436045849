import constants from './constants';
import { createFailure, createRequest, createSuccess } from 'ducks/helpers';

export const clientEmailSettingsRequest = createRequest(constants.CLIENT_EMAIL_SETTINGS);
export const clientEmailSettingsSuccess = createSuccess(constants.CLIENT_EMAIL_SETTINGS);
export const clientEmailSettingsFailure = createFailure(constants.CLIENT_EMAIL_SETTINGS);

export const enableClientEmailSettingRequest = createRequest(constants.ENABLE_CLIENT_EMAIL_SETTING);
export const enableClientEmailSettingSuccess = createSuccess(constants.ENABLE_CLIENT_EMAIL_SETTING);
export const enableClientEmailSettingFailure = createFailure(constants.ENABLE_CLIENT_EMAIL_SETTING);

export const disableClientEmailSettingRequest = createRequest(constants.DISABLE_CLIENT_EMAIL_SETTING);
export const disableClientEmailSettingSuccess = createSuccess(constants.DISABLE_CLIENT_EMAIL_SETTING);
export const disableClientEmailSettingFailure = createFailure(constants.DISABLE_CLIENT_EMAIL_SETTING);

export const clientEmailRecipientsRequest = createRequest(constants.CLIENT_EMAIL_RECIPIENTS);
export const clientEmailRecipientsSuccess = createSuccess(constants.CLIENT_EMAIL_RECIPIENTS);
export const clientEmailRecipientsFailure = createFailure(constants.CLIENT_EMAIL_RECIPIENTS);

export const updateClientEmailRecipientsRequest = createRequest(constants.UPDATE_CLIENT_EMAIL_RECIPIENTS);
export const updateClientEmailRecipientsSuccess = createSuccess(constants.UPDATE_CLIENT_EMAIL_RECIPIENTS);
export const updateClientEmailRecipientsFailure = createFailure(constants.UPDATE_CLIENT_EMAIL_RECIPIENTS);

export const requestClientEmailSettings = clientEmailSettingsRequest;
export const enableClientEmailSetting = enableClientEmailSettingRequest;
export const disableClientEmailSetting = disableClientEmailSettingRequest;
export const requestClientEmailRecipients = clientEmailRecipientsRequest;
export const updateClientEmailRecipients = updateClientEmailRecipientsRequest;
