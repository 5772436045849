import { store } from "../MainApp";
import axios from "axios";

import * as auth from 'ducks/auth';
import appConfig from '../config';
// import { LOGIN_PAGE } from 'new-design/routes/common/constants';

// Status code.
// const NOT_ACCEPTABLE = 406;
const UNAUTHORIZED = 401;

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent

  return {
    ...config,
    url: config.url.includes(appConfig.serverURL) ? config.url : `${appConfig.serverURL}${config.url}`,
    headers: {'Content-Type': 'application/json'},
    params: {
      ...config.params,
      sessionID: store.getState().auth?.sessionID,
    },
  };
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response?.data || {
    errors: [],
    payload: [],
    warnings: [],
  };
}, function (error) {
  // Handle expired token response error.
  if (
    error?.response?.status === UNAUTHORIZED
    && store.getState().auth?.sessionID
    && error?.config?.method === 'get'
  ) {
    store.dispatch(auth.actions.logoutSuccess());
    setTimeout(() => {
      store.dispatch(auth.actions.showExpiredModel());
    }, 10);
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});