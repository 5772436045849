import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { siteInfoCPSuccess } from './actions';
import initialState from './states';
import isEqual from 'react-fast-compare';
export default handleActions(
  {
    [logoutSuccess]() {
      return {
        ...initialState,
      };
    },
    [siteInfoCPSuccess](state, {payload}) {
      if (isEqual(state[payload.siteID], payload)) {
        return state;
      }

      return {
        ...state,
        [payload.siteID]: Object.freeze(payload),
      };
    },
  },
  {
    ...initialState,
  },
);
