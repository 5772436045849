import { createAction } from '../helpers';
import constants from './constants';
import preparedReducer from './reducer';

export const reducer = preparedReducer;

export const SLICE_NAME = 'credentials';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  updateCredentials: createAction(constants.update),
};
