import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';
import { siteInfoTasksSuccess } from './actions';
import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return {
        ...initialState,
      };
    },
    [siteInfoTasksSuccess](state, { payload: { siteID, data } }) {
      return {
        ...state,
        [siteID]: data,
      };
    },

  },
  {
    ...initialState,
  },
);
