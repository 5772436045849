import preparedReducer from './reducer';
import { requestTsByMobilePatrol} from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'tsByMobilePatrol';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestTsByMobilePatrol,
};
