import { SCHEDULES } from 'app/api-routes';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { REPEAT_TYPES } from 'app/types/constants';
import {
  scheduleSuccess,
  scheduleFailure,
  scheduleRequest,
  createScheduleRequest,
  createScheduleSuccess,
  createScheduleFailure,
  updateScheduleRequest,
  updateScheduleSuccess,
  updateScheduleFailure,
} from './actions';
import { formatTimeUTC } from 'util/formatting';

function mapConfigToList({ repeatType, config }) {
  switch (repeatType) {
    case REPEAT_TYPES.DAY:
      return config[REPEAT_TYPES.DAY].times
        .filter(({ value }) => !!value)
        .map(({ value }) => ({
          time: formatTimeUTC(value),
        }));

    case REPEAT_TYPES.WEEK:
      return Object.entries(config[REPEAT_TYPES.WEEK]).reduce(
        (acc, [weekday, { times, active }]) => [
          ...acc,
          ...times
            .filter(({ value }) => !!value)
            .map(({ value }) => ({ weekday, active, time: formatTimeUTC(value) })),
        ],
        []
      );

    case REPEAT_TYPES.MONTH:
      return [{ type: config[REPEAT_TYPES.MONTH].type }];

    case REPEAT_TYPES.YEAR:
      return [{ type: 'year' }];

    default:
      return [];
  }
}

function* scheduleSaga({ payload: { scheduleID } }) {
  try {
    const { payload } = yield axios.get(`${SCHEDULES}/${scheduleID}`);

    yield put(scheduleSuccess(payload));
  } catch (err) {
    yield put(scheduleFailure(err));
  }
}

function* createScheduleSaga({
  payload: { entityID, tableName, startDate, endDate, active, repeatType, config },
}) {
  try {
    const { payload: scheduleID } = yield axios.post(SCHEDULES, {
      payload: {
        entityID,
        tableName,
        startDate,
        endDate,
        active,
        repeatType,
        repeats: mapConfigToList({ repeatType, config }),
      },
    });

    yield call(scheduleSaga, { payload: { scheduleID } });
    yield put(createScheduleSuccess({ scheduleID }));
  } catch (err) {
    yield put(createScheduleFailure(err));
  }
}

function* updateScheduleSaga({
  payload: { scheduleID, entityID, tableName, startDate, endDate, active, repeatType, config },
}) {
  try {
    yield axios.put(`${SCHEDULES}/${scheduleID}`, {
      payload: {
        entityID,
        tableName,
        startDate,
        endDate,
        active,
        repeatType,
        repeats: mapConfigToList({ repeatType, config }),
      },
    });

    yield call(scheduleSaga, { payload: { scheduleID } });
    yield put(updateScheduleSuccess({ scheduleID }));
  } catch (err) {
    yield put(updateScheduleFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(scheduleRequest, scheduleSaga),
    yield takeLatest(createScheduleRequest, createScheduleSaga),
    yield takeLatest(updateScheduleRequest, updateScheduleSaga),
  ]);
}
