import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const siteInfoCPRequest = createRequest(constants.siteInfoCP);
export const siteInfoCPSuccess = createSuccess(constants.siteInfoCP);
export const siteInfoCPFailure = createFailure(constants.siteInfoCP);

export const updateSitePlanOrderRequest = createRequest(constants.updateSitePlanOrder);
export const updateSitePlanOrderSuccess = createSuccess(constants.updateSitePlanOrder);
export const updateSitePlanOrderFailure = createFailure(constants.updateSitePlanOrder);

export const requestSiteInfoCP = siteInfoCPRequest;
export const updateSitePlanOrder = updateSitePlanOrderRequest;
