import { all, put, takeLatest } from 'redux-saga/effects';

import { accountInfoRequest, accountInfoSuccess, accountInfoFailure } from './actions';

import axios from 'axios';
import { ACCOUNT_INFO, BILLING_HISTORY, SYSTEM_USAGE } from 'app/api-routes';

function* accountInfoSaga({ callback }) {
  try {
    const { payload: accountInfo } = yield axios.get(ACCOUNT_INFO);
    const { payload: billingHistory } = yield axios.get(BILLING_HISTORY);
    const { payload: systemUsage } = yield axios.get(SYSTEM_USAGE);

    yield put(accountInfoSuccess({
      ...accountInfo,
      ...billingHistory,
      usage: systemUsage,
    }));
    callback?.();
  } catch (err) {
    yield put(accountInfoFailure(err));
  }
}

export default function* () {
    yield all([yield takeLatest(accountInfoRequest, accountInfoSaga)]);
}
