import isEqual from 'react-fast-compare';
import { handleActions } from 'redux-actions';

import { logoutSuccess } from 'ducks/auth/actions';

import { irPerDateSuccess } from './actions';
import initialState from './states';

export default handleActions(
  {
    [logoutSuccess]() {
      return {
        ...initialState,
      };
    },
    [irPerDateSuccess](
      state,
      {
        payload: { siteID, irPerDate },
      },
    ) {
      if (isEqual(state[siteID], irPerDate)) {
        return state;
      }

      return {
        ...state,
        [siteID]: Object.freeze(irPerDate),
      };
    },
  },
  {
    ...initialState,
  },
);
