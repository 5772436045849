import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  profileFieldResultsFailure,
  profileFieldResultsRequest,
  profileFieldResultsSuccess,
  profileFieldsFailure,
  profileFieldsRequest,
  profileFieldsSuccess,
  updateProfileFieldResultsFailure,
  updateProfileFieldResultsRequest,
  updateProfileFieldResultsSuccess,
  updateProfileFieldsFailure,
  updateProfileFieldsRequest,
  updateProfileFieldsSuccess,
} from './actions';
import { PROFILES } from 'app/api-routes';
import { deserializeFields, serializeFields } from 'new-design/helpers';
import { prepareFieldValueByType } from 'ducks/utils/form-builder';

function* profileFieldsSaga({ payload: { tableName, setupBuilder = false } }) {
  try {
    const { payload } = yield axios.get(`${PROFILES}/${tableName}`);

    yield put(
      profileFieldsSuccess({
        tableName,
        fields: deserializeFields(payload),
        setupBuilder,
      })
    );
  } catch (err) {
    yield put(profileFieldsFailure(err));
  }
}

function* profileFieldResultsSaga({ payload: { tableName, entityID } }) {
  try {
    const { payload } = yield axios.get(`${PROFILES}/${tableName}/${entityID}`);

    yield put(
      profileFieldResultsSuccess({
        tableName,
        entityID,
        fieldResults: payload,
      })
    );
  } catch (err) {
    yield put(profileFieldResultsFailure(err));
  }
}

function* updateProfileFieldsSaga({ payload: { tableName, fields } }) {
  try {
    const { payload } = yield axios.put(`${PROFILES}/${tableName}`, {
      payload: serializeFields(fields, 'profileFieldID'),
    });

    yield put(
      updateProfileFieldsSuccess({
        tableName,
        payload,
        fields: fields,
      })
    );
  } catch (err) {
    yield put(updateProfileFieldsFailure(err));
  }
}

export function* updateProfileFieldResultsSaga({ payload: { tableName, entityID, values } }) {
  try {
    const fieldResults = [];

    const valuesEntries = Object.entries(values);
    for (let index = 0; index < valuesEntries.length; index++) {
      const [profileFieldID, payload] = valuesEntries[index];
      const { value, type, profileFieldResultID } = payload;
      const serializedValue = yield call(prepareFieldValueByType, { type, value, entityID, tableName });

      fieldResults.push({
        profileFieldResultID,
        profileFieldID,
        value: serializedValue,
        entityID,
      });
    }

    yield axios.put(`${PROFILES}/${tableName}/${entityID}`, { payload: fieldResults });

    yield call(profileFieldResultsSaga, { payload: { tableName, entityID }});
    yield put(updateProfileFieldResultsSuccess({ tableName, entityID }));
  } catch (err) {
    yield put(updateProfileFieldResultsFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(profileFieldsRequest, profileFieldsSaga),
    yield takeLatest(profileFieldResultsRequest, profileFieldResultsSaga),
    yield takeLatest(updateProfileFieldsRequest, updateProfileFieldsSaga),
    yield takeLatest(updateProfileFieldResultsRequest, updateProfileFieldResultsSaga),
  ]);
}
