import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const toursRequest = createRequest(constants.tours);
export const toursSuccess = createSuccess(constants.tours);
export const toursFailure = createFailure(constants.tours);

export const tourRequest = createRequest(constants.tour);
export const tourSuccess = createSuccess(constants.tour);
export const tourFailure = createFailure(constants.tour);

export const createTourRequest = createRequest(constants.createTour);
export const createTourSuccess = createSuccess(constants.createTour);
export const createTourFailure = createFailure(constants.createTour);

export const updateTourRequest = createRequest(constants.updateTour);
export const updateTourSuccess = createSuccess(constants.updateTour);
export const updateTourFailure = createFailure(constants.updateTour);

export const deleteTourRequest = createRequest(constants.deleteTour);
export const deleteTourSuccess = createSuccess(constants.deleteTour);
export const deleteTourFailure = createFailure(constants.deleteTour);

export const enableTourRequest = createRequest(constants.enableTour);
export const enableTourSuccess = createSuccess(constants.enableTour);
export const enableTourFailure = createFailure(constants.enableTour);

export const disableTourRequest = createRequest(constants.disableTour);
export const disableTourSuccess = createSuccess(constants.disableTour);
export const disableTourFailure = createFailure(constants.disableTour);

export const requestTours = toursRequest;
export const requestTour = tourRequest;
export const createTour = createTourRequest;
export const updateTour = updateTourRequest;
export const deleteTour = deleteTourRequest;
export const enableTour = enableTourRequest;
export const disableTour = disableTourRequest;
