import {
  ALL,
  ACTIVE_STATUS,
  UNIT_TYPES,
  DATE_TIME_FORMAT_SHORT,
  TABLE_NAMES,
  CHARTS_TYPES,
  BI_TOURS_VIEW_TYPES,
} from 'app/types/constants';
import { TIME } from 'app/types/tableFields';
import moment from 'moment';
import { IncidentReportsPageTabs } from './types';

export default Object.freeze({
  filterOpened: false,
  sort: {
    column: TIME,
    isDesc: true,
  },
  activityFilters: {
    startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
    endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
    sites: '',
    employees: '',
    clientID: undefined,
    eventTypes: [],
    search: '',
  },
  infoBoardFilters: {
    startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
    endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
    sites: '',
    employees: '',
    infoCategory: '',
    search: '',
    statuses: [ALL],
  },
  irFilters: {
    startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
    endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
    sites: '',
    employees: '',
    search: '',
    category: [],
    statuses: [ALL],
    monthYear: {
      month: moment().subtract(1, 'month').month() + 1,
      year: moment().subtract(1, 'month').year(),
    },
    viewType: IncidentReportsPageTabs.TABLE,
  },
  monitoringFilters: {
    sites: '',
    employees: '',
    clientID: undefined,
    unitType: 'all', // mobile | guard | all
    eventTypes: [],
    search: '',
  },
  workHistoryFilters: {
    startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
    endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
    sites: '',
    employees: '',
    clientID: undefined,
    unitType: 'all', // mobile | guard | all
    search: '',
    statuses: [ALL],
  },
  timesheetsFilters: {
    startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
    endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
    unitType: 'all', // mobile | guard | all
    search: '',
  },
  activityMapFilters: {
    search: '',
    city: '',
    province: '',
    unitType: 'all', // mobile | guard | all
  },
  clientDashboardFilters: {
    sites: '',
  },
  adminClientFilters: {
    cities: '',
    state: '',
    statuses: [ACTIVE_STATUS],
    search: '',
  },
  adminEmployeeFilters: {
    client: '',
    site: '',
    emplStates: [],
    position: [],
    statuses: [ACTIVE_STATUS],
    search: '',

    view: 'operational',
  },
  adminContactFilters: {
    clientID: '',
    siteID: '',
    statuses: [ACTIVE_STATUS],
    search: '',
  },
  adminMobileUnitsFilters: {
    statuses: [ACTIVE_STATUS],
    mpCities: [],
    mpStates: [],

    search: '',
  },
  adminSiteFilters: {
    statuses: [ACTIVE_STATUS],
    client: '',
    site: '',
    city: '',
    state: '',
    search: '',
  },
  adminTasksFilters: {
    siteID: undefined,
    statuses: [ALL],
    search: '',
  },
  adminToursFilters: {
    siteID: undefined,
    statuses: [ALL],
    search: '',
  },
  visitorsFilters: {
    siteID: undefined,
    statuses: [ALL],
    search: '',
  },
  performanceFilters: {
    startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
    endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
    eventType: 'scan',
    unitType: UNIT_TYPES.SITES,
  },
  formResultsFilters: {
    formID: undefined,
    startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
    endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
    search: '',
    formFieldID: undefined,
    userID: undefined,
    statuses: [ALL],
  },
  systemLogFilters: {
    startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
    endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
    tableName: ALL,
    siteID: undefined,
    userID: undefined,
  },
  visitorLogFilters: {
    startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
    endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
    siteID: undefined,
    visitorID: undefined,
    search: '',
    fieldType: undefined,
    statuses: [ALL],
  },
  formsFilters: {
    formID: undefined,
    search: '',
    statuses: [ALL],
  },
  biFilters: {
    events: {
      date: 'this_month',
      eventType: 'end',
      siteID: undefined,
    },
    entities: {
      date: 'this_month',
      entityName: 'sites',
    },
    viewType: CHARTS_TYPES.ENTITY,
  },
  biToursFilters: {
    viewType: BI_TOURS_VIEW_TYPES.MONTHLY,
    monthYear: {
      month: moment().month() + 1,
      year: moment().year(),
    },
    siteID: undefined,
  },

  selectedTab: TABLE_NAMES.EMPLOYEES,

  // Fields for activity map
  filterSelected: null,
  //
});
