export default {
  palette: {
    primary: {
      light: '#3f51b5',
      main: '#022CE6',
      dark: '#3f51b5',
      contrastText: '#fff',
      orange: '#F78F1E',
      white: '#FFFFFF',
    },
    secondary: {
      light: '#F7F7F7',
      semiLight: '#E5E5E5',
      main: '#8A8A8A',
      dark: '#FFA500',
      contrastText: '#FFA500',
      chip: '#E0E0E0',
    },
    status: {
      success: '#42BD5D',
      error: '#EC544A',
    },
    green: {
      bg: 'rgba(66, 189, 93, 0.1)',
      color: '#42BD5D',
    },
    red: {
      bg: 'rgba(236, 84, 74, 0.1)',
      color: '#EC544A',
    },
    grey: {
      bg: 'rgba(167, 167, 167, 0.1)',
      color: '#BBBBBB',
    },
    blue: {
      bg: 'rgba(0, 128, 255, 0.05)',
      color: '#0080FF',
    },
    alarm: {
      bg: 'rgba(236, 84, 74, 0.1)',
      color: '#FF4C4C',
    },
    darkBlue: {
      bg: 'rgba(0, 0, 255, 0.05)',
      color: '#0000FF',
    },
    violet: {
      bg: 'rgba(117, 74, 236, 0.05)',
      color: '#754AEC',
    },
    aquamarine: {
      bg: 'rgba(30, 0, 255, 0.05)',
      color: '#1ECCB7',
    },
    cyan: {
      bg: 'rgba(20, 237, 251, 0.05)',
      color: '#14EDFB',
    },
    pink: {
      bg: 'rgba(245, 69, 164, 0.05)',
      color: '#F545A4',
    },

    header: 'white',
    alternateTextColor: 'red',
    text: {
      primary: '#222',
      secondary: '#BBBBBB',
    },
  },
  status: {
    danger: 'orange',
  },
  shape: {
    borderRadius: 8,
  },

  typography: {
    button: {
      fontWeight: 400,
      textTransform: 'capitalize',
    },
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },

  overrides: {
    MuiButtonBase: {
      root: {
        '& svg': {
          width: 24,
          height: 24,
          '@media (min-width: 1440px)': {
            width: 20,
            height: 20,
            minHeight: 20,
            minWidth: 20,
          },
        },
        '&.Mui-disabled': {
          background: 'rgba(0,0,0,0.05)',
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: 16,
        lineHeight: '24px',
        borderRadius: 8,
        '&.default-submit-action': {
          minWidth: 150,
          height: 44,
        },
      },
      label: {
        fontWeight: 600,
      },
      outlined: {
        padding: '12px 15px',
      },
    },
    MuiIconButton: {
      root: {
        '&.Mui-disabled path': {
          // stroke: '#BBB',
        },
        '&.in-row': {
          marginLeft: 8,
          '&:first-child': {
            marginLeft: 0,
          },
        },
      },
    },
    IconButton: {
      outlined: {
        '& svg.stroke': {
          '& path': {
            stroke: '#8A8A8A !important',
          },
        },
        '& svg.fill': {
          '& path': {
            fill: '#8A8A8A !important',
          },
        },
        '&:hover': {
          '& svg.fill path': {
            fill: `#022CE6 !important`,
          },
          '& svg.stroke path': {
            stroke: `#022CE6 !important`,
          },
        },
      },
      contained: {
        '&.add-button': {
          width: 'auto',
        },
        '&:hover': {
          '& svg.fill path': {
            fill: `#022CE6 !important`,
          },
          '& svg.stroke path': {
            stroke: `#022CE6 !important`,
          },
        },
        '& svg.stroke': {
          '& path': {
            stroke: `#fff !important`,
          },
        },
        '& svg.fill': {
          '& path': {
            fill: `#fff !important`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
        lineHeight: '18px',
        '& input::placeholder': {
          fontSize: 16,
          lineHeight: '18px',
          '@media (max-width: 1440px)': {
            fontSize: 14,
            lineHeight: '16px',
          },
        },
        '@media (max-width: 1440px)': {
          fontSize: 14,
          lineHeight: '16px',
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        fontSize: 16,
        lineHeight: '18px',
        '& input::placeholder': {
          fontSize: 16,
          lineHeight: '18px',
          '@media (max-width: 1440px)': {
            fontSize: 14,
            lineHeight: '16px',
          },
        },
        '@media (max-width: 1440px)': {
          fontSize: 14,
          lineHeight: '16px',
        },
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 16,
      },
      root: {
        '&.weight': {
          '&-regular': {
            fontWeight: 400,
          },
          '&-medium': {
            fontWeight: 500,
          },
          '&-semibold': {
            fontWeight: 600,
          },
          '&-bold': {
            fontWeight: 700,
          },
        },
        '&.size': {
          '&-xs': {
            fontSize: 10,
            lineHeight: '15px',
          },
          '&-s': {
            fontSize: 12,
            lineHeight: '18px',
          },
          '&-m': {
            fontSize: 16,
            lineHeight: '24px',
          },
        },
      },
    },
  },
};
