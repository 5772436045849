import { useField } from 'formik';
import React, { useMemo, useCallback } from 'react';

export const CompanyLogo = () => {
  const [{ value }] = useField('company');
  const logoUrl = useMemo(() => `${process.env.PUBLIC_URL}/logos/${value.toLowerCase()}.png`, [value]);
  const logoErrorHandler = useCallback((e) => {
    e.target.onerror = null;
    e.target.src = `${process.env.PUBLIC_URL}/logos/default.png`;
  }, []);

  return (
    <img src={logoUrl} onError={logoErrorHandler} alt="logo" height={65} />
  );
};
