import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const shiftInfoRequest = createRequest(constants.shiftInfo);
export const shiftInfoSuccess = createSuccess(constants.shiftInfo);
export const shiftInfoFailure = createFailure(constants.shiftInfo);

export const shiftStatsRequest = createRequest(constants.shiftStats);
export const shiftStatsSuccess = createSuccess(constants.shiftStats);
export const shiftStatsFailure = createFailure(constants.shiftStats);

export const shiftStatsBySiteIDRequest = createRequest(constants.shiftStatsBySiteID);
export const shiftStatsBySiteIDSuccess = createSuccess(constants.shiftStatsBySiteID);
export const shiftStatsBySiteIDFailure = createFailure(constants.shiftStatsBySiteID);

export const checkpointInfoNewRequest = createRequest(`${constants.checkpointInfo}_NEW_VERSION`);
export const checkpointInfoNewSuccess = createSuccess(`${constants.checkpointInfo}_NEW_VERSION`);
export const checkpointInfoNewFailure = createFailure(`${constants.checkpointInfo}_NEW_VERSION`);

export const getShiftReportLinkRequest = createRequest(`${constants.checkpointInfo}_REPORT_LINK`);
export const getShiftReportLinkSuccess = createSuccess(`${constants.checkpointInfo}_REPORT_LINK`);
export const getShiftReportLinkFailure = createFailure(`${constants.checkpointInfo}_REPORT_LINK`);

export const requestShiftInfo = shiftInfoRequest;
export const requestShiftStats = shiftStatsRequest;
export const requestShiftStatsBySiteID = shiftStatsBySiteIDRequest;
export const checkpointInfoNew = checkpointInfoNewRequest;
export const getShiftReportLink = getShiftReportLinkRequest;
