import { handleActions } from 'redux-actions';
import { logoutSuccess } from 'ducks/auth/actions';

import {
  activeIRsGroupedByTypeSuccess,
  irsGroupedByDateAndTypeSuccess,
  irsGroupedByDateSuccess,
  setLoadingState,
} from './actions';

import initialState from './states';

export default handleActions(
  {
    [activeIRsGroupedByTypeSuccess](state, { payload }) {
      return {
        ...state,
        activeIRsGroupedByType: payload,
      };
    },
    [irsGroupedByDateAndTypeSuccess](state, { payload }) {
      return {
        ...state,
        irsGroupedByDateAndType: payload,
      };
    },
    [irsGroupedByDateSuccess](state, { payload }) {
      return {
        ...state,
        irsGroupedByDate: payload,
      };
    },
    [setLoadingState](state, { payload }) {
      return {
        ...state,
        loading: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
