import { createAction, createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const addField = createAction(`${constants.forms}_FIELD_ADD`);
export const updateFormFields = createAction(`${constants.forms}_FIELDS_UPDATE`);
export const updateFormInfo = createAction(`${constants.forms}_INFO_UPDATE`);
export const setForm = createAction(`${constants.forms}_SET_FORM`);
export const resetForm = createAction(`${constants.forms}_RESET_FORM`);
export const updateConfig = createAction(`${constants.forms}_UPDATE_CONFIG`);

export const formsRequest = createRequest(constants.forms);
export const formsSuccess = createSuccess(constants.forms);
export const formsFailure = createFailure(constants.forms);

export const requestForms = formsRequest;

export const formInfoRequest = createRequest(constants.formInfo);
export const formInfoSuccess = createSuccess(constants.formInfo);
export const formInfoFailure = createFailure(constants.formInfo);

export const requestFormInfo = formInfoRequest;

export const createFormRequest = createRequest(constants.createForm);
export const createFormSuccess = createSuccess(constants.createForm);
export const createFormFailure = createFailure(constants.createForm);

export const createForm = createFormRequest;

export const updateFormRequest = createRequest(constants.updateForm);
export const updateFormSuccess = createSuccess(constants.updateForm);
export const updateFormFailure = createFailure(constants.updateForm);

export const updateForm = updateFormRequest;

export const updateFormSettingsRequest = createRequest(constants.updateFormSettings);
export const updateFormSettingsSuccess = createSuccess(constants.updateFormSettings);
export const updateFormSettingsFailure = createFailure(constants.updateFormSettings);

export const updateFormSettings = updateFormSettingsRequest;

export const updateFormStatusRequest = createRequest(constants.updateFormStatus);
export const updateFormStatusSuccess = createSuccess(constants.updateFormStatus);
export const updateFormStatusFailure = createFailure(constants.updateFormStatus);

export const updateFormStatus = updateFormStatusRequest;

export const deleteFormRequest = createRequest(constants.deleteForm);
export const deleteFormSuccess = createSuccess(constants.deleteForm);
export const deleteFormFailure = createFailure(constants.deleteForm);

export const deleteForm = deleteFormRequest;

export const fillFormRequest = createRequest(constants.fillForm);
export const fillFormSuccess = createSuccess(constants.fillForm);
export const fillFormFailure = createFailure(constants.fillForm);

export const fillForm = fillFormRequest;

export const formFieldsRequest = createRequest(constants.formFields);
export const formFieldsSuccess = createSuccess(constants.formFields);
export const formFieldsFailure = createFailure(constants.formFields);

export const requestFormFields = formFieldsRequest;

export const formEmailSettingsRequest = createRequest(constants.formEmailSettings);
export const formEmailSettingsSuccess = createSuccess(constants.formEmailSettings);
export const formEmailSettingsFailure = createFailure(constants.formEmailSettings);

export const requestFormEmailSettings = formEmailSettingsRequest;

export const updateFormEmailSettingsRequest = createRequest(constants.updateFormEmailSettings);
export const updateFormEmailSettingsSuccess = createSuccess(constants.updateFormEmailSettings);
export const updateFormEmailSettingsFailure = createFailure(constants.updateFormEmailSettings);

export const updateFormEmailSettings = updateFormEmailSettingsRequest;
