import preparedReducer from './reducer';
import { requestRoles } from './actions';

export const reducer = preparedReducer;

export const SLICE_NAME = 'roles';

export const selectors = state => state[SLICE_NAME];

export const getRoles = (userType) => (state) => state[SLICE_NAME][userType];

export const actions = {
  requestRoles,
};
