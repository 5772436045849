import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Field } from 'formik';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { DateTimePicker } from 'new-design/common/ui/DateTimePickerNew';
import { useField } from 'formik';
import moment from 'moment';
import { isNil } from 'lodash';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'app/types/constants';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      inputRoot: {
        marginTop: ({ disabledError }) => (disabledError ? 0 : theme.spacing(1.25)),
        '& .MuiFormControl-root': {
          width: '100%',
        },
      },
      root: {
        marginBottom: ({ disabledError }) => (disabledError ? 0 : 24),
        '& .MuiFormControl-root': {
          width: '100%',
        },
      },
    }),
  {
    name: 'FormDateField',
  }
);

export const FormDateField = ({
  label,
  name,
  className,
  readOnly,
  infinityEndDate,
  dateOnly,
  emptyInput,
  disabledError,
  emptyPlaceholder,
  hideRefresh,
}) => {
  const classes = useStyles({ disabledError });
  const [{ value: formValue }, , helpers] = useField(name);

  useEffect(() => {
    if (isNil(formValue)) {
      helpers.setValue(emptyInput ? null : moment().format(dateOnly ? DATE_FORMAT : DATE_TIME_FORMAT));
    }
  }, [readOnly, formValue]);

  return (
    <Box className={clsx(classes.root, className)}>
      <Field name={name} className={classes.field}>
        {({ meta }) => (
          <Box
            className={clsx({
              [classes.inputRoot]: label,
            })}
          >
            <DateTimePicker
              onChange={(value) => {
                if (value) {
                  helpers.setValue(moment(value).format(dateOnly ? DATE_FORMAT : DATE_TIME_FORMAT));
                  helpers.setTouched();
                } else {
                  helpers.setValue(undefined);
                }
              }}
              defaultValue={formValue}
              readOnly={readOnly}
              infinityEndDate={infinityEndDate}
              dateOnly={!!dateOnly}
              emptyInput={!!emptyInput}
              label={label}
              emptyPlaceholder={emptyPlaceholder}
              hideRefresh={hideRefresh}
            />
            {!disabledError && !!meta.error && meta.touched && (
              <Box mt={1}>
                <Typography color="error">{meta.error}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Field>
    </Box>
  );
};
