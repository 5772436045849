import { all, call, put, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { CLIENTS } from 'app/api-routes';

import {
  clientsRequest,
  clientsSuccess,
  clientsFailure,

  clientRequest,
  clientSuccess,
  clientFailure,

  updateClientStatusRequest,
  updateClientStatusSuccess,
  updateClientStatusFailure,

  createClientRequest,
  createClientFailure,

  updateClientRequest,
  updateClientFailure,

  deleteClientRequest,
  deleteClientFailure,
  updateClientSuccess,
  deleteClientSuccess,
} from './actions';

function* clientsSaga() {
  try {
    const { payload, warnings } = yield axios.get(CLIENTS);

    yield put(clientsSuccess(payload, { warnings }));
  } catch (err) {
    yield put(clientsFailure(err));
  }
}

function* getClientSaga({ payload: clientID }) {
  try {
    const { payload, warnings } = yield axios.get(`${CLIENTS}/${clientID}`);

    yield put(clientSuccess(payload, { warnings }));
  } catch (err) {
    yield put(clientFailure(err));
  }
}

function* updateClientStatusSaga({ payload: { clientID, status } }) {
  try {
    yield axios.put(`${CLIENTS}/${clientID}/status/${status}`);

    yield put(updateClientStatusSuccess({ status, clientID }));
  } catch (err) {
    yield put(updateClientStatusFailure(err));
  }
}

function* createClientSaga({ payload: { data, onSuccess, onFailure } }) {
  try {
    const { payload: id } = yield axios.post(CLIENTS, {
      payload: data,
    });
    
    onSuccess?.(id);

  } catch (err) {
    onFailure?.();
    yield put(createClientFailure(err));
  }
}

function* updateClientSaga({ payload: { clientID, data, onSuccess, onFailure } }) {
  try {
    yield axios.put(`${CLIENTS}/${clientID}`, {
      payload: data,
    });

    onSuccess?.();
    yield call(getClientSaga, { payload: clientID });
    yield put(updateClientSuccess({ clientID, data }));
  } catch (err) {
    onFailure?.();
    yield put(updateClientFailure(err));
  }
}

function* deleteClientSaga({ payload: { clientID, callback } }) {
  try {
    yield axios.delete(`${CLIENTS}/${clientID}`);
    callback?.();
    yield put(deleteClientSuccess(clientID));
  } catch (err) {
    yield put(deleteClientFailure(err));
  }
}

export default function*() {
  yield all([
    yield takeLatest(clientsRequest, clientsSaga),
    yield takeLatest(clientRequest, getClientSaga),
    yield takeLatest(updateClientStatusRequest, updateClientStatusSaga),
    yield takeLatest(createClientRequest, createClientSaga),
    yield takeLatest(updateClientRequest, updateClientSaga),
    yield takeLatest(deleteClientRequest, deleteClientSaga),
  ]);
}
