import {createAction as _createAction} from 'redux-actions';

export function mapDispatchToProps(obj) {
  return dispatch =>
    Object.keys(obj).reduce(
      (result, key) => {
        result[key] = (...args) => dispatch(obj[key](...args));
        return result;
      },
      {dispatch: (...args) => dispatch(...args)},
    );
}

export const createAction = type => _createAction(type, payload => payload, (payload, meta) => meta);
