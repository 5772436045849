import { createFailure, createRequest, createSuccess } from 'ducks/helpers';
import constants from './constants';

export const visitorLogEventsRequest = createRequest(constants.visitorLogEvents);
export const visitorLogEventsSuccess = createSuccess(constants.visitorLogEvents);
export const visitorLogEventsFailure = createFailure(constants.visitorLogEvents);

export const visitorLogEventRequest = createRequest(constants.visitorLogEvent);
export const visitorLogEventSuccess = createSuccess(constants.visitorLogEvent);
export const visitorLogEventFailure = createFailure(constants.visitorLogEvent);

export const createVisitRequest = createRequest(constants.createVisit);
export const createVisitSuccess = createSuccess(constants.createVisit);
export const createVisitFailure = createFailure(constants.createVisit);

export const visitorLogSitesRequest = createRequest(constants.visitorLogSites);
export const visitorLogSitesSuccess = createSuccess(constants.visitorLogSites);
export const visitorLogSitesFailure = createFailure(constants.visitorLogSites);

export const visitorLogVisitorsRequest = createRequest(constants.visitorLogVisitors);
export const visitorLogVisitorsSuccess = createSuccess(constants.visitorLogVisitors);
export const visitorLogVisitorsFailure = createFailure(constants.visitorLogVisitors);

export const requestVisitorLogEvents = visitorLogEventsRequest;
export const requestVisitorLogEvent = visitorLogEventRequest;
export const createVisit = createVisitRequest;
export const requestSitesForVisit = visitorLogSitesRequest;
export const requestVisitorsForVisit = visitorLogVisitorsRequest;
