import { styled } from '@material-ui/styles';
import { Switch as MUSwith } from '@material-ui/core';

const Switch = styled(MUSwith)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#42BD5D',
    '&:hover': {
      backgroundColor: `rgba(66, 189, 93, 0.2)`,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#42BD5D',
  },
}));

export { Switch };
