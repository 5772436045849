import {
  requestClients,
  requestClient,
  deselectClient,
  updateClientStatus,
  createClient,
  updateClient,
  deleteClient,
} from './actions';
import preparedReducer from './reducer';

export const reducer = preparedReducer;

export const SLICE_NAME = 'clients';

export const selectors = state => state[SLICE_NAME];

export const actions = {
  requestClients,
  requestClient,
  deselectClient,
  updateClientStatus,
  createClient,
  updateClient,
  deleteClient,
};
