import constants from './constants';
import { createAction, createFailure, createRequest, createSuccess } from 'ducks/helpers';

export const clientsRequest = createRequest(constants.clients);
export const clientsSuccess = createSuccess(constants.clients);
export const clientsFailure = createFailure(constants.clients);

export const clientRequest = createRequest(constants.getClient);
export const clientSuccess = createSuccess(constants.getClient);
export const clientFailure = createFailure(constants.getClient);

export const updateClientStatusRequest = createRequest(`${constants.getClient}_STATUS`);
export const updateClientStatusSuccess = createSuccess(`${constants.getClient}_STATUS`);
export const updateClientStatusFailure = createFailure(`${constants.getClient}_STATUS`);

export const createClientRequest = createRequest(`${constants.getClient}_CREATE`);
export const createClientSuccess = createSuccess(`${constants.getClient}_CREATE`);
export const createClientFailure = createFailure(`${constants.getClient}_CREATE`);

export const updateClientRequest = createRequest(`${constants.getClient}_UPDATE`);
export const updateClientSuccess = createSuccess(`${constants.getClient}_UPDATE`);
export const updateClientFailure = createFailure(`${constants.getClient}_UPDATE`);

export const deleteClientRequest = createRequest(`${constants.getClient}_DELETE`);
export const deleteClientSuccess = createSuccess(`${constants.getClient}_DELETE`);
export const deleteClientFailure = createFailure(`${constants.getClient}_DELETE`);

export const deselectClient = createAction(`${constants.getClient}_DESELECT`)

export const requestClients = clientsRequest;
export const requestClient = clientRequest;
export const updateClientStatus = updateClientStatusRequest;
export const createClient = createClientRequest;
export const updateClient = updateClientRequest;
export const deleteClient = deleteClientRequest;
