import React, { useMemo } from 'react';
import { IconButton } from 'new-design/common/ui';
import { Badge, Tooltip } from '@material-ui/core';
import { BellIcon } from 'assets/icons';
import * as notifications from 'ducks/notifications';
import { useSelector } from 'react-redux';
import { getSiteWeight, getColor } from '../GoogleMap/helpers';
import _ from 'lodash';
import { useStyles } from './styles';
import IntlMessages from 'util/IntlMessages';
import { useSpring, animated } from '@react-spring/web';

export const BellComponent = ({ isOpened, handleModalOpen }) => {
  const leftWaveSpring = useSpring({
    loop: true,
    from: { opacity: 1, scale: 0.5, x: 0 },
    to: { opacity: 0, scale: 1, x: -10 },
  });
  const rightWaveSpring = useSpring({
    loop: true,
    from: { opacity: 1, scale: 0.5, x: 0 },
    to: { opacity: 0, scale: 1, x: 10 },
  });

  const notificationsItems = useSelector(notifications.selectors);

  const notificationType = useMemo(() => {
    let selectedType = null;
    notificationsItems.forEach((item) => {
      if (getSiteWeight(item.type) > getSiteWeight(selectedType)) {
        selectedType = item.type;
      }
    });

    return selectedType;
  }, [notificationsItems]);

  const classes = useStyles({
    pointColor: getColor(notificationType),
    isOpened,
    isEmpty: !!notificationType,
  });

  return (
    <Badge
      variant="dot"
      invisible={_.isNil(notificationType)}
      overlap="circular"
      className={classes.badge}
      onClick={handleModalOpen}
    >
      <IconButton className={classes.iconRoot}>
        <Tooltip title={<IntlMessages id="notifications" />}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {notificationType && (
              <>
                <animated.div
                  className={classes.leftWaveBig}
                  style={leftWaveSpring}
                />
                <animated.div
                  className={classes.leftWaveSmall}
                  style={leftWaveSpring}
                />
              </>
            )}

            <BellIcon />

            {notificationType && (
              <>
                <animated.div
                  className={classes.rightWaveSmall}
                  style={rightWaveSpring}
                />
                <animated.div
                  className={classes.rightWaveBig}
                  style={rightWaveSpring}
                />
              </>
            )}
          </div>
        </Tooltip>
      </IconButton>
    </Badge>
  );
};
