import { FORM_TABS } from './types';

export default Object.freeze({
  info: {},
  form: {
    creatorFields: {
      active: [],
      deleted: [],
    },
    reviewerFields: {
      active: [],
      deleted: [],
    },
  },
  config: {
    tab: FORM_TABS.CREATOR,
  },
  list: [],
  fields: {},
  emailSettings: {},
});
