import constants from './constants';
import { createFailure, createRequest, createSuccess } from 'ducks/helpers';

export const mobilePatrolEmailSettingsRequest = createRequest(constants.MOBILE_PATROL_EMAIL_SETTINGS);
export const mobilePatrolEmailSettingsSuccess = createSuccess(constants.MOBILE_PATROL_EMAIL_SETTINGS);
export const mobilePatrolEmailSettingsFailure = createFailure(constants.MOBILE_PATROL_EMAIL_SETTINGS);

export const enableMobilePatrolEmailSettingRequest = createRequest(constants.ENABLE_MOBILE_PATROL_EMAIL_SETTING);
export const enableMobilePatrolEmailSettingSuccess = createSuccess(constants.ENABLE_MOBILE_PATROL_EMAIL_SETTING);
export const enableMobilePatrolEmailSettingFailure = createFailure(constants.ENABLE_MOBILE_PATROL_EMAIL_SETTING);

export const disableMobilePatrolEmailSettingRequest = createRequest(constants.DISABLE_MOBILE_PATROL_EMAIL_SETTING);
export const disableMobilePatrolEmailSettingSuccess = createSuccess(constants.DISABLE_MOBILE_PATROL_EMAIL_SETTING);
export const disableMobilePatrolEmailSettingFailure = createFailure(constants.DISABLE_MOBILE_PATROL_EMAIL_SETTING);

export const mobilePatrolEmailRecipientsRequest = createRequest(constants.MOBILE_PATROL_EMAIL_RECIPIENTS);
export const mobilePatrolEmailRecipientsSuccess = createSuccess(constants.MOBILE_PATROL_EMAIL_RECIPIENTS);
export const mobilePatrolEmailRecipientsFailure = createFailure(constants.MOBILE_PATROL_EMAIL_RECIPIENTS);

export const updateMobilePatrolEmailRecipientsRequest = createRequest(constants.UPDATE_MOBILE_PATROL_EMAIL_RECEPIENTS);
export const updateMobilePatrolEmailRecipientsSuccess = createSuccess(constants.UPDATE_MOBILE_PATROL_EMAIL_RECEPIENTS);
export const updateMobilePatrolEmailRecipientsFailure = createFailure(constants.UPDATE_MOBILE_PATROL_EMAIL_RECEPIENTS);

export const requestMobilePatrolEmailSettings = mobilePatrolEmailSettingsRequest;
export const enableMobilePatrolEmailSetting = enableMobilePatrolEmailSettingRequest;
export const disableMobilePatrolEmailSetting = disableMobilePatrolEmailSettingRequest;
export const requestMobilePatrolEmailRecipients = mobilePatrolEmailRecipientsRequest;
export const updateMobilePatrolEmailRecipients = updateMobilePatrolEmailRecipientsRequest;
